<template>
  <div>
    <h3 class="match-title">{{ $lang.campaign_CreateMatches }}</h3>
    <p>
      {{ $lang.campaign_CreateMatchesStartupInstructions }}
    </p>
    <p>
      {{ $lang.campaign_CreateMatchesInstructions }}
    </p>
    <p class="match-stats">
      {{ $lang.campaign_AmbassadorsMatched }}: {{ matchedAmbassadorsCount }}/{{
        allAmbassadors.length
      }}<br />
      {{ $lang.campaign_RecipientsMatched }}: {{ matchedRecipientsCount }}/{{
        importedRecipientCount
      }}
    </p>

    <div class="match-buttons">
      <v-tooltip bottom :disabled="!allMatched">
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on">
            <v-btn
              block
              text
              color="primary"
              class="match-button grey lighten-3"
              :disabled="allMatched"
              @click="addMatch()"
              v-bind="attrs"
              v-on="on">
              <v-icon left>$plus</v-icon>
              {{ $lang.campaign_MatchManually }}
            </v-btn>
          </div>
        </template>
        <span>{{ $lang.campaign_AllMatched }}</span>
      </v-tooltip>
      <v-btn
        block
        text
        color="primary"
        class="match-button grey lighten-3"
        :disabled="matchedRecipientsCount >= importedRecipientCount"
        @click="autoMatch">
        <v-icon left>$shimmer</v-icon>
        {{ $lang.campaign_AutoMatch }}
      </v-btn>
    </div>

    <OutreachMatchTable
      v-if="matches.length"
      v-model="matches"
      :headers="data.campaign.dynamicColumns"
      @openUserSelect="openUserSelect" />

    <p-modal
      :title="$lang.campaign_AutoMatchTitle"
      size="sm"
      v-model="showAutoMatchModal"
      :saveButtonText="$lang.general_Continue"
      @cancel="showAutoMatchModal = false"
      @save="performAutoMatch">
      {{ $lang.campaign_AutoMatchDescription }}

      <v-checkbox
        v-model="includeVacationMode"
        hide-details
        :label="$lang.campaign_IncludeVacationMode"></v-checkbox>
      <v-checkbox
        v-model="includeInactive"
        hide-details
        :label="$lang.campaign_IncludeInactive"></v-checkbox>
    </p-modal>

    <MultiUserSelectModal
      v-model="showUserSelectModal"
      :isAmbassador="isSelectingAmbassador"
      :headers="userSelectModalHeaders"
      :users="userSelectModalUsers"
      :matchedUsers="matchedUsers"
      :clickedUsers="clickedUsers"
      :isSingleSelect="isSingleSelectModal"
      :isAutoMatch="isAutoMatch"
      @selected="selectUsers"
      @addManually="addManuallyDisplay" />
    <MultiUserSelectAddManuallyModal
      v-model="showAddManuallyModal"
      :fields="addManuallyFields"
      @back="addManuallyBack"
      @add="addManuallyAdd" />
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import MultiUserSelectAddManuallyModal from "@/components/dashboard/outreach/outreachComponents/MultiUserSelectAddManuallyModal.vue";
  import MultiUserSelectModal from "@/components/dashboard/outreach/outreachComponents/MultiUserSelectModal.vue";
  import OutreachMatchTable from "@/components/dashboard/outreach/outreachComponents/OutreachMatchTable.vue";
  import { toFullName } from "@/shared/utils";

  export default {
    name: "CampaignMatchStep",
    components: {
      MultiUserSelectAddManuallyModal,
      MultiUserSelectModal,
      OutreachMatchTable,
    },
    props: {
      data: {
        type: Object,
        required: true,
      },
      allAmbassadors: {
        type: Array,
        default: () => {
          return [];
        },
      },
      csvReplaced: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        importedInvitees: [],
        matches: [],
        showAutoMatchModal: false,
        includeVacationMode: false,
        includeInactive: false,
        isSelectingAmbassador: false,
        showUserSelectModal: false,
        isSingleSelectModal: true,
        clickedUsers: [],
        selectedMatch: null,
        isAutoMatch: false,
        showAddManuallyModal: false,
        isFirstUpdate: true,
      };
    },

    computed: {
      importedRecipientCount() {
        return this.importedInvitees.filter((invite) => invite.invitee).length;
      },

      userSelectModalUsers() {
        return this.isSelectingAmbassador
          ? this.allAmbassadors
          : this.allProspects;
      },

      userSelectModalHeaders() {
        return this.isSelectingAmbassador
          ? [
              {
                text: this.$lang.campaignOutreach_SelectUserModalNameColumn,
                value: "name",
              },
              {
                text: this.$lang
                  .campaignOutreach_SelectUserModalConversationsColumn,
                value: "convoCount",
              },
              {
                text: this.$lang
                  .campaignOutreach_SelectUserModalProfileVisibleColumn,
                value: "isActive",
              },
              {
                text: this.$lang.campaignOutreach_SelectUserModalStatusColumn,
                value: "status",
              },
            ]
          : [
              {
                text: this.$lang
                  .campaignOutreach_SelectUserModalRecipientNameColumn,
                value: "recipientName",
              },
              ...(this.data.campaign.dynamicColumns ?? []).map((column) => ({
                text: column.name,
                value: column.name,
              })),
            ];
      },

      addManuallyFields() {
        return (this.data.campaign.dynamicColumns ?? []).map(
          (column) => column.name
        );
      },

      matchedAmbassadors() {
        return this.allAmbassadors.filter((ambassador) =>
          this.matches.find(
            (match) =>
              match.ambassador && match.ambassador._id === ambassador._id
          )
        );
      },

      matchedRecipients() {
        return this.matches
          .map((match) => match.invites.map(this.getInviteeDynamicColumns))
          .flat()
          .filter(
            (enrichedInvite) =>
              !this.clickedUsers.some(
                (user) =>
                  JSON.stringify(user) === JSON.stringify(enrichedInvite)
              )
          );
      },

      matchedUsers() {
        return this.isSelectingAmbassador
          ? this.matchedAmbassadors
          : this.matchedRecipients;
      },

      allProspects() {
        return this.importedInvitees
          .filter((invite) => invite.invitee)
          .map(this.getInviteeDynamicColumns);
      },
      matchedAmbassadorsCount() {
        return this.matches.filter((match) => match.ambassador).length;
      },
      matchedRecipientsCount() {
        return this.matches.map((match) => match.invites).flat().length;
      },
      allMatched() {
        return (
          this.matchedAmbassadorsCount >= this.allAmbassadors.length ||
          this.matchedRecipientsCount >= this.importedRecipientCount
        );
      },

      ...mapGetters({ branding: "getBranding" }),
    },

    watch: {
      csvReplaced: {
        immediate: true,
        handler() {
          const map = new Map();
          this.matches = [];

          this.importedInvitees = this.data.pendingInvitations.filter(
            (invite) => invite.invitee
          );
          // group invites by ambassador/inviterID
          for (const invite of this.data.pendingInvitations) {
            if (invite.inviterID) {
              const match = map.get(invite.inviterID);

              if (match) {
                if (invite.invitee) {
                  // Don't push matches that don't have an invitee
                  match.push(invite);
                }
              } else {
                // If ambassador is set but has no invitee, don't count it as a match
                map.set(invite.inviterID, invite.invitee ? [invite] : []);
              }
            }
          }

          // link ambassadors
          for (const [key, val] of map.entries()) {
            const ambassador = this.allAmbassadors.find(
              (user) => user._id === key
            );

            const hasMatches = val?.length && val.some((invitee) => !!invitee);

            this.matches.push({
              selected: false,
              ambassador,
              invites: val,
              hasMatches,
            });
          }
          if (this.importedInvitees.some((invitee) => invitee.inviterID)) {
            this.$emit("complete", true);
          }
        },
      },
      matches: {
        immediate: true,
        deep: true,
        handler(newValue) {
          const newInvitations = newValue
            .map((match) =>
              match.invites.map((invite) => {
                const oldInvite = this.data.pendingInvitations.find(
                  ({ _id, invitee }) =>
                    _id && invitee?.email === invite.invitee.email
                );
                const { adminID, inviteType, _id } = this.data.campaign;
                const newInvite = {
                  adminID,
                  campaignID: _id,
                  dynamicColumns: invite.dynamicColumns,
                  inviteType,
                  invitee: invite.invitee,
                  inviterID: match.ambassador._id,
                  notes: invite.notes,
                  studentInfo: invite.studentInfo,
                };
                newInvite._id = oldInvite?._id;
                return newInvite;
              })
            )
            .flat();
          this.$emit("update", {
            pendingInvitations: newInvitations,
            isFirstUpdate: this.isFirstUpdate,
          });
          this.isFirstUpdate = false;
          if (this.importedInvitees.some((invitee) => invitee.inviterID)) {
            this.$emit("complete", true);
          }
        },
      },

      "data.pendingInvitations": {
        immediate: true,
        deep: true,
        handler(invites) {
          let finalInvitations = [];

          for (let invite of this.importedInvitees) {
            let foundInvite = false;
            for (const pendingInvitation of invites) {
              if (invite.invitee?.email === pendingInvitation.invitee?.email) {
                invite.inviterID = pendingInvitation.inviterID;
                foundInvite = true;
              }
            }

            if (!foundInvite) {
              invite.inviterID = null;
            }

            finalInvitations.push(invite);
          }

          this.importedInvitees = finalInvitations;
        },
      },

      importedInvitees: {
        deep: true,
        handler(val) {
          this.$emit("update", {
            importedInvitees: val,
            isFirstUpdate: this.isFirstUpdate,
          });
          this.isFirstUpdate = false;
        },
      },
    },

    methods: {
      addMatch(
        match = {
          ambassador: null,
          selected: false,
          hasMatches: false,
          invites: [],
        }
      ) {
        this.matches.push(match);
      },
      autoMatch() {
        //Pop up modal - if no ambassadors, ask which ambassadors from org they want. If ambassadors, let them check each included user
        //Get ambassadors based on filters
        //Match with prospects
        if (this.matches.length) {
          // Ambassadors exist, give them the ambassador selection modal
          this.isAutoMatch = true;
          this.isSelectingAmbassador = true;
          this.isSingleSelectModal = false;
          this.showUserSelectModal = true;
        } else {
          this.showAutoMatchModal = true;
        }
      },

      async performAutoMatch(users) {
        this.isAutoMatch = false;
        this.showUserSelectModal = false;
        this.isSelectingAmbassador = false;

        let invitations = [];
        let pendingInvitations = [...this.importedInvitees];

        if (pendingInvitations.length) {
          const ambassadors = users
            ? users
            : this.allAmbassadors.filter(
                ({ isOnVacation, isActive }) =>
                  (this.includeVacationMode || !isOnVacation) &&
                  (this.includeInactive || isActive)
              );

          if (ambassadors?.length) {
            let index = 0;
            for (let pendingInvitation of pendingInvitations) {
              if (pendingInvitation.inviterID) {
                if (pendingInvitation.invitee) {
                  // Invite already has an assigned ambassador, auto-match doesn't overwrite
                  invitations.push(pendingInvitation);
                }
                continue;
              }

              pendingInvitation.inviterID = ambassadors[index]._id;
              // find a match with ambassador
              const foundMatch = this.matches.find(({ invites }) =>
                invites.some(
                  ({ inviterID }) => inviterID === ambassadors[index]._id
                )
              );
              // if exists add to match invites
              if (foundMatch) {
                foundMatch.invites.push(pendingInvitation);
              }
              // else make new match with invite
              else {
                this.addMatch({
                  ambassador: ambassadors[index],
                  selected: false,
                  hasMatches: true,
                  invites: [pendingInvitation],
                });
              }
              index++;

              if (index === ambassadors.length) {
                // Hit the end of the ambassador list, start assigning from the beginning
                index = 0;
              }
            }
          }
        }

        this.includeVacationMode = false;
        this.includeInactive = false;
        this.showAutoMatchModal = false;
      },

      openUserSelect({ match, isAmbassador, isSingleSelect }) {
        this.selectedMatch = match;
        if (isAmbassador) {
          this.clickedUsers = match?.ambassador ? [match.ambassador] : [];
        } else {
          this.clickedUsers = match.invites.map((invite) =>
            this.getInviteeDynamicColumns(invite)
          );
        }
        this.showUserSelectModal = true;
        this.isSelectingAmbassador = isAmbassador;
        this.isSingleSelectModal = isSingleSelect;
      },

      selectUsers(users) {
        if (this.isAutoMatch) {
          this.performAutoMatch(users);
        } else if (this.isSelectingAmbassador) {
          this.selectAmbassadors(users);
        } else {
          this.selectRecipients(users);
        }
      },

      selectAmbassadors(ambassadors) {
        const ambassador = ambassadors.shift();
        const index = this.matches.findIndex(
          (match) =>
            JSON.stringify(match) === JSON.stringify(this.selectedMatch)
        );
        this.matches[index].ambassador = ambassador;
        this.$set(this.matches, index, this.matches[index]);
        this.matches = [...this.matches];
        if (ambassadors.length) {
          ambassadors.forEach((ambassador) => {
            this.matches.push({
              ambassador,
              hasMatches: false,
              invites: [],
              selected: false,
            });
          });
        }
      },

      selectRecipients(prospects) {
        const matchIndex = this.matches.findIndex(
          (match) => match.ambassador._id === this.selectedMatch.ambassador._id
        );
        const match = this.matches[matchIndex];
        match.hasMatches = true;
        match.invites = this.importedInvitees.filter((invitation) =>
          prospects.some(
            (prospect) => prospect.email === invitation.invitee?.email
          )
        );
      },

      getInviteeDynamicColumns(match) {
        return {
          ...match.invitee,
          ...match.dynamicColumns,
          recipientName: toFullName(
            match.invitee.first,
            match.invitee.last,
            this.$lang.general_DeletedUser
          ),
        };
      },

      addManuallyDisplay() {
        this.showUserSelectModal = false;
        this.showAddManuallyModal = true;
      },

      addManuallyBack() {
        this.showAddManuallyModal = false;
        this.showUserSelectModal = true;
      },

      addManuallyAdd(users) {
        this.addManuallyBack();
        this.importedInvitees = [...this.importedInvitees, ...users];
      },
    },
  };
</script>

<style lang="scss" scoped>
  .match-title {
    margin-bottom: 1rem;
  }
  .match-stats {
    font-weight: 500;
  }
  .match-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    .v-btn.match-button {
      height: 76px;
      border-radius: 20px;
    }
  }

  .match-table-empty-text {
    text-align: center;
    color: $secondary-text;
  }
</style>
