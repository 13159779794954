<template>
  <a
    class="org-logo-container"
    rel="noreferrer"
    :href="linkUrl"
    target="_blank"
    :style="{ 'background-color': primaryColor }"
    @click.stop
    ><img class="org-logo" :src="optimizedUrl" alt="logo image" loading="lazy"
  /></a>
</template>

<script>
  import colors from "@/constants/colors";

  export default {
    name: "common-accordion",
    props: {
      srcUrl: String,
      linkUrl: {
        type: String,
        default: "www.graduway.com",
      },
      primaryColor: {
        type: String,
        default: `#${colors.PEERPAL_BLUE}`,
      },
      height: {
        type: Number,
        default: 40,
      },
    },
    computed: {
      optimizedUrl() {
        if (!this.srcUrl) {
          return "";
        }
        const prefix = "https://res.cloudinary.com/peerpal/image/upload/";
        const filename = this.srcUrl.slice(this.srcUrl.lastIndexOf("/"));
        return prefix + "c_limit,w_180,h_" + this.height + filename;
      },
    },
  };
</script>

<style scoped lang="scss">
  .org-logo-container {
    box-sizing: border-box;
    padding: 4px;
    border-radius: 8px;
    max-width: 180px;
    max-height: 40px;
    width: 100%;
    height: 100%;
    border: solid 1px $grey-100;
    display: flex;
    justify-content: center;
    align-content: center;
    transition: border 0.3s;
    &:hover {
      border: solid 1px $blue-400;
    }
  }

  .org-logo {
    max-height: 100%;
    max-width: 100%;
    margin: auto;
  }
</style>
