<template>
  <svg
    class="sort-icon"
    :class="{ active }"
    viewBox="0 0 13 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.66665 7L3.99998 4.66667L6.33331 7"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M6.35605 11.0126L4.04058 13.3637L1.68951 11.0482"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
  export default {
    props: {
      active: {
        type: Boolean,
      },
    },
    data() {
      return {
        iconActive: this.active,
      };
    },
    watch: {
      active(newVal) {
        this.iconActive = newVal;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sort-icon {
    width: 100%;
    height: 100%;
    stroke: currentColor;

    &.active {
      color: $blue-400;
    }
  }
</style>
