<template>
  <div class="header-wrapper">
    <header>
      <div>
        <div class="header">
          <div class="nav-toggle" @click="toggleNav">
            <ppicon name="menu" />
          </div>

          <router-link class="peerpal-logo-link" :to="{ name: 'HOME_SCREEN' }">
            <img
              class="peerpal-logo"
              src="@/assets/images/logos/peerpal-gravyty-logo.svg" />
          </router-link>

          <!-- Admission Year Button -->
          <admission-year
            :subMenuOpened="subMenuOpened"
            openLeft
            @toggle-submenu="toggleSubmenu" />
        </div>

        <div
          class="nav-overlay"
          :class="{ hidden: !showMobileNav, removed: removeMobileNav }"
          @click.self="toggleNav">
          <sidebar
            class="nav"
            :parentSubMenuOpened="subMenuOpened"
            hideHeader
            @toggle-mobile-nav="toggleNav"
            @set-submenu="subMenuOpened = $event" />
        </div>
      </div>
    </header>
  </div>
</template>

<script>
  import Sidebar from "@/components/dashboard/sidebar/Sidebar.vue";
  import AdmissionYear from "@/components/dashboard/sidebar/AdmissionYear.vue";

  export default {
    name: "Header",
    components: { Sidebar, AdmissionYear },

    data() {
      return {
        showMobileNav: false,
        removeMobileNav: true,
        subMenuOpened: null,
        closeAllSubmenus: false,
      };
    },

    methods: {
      toggleNav() {
        this.subMenuOpened = null;

        if (this.removeMobileNav) {
          this.removeMobileNav = false;
        } else {
          setTimeout(() => {
            this.removeMobileNav = true;
          }, 300);
        }

        this.showMobileNav = !this.showMobileNav;
      },

      toggleSubmenu(menu) {
        this.subMenuOpened = this.subMenuOpened === menu ? null : menu;
      },
    },
  };
</script>

<style scoped lang="scss">
  header {
    box-shadow: $box-shadow-lg;
    z-index: 100;
    background-color: $grey-100;
    position: relative;
    position: fixed;
    width: 100%;
  }

  .header {
    display: flex;
  }

  .header-wrapper {
    height: 88px;
    display: flex;
  }

  .nav-overlay {
    display: flex;
    align-items: center;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100vw;
    background: $overlay-bg;
    opacity: 1;
    transition: opacity var(--transition-duration),
      transform var(--transition-duration);

    &.hidden {
      opacity: 0;

      &.removed {
        width: 0;
      }

      .nav {
        transform: translateX(-100%);
      }
    }
  }

  .nav-toggle {
    margin: 1rem;
    height: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .nav {
    height: calc(100vh - 88px);
    transition: transform var(--transition-duration);
  }

  .peerpal-logo-link {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  .peerpal-logo {
    height: 60px;
  }

  ::v-deep {
    .sidebar-content {
      height: calc(100vh - 88px);
      overflow: auto;
      position: initial;
    }

    .org-menu-container,
    .sidebar-footer {
      position: initial;
    }

    .sidebar-menu-wrapper {
      bottom: 18px;
      left: 6.5rem;
    }

    .admission-year-container {
      margin-left: auto;
    }
  }
</style>
