import httpService, { login, logout } from "./http.service";

const request = httpService(process.env.VUE_APP_ENDPOINT);

function dashboard() {
  return request({
    requireToken: true,
    method: "get",
    path: "dashboard",
  });
}

function authenticate(data) {
  return request({
    data,
    method: "post",
    path: "dashboard/authenticate",
  }).then((response) => {
    login(response.data);
    return response;
  });
}

function updateBranding(data) {
  return request({
    data,
    requireToken: true,
    method: "put",
    path: "dashboard/updateBranding",
  });
}

function indexedParents(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/IndexedParents",
  });
}

function updateParents(data) {
  return request({
    data,
    requireToken: true,
    method: "put",
    path: "dashboard/updateParents",
  });
}

function updateParentsBatch(data) {
  return request({
    data,
    requireToken: true,
    method: "put",
    path: "dashboard/updateParentsBatch",
  });
}

function updateParentsIndexBatch(data) {
  return request({
    data,
    requireToken: true,
    method: "put",
    path: "dashboard/updateParentsIndexBatch",
  });
}

function addSuperAdmin(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/addSuperAdmin",
  });
}

function verifySuperAdmin() {
  return request({
    requireToken: true,
    method: "get",
    path: "dashboard/verifySuperAdmin",
  });
}

function superAdminGetAllOrganizations() {
  return request({
    requireToken: true,
    method: "get",
    path: "dashboard/superAdminGetAllOrganizations",
  });
}

function deleteAdmin(pathParams) {
  return request({
    method: "delete",
    requireToken: true,
    path: `dashboard/deleteAdmin/${pathParams.id}/${pathParams.organization}`,
  });
}

function deleteInvite(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/deleteInvite",
  });
}

function deleteVideoInvitationSubmission(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/deleteVideoInvitationSubmission",
  });
}

function deleteUser(pathParams) {
  return request({
    method: "delete",
    requireToken: true,
    path: `dashboard/delete/${pathParams.userId}`,
  });
}

function register(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/register",
  });
}

function updateFields(data) {
  return request({
    data,
    requireToken: true,
    method: "put",
    path: "dashboard/updateFields",
  });
}

function updatePassword(data) {
  return request({
    data,
    requireToken: true,
    method: "put",
    path: "dashboard/updatePassword",
  });
}

function forgotPassword(data) {
  return request({
    data,
    method: "post",
    path: "dashboard/forgotPassword",
  });
}

function updatePwd(data) {
  return request({
    data,
    method: "put",
    path: "dashboard/updatePwd",
  });
}

function checkEmail(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/checkEmail",
  });
}

function inviteAmb(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/inviteAmb",
  });
}

function bulkInviteAmb(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/bulkInviteAmb",
  });
}

function deleteAmbInvite(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/deleteAmbInvite",
  });
}

function archiveAmbsBatch(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/archiveAmbsBatch",
  });
}

function restoreAmbsBatch(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/restoreAmbsBatch",
  });
}

function getInvitedAmbs(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/getInvitedAmbs",
  });
}

function updateIQ(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/updateIQ",
  });
}

function getAdmins(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/getAdmins",
  });
}

function branding(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/branding",
  });
}

function currentParents(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/CurrentParents",
  });
}

function updateVideoInviteSubmission(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/updateVideoInviteSubmission",
  });
}

function amplitude(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/amplitude",
  });
}

function activeVideoInvitesOrg(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/ActiveVideoInvitesOrg",
  });
}

function getRespondedInvitesOrg(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/getRespondedInvitesOrg",
  });
}

function prospectiveParents(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/ProspectiveParents",
  });
}

function allConvos(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/allConvos",
  });
}

function getAllCalls(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/allCalls",
  });
}

function allCallInvitations(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/allCallInvitations",
  });
}

function invitesOrg(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/InvitesOrg",
  });
}

function createInvite(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/createInvite",
  });
}

function remindInvitedAmb(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/remindInvitedAmb",
  });
}

function createVideoInviteRequest(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/createVideoInviteRequest",
  });
}

function convoDetails(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/convoDetails",
  });
}

function userData(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/userData",
  });
}

function handleSignup(data) {
  return request({
    data,
    method: "post",
    path: "dashboard/handleSignUp",
  }).then((response) => {
    login(response.data);
    return response;
  });
}

function adminVerify(data) {
  return request({
    data,
    method: "post",
    path: "dashboard/adminVerify",
  });
}

function getUnsubscribe(data) {
  return request({
    data,
    method: "get",
    path: "dashboard/unsubscribeEmail",
  });
}

function updateUnsubscribe(data) {
  return request({
    data,
    method: "post",
    path: "dashboard/updateUnsubscribe",
  });
}

function createCallRequest(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/createCallRequest",
  });
}

function closeCall(callId) {
  return request({
    requireToken: true,
    method: "put",
    path: `dashboard/closeCall/${callId}`,
  });
}

function getCampaigns(org, year) {
  const query = year ? "?year=" + year : "";
  return request({
    requireToken: true,
    method: "get",
    path: `dashboard/getCampaigns/${org}${query}`,
  });
}

function getAllCampaignInfo(pathParams) {
  return request({
    requireToken: true,
    method: "get",
    path: `dashboard/getAllCampaignInfo/${pathParams}`,
  });
}

function saveCampaign(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/saveCampaignInfo",
  });
}

function sendCampaign(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/sendCampaign",
  });
}

function deleteCampaign(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/deleteCampaignInfo",
  });
}

function getPendingVideoInviteSubmissions(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/getPendingVideoInviteSubmissions",
  });
}

function getPendingVideoStories(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/getPendingVideoStories",
  });
}

function deleteVideoStory(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "users/videoStory/delete",
  });
}

function updateVideoStory(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "users/videoStory/update",
  });
}

function createVideoStory(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    headers: {
      "content-type": undefined,
    },
    path: "users/videoStory/create",
  });
}

function getPublishedVideoStories(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/getPublishedVideoStories",
  });
}

function getVideoStory(pathParams) {
  return request({
    requireToken: true,
    method: "get",
    path: `users/videoStory/read?uid=${pathParams.id}`,
  });
}

function upsertUserBan(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/upsertUserBan",
  });
}

function getConversations(data) {
  return request({
    data,
    requireToken: true,
    method: "get",
    path: "dashboard/getConversations",
  });
}

function upsertFeatureFlags(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: "dashboard/upsertFeatureFlags",
  });
}

function getAllOutreachRequests(data) {
  return request({
    data,
    requireToken: true,
    method: "get",
    path: "dashboard/getAllOutreachRequests",
  });
}

function getZendeskToken() {
  return request({
    requireToken: true,
    method: "get",
    path: "dashboard/zendesk",
  });
}

function update(data) {
  return request({
    data,
    requireToken: true,
    method: "put",
    path: "dashboard/update",
  });
}

export default {
  activeVideoInvitesOrg,
  addSuperAdmin,
  adminVerify,
  allCallInvitations,
  allConvos,
  amplitude,
  archiveAmbsBatch,
  authenticate,
  branding,
  bulkInviteAmb,
  checkEmail,
  closeCall,
  convoDetails,
  createCallRequest,
  createInvite,
  createVideoInviteRequest,
  createVideoStory,
  currentParents,
  dashboard,
  deleteAdmin,
  deleteAmbInvite,
  deleteCampaign,
  deleteInvite,
  deleteUser,
  deleteVideoInvitationSubmission,
  deleteVideoStory,
  forgotPassword,
  getAdmins,
  getAllCalls,
  getAllCampaignInfo,
  getAllOutreachRequests,
  getCampaigns,
  getConversations,
  getInvitedAmbs,
  getPendingVideoInviteSubmissions,
  getPendingVideoStories,
  getPublishedVideoStories,
  getRespondedInvitesOrg,
  getUnsubscribe,
  getVideoStory,
  getZendeskToken,
  handleSignup,
  indexedParents,
  inviteAmb,
  invitesOrg,
  logout,
  prospectiveParents,
  register,
  remindInvitedAmb,
  restoreAmbsBatch,
  saveCampaign,
  sendCampaign,
  superAdminGetAllOrganizations,
  update,
  updateBranding,
  updateFields,
  updateIQ,
  updateParents,
  updateParentsBatch,
  updateParentsIndexBatch,
  updatePassword,
  updatePwd,
  updateUnsubscribe,
  updateVideoInviteSubmission,
  updateVideoStory,
  upsertFeatureFlags,
  upsertUserBan,
  userData,
  verifySuperAdmin,
};
