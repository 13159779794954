<template>
  <div class="multi-toggle">
    <v-tooltip
      :open-on-click="false"
      :open-on-focus="false"
      top
      :disabled="!option.disabled || !option.disabledMessage"
      v-for="(option, index) of multiToggleOptions"
      :key="`common-multi-toggle-option-index-${index}`">
      <template v-slot:activator="{ on }">
        <div
          class="multi-toggle-wrapper"
          :class="{ disabled: option.disabled || !option.disabledMessage }"
          v-on="on">
          <button
            class="multi-toggle-button"
            :class="{ active: selectedOption === option.value }"
            :disabled="option.disabled"
            @click="emitToggle(option)">
            <img
              v-if="option.imgSrc"
              class="multi-toggle-button-image"
              :src="option.imgSrc" />
            {{ option.label }}
          </button>
        </div>
      </template>
      <span>{{ option.disabledMessage }}</span>
    </v-tooltip>
  </div>
</template>

<script>
  export default {
    name: "common-multi-toggle",
    props: {
      options: {
        type: Array,
        required: true,
      },
      selected: {
        type: [Number, String],
        default: 0,
      },
    },
    data() {
      return {
        selectedOption: this.selected,
        multiToggleOptions: this.options,
      };
    },
    methods: {
      emitToggle(option) {
        this.$emit("toggle", option.value);
      },
    },
    watch: {
      selected(newVal) {
        this.selectedOption = newVal;
      },
    },
    created() {
      this.selectedOption = this.multiToggleOptions[0].value;
    },
  };
</script>

<style lang="scss" scoped>
  .multi-toggle {
    display: flex;
    height: 3.75rem;
    gap: 1rem;

    .multi-toggle-wrapper {
      height: 100%;
      flex-grow: 1;
      display: flex;
    }

    .multi-toggle-button-image {
      height: 100%;
      padding: 4px;
    }

    .multi-toggle-button {
      background-color: $grey-100;
      height: 100%;
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
      border: 1px solid $grey-100;
      border-radius: 0.5rem;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      &.active,
      &:hover:not([disabled]) {
        background-color: $blue-100;
        border-color: $blue-300;
      }

      &:disabled {
        opacity: 0.7;
      }
    }
  }
</style>
