<template>
  <div>
    <div class="match-toolbar">
      <v-checkbox
        v-model="selectAll"
        label="Select All"
        class="select-all"
        :disabled="!value[0].ambassador"
        hide-details />
      <v-spacer />
      <v-btn
        icon
        class="icon-button"
        :aria-label="$lang.general_Delete"
        @click="deleteMatches"
        :disabled="!value.some((match) => match.selected)">
        <v-icon>$delete</v-icon>
      </v-btn>

      <SearchInput class="match-search" v-model="searchText" />
    </div>
    <v-expansion-panels
      v-for="(match, i) in filteredMatches"
      :key="'match' + ((match.ambassador && match.ambassador._id) || i)"
      class="match-panel"
      flat>
      <v-expansion-panel :readonly="!match.invites.length">
        <v-expansion-panel-header>
          <template v-slot:actions>
            <!-- hide chevron but occupy space -->
            <v-icon :class="{ hidden: !match.invites.length }" alt="">
              $chevron-down
            </v-icon>
          </template>

          <div class="match-panel-header">
            <v-simple-checkbox
              v-model="match.selected"
              aria-label="selected"
              @click.stop=""
              v-ripple />

            <div>
              <v-btn
                :class="{ 'match-panel-button': match.ambassador }"
                color="primary"
                text
                large
                @click.stop="
                  $emit('openUserSelect', {
                    match,
                    isAmbassador: true,
                    isSingleSelect: !(
                      !match.ambassador && !match.invites.length
                    ),
                  })
                ">
                <template v-if="match.ambassador">
                  <Avatar
                    :imgSrc="match.ambassador.imageURL"
                    size="34"
                    class="match-ambassador-avatar" />
                  {{ toFullName(match.ambassador) }}
                  <IconTooltip
                    icon="$alert-circle"
                    v-if="match.ambassador.isOnVacation"
                    :text="$lang.campaign_UserOnVacation" />
                </template>

                <template v-else>
                  {{ $lang.campaign_SelectAmbassadors }}
                </template>
              </v-btn>
            </div>

            <div class="match-arrow">
              <hr class="match-arrow-shaft" />
              <v-icon color="primary">$chevron-right</v-icon>
            </div>

            <div class="match-recipients">
              <v-btn
                :class="{
                  'match-panel-button':
                    match.hasMatches && match.invites.length,
                }"
                color="primary"
                text
                large
                :disabled="!match.ambassador"
                @click.stop="
                  $emit('openUserSelect', {
                    match,
                    isAmbassador: false,
                    isSingleSelect: false,
                  })
                ">
                <template v-if="match.invites.length > 1">
                  {{ match.invites.length }} {{ $lang.campaign_Recipients }}
                </template>
                <template v-else-if="match.invites.length === 1">
                  {{ toFullName(match.invites[0].invitee) }}
                </template>
                <template v-else>
                  <v-icon left>$plus</v-icon>
                  {{ $lang.campaign_SelectRecipients }}
                </template>
              </v-btn>
            </div>
          </div>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-simple-table class="match-table" v-if="match.invites.length">
            <thead>
              <tr>
                <th>{{ $lang.campaign_RecipientName }}</th>
                <th v-for="col in headers" :key="col.name">
                  {{ col.name }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="invite in match.invites">
                <tr :key="invite._id" v-if="invite.invitee">
                  <td>{{ toFullName(invite.invitee) }}</td>
                  <td v-for="col in headers" :key="col.name">
                    {{ invite.dynamicColumns[col.name] }}
                  </td>
                </tr>
              </template>
            </tbody>
          </v-simple-table>

          <div class="match-table-empty-text" v-else>
            {{ $lang.general_TableNoContent }}
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
  import Avatar from "@/components/common/vuetify/Avatar.vue";
  import IconTooltip from "@/components/common/vuetify/IconTooltip.vue";
  import { toFullName } from "@/shared/utils";
  import SearchInput from "./SearchInput.vue";

  export default {
    name: "OutreachMatchTable",
    components: { Avatar, IconTooltip, SearchInput },
    props: {
      value: {
        type: Array,
        required: true,
      },
      headers: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        searchText: "",
      };
    },
    computed: {
      selectAll: {
        get() {
          return this.value.every((match) => match.selected);
        },
        set(val) {
          this.$emit(
            "input",
            this.value.map((match) => ({ ...match, selected: val }))
          );
        },
      },
      filteredMatches() {
        const searchText = !this.searchText
          ? ""
          : this.searchText.trim().toLowerCase();
        if (searchText.length < 2) {
          return this.value;
        }

        function searchName(user) {
          if (user) {
            const fullname = (user.first + " " + user.last).toLowerCase();
            if (fullname.includes(searchText)) {
              return true;
            }
          }
          return false;
        }

        return this.value.filter((match) => {
          if (searchName(match.ambassador)) {
            return true;
          }

          for (const invite of match.invites) {
            if (searchName(invite.invitee)) {
              return true;
            }
          }
        });
      },
    },
    methods: {
      deleteMatches() {
        this.$emit("input", [...this.value.filter((match) => !match.selected)]);
      },
      toFullName(user) {
        return toFullName(
          user.first,
          user.last,
          this.$lang.general_DeletedUser
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  .select-all {
    display: flex;
    align-items: center;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 20px;
    height: 36px;
    padding: 0 16px;
    margin-top: 0;
  }
  .match-toolbar {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 30px 0 16px;

    .match-search {
      max-width: 268px;

      // shift icon to left
      ::v-deep .v-input__slot {
        padding-left: 16px;
      }
    }
    .icon-button.v-btn.v-btn--icon.theme--light {
      background-color: $grey-100;
      color: $grey-800;
      opacity: 1;
    }
  }
  .match-panel {
    border: 1px solid hsl(0, 0%, 80%);
    overflow: hidden;

    .match-panel-button {
      text-transform: unset;
    }
    .hidden {
      visibility: hidden;
    }
  }
  .match-panel + .match-panel {
    margin-top: 16px;
  }
  .match-panel-header {
    display: grid;
    grid-template-columns: 38px 1fr minmax(10px, 168px) 1fr;
    align-items: center;
  }
  .match-table.v-data-table {
    margin: 0 -16px -16px;
    max-width: unset;
    background-color: $grey-100;
    border-radius: 0;
  }
  .match-recipients {
    display: flex;

    @media (min-width: $md) {
      padding-left: min(200px, 15%);
    }
  }
  .match-arrow {
    display: flex;
    align-items: center;
  }
  .match-arrow-shaft {
    width: 100%;
    border: none;
    border-top: 2px dotted $blue-300;
    margin-right: -6px;
  }
  .match-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    .v-btn.match-button {
      height: 76px;
      border-radius: 20px;
    }
  }

  .match-ambassador-avatar {
    margin-right: 1.25rem;
  }
</style>
