<template>
  <div class="w-full min-w-max pt-8">
    <!-- Title and Quick Menu -->
    <div class="flex justify-between align-center mb-9">
      <div class="">
        <h1 class="text-2xl font-medium">
          Welcome back, {{ getCurrentUser.first }}!
        </h1>
        <p class="text-sm font-semibold">{{ orgDisplayName }}</p>
      </div>
      <!-- Quick Menu -->
      <div class="flex flex-shrink-0">
        <common-dropdown
          class="lg:ml-3"
          variant="secondary"
          :options="quickMenuOptions"
          @input="handleQuickMenuClick($event)"
          :button="{ label: $lang.general_QuickMenuLabel, icon: true }"
          alignDropdownLeft
          maxHeight>
          <template slot="icon">
            <img :src="require('@/assets/lightning-icon-white.svg')" />
          </template>
        </common-dropdown>
      </div>
    </div>
    <!-- Data Refresh -->
    <div
      class="flex items-center text-xs text-grey justify-between lg:justify-end mb-6 lg:my-8">
      <p class="hidden lg:block" v-if="lastFetched">
        Last updated {{ lastFetched.toLocaleDateString() }} at
        {{ lastFetched.toLocaleTimeString() }}
      </p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="lg:ml-2 h-6 w-6 lg:h-3 lg:w-3 cursor-pointer focus:outline-none"
        :class="{ 'animate-spin': fetching }"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        content="Fetch most recent data"
        v-tippy="{ placement: 'bottom', arrow: true, maxWidth: '500px' }"
        @click="fetchMostRecent">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
      </svg>
    </div>
    <!-- Stats Cards -->
    <div class="flex justify-between mb-6">
      <!-- Prospects -->
      <div
        class="w-1/4 h-32 rounded-lg border-grey-200 border mr-4 py-8 px-6 flex"
        v-if="prospectStats.macro !== null">
        <div
          class="bg-blue-100 h-14 w-14 rounded flex justify-center items-center">
          <img src="../../../assets/Rocket.svg" alt="" />
        </div>
        <div class="ml-4">
          <p class="text-xs text-grey-500">Prospect Sign Ups</p>
          <h1
            class="text-3xl w-max"
            :content="prospectStatTip"
            v-tippy="{ placement: 'bottom', arrow: true, maxWidth: '500px' }">
            {{ prospectStats.macro }}
          </h1>
        </div>
      </div>
      <div
        class="w-1/4 h-32 rounded-lg border-grey-200 border mr-4 py-8 px-6 flex flex-col"
        v-else>
        <div class="h-4 w-32 bg-grey-300 animate-pulse rounded-lg mb-6"></div>
        <div class="h-4 w-44 bg-grey-300 animate-pulse rounded-lg"></div>
      </div>
      <!-- Ambassadors -->
      <div
        class="w-1/4 h-32 rounded-lg border-grey-200 border mr-4 py-8 px-6 flex"
        v-if="ambStats.macro !== null">
        <div
          class="bg-green-100 h-14 w-14 rounded flex justify-center items-center">
          <img src="../../../assets/Ribbon.svg" alt="" />
        </div>
        <div class="ml-4">
          <p class="text-xs text-grey-500">Ambassadors</p>
          <h1
            class="text-3xl w-max"
            :content="ambStatTip"
            v-tippy="{ placement: 'bottom', arrow: true, maxWidth: '500px' }">
            {{ ambStats.macro }}
          </h1>
        </div>
      </div>
      <div
        class="w-1/4 h-32 rounded-lg border-grey-200 border mr-4 py-8 px-6 flex flex-col"
        v-else>
        <div class="h-4 w-32 bg-grey-300 animate-pulse rounded-lg mb-6"></div>
        <div class="h-4 w-44 bg-grey-300 animate-pulse rounded-lg"></div>
      </div>
      <!-- Conversations -->
      <div
        class="w-1/4 h-32 rounded-lg border-grey-200 border mr-4 py-8 px-6 flex"
        v-if="convoStats.macro !== null">
        <div
          class="bg-blue-100 h-14 w-14 rounded flex justify-center items-center">
          <svg
            width="20"
            height="19"
            viewBox="0 0 20 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.399902 3.00001C0.399902 1.67452 1.47442 0.600006 2.7999 0.600006H11.1999C12.5254 0.600006 13.5999 1.67452 13.5999 3.00001V7.80001C13.5999 9.12549 12.5254 10.2 11.1999 10.2H8.7999L5.1999 13.8V10.2H2.7999C1.47442 10.2 0.399902 9.12549 0.399902 7.80001V3.00001Z"
              fill="#026BE0" />
            <path
              d="M15.9999 5.40001V8.6C15.9999 10.8091 14.209 12.6 11.9999 12.6H9.79401L7.67397 14.72C8.00975 14.8987 8.393 15 8.7999 15H11.1999L14.7999 18.6V15H17.1999C18.5254 15 19.5999 13.9255 19.5999 12.6V7.80001C19.5999 6.47452 18.5254 5.40001 17.1999 5.40001H15.9999Z"
              fill="#026BE0" />
          </svg>
        </div>
        <div class="ml-4">
          <p class="text-xs text-grey-500">Conversations</p>
          <h1
            class="text-3xl w-max"
            :content="convoStatTip"
            v-tippy="{ placement: 'bottom', arrow: true, maxWidth: '500px' }">
            {{ convoStats.macro }}
          </h1>
        </div>
      </div>
      <div
        class="w-1/4 h-32 rounded-lg border-grey-200 border mr-4 py-8 px-6 flex flex-col"
        v-else>
        <div class="h-4 w-32 bg-grey-300 animate-pulse rounded-lg mb-6"></div>
        <div class="h-4 w-44 bg-grey-300 animate-pulse rounded-lg"></div>
      </div>
      <!-- Vistors -->
      <div
        class="w-1/4 h-32 rounded-lg border-grey-200 border py-8 px-6 flex"
        v-if="visitorStats.macro !== null">
        <div
          class="bg-pink-100 h-14 w-14 rounded flex justify-center items-center">
          <img src="../../../assets/Eye.svg" alt="" />
        </div>
        <div class="ml-4">
          <p class="text-xs text-grey-500">Visitor Views</p>
          <h1
            class="text-3xl w-max"
            :content="visitorStatTip"
            v-tippy="{ placement: 'bottom', arrow: true, maxWidth: '500px' }">
            {{ visitorStats.macro }}
          </h1>
        </div>
      </div>
      <div
        class="w-1/4 h-32 rounded-lg border-grey-200 border mr-4 py-8 px-6 flex flex-col"
        v-else>
        <div class="h-4 w-32 bg-grey-300 animate-pulse rounded-lg mb-6"></div>
        <div class="h-4 w-44 bg-grey-300 animate-pulse rounded-lg"></div>
      </div>
    </div>
    <!-- Engagement -->
    <div class="mb-6">
      <p class="text-sm text-grey-500 mb-2">
        Prospect Engagement (Last 30 Days)
      </p>
      <div class="flex justify-between">
        <!-- Stats -->
        <div
          class="w-1/4 h-96 mr-2 rounded-lg grid grid-rows-3 grid-cols-2 border"
          v-if="
            engagementTotals.convos !== null &&
            engagementTotals.messages !== null &&
            engagementTotals.landingPage !== null &&
            engagementTotals.widget !== null &&
            engagementTotals.videoProfiles !== null &&
            engagementTotals.ctaButtons !== null
          ">
          <!-- Conversations -->
          <div class="flex flex-col justify-center items-center border-b">
            <p class="text-sm px-6 w-full">Conversations</p>
            <div class="flex justify-start px-6 w-full">
              <div
                v-if="engagementTotals.convos === null"
                class="h-8 w-8 bg-grey-300 animate-pulse rounded"></div>
              <h2 v-else class="text-2xl">
                {{ engagementTotals.convos }}
              </h2>
              <!-- Green UI -->
              <div
                v-if="
                  engagementTotals.convos !== null && engagementCalcs.convos > 0
                "
                class="flex items-center ml-2">
                <img src="../../../assets/GreenUp.svg" alt="" />
                <p class="text-green-400 text-sm ml-1">
                  {{ engagementCalcs.convos }}%
                </p>
              </div>
              <!-- Red UI -->
              <div
                v-else-if="
                  engagementTotals.convos !== null && engagementCalcs.convos < 0
                "
                class="flex items-center ml-2">
                <img src="../../../assets/RedDown.svg" alt="" />
                <p class="text-salmon-400 text-sm ml-1">
                  {{ Math.abs(engagementCalcs.convos) }}%
                </p>
              </div>
            </div>
          </div>
          <!-- Messages -->
          <div class="flex flex-col justify-center items-center border-b">
            <p class="text-sm px-6 w-full">Messages</p>
            <div class="flex justify-start px-6 w-full">
              <div
                v-if="engagementTotals.messages === null"
                class="h-8 w-8 bg-grey-300 animate-pulse rounded"></div>
              <h2 v-else class="text-2xl">
                {{ engagementTotals.messages }}
              </h2>
              <!-- Green UI -->
              <div
                v-if="
                  engagementTotals.messages !== null &&
                  engagementCalcs.messages > 0
                "
                class="flex items-center ml-2">
                <img src="../../../assets/GreenUp.svg" alt="" />
                <p class="text-green-400 text-sm ml-1">
                  {{ engagementCalcs.messages }}%
                </p>
              </div>
              <!-- Red UI -->
              <div
                v-else-if="
                  engagementTotals.messages !== null &&
                  engagementCalcs.messages < 0
                "
                class="flex items-center ml-2">
                <img src="../../../assets/RedDown.svg" alt="" />
                <p class="text-salmon-400 text-sm ml-1">
                  {{ Math.abs(engagementCalcs.messages) }}%
                </p>
              </div>
            </div>
          </div>
          <!-- Landing Page Views -->
          <div class="flex flex-col justify-center items-center border-b">
            <p class="text-sm px-6 w-full">Landing Page</p>
            <div class="flex justify-start px-6 w-full">
              <div
                v-if="engagementTotals.landingPage === null"
                class="h-8 w-8 bg-grey-300 animate-pulse rounded"></div>
              <h2 v-else class="text-2xl">
                {{ engagementTotals.landingPage }}
              </h2>
              <!-- Green UI -->
              <div
                v-if="
                  engagementTotals.landingPage !== null &&
                  engagementCalcs.landingPage > 0
                "
                class="flex items-center ml-2">
                <img src="../../../assets/GreenUp.svg" alt="" />
                <p class="text-green-400 text-sm ml-1">
                  {{ engagementCalcs.landingPage }}%
                </p>
              </div>
              <!-- Red UI -->
              <div
                v-else-if="
                  engagementTotals.landingPage !== null &&
                  engagementCalcs.landingPage < 0
                "
                class="flex items-center ml-2">
                <img src="../../../assets/RedDown.svg" alt="" />
                <p class="text-salmon-400 text-sm ml-1">
                  {{ Math.abs(engagementCalcs.landingPage) }}%
                </p>
              </div>
            </div>
          </div>
          <!-- Widget -->
          <div class="flex flex-col justify-center items-center border-b">
            <p class="text-sm px-6 w-full">Widget</p>
            <div class="flex justify-start px-6 w-full">
              <div
                v-if="engagementTotals.widget === null"
                class="h-8 w-8 bg-grey-300 animate-pulse rounded"></div>
              <h2 v-else class="text-2xl">
                {{ engagementTotals.widget }}
              </h2>
              <!-- Green UI -->
              <div
                v-if="
                  engagementTotals.widget !== null && engagementCalcs.widget > 0
                "
                class="flex items-center ml-2">
                <img src="../../../assets/GreenUp.svg" alt="" />
                <p class="text-green-400 text-sm ml-1">
                  {{ engagementCalcs.widget }}%
                </p>
              </div>
              <!-- Red UI -->
              <div
                v-else-if="
                  engagementTotals.widget !== null && engagementCalcs.widget < 0
                "
                class="flex items-center ml-2">
                <img src="../../../assets/RedDown.svg" alt="" />
                <p class="text-salmon-400 text-sm ml-1">
                  {{ Math.abs(engagementCalcs.widget) }}%
                </p>
              </div>
            </div>
          </div>
          <!-- Video Profiles -->
          <div class="flex flex-col justify-center items-center">
            <p class="text-sm px-6 w-full">Video Profiles</p>
            <div class="flex justify-start px-6 w-full">
              <div
                v-if="engagementTotals.videoProfiles === null"
                class="h-8 w-8 bg-grey-300 animate-pulse rounded"></div>
              <h2 v-else class="text-2xl">
                {{ engagementTotals.videoProfiles }}
              </h2>
              <!-- Green UI -->
              <div
                v-if="
                  engagementTotals.videoProfiles !== null &&
                  engagementCalcs.videoProfiles > 0
                "
                class="flex items-center ml-2">
                <img src="../../../assets/GreenUp.svg" alt="" />
                <p class="text-green-400 text-sm ml-1">
                  {{ engagementCalcs.videoProfiles }}%
                </p>
              </div>
              <!-- Red UI -->
              <div
                v-else-if="
                  engagementTotals.videoProfiles !== null &&
                  engagementCalcs.videoProfiles < 0
                "
                class="flex items-center ml-2">
                <img src="../../../assets/RedDown.svg" alt="" />
                <p class="text-salmon-400 text-sm ml-1">
                  {{ Math.abs(engagementCalcs.videoProfiles) }}%
                </p>
              </div>
            </div>
          </div>
          <!-- CTA Buttons -->
          <div class="flex flex-col justify-center items-center">
            <p class="text-sm px-6 w-full">CTA Buttons</p>
            <div class="flex justify-start px-6 w-full">
              <div
                v-if="engagementTotals.ctaButtons === null"
                class="h-8 w-8 bg-grey-300 animate-pulse rounded"></div>
              <h2 v-else class="text-2xl">
                {{ engagementTotals.ctaButtons }}
              </h2>
              <!-- Green UI -->
              <div
                v-if="
                  engagementTotals.ctaButtons !== null &&
                  engagementCalcs.ctaButtons > 0
                "
                class="flex items-center ml-2">
                <img src="../../../assets/GreenUp.svg" alt="" />
                <p class="text-green-400 text-sm ml-1">
                  {{ engagementCalcs.ctaButtons }}%
                </p>
              </div>
              <!-- Red UI -->
              <div
                v-else-if="
                  engagementTotals.ctaButtons !== null &&
                  engagementCalcs.ctaButtons < 0
                "
                class="flex items-center ml-2">
                <img src="../../../assets/RedDown.svg" alt="" />
                <p class="text-salmon-400 text-sm ml-1">
                  {{ Math.abs(engagementCalcs.ctaButtons) }}%
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="w-1/4 h-96 mr-2 rounded-lg border flex flex-col justify-around py-8 px-6"
          v-else>
          <div>
            <div
              class="h-4 w-32 bg-grey-300 animate-pulse rounded-lg mb-6"></div>
            <div
              class="h-4 w-44 bg-grey-300 animate-pulse rounded-lg mb-6"></div>
            <div class="h-4 w-32 bg-grey-300 animate-pulse rounded-lg"></div>
          </div>
          <div>
            <div
              class="h-4 w-32 bg-grey-300 animate-pulse rounded-lg mb-6"></div>
            <div
              class="h-4 w-44 bg-grey-300 animate-pulse rounded-lg mb-6"></div>
            <div class="h-4 w-32 bg-grey-300 animate-pulse rounded-lg"></div>
          </div>
        </div>
        <!-- Graph -->
        <div
          class="w-3/4 h-96 ml-2 rounded-lg border px-6 py-6"
          v-show="activityChart.data">
          <h3 class="text-lg font-medium">{{ activityChart.title }}</h3>
          <div class="relative pt-6 h-80">
            <Chart v-bind="activityChart"></Chart>
          </div>
        </div>
        <div
          class="w-3/4 h-96 ml-2 rounded-lg border flex flex-col justify-around py-14 px-6"
          v-show="!activityChart.data">
          <div>
            <div
              class="h-4 w-32 bg-grey-300 animate-pulse rounded-lg mb-6"></div>
            <div
              class="h-4 w-44 bg-grey-300 animate-pulse rounded-lg mb-6"></div>
            <div class="h-4 w-32 bg-grey-300 animate-pulse rounded-lg"></div>
          </div>
          <div>
            <div
              class="h-4 w-32 bg-grey-300 animate-pulse rounded-lg mb-6"></div>
            <div
              class="h-4 w-44 bg-grey-300 animate-pulse rounded-lg mb-6"></div>
            <div class="h-4 w-32 bg-grey-300 animate-pulse rounded-lg"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- Management -->
    <div class="mb-6">
      <p class="text-sm text-grey-500 mb-2">Management</p>
      <div class="flex justify-between">
        <!-- Feedback Received -->
        <Feedback
          :conversations="getConversations"
          :callOutreach="getCallOutreach"
          :calls="calls"
          :organizationName="organization"
          @openMessageModal="
            (conversation) => openMessageModal(conversation)
          " />
        <!-- Stalled Conversations -->
        <div class="w-1/3 h-96 ml-4 rounded-lg border">
          <div class="flex justify-start items-center h-16 px-6">
            <h3 class="text-lg font-medium mr-2">Stalled Conversations</h3>
            <img
              src="../../../assets/InfoGrey.svg"
              alt=""
              content="Conversations with no activity for the past 5 days."
              v-tippy="{ placement: 'bottom', arrow: true, maxWidth: '250px' }"
              class="h-3 w-3" />
          </div>
          <ul class="h-80 overflow-y-scroll">
            <li
              v-for="(convo, index) of stalledConvos"
              :key="convo._id"
              @click="viewStalledConvo(convo)"
              class="grid grid-rows-1 py-3 pl-6 pr-3 pointer hover:bg-blue-100"
              style="grid-template-columns: 56px auto 80px 48px">
              <img
                v-if="convo.recipImageURL"
                :src="convo.recipImageURL"
                class="h-10 w-10 rounded-3xl object-cover" />
              <img
                v-else
                :src="greyProfileImgURL"
                alt=""
                class="h-10 w-10 rounded-3xl object-cover" />
              <div>
                <p class="text-sm font-medium">{{ convo.recipName }}</p>
                <p class="text-xs font-medium text-grey-500">
                  {{ convo.author }}
                </p>
              </div>
              <p class="text-xs font-medium text-grey-500">
                {{ convo.messageFormattedDate }}
              </p>
              <div>
                <svg
                  width="33"
                  height="32"
                  viewBox="0 0 33 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="ml-4 my-1 hover:bg-blue-300 rounded-2xl"
                  tabindex="0"
                  @click.stop="toggleConvoDropdown(convo, index)"
                  @blur="toggleConvoDropdown(convo, index, true)">
                  <path
                    d="M22.0416 14.5L16.5 20.3333L10.9583 14.5"
                    stroke="#0074E0"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
                <div class="z-10 mt-2 w-full" :class="convoDropdown(index)">
                  <div
                    class="relative transition-all transform origin-top-left scale-95 right-20">
                    <div
                      class="w-max absolute bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-sm outline-none"
                      :class="{
                        'bottom-5': index > stalledConvos.length - 4,
                        'top-1': index < stalledConvos.length - 4,
                      }"
                      aria-labelledby="dropdown-menu-button"
                      id="dropdown-menu"
                      role="menu">
                      <div class="py-1 h-full">
                        <a
                          href="javascript:void(0)"
                          class="hover:bg-blue text-gray-700 hover:text-white flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"
                          role="menuitem"
                          @click.stop="openMessageModal(convo)">
                          Send Message
                        </a>
                        <a
                          href="javascript:void(0)"
                          class="hover:bg-blue text-gray-700 hover:text-white flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"
                          role="menuitem"
                          @click.stop="closeConversation(convo)">
                          Mark as Closed
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Outreach Requests -->
    <div class="mb-6">
      <p class="text-sm text-grey-500 mb-2">Outreach Requests</p>
      <!-- Outreach Request Stat Cards -->
      <div class="flex justify-between mb-8">
        <div
          class="w-1/3 h-32 rounded-lg border-grey-200 border mr-8 py-8 px-6 flex">
          <div
            class="bg-pink-100 h-14 w-14 rounded flex justify-center items-center">
            <img src="../../../assets/Map.svg" alt="" />
          </div>
          <div class="ml-2 flex-grow-0">
            <p class="text-sm text-grey-500">Total Requests</p>
            <h1
              v-if="totalRequests.count !== null"
              class="text-3xl w-max"
              :content="totalRequestTip"
              v-tippy="{ placement: 'bottom', arrow: true, maxWidth: '500px' }">
              {{ totalRequests.count }}
            </h1>
            <div
              v-else
              class="h-7 w-9 bg-grey-300 animate-pulse my-1 rounded"></div>
          </div>
        </div>
        <div
          class="w-1/3 h-32 rounded-lg border-grey-200 border mr-8 py-8 px-6 flex">
          <div
            class="bg-green-100 h-14 w-14 rounded flex justify-center items-center">
            <img src="../../../assets/Clock.svg" alt="" />
          </div>
          <div class="ml-2 flex-grow-0">
            <p class="text-sm text-grey-500">Incomplete Requests</p>
            <h1
              v-if="incompleteRequests.count !== null"
              class="text-3xl w-max"
              :content="incompleteRequestTip"
              v-tippy="{ placement: 'bottom', arrow: true, maxWidth: '500px' }">
              {{ incompleteRequests.count }}
            </h1>
            <div
              v-else
              class="h-7 w-9 bg-grey-300 animate-pulse my-1 rounded"></div>
          </div>
        </div>
        <div
          class="w-1/3 h-32 rounded-lg border-grey-200 border py-8 px-6 flex">
          <div
            class="bg-blue-100 h-14 w-14 rounded flex justify-center items-center">
            <img src="../../../assets/Lightning.svg" alt="" />
          </div>
          <div class="ml-2 flex-grow-0">
            <p class="text-sm text-grey-500">Prospect Responses</p>
            <h1
              v-if="prospectResponses.count !== null"
              class="text-3xl w-max"
              :content="prospectResponsesTip"
              v-tippy="{ placement: 'bottom', arrow: true, maxWidth: '500px' }">
              {{ prospectResponses.count }}
            </h1>
            <div
              v-else
              class="h-7 w-9 bg-grey-300 animate-pulse my-1 rounded"></div>
          </div>
        </div>
      </div>
      <div class="flex justify-between">
        <!-- Convo Stats Bar Graph -->
        <div class="w-2/3 h-96 mr-4 rounded-lg border px-6 py-6">
          <h3 class="text-lg font-medium">{{ conversationChart.title }}</h3>
          <div v-show="conversationChart.data" class="relative pt-6 h-80">
            <Chart v-bind="conversationChart"></Chart>
          </div>
        </div>
        <!-- Pending Video Reviews -->
        <div class="w-1/3 h-96 ml-4 rounded-lg border">
          <div class="flex justify-start items-center h-16 px-6">
            <h3 class="text-lg font-medium mr-2">Pending Video Reviews</h3>
          </div>
          <ul class="h-80 overflow-y-scroll">
            <li
              v-for="vidReview of pendingVideoReviews"
              :key="vidReview._id"
              @click="viewPendingVideo(vidReview)"
              class="grid grid-rows-1 py-3 px-6 pointer hover:bg-blue-100"
              style="grid-template-columns: 56px auto 80px">
              <img
                v-if="vidReview.ambImageURL"
                :src="vidReview.ambImageURL"
                class="h-10 w-10 rounded-3xl object-cover" />
              <img
                v-else
                :src="greyProfileImgURL"
                alt=""
                class="h-10 w-10 rounded-3xl object-cover" />
              <div>
                <p class="text-sm font-medium">
                  {{ vidReview.ambName }}
                  <label v-if="vidReview.type === 'Outreach'"
                    >&#8594; {{ vidReview.prospectName }}</label
                  >
                </p>
                <p
                  class="text-xs font-medium text-grey-500"
                  v-if="vidReview.type === 'WMS'">
                  Watch My Story / Profile
                </p>
                <p
                  class="text-xs font-medium text-grey-500"
                  v-else-if="vidReview.type === 'Outreach'">
                  Outreach Request
                </p>
              </div>
              <p class="text-xs font-medium text-grey-500">
                {{ vidReview.formattedDate }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Stalled Conversation Modal -->
    <common-modal
      v-if="stalledConvoModalOpened"
      @close="closeStalledConvoModal">
      <ul class="h-96 overflow-y-scroll">
        <li
          v-for="message of stalledConvoMessages"
          :key="message.id"
          class="flex my-2"
          :class="{
            'justify-start': message.author === 'amb',
            'justify-end': message.author === 'prospect',
          }">
          <div
            v-if="message.author === 'amb'"
            class="text-grey-500 text-sm bg-grey-100 px-2 py-1 rounded-lg">
            Message Sent: {{ message.formattedTime }}
          </div>
          <div
            v-else-if="message.author === 'prospect'"
            class="text-blue-500 text-sm bg-blue-100 px-2 py-1 rounded-lg">
            Message Sent: {{ message.formattedTime }}
          </div>
        </li>
      </ul>
      <div class="grid grid-cols-2 border-t">
        <div class="flex justify-center items-center flex-col h-24 border-r">
          <p class="text-sm">{{ selectedStalledConvo.recipName }}</p>
          <p class="text-sm text-grey-500">
            Messages Sent: {{ numAmbMessages }}
          </p>
        </div>
        <div class="flex justify-center items-center flex-col h-24">
          <p class="text-sm">{{ selectedStalledConvo.author }}</p>
          <p class="text-sm text-grey-500">
            Messages Sent: {{ numProspectMessages }}
          </p>
        </div>
      </div>
    </common-modal>
    <!-- Pending Video Modal -->
    <common-modal
      v-if="pendingVideoModalOpened"
      @close="closePendingVideoModal"
      height="h-176">
      <h1 class="font-semibold text-2xl mb-7">Review Video Outreach</h1>
      <video
        v-if="selectedPendingVideo.video"
        :poster="selectedPendingVideo.video.jpgUrl"
        :src="selectedPendingVideo.video.mp4Url"
        controls
        class="h-72 mb-4"
        style="width: 588px" />
      <h2 class="text-grey-800 text-lg font-medium">
        {{ selectedPendingVideo.ambName }} to
        {{ selectedPendingVideo.prospectName }}
      </h2>
      <p class="text-grey-800 text-sm font-medium mb-4">
        Uploaded {{ selectedPendingVideo.formattedDate }}
      </p>
      <!-- default view -->
      <div v-if="!showPendingVideoFeedback">
        <p class="text-grey-800 text-sm font-medium mb-28">
          By clicking send, the prospect will receive the video. If you’d prefer
          the video be rerecorded reach out to your ambassador directly by
          pressing rerecord.
        </p>
        <div class="flex justify-end">
          <div class="block">
            <common-button
              variant="secondary"
              @click="showPendingVideoFeedback = true">
              Rerecord
            </common-button>
          </div>
          <div class="block ml-8">
            <common-button
              variant="primary"
              @click="approveVideoInviteSubmission">
              Approve
            </common-button>
          </div>
        </div>
      </div>
      <!-- Clicked rerecord and sending feedback -->
      <div v-else>
        <common-textarea
          v-model="selectedPendingVideo.feedback"
          placeholder="Request a new video here..."
          class="mb-12" />
        <div class="flex justify-end">
          <div class="block">
            <common-button
              variant="secondary"
              @click="showPendingVideoFeedback = false">
              Cancel
            </common-button>
          </div>
          <div class="block ml-8">
            <common-button variant="primary" @click="denyVideoInviteSubmission">
              Send
            </common-button>
          </div>
        </div>
      </div>
    </common-modal>
    <!-- Video Story Modal -->
    <VideoStoryApprovalModal
      v-model="pendingVideoStoryModalOpened"
      :videoStory="selectedPendingVideoStory"
      @refresh="refreshPendingStories" />

    <!-- Invite Ambassador Modal -->
    <InviteAmbassadorModal
      v-if="inviteAmbassadorModalOpened"
      @close="inviteAmbassadorModalOpened = false" />
    <!-- Request Outreach Modal (2nd step to send outreach) -->
    <RequestOutreachModal v-model="requestOutreachModalOpened" />
    <!-- Send Message to Ambassador Modal -->
    <SendMessageModal
      v-if="sendMessageModalOpened"
      @close="sendMessageModalOpened = false"
      :user="selectedAmbassadorMessage" />
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import {
    GET_BRANDING,
    GET_CURRENT_USER,
    GET_PROSPECTS,
    GET_CURRENT_PARENTS,
    GET_INVITED_AMBASSADORS,
    GET_CALL_OUTREACH,
    GET_EMAIL_OUTREACH,
    GET_VIDEO_OUTREACH,
    GET_CONVERSATIONS,
    GET_ACTIVE_YEAR,
    GET_VISITOR_VIEWS,
    GET_ENGAGEMENT_STATS,
    GET_ENGAGEMENT_GRAPH,
    GET_PENDING_VIDEO_INVITATIONS,
    GET_PENDING_VIDEO_STORIES,
    GET_CONVERSATION_GRAPH,
    GET_LAST_FETCHED,
  } from "@/store/getters.type";
  import {
    SET_VISITOR_VIEWS,
    SET_ENGAGEMENT_STATS,
    SET_ENGAGEMENT_STATS_VIDEO,
    SET_ENGAGEMENT_GRAPH,
    SET_PENDING_VIDEO_INVITATIONS,
    SET_PENDING_VIDEO_STORIES,
    SET_CONVERSATION_GRAPH,
    FETCH_CURRENT_PARENTS,
    FETCH_INVITED_AMBASSADORS,
    FETCH_PROSPECTS,
    FETCH_ALL_OUTREACH,
    FETCH_CONVERSATIONS,
    SET_LAST_FETCHED,
  } from "@/store/actions.type.js";
  import screens from "@/constants/screens";
  import APIService from "@/services/api/index";
  import dateService from "@/services/date.service.js";
  import Chart from "@/components/common/Chart.vue";
  import chartColors from "@/constants/chartColors";

  const VideoStoryApprovalModal = () =>
    import("@/components/common/tailwind/modals/VideoStoryApprovalModal.vue");
  const InviteAmbassadorModal = () =>
    import("@/components/common/tailwind/modals/InviteAmbassadorModal.vue");
  const RequestOutreachModal = () =>
    import("@/components/common/tailwind/modals/RequestOutreachModal.vue");
  const SendMessageModal = () =>
    import("@/components/common/tailwind/modals/SendMessageModal.vue");
  import Feedback from "./Feedback.vue";
  import { exportSafeguardingReport } from "@/shared/utils.js";

  export default {
    components: {
      VideoStoryApprovalModal,
      InviteAmbassadorModal,
      RequestOutreachModal,
      SendMessageModal,
      Feedback,
      Chart,
    },
    data() {
      return {
        organization: null,
        fetching: false,
        prospectStats: {
          macro: null,
          new: 0,
        },
        ambStats: {
          macro: null,
          new: 0,
          active: 0,
          invited: 0,
          visible: 0,
        },
        convoStats: {
          macro: null,
          new: 0,
          active: 0,
          closed: 0,
          stalled: 0,
        },
        visitorStats: {
          macro: null,
          landingPage: 0,
          widget: 0,
        },
        engagementTotals: {
          convos: null,
          messages: null,
          landingPage: null,
          widget: null,
          videoProfiles: null,
          ctaButtons: null,
        },
        engagementTotalsPast: {
          convos: null,
          messages: null,
          landingPage: null,
          widget: null,
          videoProfiles: null,
          ctaButtons: null,
        },
        convoFeedback: [],
        stalledConvos: [],
        stalledConvoMessages: [],
        greyProfileImgURL:
          "https://res.cloudinary.com/peerpal/image/upload/v1579116680/GreyProfile_y1llco.png",
        stalledConvoModalOpened: false,
        selectedStalledConvo: {},
        totalRequests: {
          count: null,
          email: 0,
          video: 0,
          phone: 0,
        },
        incompleteRequests: {
          count: null,
          pendingAmb: 0,
          pendingVidReview: 0,
          scheduled: 0,
        },
        prospectResponses: {
          count: null,
          email: 0,
          video: 0,
          phone: 0,
        },
        pendingVideoReviews: [],
        pendingVideoModalOpened: false,
        selectedPendingVideo: {},
        showPendingVideoFeedback: false,
        selectedPendingVideoStory: {},
        pendingVideoStoryModalOpened: false,
        inviteAmbassadorModalOpened: false,
        requestOutreachModalOpened: false,
        sendMessageModalOpened: false,
        selectedAmbassadorMessage: null,
        lastFetched: "",
        convoDropdownIndex: -1,
        daysInPast: 30,
        activityChart: {
          title: "Platform Activity by Month",
          type: "line",
          options: {
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
              mode: "index",
              intersect: false,
            },
            plugins: {
              legend: {
                position: "bottom",
              },
              datalabels: {
                display: false,
              },
            },
            elements: {
              point: {
                radius: 0,
              },
            },
            scales: {
              x: {
                offset: false,
              },
            },
          },
          data: null,
        },
        conversationChart: {
          title: "Conversations Started by Month",
          type: "bar",
          options: {
            responsive: true,
            maintainAspectRatio: false,
            borderRadius: 10,
            plugins: {
              legend: {
                position: "bottom",
              },
              tooltip: {
                mode: "index",
                intersect: false,
              },
              datalabels: {
                color: "white",
                formatter: (val) => {
                  return val || "";
                },
              },
            },
            scales: {
              x: {
                stacked: true,
              },
              y: {
                stacked: true,
              },
            },
          },
          data: null,
        },

        calls: [],
      };
    },
    computed: {
      quickMenuOptions() {
        let options = [
          {
            label: "Invite Ambassadors",
            value: 0,
          },
          // {
          // 	label: 'Message Ambassador',
          // 	value: 1,
          // },
          {
            label: "Initiate Individual Outreach",
            value: 2,
          },
          {
            label: "Initiate Bulk Outreach",
            value: 3,
          },
        ];

        if (this.getBranding?.features?.allowConversationExport) {
          options.push({
            label: this.$lang.general_ExportSafeguarding,
            value: 4,
          });
        }

        return options;
      },

      fromDate() {
        const today = new Date();
        return new Date().setDate(today.getDate() - this.daysInPast);
      },

      prospectStatTip() {
        return `<p style="text-align: left;">New in last 30 days: ${this.prospectStats.new}</p>`;
      },

      ambStatTip() {
        let newAmbs;
        let active;
        let invited;

        if (this.ambStats.macro === 0 || this.ambStats.macro === null) {
          newAmbs, active, (invited = "0 (0%)");
        } else {
          newAmbs = `${this.ambStats.new} (${Math.round(
            (this.ambStats.new / this.ambStats.macro) * 100
          )}%)`;
          active = `${this.ambStats.active} (${Math.round(
            (this.ambStats.active / this.ambStats.macro) * 100
          )}%)`;
          invited = `${this.ambStats.invited} (${Math.round(
            (this.ambStats.invited / this.ambStats.macro) * 100
          )}%)`;
        }

        return `<p style="text-align: left;">New: ${newAmbs}</p><p style="text-align: left;">Active: ${active}</p><p style="text-align: left;">Invite: ${invited}</p><p style="text-align: left;">Profiles Visible: ${this.ambStats.visible}</p>`;
      },

      convoStatTip() {
        return `<p style="text-align: left;">New in last 30 Days: ${this.convoStats.new}</p><p style="text-align: left;">Active: ${this.convoStats.active}</p><p style="text-align: left;">Closed: ${this.convoStats.closed}</p><p style="text-align: left;">Stalled: ${this.convoStats.stalled}</p>`;
      },

      visitorStatTip() {
        let widgetTip = "";
        if (
          this.visitorStats.landingPage === 0 ||
          this.visitorStats.landingPage === null
        ) {
          widgetTip = this.visitorStats.widget;
        } else {
          widgetTip = `${this.visitorStats.widget} (${Math.round(
            (this.visitorStats.widget /
              (this.visitorStats.landingPage + this.visitorStats.widget)) *
              100
          )}%)`;
        }
        return `<p style="text-align: left;">Landing Page Loads: ${this.visitorStats.landingPage}</p><p style="text-align: left;">Widget Expands: ${widgetTip}</p>`;
      },

      engagementCalcs() {
        return {
          convos: this.calcPercentDifference(
            this.engagementTotals.convos,
            this.engagementTotalsPast.convos
          ),
          messages: this.calcPercentDifference(
            this.engagementTotals.messages,
            this.engagementTotalsPast.messages
          ),
          landingPage: this.calcPercentDifference(
            this.engagementTotals.landingPage,
            this.engagementTotalsPast.landingPage
          ),
          widget: this.calcPercentDifference(
            this.engagementTotals.widget,
            this.engagementTotalsPast.widget
          ),
          videoProfiles: this.calcPercentDifference(
            this.engagementTotals.videoProfiles,
            this.engagementTotalsPast.videoProfiles
          ),
          ctaButtons: this.calcPercentDifference(
            this.engagementTotals.ctaButtons,
            this.engagementTotalsPast.ctaButtons
          ),
        };
      },

      // returns number of messages from the ambassador in the stalled conversation
      numAmbMessages() {
        return this.stalledConvoMessages.filter(
          (message) => message.author === "amb"
        ).length;
      },

      // returns number of messages from the prospect in the stalled conversation
      numProspectMessages() {
        return this.stalledConvoMessages.filter(
          (message) => message.author === "prospect"
        ).length;
      },

      totalRequestTip() {
        let email;
        let video;
        let phone;
        if (this.totalRequests.count === 0) {
          (email = "0 (0%)"), (video = "0 (0%)"), (phone = "0 (0%)");
        } else {
          email = `${this.totalRequests.email} (${Math.round(
            (this.totalRequests.email / this.totalRequests.count) * 100
          )}%)`;
          video = `${this.totalRequests.video} (${Math.round(
            (this.totalRequests.video / this.totalRequests.count) * 100
          )}%)`;
          phone = `${this.totalRequests.phone} (${Math.round(
            (this.totalRequests.phone / this.totalRequests.count) * 100
          )}%)`;
        }

        return `<p style="text-align: left;">Email: ${email}</p><p style="text-align: left;">Video: ${video}</p><p style="text-align: left;">Phone: ${phone}</p>`;
      },

      incompleteRequestTip() {
        return `<p style="text-align: left;">Pending Ambassador: ${this.incompleteRequests.pendingAmb}</p><p style="text-align: left;">Pending Video Review: ${this.incompleteRequests.pendingVidReview}</p><p style="text-align: left;">Scheduled to be Sent: ${this.incompleteRequests.scheduled}</p>`;
      },

      prospectResponsesTip() {
        let email;
        let video;
        let phone;
        if (this.prospectResponses.count === 0) {
          (email = "0 (0%)"), (video = "0 (0%)"), (phone = "0 (0%)");
        } else {
          email = `${this.prospectResponses.email} (${Math.round(
            (this.prospectResponses.email / this.prospectResponses.count) * 100
          )}%)`;
          video = `${this.prospectResponses.video} (${Math.round(
            (this.prospectResponses.video / this.prospectResponses.count) * 100
          )}%)`;
          phone = `${this.prospectResponses.phone} (${Math.round(
            (this.prospectResponses.phone / this.prospectResponses.count) * 100
          )}%)`;
        }

        return `<p style="text-align: left;">Email Outreach: ${email}</p><p style="text-align: left;">Video Outreach: ${video}</p><p style="text-align: left;">Phone Outreach: ${phone}</p>`;
      },

      ampEncodedOrg() {
        return this.organization?.replace("&", "%26");
      },

      orgDisplayName() {
        return this.getBranding.displayName || this.getBranding.name;
      },

      ...mapGetters({
        getBranding: GET_BRANDING,
        getCurrentUser: GET_CURRENT_USER,
        getProspects: GET_PROSPECTS,
        getAmbassadors: GET_CURRENT_PARENTS,
        getInvitedAmbassadors: GET_INVITED_AMBASSADORS,
        getCallOutreach: GET_CALL_OUTREACH,
        getEmailOutreach: GET_EMAIL_OUTREACH,
        getVideoOutreach: GET_VIDEO_OUTREACH,
        getConversations: GET_CONVERSATIONS,
        getActiveYear: GET_ACTIVE_YEAR,
        getVisitorViews: GET_VISITOR_VIEWS,
        getEngagementStats: GET_ENGAGEMENT_STATS,
        getEngagementGraph: GET_ENGAGEMENT_GRAPH,
        getPendingVideoInvitations: GET_PENDING_VIDEO_INVITATIONS,
        getPendingVideoStories: GET_PENDING_VIDEO_STORIES,
        getConversationGraph: GET_CONVERSATION_GRAPH,
        getLastFetched: GET_LAST_FETCHED,
      }),
    },

    watch: {
      getAmbassadors() {
        this.parseAmbStats();
      },

      getInvitedAmbassadors() {
        this.parseAmbStats();
      },

      getProspects() {
        this.parseProspectStats();
      },

      getCallOutreach() {
        this.parseAllOutreach();
      },

      getEmailOutreach() {
        this.parseAllOutreach();
      },

      getVideoOutreach() {
        this.parseAllOutreach();
      },

      getConversations() {
        this.parseAllConversations();
        this.parseConversationsForGraph(true);
      },

      getPendingVideoInvitations() {
        this.parsePendingVideoInviteSubmissions();
      },

      getPendingVideoStories() {
        this.parsePendingVideoStories();
      },

      getEngagementGraph() {
        this.setupActivityChart();
      },

      getConversationGraph() {
        this.setupConversationChart();
      },

      getLastFetched(recentFetch) {
        if (this.fetching) {
          // only show toast message if refreshing
          this.$toasted.show("Table data refreshed.", this.$toastedSuccess);
        }
        this.lastFetched = recentFetch.home;
        this.fetching = false;
      },
    },

    created() {
      this.organization = this.getBranding.name;
      this.$store.dispatch(SET_LAST_FETCHED, { home: new Date() });
      if (this.organization) {
        this.loadCalls();
        this.loadVisitorViews();
        this.loadEngagementStats();
        this.parseProspectStats();
        this.parseAmbStats();
        this.parseAllConversations();
        this.parseConversationsForGraph();
        this.parseAllOutreach();
        this.parsePendingVideoInviteSubmissions();
        this.parsePendingVideoStories();
        this.parsePublishedVideoStories();
        this.loadEngagementGraph();
      }
    },
    mounted() {
      this.setupActivityChart();
      this.setupConversationChart();
    },
    methods: {
      async loadCalls() {
        const data = {
          selectedYear: this.getActiveYear,
          organization: this.getBranding.name,
        };

        let response = await APIService.dashboard.getAllCalls(data);
        if (response.status === 200) {
          this.calls = response.data;
        }
      },

      convoDropdown(index) {
        return this.convoDropdownIndex === index ? "block" : "hidden";
      },

      toggleConvoDropdown(convo, index, blur = false) {
        // This is because @blur gets called before @click. We need to keep track of this index because of the setTimeout func.
        const previousIndex = this.convoDropdownIndex;
        // const clickedConvoIndex = this.stalledConvos.indexOf(convo);
        if (this.convoDropdownIndex === index) {
          // Saving the index of the ambassador in the original array, not the displayed ambassadors array. This is because the displayed ambassadors array
          // can vary, depending on what filter is on, what pagination page is shown, etc...

          setTimeout(() => {
            if (previousIndex === this.convoDropdownIndex)
              this.convoDropdownIndex = -1;
          }, 200);
        } else {
          if (!blur) {
            this.convoDropdownIndex = index;
            // this.ambassadorsListIndex = clickedConvoIndex;
          }
        }
      },

      handleQuickMenuClick(option) {
        switch (option) {
          case 0:
            this.inviteAmbassadorModalOpened = true;
            return;
          case 1:
            return;
          case 2:
            this.requestOutreachModalOpened = true;
            return;
          case 3:
            this.$router.push({ name: screens.OUTREACH_SCREEN });
            return;
          case 4:
            this.exportConversations();
            return;
        }
      },

      async exportConversations() {
        if (
          !(await exportSafeguardingReport(
            null,
            null,
            this.organization,
            this.fromDate
          ))
        ) {
          this.$toasted.info(this.$lang.ambassadorPage_SafeguardingNoMessages);
        }
      },

      openMessageModal(convo) {
        let ambassador = {
          _id: convo.recipID,
          first: convo.recipName.split(" ")[0],
          last: convo.recipName.split(" ")[1],
        };
        this.selectedAmbassadorMessage = ambassador;
        this.sendMessageModalOpened = true;
      },

      closeConversation: async function (convo) {
        // make API request to close conversation and then filter it out of stalled convos array
        let response = await APIService.chat.closeConversation(convo.id);

        if (response?.status === 200) {
          let filteredStalledConvos = this.stalledConvos.filter(
            (selectedConvo) => {
              return selectedConvo.id !== convo.id;
            }
          );

          this.stalledConvos = [...filteredStalledConvos];
          this.$toasted.show(
            "The conversation was marked as closed.",
            this.$toastedSuccess
          );
        } else {
          this.$toasted.show(
            "Failed to mark the conversation was marked as closed.",
            this.$toastedFailure
          );
        }
      },

      fetchMostRecent: async function () {
        this.fetching = true;

        this.clearData();

        // ambassador loading
        await this.$store.dispatch(FETCH_CURRENT_PARENTS);
        await this.$store.dispatch(FETCH_INVITED_AMBASSADORS);
        // prospect loading
        await this.$store.dispatch(FETCH_PROSPECTS);
        // outreach loading
        await this.$store.dispatch(FETCH_ALL_OUTREACH);
        // conversation loading
        await this.$store.dispatch(FETCH_CONVERSATIONS);
        await this.$store.dispatch(SET_LAST_FETCHED, { home: new Date() });

        // pending videos
        this.parsePendingVideoInviteSubmissions(true);
        this.parsePendingVideoStories(true);
        this.parsePublishedVideoStories(true);

        // amplitude stat loading
        this.loadVisitorViews(true);
        this.loadEngagementStats(true);
        this.loadEngagementGraph(true);
      },

      clearData() {
        this.prospectStats = {
          macro: null,
          new: 0,
        };

        this.ambStats = {
          macro: null,
          new: 0,
          active: 0,
          invited: 0,
          visible: 0,
        };

        this.convoStats = {
          macro: null,
          new: 0,
          active: 0,
          closed: 0,
          stalled: 0,
        };

        this.visitorStats = {
          macro: null,
          landingPage: 0,
          widget: 0,
        };

        this.engagementTotals = {
          convos: null,
          messages: null,
          landingPage: null,
          widget: null,
          videoProfiles: null,
          ctaButtons: null,
        };

        this.engagementTotalsPast = {
          convos: null,
          messages: null,
          landingPage: null,
          widget: null,
          videoProfiles: null,
          ctaButtons: null,
        };
        this.activityChart.data = null;
        this.conversationChart.data = null;
        this.stalledConvos = [];
        this.totalRequests = {
          count: null,
          email: 0,
          video: 0,
          phone: 0,
        };
        this.incompleteRequests = {
          count: null,
          pendingAmb: 0,
          pendingVidReview: 0,
          scheduled: 0,
        };

        this.prospectResponses = {
          count: null,
          email: 0,
          video: 0,
          phone: 0,
        };
        this.pendingVideoReviews = [];
      },

      viewStalledConvo(convo) {
        this.stalledConvoModalOpened = true;
        this.selectedStalledConvo = { ...convo };
        this.loadStalledConversation();
      },

      closeStalledConvoModal() {
        this.stalledConvoModalOpened = false;
        this.selectedStalledConvo = {};
        this.stalledConvoMessages = [];
      },

      viewPendingVideo(vid) {
        if (vid.type === "Outreach") {
          this.pendingVideoModalOpened = true;
          this.selectedPendingVideo = { ...vid };
        } else if (vid.type === "WMS") {
          // WMS = watch my story
          this.pendingVideoStoryModalOpened = true;
          this.selectedPendingVideoStory = vid;
        }
      },

      closePendingVideoModal() {
        this.pendingVideoModalOpened = false;
        this.showPendingVideoFeedback = false;
        this.selectedPendingVideo = {};
      },

      parseProspectStats() {
        const prospects = this.getProspects;

        if (!prospects) {
          this.prospectStats = {
            macro: 0,
            new: 0,
          };
          return;
        }

        let newProspects = prospects.filter((prospect) => {
          return dateService.isOlderThan(prospect.createdDate, 30);
        });

        this.prospectStats = {
          macro: prospects.length,
          new: newProspects.length,
        };
      },

      parseAmbStats() {
        const ambs = this.getAmbassadors;
        const invitedAmbs = this.getInvitedAmbassadors;

        if (!ambs || !invitedAmbs) {
          this.ambStats = {
            macro: 0,
            new: 0,
            active: 0,
            invited: 0,
            visible: 0,
          };
          return;
        }

        let total = ambs.length + invitedAmbs.length;
        let newAmbs = 0;
        let activeAmbs = 0;
        let invited = invitedAmbs.length;
        let visible = 0;

        for (let amb of ambs) {
          if (dateService.isOlderThan(amb.createdDate, 7)) {
            newAmbs += 1;
          }

          if (!amb.archived) {
            activeAmbs += 1;

            if (amb.isActive) {
              visible += 1;
            }
          }
        }

        this.ambStats = {
          macro: total,
          new: newAmbs,
          active: activeAmbs,
          invited: invited,
          visible: visible,
        };
      },

      // this is the wrapper function that calls all the parsing functions relevant outreach
      parseAllOutreach() {
        const emailOutreach = this.getEmailOutreach || [];
        const videoOutreach = this.getVideoOutreach;
        const callOutreach = this.getCallOutreach;

        this.parseOutreachStats(emailOutreach, videoOutreach, callOutreach);
        this.parseIncompleteOutreachStats(
          emailOutreach,
          videoOutreach,
          callOutreach
        );
        this.parseProspectResponseStats(
          emailOutreach,
          videoOutreach,
          callOutreach
        );
      },

      parseOutreachStats(emailOutreach, videoOutreach, callOutreach) {
        let emailTotal = 0;
        let videoTotal = 0;
        let callTotal = 0;
        let total = 0;

        if (emailOutreach) {
          emailTotal = emailOutreach.length;
        }
        if (
          videoOutreach &&
          videoOutreach.videoInvites &&
          videoOutreach.videoInvites.invitations &&
          videoOutreach.videoInvites.requests
        ) {
          videoTotal =
            videoOutreach.videoInvites.invitations.length +
            videoOutreach.videoInvites.requests.length;
        }

        if (
          callOutreach &&
          callOutreach.completedRequests &&
          callOutreach.pendingRequests
        ) {
          callTotal =
            callOutreach.completedRequests.length +
            callOutreach.pendingRequests.length;
        }

        total = emailTotal + videoTotal + callTotal;

        this.totalRequests = {
          count: total,
          email: emailTotal,
          video: videoTotal,
          phone: callTotal,
        };
      },

      parseIncompleteOutreachStats(emailOutreach, videoOutreach, callOutreach) {
        let pendingAmbTotal = 0;
        let pendingVidReviewTotal = 0;
        let scheduledTotal = 0;
        let total = 0;

        if (emailOutreach) {
          pendingAmbTotal += emailOutreach.filter((request) => {
            return !request.inviteSent;
          }).length;
        }

        if (videoOutreach && videoOutreach.videoInvites) {
          if (videoOutreach.videoInvites.requests) {
            pendingAmbTotal += videoOutreach.videoInvites.requests.length;
          }
          if (videoOutreach.videoInvites.submissions) {
            pendingVidReviewTotal =
              videoOutreach.videoInvites.submissions.length;
          }
          if (videoOutreach.videoInvites.scheduledSubmissions) {
            scheduledTotal =
              videoOutreach.videoInvites.scheduledSubmissions.length;
          }
        }
        if (callOutreach && callOutreach.pendingRequests) {
          pendingAmbTotal += callOutreach.pendingRequests.length;
        }

        total = pendingAmbTotal + pendingVidReviewTotal + scheduledTotal;

        this.incompleteRequests = {
          count: total,
          pendingAmb: pendingAmbTotal,
          pendingVidReview: pendingVidReviewTotal,
          scheduled: scheduledTotal,
        };
      },

      parseProspectResponseStats(emailOutreach, videoOutreach, callOutreach) {
        let emailTotal = 0;
        let videoTotal = 0;
        let callTotal = 0;
        let total = 0;

        if (emailOutreach) {
          emailTotal += emailOutreach.filter((request) => {
            return request.accountMade;
          }).length;
        }

        if (videoOutreach && videoOutreach.respondedVideoInvites) {
          videoTotal = videoOutreach.respondedVideoInvites.length;
        }

        if (callOutreach && callOutreach.completedRequests) {
          callTotal = callOutreach.completedRequests.length;
        }

        total = emailTotal + videoTotal + callTotal;
        this.prospectResponses = {
          count: total,
          email: emailTotal,
          video: videoTotal,
          phone: callTotal,
        };
      },

      parseAllConversations() {
        let convos = this.getConversations;
        if (!convos?.messages) {
          this.convoStats.macro = 0;
          this.convoStats.new = 0;
          this.convoStats.active = 0;
          this.convoStats.closed = 0;
          this.convoStats.stalled = 0;
          return;
        }

        let stalledConvos = [];
        let activeConvos = 0;
        let closedConvos = 0;
        let newConvos = 0;

        for (let convo of convos.messages) {
          convo.messageFormattedDate = this.formatDate(convo.sentAt); // TODO: leaving blank for now because the system doesn't track feedback submit date
          convo.createdAtFormattedDate = this.formatDate(convo.createdAt);

          // stalled convos are those that are still active, where the last message was sent more than 5 days ago
          if (convo.isConvoActive) {
            if (!dateService.isOlderThan(convo.sentAt, 5)) {
              stalledConvos.push(convo);
            }
            activeConvos += 1;
          } else {
            closedConvos += 1;
          }

          if (dateService.isOlderThan(convo.createdAt, 30)) {
            newConvos += 1;
          }
        }

        this.stalledConvos = stalledConvos.reverse();
        this.sortStalledConvos(); // sort stalled convos

        this.convoStats.macro = convos.messages.length;
        this.convoStats.new = newConvos;
        this.convoStats.active = activeConvos;
        this.convoStats.closed = closedConvos;
        this.convoStats.stalled = stalledConvos.length;
      },

      loadStalledConversation: async function () {
        const data = { conversationID: this.selectedStalledConvo.id };

        const response = await APIService.dashboard.convoDetails(data);
        if (response?.status === 200) {
          let messages = response.data.chats;
          messages.forEach((message) => {
            // show blue messages if prospect sent them, grey messages if amb sent them
            if (
              message.authorUserId === this.selectedStalledConvo.authorID ||
              message.authorAdministratorId ===
                this.selectedStalledConvo.authorID
            ) {
              message.author = "prospect";
            } else {
              message.author = "amb";
            }
            message.formattedTime = this.formatDate(message.createdDate);
          });
          this.stalledConvoMessages = messages;
        }
      },

      loadVisitorViews: async function (hardReset) {
        // leave function if stats are already in state
        let visitorStats = this.getVisitorViews;
        if (visitorStats && visitorStats.macro && !hardReset) {
          this.visitorStats = visitorStats;
          return;
        }

        let { changeOver, today } = this.getChangeOver();

        // getting landing page views
        let url = `https://amplitude.com/api/2/events/segmentation?e={"event_type":"HOME_PAGE_VIEW","filters":[{"subprop_type":"event","subprop_key":"org","subprop_op":"is","subprop_value":["${this.ampEncodedOrg}"]}]}&m=totals&start=${changeOver}&end=${today}`;
        let response = await APIService.dashboard.amplitude({ url: url });
        let landingPageViews = 0;
        if (response?.status === 200) {
          if (
            response.data &&
            response.data.series &&
            response.data.series[0]
          ) {
            landingPageViews = response.data.series[0].reduce(
              (a, b) => a + b,
              0
            );
          }
        } else {
          this.visitorStats = {
            macro: 0,
            landingPage: 0,
            widget: 0,
          };
          this.$store.dispatch(SET_VISITOR_VIEWS, this.visitorStats);
          return;
        }

        // getting widget expands
        url = `https://amplitude.com/api/2/events/segmentation?e={"event_type":"WIDGET_EXPANDED","filters":[{"subprop_type":"event","subprop_key":"org","subprop_op":"is","subprop_value":["${this.ampEncodedOrg}"]}]}&m=totals&start=${changeOver}&end=${today}`;
        response = await APIService.dashboard.amplitude({ url: url });
        let widgetExpands = 0;
        if (response?.status === 200) {
          if (response?.data?.series?.[0]) {
            widgetExpands = response.data.series[0].reduce((a, b) => a + b, 0);
          }
        } else {
          this.visitorStats = {
            macro: 0,
            landingPage: 0,
            widget: 0,
          };
          this.$store.dispatch(SET_VISITOR_VIEWS, this.visitorStats);
          return;
        }

        this.visitorStats = {
          macro: landingPageViews + widgetExpands,
          landingPage: landingPageViews,
          widget: widgetExpands,
        };
        this.$store.dispatch(SET_VISITOR_VIEWS, this.visitorStats);
      },

      loadEngagementStats: async function (hardReset) {
        let { engagementTotals, engagementTotalsPast } =
          this.getEngagementStats;

        if (
          engagementTotals &&
          engagementTotals.messages !== null &&
          engagementTotalsPast &&
          engagementTotalsPast.messages !== null &&
          !hardReset
        ) {
          this.engagementTotals = engagementTotals;
          this.engagementTotalsPast = engagementTotalsPast;
          return;
        }

        let { twoMonthsAgo, today } = this.getDateTwoMonthsAgo();

        // CONVO_STARTED
        let url = `https://amplitude.com/api/2/events/segmentation?e={"event_type":"CONVO_STARTED","filters":[{"subprop_type":"user","subprop_key":"gp:org","subprop_op":"is","subprop_value":["${this.ampEncodedOrg}"]}]}&m=totals&start=${twoMonthsAgo}&end=${today}`;
        let response = await APIService.dashboard.amplitude({ url: url });
        let convoStartedPast = 0;
        let convoStartedPresent = 0;

        if (response?.status === 200 && response?.data?.series?.[0]) {
          let result = this.findSumForHalvesOfArray(response.data.series[0]);
          convoStartedPast = result[0];
          convoStartedPresent = result[1];
        }

        // MESSAGE_SENT
        url = `https://amplitude.com/api/2/events/segmentation?e={"event_type":"MESSAGE_SENT","filters":[{"subprop_type":"user","subprop_key":"gp:org","subprop_op":"is","subprop_value":["${this.ampEncodedOrg}"]}]}&m=totals&start=${twoMonthsAgo}&end=${today}`;
        response = await APIService.dashboard.amplitude({ url: url });
        let messagesSentPast = 0;
        let messagesSentPresent = 0;

        if (response?.status === 200) {
          if (response?.data?.series?.[0]) {
            let result = this.findSumForHalvesOfArray(response.data.series[0]);
            messagesSentPast = result[0];
            messagesSentPresent = result[1];
          }
        }

        // HOME_PAGE_VIEW
        url = `https://amplitude.com/api/2/events/segmentation?e={"event_type":"HOME_PAGE_VIEW","filters":[{"subprop_type":"event","subprop_key":"org","subprop_op":"is","subprop_value":["${this.ampEncodedOrg}"]}]}&m=totals&start=${twoMonthsAgo}&end=${today}`;
        response = await APIService.dashboard.amplitude({ url: url });
        let homePageViewPast = 0;
        let homePageViewPresent = 0;

        if (response?.status === 200) {
          if (response?.data?.series?.[0]) {
            let result = this.findSumForHalvesOfArray(response.data.series[0]);
            homePageViewPast = result[0];
            homePageViewPresent = result[1];
          }
        }

        // WIDGET_EXPANDED
        url = `https://amplitude.com/api/2/events/segmentation?e={"event_type":"WIDGET_EXPANDED","filters":[{"subprop_type":"event","subprop_key":"org","subprop_op":"is","subprop_value":["${this.ampEncodedOrg}"]}]}&m=totals&start=${twoMonthsAgo}&end=${today}`;
        response = await APIService.dashboard.amplitude({ url: url });
        let widgetExpandPast = 0;
        let widgetExpandPresent = 0;

        if (response?.status === 200) {
          if (response?.data?.series?.[0]) {
            let result = this.findSumForHalvesOfArray(response.data.series[0]);
            widgetExpandPast = result[0];
            widgetExpandPresent = result[1];
          }
        }

        // LINK_CLICKED
        url = `https://amplitude.com/api/2/events/segmentation?e={"event_type":"LINK_CLICKED","filters":[{"subprop_type":"event","subprop_key":"org","subprop_op":"is","subprop_value":["${this.ampEncodedOrg}"]}]}&m=totals&start=${twoMonthsAgo}&end=${today}`;
        response = await APIService.dashboard.amplitude({ url: url });
        let linkClickedPast = 0;
        let linkClickedPresent = 0;

        if (response?.status === 200) {
          if (response?.data?.series?.[0]) {
            let result = this.findSumForHalvesOfArray(response.data.series[0]);
            linkClickedPast = result[0];
            linkClickedPresent = result[1];
          }
        }

        this.engagementTotalsPast.convos = convoStartedPast;
        this.engagementTotals.convos = convoStartedPresent;
        this.engagementTotalsPast.messages = messagesSentPast;
        this.engagementTotals.messages = messagesSentPresent;
        this.engagementTotalsPast.landingPage = homePageViewPast;
        this.engagementTotals.landingPage = homePageViewPresent;
        this.engagementTotalsPast.widget = widgetExpandPast;
        this.engagementTotals.widget = widgetExpandPresent;
        this.engagementTotalsPast.ctaButtons = linkClickedPast;
        this.engagementTotals.ctaButtons = linkClickedPresent;

        this.$store.dispatch(SET_ENGAGEMENT_STATS, {
          engagementTotals: this.engagementTotals,
          engagementTotalsPast: this.engagementTotalsPast,
        });
      },

      loadEngagementGraph: async function (hardReset) {
        if (this.getEngagementGraph.data?.length && !hardReset) {
          return;
        }

        // CONVO_STARTED
        let { changeOver, today } = this.getChangeOver();
        let labels = [];

        let url = `https://amplitude.com/api/2/events/segmentation?e={"event_type":"CONVO_STARTED","filters":[{"subprop_type":"user","subprop_key":"gp:org","subprop_op":"is","subprop_value":["${this.ampEncodedOrg}"]}]}&m=totals&i=30&start=${changeOver}&end=${today}`;
        let response = await APIService.dashboard.amplitude({ url: url });
        let convoStartedGraphData = [];

        if (response?.data?.series?.[0]) {
          convoStartedGraphData = response.data.series[0];
          labels = response.data.xValues.map((xValue) => {
            const [year, month, day] = xValue.split("-");
            const date = new Date(year, month - 1, day);
            return dateService.toLocaleDateString(date, { month: "long" });
          });
        }

        // HOME_PAGE_VIEW
        url = `https://amplitude.com/api/2/events/segmentation?e={"event_type":"HOME_PAGE_VIEW","filters":[{"subprop_type":"event","subprop_key":"org","subprop_op":"is","subprop_value":["${this.ampEncodedOrg}"]}]}&m=totals&i=30&start=${changeOver}&end=${today}`;
        response = await APIService.dashboard.amplitude({ url: url });
        let homePageGraphData = [];

        if (response?.status === 200 && response.data?.series?.[0]) {
          homePageGraphData = response.data.series[0];
        }

        // WIDGET_EXPANDED
        url = `https://amplitude.com/api/2/events/segmentation?e={"event_type":"WIDGET_EXPANDED","filters":[{"subprop_type":"event","subprop_key":"org","subprop_op":"is","subprop_value":["${this.ampEncodedOrg}"]}]}&m=totals&i=30&start=${changeOver}&end=${today}`;
        response = await APIService.dashboard.amplitude({ url: url });
        let widgetExpandGraphData = [];

        if (response?.status === 200 && response.data?.series?.[0]) {
          widgetExpandGraphData = response.data.series[0];
        }

        // update line graph data
        const data = [
          {
            name: "Landing Page Loads",
            data: homePageGraphData,
          },
          {
            name: "Widget Expands",
            data: widgetExpandGraphData,
          },
          {
            name: "Conversations Started",
            data: convoStartedGraphData,
          },
        ];

        this.$store.dispatch(SET_ENGAGEMENT_GRAPH, { data, labels });
      },

      // load pending video invite submissions waiting for approval from admin
      parsePendingVideoInviteSubmissions: async function (hardReset) {
        let pendingVideoInvites = this.getPendingVideoInvitations;
        if (pendingVideoInvites && !hardReset) {
          // if video invites exist in state, remove existing invites and replace
          let vidReviewsWithoutInvites = this.pendingVideoReviews.filter(
            (video) => {
              return video.type !== "Outreach";
            }
          );
          this.pendingVideoReviews = [
            ...vidReviewsWithoutInvites,
            ...pendingVideoInvites,
          ];
          return;
        }

        let response =
          await APIService.dashboard.getPendingVideoInviteSubmissions({
            organization: this.organization,
          });

        if (response?.status === 200) {
          let pendingSubmissions = response.data;

          // parsing invitation submissions
          let submissions = pendingSubmissions.filter((submission) => {
            return (
              submission.videoInvitationRequest &&
              submission.videoInvitationRequest.inviterUserId &&
              submission.videoInvitationRequest.inviteeFirst
            );
          });

          submissions = submissions.map((submission) => {
            let formattedDate = this.formatDate(submission.createdDate);

            return {
              ambName:
                submission.videoInvitationRequest.inviterUser.first +
                " " +
                submission.videoInvitationRequest.inviterUser.last,
              ambImageURL:
                submission.videoInvitationRequest.inviterUser.profileImageUrl,
              prospectName:
                submission.videoInvitationRequest.inviteeFirst +
                " " +
                submission.videoInvitationRequest.inviteeLast,
              date: submission.createdDate,
              formattedDate: formattedDate,
              type: "Outreach",
              video: submission.video,
              _id: submission.id,
            };
          });

          this.pendingVideoReviews = [
            ...this.pendingVideoReviews,
            ...submissions,
          ];

          this.sortPendingVideoReviews(); // method to sort the video reviews based on date
          this.$store.dispatch(SET_PENDING_VIDEO_INVITATIONS, submissions);
        }
      },

      // load pending video stories waiting for approval from admin
      // hard reset and make API call if data isn't in state or we want to refresh the list
      parsePendingVideoStories: async function (hardReset) {
        let pendingVideoStories = this.getPendingVideoStories;
        if (pendingVideoStories && !hardReset) {
          // if video invites exist in state, remove existing stories and replace
          let vidReviewsWithoutStories = this.pendingVideoReviews.filter(
            (video) => {
              return video.type !== "WMS";
            }
          );
          this.pendingVideoReviews = [
            ...vidReviewsWithoutStories,
            ...pendingVideoStories,
          ];
          return;
        }

        let response = await APIService.dashboard.getPendingVideoStories({
          organization: this.organization,
        });

        if (response?.status === 200) {
          let pendingStories = response.data.filter((story) => {
            return story.userID;
          });

          let stories = pendingStories.map((story) => {
            let formattedDate = this.formatDate(story.createdDate);

            return {
              ambID: story.ambassadorUser.id,
              ambName:
                story.ambassadorUser.first + " " + story.ambassadorUser.last,
              ambImageURL: story.ambassadorUser.profileImageUrl,
              date: story.createdDate,
              formattedDate: formattedDate,
              type: "WMS",
              video: story.video,
              title: story.title,
              _id: story.id,
              authorID: story.authorUserId || story.authorAdministratorId,
              authorType: story.authorUserId ? "User" : "Administrator",
            };
          });

          this.pendingVideoReviews = [...this.pendingVideoReviews, ...stories];
          this.sortPendingVideoReviews(); // sort pending reviews based on date
          this.$store.dispatch(SET_PENDING_VIDEO_STORIES, stories);
        }
      },

      refreshPendingStories() {
        this.pendingVideoReviews = this.pendingVideoReviews.filter((video) => {
          return video.type !== "WMS";
        });
        this.parsePendingVideoStories(true);
      },

      findSumForHalvesOfArray(arr) {
        const { firstHalf, secondHalf } = this.splitArrayInHalf(arr);

        const firstHalfSum = firstHalf.reduce((a, b) => a + b, 0);
        const secondHalfSum = secondHalf.reduce((a, b) => a + b, 0);

        return [firstHalfSum, secondHalfSum];
      },

      splitArrayInHalf(arr) {
        const half = Math.ceil(arr.length / 2);

        const firstHalf = arr.slice(0, half);
        const secondHalf = arr.slice(-half);
        return {
          firstHalf,
          secondHalf,
        };
      },

      calcPercentDifference(num1, num2) {
        if (num2 === 0) {
          return null;
        }

        let percentDif = (num1 - num2) / num2;
        percentDif = Math.round(percentDif * 100);

        return percentDif;
      },

      formatDate(date) {
        return dateService.toDisplayDate(date);
      },

      getDateTwoMonthsAgo() {
        const today = new Date();
        const twoMonthsAgo = new Date();
        twoMonthsAgo.setDate(twoMonthsAgo.getDate() - 59);

        return {
          twoMonthsAgo: dateService.amplitudeDate(twoMonthsAgo),
          today: dateService.amplitudeDate(today),
        };
      },

      // gets the date and year of the changeover in amplitude friendly form
      getChangeOver() {
        if (!this.getActiveYear) {
          return;
        }

        let selectedYear = this.getActiveYear;
        let month;
        let day;

        if (
          !this.getBranding.changeOverDate ||
          !this.getBranding.changeOverDate[0]
        ) {
          month = "06";
          day = "01";
        } else {
          month = this.getBranding.changeOverDate[0];
          day = this.getBranding.changeOverDate[1];
        }

        const today = dateService.amplitudeDate(new Date());
        const changeOver = selectedYear + month + day;
        const nextChangeOver = selectedYear + 1 + month + day;

        if (today > nextChangeOver) {
          // if today is after next change over date, send next change over date
          return {
            changeOver,
            today: nextChangeOver,
          };
        } else {
          // if today is before next change over date, send todays date
          return {
            changeOver,
            today,
          };
        }
      },

      dateStringToDateObj(dateString) {
        let year = dateString.substring(0, 4);
        let month = dateString.substring(4, 6);
        let day = dateString.substring(6, 8);

        return new Date(year, month - 1, day);
      },

      sortStalledConvos() {
        let convos = [...this.stalledConvos];
        let sortedConvos = this.quickSort(
          convos,
          "sentAt",
          0,
          convos.length - 1
        );
        this.stalledConvos = sortedConvos;
      },

      sortPendingVideoReviews() {
        let videos = [...this.pendingVideoReviews];
        let sortedVideos = this.quickSort(videos, "date", 0, videos.length - 1);
        this.pendingVideoReviews = sortedVideos;
      },

      approveVideoInviteSubmission: async function () {
        const allowed = confirm(
          `Are you sure you want to approve and send this video to ${this.selectedPendingVideo.prospectName}?`
        );

        if (!allowed) {
          return;
        }

        let payload = {
          videoInvitationSubmission: this.selectedPendingVideo._id,
          status: "approved",
        };

        const response = await APIService.dashboard.updateVideoInviteSubmission(
          payload
        );
        if (response?.status === 200) {
          this.$toasted.show(
            "The video message was successfully sent.",
            this.$toastedSuccess
          );
          this.pendingVideoReviews = this.pendingVideoReviews.filter(
            (video) => {
              return video._id !== this.selectedPendingVideo._id;
            }
          );
          this.closePendingVideoModal();
        } else {
          this.$toasted.show(
            `There was an error updating this submission. Please try again.`,
            this.$toastedFailure
          );
        }
      },

      clickedRerecordVideoInviteSubmission: async function () {
        this.showPendingVideoFeedback = true;
      },

      denyVideoInviteSubmission: async function () {
        let payload = {
          videoInvitationSubmission: this.selectedPendingVideo._id,
          status: "rejected",
          feedback: this.selectedPendingVideo.feedback,
        };

        const allowed = confirm(
          `Are you sure you want to reject this video? If so, ${this.selectedPendingVideo.ambName} will be prompted to re-record their submission.`
        );

        if (!allowed) {
          return;
        }

        const response = await APIService.dashboard.updateVideoInviteSubmission(
          payload
        );
        if (response?.status === 200) {
          this.$toasted.show(
            "This submission has been rejected and a new video has been requested.",
            this.$toastedSuccess
          );
          this.pendingVideoReviews = this.pendingVideoReviews.filter(
            (video) => {
              return video._id !== this.selectedPendingVideo._id;
            }
          );
          this.closePendingVideoModal();
        } else {
          this.$toasted.show(
            "There was an error processing your request. Please try again.",
            this.$toastedFailure
          );
        }
      },

      parsePublishedVideoStories: async function (hardReset) {
        let stats = this.getEngagementStats;
        if (
          stats.engagementTotals.videoProfiles &&
          stats.engagementTotalsPast.videoProfiles &&
          !hardReset
        ) {
          this.engagementTotals.videoProfiles =
            stats.engagementTotals.videoProfiles;
          this.engagementTotalsPast.videoProfiles =
            stats.engagementTotalsPast.videoProfiles;
          return;
        }

        const response = await APIService.dashboard.getPublishedVideoStories({
          organization: this.organization,
        });

        if (response?.status === 200) {
          let stories = response.data;
          let storiesPast = 0;
          let storiesPresent = 0;
          for (let story of stories) {
            if (dateService.isOlderThan(story.createdAt, 30)) {
              // stories within the last 30 days
              storiesPresent += 1;
            } else if (dateService.isOlderThan(story.createdAt, 60)) {
              // stories between 30 days and 60 days ago
              storiesPast += 1;
            }
          }

          this.engagementTotals.videoProfiles = storiesPresent;
          this.engagementTotalsPast.videoProfiles = storiesPast;
          this.$store.dispatch(SET_ENGAGEMENT_STATS_VIDEO, {
            present: storiesPresent,
            past: storiesPast,
          });
        }
      },

      parseConversationsForGraph(hardReset) {
        if (this.getConversationGraph?.length && !hardReset) {
          return;
        }

        let convos = this.getConversations?.messages;
        if (!convos) {
          return;
        }

        let { changeOver, today } = this.getChangeOver();
        let changeOverDate = this.dateStringToDateObj(changeOver);
        let todayDate = this.dateStringToDateObj(today);
        const monthOnly = { month: "short" };

        // setting the x-axis and map
        const labels = [];
        const monthToIndexMap = {};
        const cur = changeOverDate;
        let i = 0;

        while (cur < todayDate) {
          const month = dateService.toLocaleDateString(cur, monthOnly);
          labels.push(month);
          monthToIndexMap[cur.getFullYear() + "-" + cur.getMonth()] = i++;
          cur.setMonth(cur.getMonth() + 1);
        }

        let prospectInitiatedConvos = new Array(labels.length).fill(0);
        let outreachInitiatedConvos = new Array(labels.length).fill(0);

        // sort conversations into months
        for (const convo of convos) {
          const date = new Date(convo.createdAt);
          const yearMonth = date.getFullYear() + "-" + date.getMonth();
          const index = monthToIndexMap[yearMonth];

          if (index !== undefined) {
            if (convo.initiator === "prospect") {
              prospectInitiatedConvos[index]++;
            } else if (convo.initiator === "amb") {
              outreachInitiatedConvos[index]++;
            }
          }
        }

        const data = [
          {
            name: "Prospect Initiated",
            data: prospectInitiatedConvos,
          },
          {
            name: "Outreach Initiated",
            data: outreachInitiatedConvos,
          },
        ];

        this.$store.dispatch(SET_CONVERSATION_GRAPH, { data, labels });
      },

      changeOverDateArrToDate(changeOverDateArr) {
        let currentYear = new Date().getFullYear();
        let changeOver =
          currentYear + "." + changeOverDateArr[0] + "." + changeOverDateArr[1];
        let changeOverDate = new Date(changeOver);
        let todayDate = new Date();

        // if changeOverDate is greater than today's date, subtract one year from changeover date bc its in the future
        // otherwise, the changeOverDate is right
        if (changeOverDate > todayDate) {
          changeOver =
            currentYear -
            1 +
            "." +
            changeOverDateArr[0] +
            "." +
            changeOverDateArr[1];
          changeOverDate = new Date(changeOverDate);
        }

        return changeOverDate;
      },

      setupChart(chart, graph) {
        if (!graph.data?.length) {
          return;
        }

        let colorIndex = 0;

        chart.data = {
          labels: graph.labels,
          datasets: graph.data.map((set) => {
            const color = chartColors[colorIndex++];
            return {
              label: set.name,
              data: set.data,
              fill: false,
              tension: 0,
              borderColor: color,
              backgroundColor: color,
            };
          }),
        };
      },

      setupActivityChart() {
        this.setupChart(this.activityChart, this.getEngagementGraph);
        const chart = this.activityChart;
        const graph = this.getEngagementGraph;

        // offset chart when there is only one month of data
        const onlyOneMonth = graph.data[0]?.data.length === 1;

        chart.options.scales.x.offset = onlyOneMonth;
        chart.options.elements.point.radius = onlyOneMonth ? 3 : 0;
      },

      setupConversationChart() {
        this.setupChart(this.conversationChart, this.getConversationGraph);
      },

      quickSort(items, key, left, right) {
        let index;
        if (items.length > 1) {
          index = this.partition(items, key, left, right); //index returned from partition
          if (left < index - 1) {
            //more elements on the left side of the pivot
            this.quickSort(items, key, left, index - 1);
          }
          if (index < right) {
            //more elements on the right side of the pivot
            this.quickSort(items, key, index, right);
          }
        }
        return items;
      },

      // used for quicksort algorithm
      partition(items, key, left, right) {
        let pivot = items[Math.floor((right + left) / 2)], //middle element
          i = left, //left pointer
          j = right; //right pointer
        while (i <= j) {
          while (items[i][key] > pivot[key]) {
            i++;
          }
          while (items[j][key] < pivot[key]) {
            j--;
          }
          if (i <= j) {
            this.swap(items, i, j); //swap two elements
            i++;
            j--;
          }
        }
        return i;
      },

      swap(items, leftIndex, rightIndex) {
        let temp = items[leftIndex];
        items[leftIndex] = items[rightIndex];
        items[rightIndex] = temp;
      },
    },
  };
</script>
<style>
  .h-176 {
    height: 44rem;
  }
</style>
