<template>
  <focus-trap v-model="dropdownOpened" clickOutsideDeactivates>
    <div class="common-dropdown">
      <div ref="dropdownWrapper" class="common-dropdown-wrapper">
        <div
          :class="[
            'common-dropdown-options-wrapper',
            { hidden: !dropdownOpened },
          ]">
          <div
            :class="[
              'common-dropdown-options-inner-wrapper',
              dropdownDirection,
              width,
              { 'align-left': alignDropdownLeft },
            ]"
            aria-labelledby="dropdown-menu-button"
            role="menu">
            <div v-if="custom">
              <!-- Dropdown menu from slot -->
              <div
                :class="['common-dropdown-options-menu', calculateMenuHeight]">
                <slot name="custom" />
              </div>
            </div>

            <div v-else>
              <!-- Dropdown menu with groups -->
              <div
                v-if="groups"
                :class="['common-dropdown-options-menu', calculateMenuHeight]">
                <div
                  v-for="(group, index) of dropdownOptions"
                  :key="`common-dropdown-group-${index}`"
                  :class="`common-dropdown-group`">
                  <dropdown-option
                    :toggle="toggle"
                    :option="option"
                    v-for="(option, index) of group"
                    :key="`common-dropdown-option-${index}`"
                    @change="emitValue" />
                </div>
              </div>

              <!-- Dropdown menu without groups -->
              <div
                v-else
                :class="['common-dropdown-options-menu', calculateMenuHeight]">
                <dropdown-option
                  :toggle="toggle"
                  :option="option"
                  v-for="(option, index) of dropdownOptions"
                  :key="`common-dropdown-option-${index}`"
                  @change="emitValue" />
              </div>
            </div>
          </div>
        </div>

        <span :class="['common-dropdown-button-wrapper', variant]">
          <common-button
            variant="text"
            v-if="!button"
            :class="['common-dropdown-button']"
            type="button"
            aria-haspopup="true"
            aria-expanded="true"
            :size="size"
            :width="width"
            @click="dropdownOpened = !dropdownOpened">
            <span class="common-dropdown-option-label">{{
              displayedOptionLabel
            }}</span>

            <slot name="icon">
              <ppicon name="chevron-down" class="chevron-down-icon" />
            </slot>
          </common-button>

          <!-- Custom Dropdown Button -->
          <div v-else @click="dropdownOpened = !dropdownOpened">
            <common-button
              :width="`${button.width || 'w-max'}`"
              :variant="button.variant ? button.variant : 'primary'"
              :icon="button.icon"
              :iconOnly="button.iconOnly">
              <template slot="icon">
                <slot name="icon" />
              </template>
              {{ button.label }}
            </common-button>
          </div>
        </span>
      </div>
    </div>
  </focus-trap>
</template>

<script>
  import { eventBus } from "@/main.js";
  import DropdownOption from "./DropdownOption.vue";

  export default {
    name: "common-dropdown",

    components: {
      DropdownOption,
    },

    props: {
      value: {
        type: [String, Number, Object],
      },

      size: {
        type: String,
        default: "md",
        validator(value) {
          const sizes = ["sm", "md"];
          return sizes.includes(value);
        },
      },

      options: {
        type: Array,
      },

      groups: {
        type: Boolean,
      },

      maxHeight: {
        type: Boolean,
      },

      width: {
        type: String,
        default: "w-max",
      },

      alignDropdownLeft: {
        type: Boolean,
        default: false,
      },

      placeholder: {
        type: String,
        default: "Select",
      },

      fixedPlaceholder: {
        type: Boolean,
      },

      variant: {
        type: String,
        default: "primary",
      },

      button: {
        type: Object,
      },

      custom: {
        type: Boolean,
      },

      toggle: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        dropdownOptions: this.options,
        dropdownOpened: false,
        dropdownDirection: "align-bottom",
      };
    },

    computed: {
      defaultOption() {
        if (!this.groups) {
          for (let option of this.options || []) {
            if (option.default) {
              return option;
            }
          }
        }

        return null;
      },

      displayedOptionLabel() {
        let label = this.placeholder;
        if (!this.fixedPlaceholder) {
          label =
            !this.value && this.defaultOption ? this.defaultOption : this.value;
        }

        if (typeof label === "object") {
          return label.label || label.value;
        }

        return label || "";
      },

      calculateMenuHeight() {
        return this.maxHeight ? "" : "short-menu";
      },
    },

    watch: {
      options: {
        deep: true,
        handler(updatedOptions) {
          this.dropdownOptions = updatedOptions;
        },
      },
      dropdownOpened() {
        if (this.dropdownOpened) {
          this.dropdownDirection =
            window.innerHeight * 0.75 <
            this.$refs.dropdownWrapper.getBoundingClientRect().top
              ? "align-top"
              : "align-bottom";
        }
      },
    },

    created() {
      eventBus.$on("toggle-dropdown", (event) => (this.dropdownOpened = event));
    },

    methods: {
      emitValue(option) {
        if (this.dropdownOpened && !this.toggle) {
          this.dropdownOpened = false;
          let value = Object.prototype.hasOwnProperty.call(option, "value")
            ? option.value
            : option.label;
          this.$emit("input", value);
        } else {
          this.$emit("input", option);
        }
      },

      delayedBlur() {
        setTimeout(() => (this.dropdownOpened = false), 200);
      },
    },
  };
</script>

<style scoped lang="scss">
  .common-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .common-dropdown-wrapper {
    display: inline-block;
  }

  .common-dropdown-options-wrapper {
    transform: translate(0) scale(1);
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    position: absolute;
    transform-origin: top right;
    transform: translateY(-0.5rem) scaleX(0.95) scaleY(0.95);
    z-index: 10;
  }

  .common-dropdown-options-inner-wrapper {
    position: absolute;
    margin-bottom: 0.5rem;
    transform-origin: top right;
    background-color: $white;
    border: 1px solid $border-grey;
    border-radius: 0.375rem;
    box-shadow: $box-shadow-lg;

    &.align-left {
      right: 0;
    }

    &.align-top {
      bottom: -1rem;
    }

    &.align-bottom {
      top: 3rem;
    }
  }

  .common-dropdown-options-menu {
    overflow: auto;
    border-radius: 8px;

    &.short-menu {
      max-height: 15rem;
    }
  }

  .common-dropdown-group {
    border-bottom: 1px solid $border-grey;

    &:last-child {
      border: none;
    }
  }

  ::v-deep .common-dropdown-button {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-radius: 28px;
    padding-left: 0.75rem;
    padding-right: 0.75rem;

    &.sm {
      height: 2rem;
    }

    &:hover {
      color: $grey-500;
    }

    &:active {
      color: $grey-800;
      background-color: $grey-50;
    }
  }

  .common-dropdown-button-wrapper {
    box-shadow: $box-shadow-sm;
    border-radius: 0.375rem;
  }

  .common-dropdown-option-label {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .secondary {
    color: $blue-400;

    .common-dropdown-button {
      background-color: $blue-200;

      &:hover {
        background-color: $blue-100;
      }

      &:disabled {
        background-color: $grey-200;
      }
    }
  }

  .primary {
    .common-dropdown-button {
      background-color: $white;
      border: 1px solid $grey-200;

      &:hover {
        background-color: $grey-100;
      }
    }
  }

  .icon {
    border: none;
    box-shadow: none;
  }

  .chevron-down-icon {
    font-size: 1.25rem;
    margin-left: auto;
  }

  ::v-deep {
    .btn-text {
      color: inherit;
    }
  }
</style>
