<!-- component -->
<template>
  <div class="w-full">
    <ul class="flex overflow-auto scrollbar-hide">
      <li
        class="mr-2 sm:mr-10 lg:mr-20 cursor-pointer flex-none"
        v-for="(tab, index) of tabs"
        :key="`common-tabs-tab-${index}`"
        @click="emitValue(index)"
      >
        <div
          class="font-semibold text-sm py-0.5"
          :class="{
            'border-blue border-b-2 text-blue': selectedTab === index,
            'text-grey-500': selectedTab !== index,
          }"
        >
          {{ tab }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: "common-tabs",
    props: {
      tabs: {
        required: true,
        type: Array,
      },
    },
    data() {
      return {
        selectedTab: 0,
      };
    },
    methods: {
      emitValue(index) {
        this.selectedTab = index;
        this.$emit("tab-click", index);
      },
    },
  };
</script>
