import Vue from "vue";
import APIService from "../../services/api/index";
import { FETCH_PROSPECTS } from "../actions.type.js";
import { UPDATE_PROSPECTS } from "../mutations.type.js";
import { GET_PROSPECTS } from "../getters.type.js";

const dashboardService = APIService.dashboard;

export const initialState = {
  prospects: null,
};

export const state = { ...initialState };

export const actions = {
  async [FETCH_PROSPECTS]({ getters, commit }) {
    const data = {
      selectedYear: getters.getActiveYear,
      organization: getters.getBranding.name,
    };

    const response = await dashboardService.prospectiveParents(data);
    commit(UPDATE_PROSPECTS, response.data);
    return response;
  },

  updateProspect({ commit }, prospect) {
    commit("UPDATE_PROSPECT", prospect);
  },

  deleteProspect({ commit }, prospect) {
    commit("DELETE_PROSPECT", prospect);
  },
};

export const mutations = {
  [UPDATE_PROSPECTS](state, prospects) {
    state.prospects = prospects;
  },

  UPDATE_PROSPECT(state, record) {
    for (let i = 0; i < state.prospects.length; i++) {
      if (state.prospects[i]._id === record.id) {
        Vue.set(state.prospects, i, {
          ...state.prospects[i],
          ...record.updates,
        });

        return;
      }
    }
  },

  DELETE_PROSPECT(state, prospect) {
    let prospects = state.prospects;

    for (let i = 0; i < prospects.length; i++) {
      if (prospects[i]._id === prospect.id) {
        prospects.splice(i, 1);
        state.prospects = prospects;
        return;
      }
    }
  },
};

export const getters = {
  [GET_PROSPECTS](state) {
    return state.prospects;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
