<template>
  <p-modal
    :value="value"
    @input="$emit('input', false)"
    size="sm"
    :title="$lang.feedbackModal_HeaderText.format(source)"
    :showCancelButton="false"
    :saveButtonText="$lang.feedbackModal_ExportButton"
    @save="exportConversationFeedback">
    <div v-if="ready" class="feedback-body">
      <section v-for="(user, i) in feedback" :key="i">
        <h3>{{ $lang.feedbackModal_AuthorHeader.format(user.name) }}</h3>
        <div v-for="answer in user.answers" :key="answer._id">
          <div class="feedback-question">
            {{ answer.question.text }}
          </div>
          <div class="feedback-answer">
            <v-slider
              v-if="answer.question.type === 'slider'"
              v-model="answer.text"
              thumb-label="always"
              :readonly="true"
              max="10"
              class="feedback-slider" />
            <p v-else>
              {{ answer.text }}
            </p>
          </div>
        </div>
      </section>
    </div>

    <v-skeleton-loader v-else type="article@2" />
  </p-modal>
</template>

<script>
  import { exportCsvFile } from "@/shared/utils.js";
  import organizationService from "@/services/api/organization.service.js";

  export default {
    props: {
      value: Boolean,
      source: {
        type: String,
        default: "",
      },
      prospectName: {
        type: String,
        required: true,
      },
      ambassadorName: {
        type: String,
        required: true,
      },
      conversationCallOrCallInvitationId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        ready: false,
        feedback: [],
      };
    },
    created() {
      organizationService
        .getAnswers(this.conversationCallOrCallInvitationId)
        .then(({ data }) => {
          const ambassadorFeedback = [];
          const prospectFeedback = [];

          for (const answer of data) {
            const usage = answer.question.usage;
            if (usage === "feedbackAmbassador") {
              ambassadorFeedback.push(answer);
            } else if (usage === "feedbackProspect") {
              prospectFeedback.push(answer);
            }
          }
          if (ambassadorFeedback.length) {
            this.feedback.push({
              role: "Ambassador",
              name: this.ambassadorName,
              answers: ambassadorFeedback,
            });
          }
          if (prospectFeedback.length) {
            this.feedback.push({
              role: "Prospect",
              name: this.prospectName,
              answers: prospectFeedback,
            });
          }
          this.ready = true;
        });
    },
    methods: {
      exportConversationFeedback() {
        const csv = [
          {
            Ambassador: this.ambassadorName,
            Prospect: this.prospectName,
            Source: this.source,
          },
        ];

        for (const user of this.feedback) {
          for (const answer of user.answers) {
            const label = `${user.role}: ${answer.question.text}`;
            csv[0][label] = answer.text;
          }
        }

        exportCsvFile(csv, "PeerPal_Conversation_Feedback.csv");
      },
    },
  };
</script>

<style lang="scss" scoped>
  .feedback-body {
    display: flex;
    flex-direction: column;
    gap: 20px;

    h3 {
      margin-bottom: 8px;
    }
  }
  .feedback-question {
    font-weight: 500;
  }
  .feedback-slider {
    padding-top: 40px;
  }
</style>
