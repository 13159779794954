import APIService from "../../services/api/index";
import {
  FETCH_DASHBOARD,
  SET_ACTIVE_YEAR,
  SET_LAST_FETCHED,
  LOGOUT,
} from "../actions.type.js";
import {
  UPDATE_CURRENT_USER,
  UPDATE_ACTIVE_YEAR,
  UPDATE_LAST_FETCHED,
} from "../mutations.type.js";
import {
  GET_CURRENT_USER,
  GET_ACTIVE_YEAR,
  GET_LAST_FETCHED,
} from "../getters.type.js";
import screens from "@/constants/screens";

const dashboardService = APIService.dashboard;

export const initialState = {
  currentScreen: screens.HOME_SCREEN,
  branding: {},
  currentParents: [],
  currentUser: null,
  activeYear: new Date().getFullYear(),
  logo: null,
  color: null,
  lastFetched: {},
};

export const state = { ...initialState };

export const actions = {
  async [FETCH_DASHBOARD](context) {
    const response = await dashboardService.dashboard();
    if (response) context.commit(UPDATE_CURRENT_USER, response.data);
    return response;
  },
  [SET_ACTIVE_YEAR]({ commit, getters }, year) {
    const today = new Date();
    const thisYear = today.getFullYear();
    year = +year;

    if (!Number.isInteger(year)) {
      year = thisYear;
    }

    const changeOver = getters.getBranding.changeOverDate;

    // prevent going going into the future
    if (changeOver) {
      const changeOverDate = new Date(
        today.getFullYear(),
        changeOver[0] - 1,
        changeOver[1]
      );

      if (today < changeOverDate && thisYear <= year) {
        year = thisYear - 1;
      }
    }
    commit(UPDATE_ACTIVE_YEAR, year);
  },
  [SET_LAST_FETCHED](context, payload) {
    context.commit(UPDATE_LAST_FETCHED, payload);
  },
  [LOGOUT](context) {
    context.commit(UPDATE_CURRENT_USER, null);
    APIService.dashboard.logout();
  },
};

export const mutations = {
  [UPDATE_CURRENT_USER](state, user) {
    state.currentUser = user;
  },
  [UPDATE_ACTIVE_YEAR](state, updatedActiveYear) {
    state.activeYear = updatedActiveYear;
  },
  [UPDATE_LAST_FETCHED](state, lastFetched) {
    state.lastFetched = { ...state.lastFetched, ...lastFetched };
  },
};

export const getters = {
  [GET_CURRENT_USER](state) {
    return state.currentUser;
  },
  [GET_ACTIVE_YEAR](state) {
    return state.activeYear;
  },
  [GET_LAST_FETCHED](state) {
    return state.lastFetched;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
