<template>
  <div class="w-96 sm:w-full">
    <div class="h-screen flex flex-col w-full">
      <div class="bg-blue h-16">
        <div
          class="w-full h-16 flex justify-center items-center text-white font-medium">
          <h3>{{ recipientName }}</h3>
        </div>
      </div>
      <div class="flex-grow overflow-y-auto flex flex-col-reverse">
        <div
          v-for="message in messages"
          v-bind:key="message.id"
          class="w-full flex flex-col py-3">
          <div
            class="p-4 rounded-2xl text-sm max-w-message mx-8"
            v-bind:class="{
              'bg-blue self-end text-white': message.fromYou,
              'bg-grey-100 self-start': !message.fromYou,
            }">
            <label>{{ message.body }}</label>
          </div>
          <p
            class="text-xs text-grey mx-8 mt-2"
            :class="{ 'self-end': message.fromYou }">
            {{ message.sent }}
          </p>
        </div>
      </div>
      <div class="flex ml-8 my-2">
        <common-input
          class="flex-grow"
          placeholder="Send a message"
          v-model="messageText"
          @keyup.native.enter="sendMessage" />
        <div class="w-20 mx-8">
          <common-button @click="sendMessage">Send</common-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import APIService from "@/services/api/index";
  import io from "socket.io-client";
  import { mapGetters } from "vuex";
  import { GET_BRANDING, GET_CURRENT_USER } from "@/store/getters.type";
  import { getToken } from "@/services/api/http.service";

  export default {
    props: ["conversationID"],
    data() {
      return {
        uid: null,
        token: null,
        organization: null,
        messages: [],
        messageText: null,
        recipientID: null,
        recipientName: null,
        socket: null,
      };
    },
    computed: {
      ...mapGetters({
        branding: GET_BRANDING,
        currentUser: GET_CURRENT_USER,
      }),
    },
    methods: {
      sendMessage() {
        let sentMessage = this.messageText;
        sentMessage = sentMessage.replace("<", "");
        sentMessage = sentMessage.replace(">", "");
        this.socket.emit("new message", {
          conversationID: this.conversationID,
          composedMessage: sentMessage,
          authorUserId: this.uid,
          recipientID: this.recipientID,
          schoolName: this.organization,
        });
      },
    },
    async created() {
      this.uid = this.currentUser._id;
      this.token = getToken();
      this.organization = this.branding.name;

      if (!this.conversationID) {
        return;
      }

      const response = await APIService.chat.getConversation({
        conversationId: this.conversationID,
      });

      if (response?.status === 200) {
        this.recipientID = response.data.recipientID;
        this.recipientName = response.data.recipientName;
        const messages = response.data.chats;

        messages.forEach((message) => {
          const body = message.body;
          let fromYou;
          if (message.authorAdministratorId === this.uid) {
            fromYou = true;
          } else {
            fromYou = false;
          }
          this.messages.unshift({
            body: body,
            fromYou: fromYou,
            sent: new Date(message.createdDate).toLocaleTimeString([], {
              hour: "numeric",
              minute: "2-digit",
            }),
          });
        });
      }

      this.socket = io(process.env.VUE_APP_ENDPOINT_TWO, {
        query: { token: this.token },
      });

      this.socket.on("news", function (data) {
        console.log(data);
      });

      this.socket.on("refresh messages", (data) => {
        const messageText = data.composedMessage;
        let fromYou = false;
        if (this.uid === data.authorUserId) {
          fromYou = true;
        }
        this.messages.unshift({ body: messageText, fromYou: fromYou });
        this.messageText = "";
        if (this.messages.length === 1) {
          alert(
            "You will receive an email notification when you get a reply! Expect a response within 24-48 hours."
          );
        }
        this.$emit("reloadInbox", [1]);

        if (process.env.VUE_APP_API_KEY) {
          this.$amplitude.getInstance().logEvent("MESSAGE_SENT");
        }
      });

      this.socket.on("disconnect", (reason) => {
        this.socket.connect();
        this.socket.emit("enter conversation", this.conversationID);
      });

      this.socket.emit("enter conversation", this.conversationID);
    },
    beforeDestroy() {
      //emit event to messagingpage that emits event to dashboard that deletes its convoID
      this.$emit("destroyConvoID");
    },
  };
</script>
