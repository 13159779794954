<template>
  <div>
    <div class="table-header">
      <!-- Filters -->
      <v-menu
        v-if="headers"
        offset-y
        :close-on-content-click="false"
        nudge-bottom="10">
        <template v-slot:activator="{ on, attrs }">
          <v-btn depressed outlined v-bind="attrs" v-on="on">
            <v-icon left>$filter-outline</v-icon>
            {{ $lang.outreachPage_Filters }}
            <v-icon right>$chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="header in headers" :key="header.value">
            <v-list-item-content class="filter-content">
              <v-list-item-title class="filter-title">
                {{ header.text }}
              </v-list-item-title>
              <ul class="filter-list">
                <li v-for="(item, prop) in header.map" :key="item.text">
                  <v-checkbox
                    v-model="filters[header.value].value"
                    :value="typeCoercion(prop)"
                    @change="filterChange(header.value, $event)"
                    :label="item.text"
                    dense
                    hide-details />
                </li>
              </ul>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer />

      <SearchInput
        class="search-input"
        :value="search"
        @input="$emit('input', $event)" />
      <v-btn color="primary" depressed @click="$emit('export')">
        <v-icon left>$download</v-icon>{{ $lang.outreachPage_ExportButton }}
      </v-btn>
    </div>

    <div class="filter-chips">
      <template v-for="(filter, prop) in filters">
        <v-chip
          v-for="item in filter.value"
          :key="`${prop}-${item}`"
          small
          close
          close-icon="$close-circle"
          @click:close="removeFilter(filter, item)">
          {{ filter.map[item].text }} ({{ filter.text }})
        </v-chip>
      </template>
    </div>
  </div>
</template>

<script>
  import SearchInput from "./SearchInput.vue";
  export default {
    name: "TableHeader",
    components: { SearchInput },
    props: {
      search: String,
      headers: Array,
    },
    data() {
      return {
        filters: {},
      };
    },
    created() {
      const filters = {};
      for (const header of this.headers) {
        filters[header.value] = {
          text: header.text,
          map: header.map,
          value: [],
        };
      }
      this.filters = filters;
    },
    methods: {
      filterChange(header, $event) {
        this.$emit("filter", { header, filter: $event });
      },
      typeCoercion(string) {
        if (string === "true") {
          return true;
        }
        if (string === "false") {
          return false;
        }
        return string;
      },
      removeFilter(filter, item) {
        const index = filter.value.indexOf(item);
        filter.value.splice(index, 1);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .table-header {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 8px;

    .search-input {
      max-width: 268px;
    }
  }
  .filter-content {
    overflow: visible;
  }
  .filter-title {
    font-size: 14px;
    font-weight: 500;
  }
  ul.filter-list {
    padding-left: 0;
  }
  .filter-chips {
    display: flex;
    gap: 8px;
    margin-bottom: 4px;
    flex-wrap: wrap;
    height: 24px;
  }
</style>
