<template>
  <div class="campaign-outreach-email">
    <h3 class="campaign-outreach-email-title">
      {{ $lang.campaignOutreach_outreachEmailTitle }}
    </h3>
    <div class="campaign-outreach-email-body-wrapper">
      <div class="campaign-outreach-email-label">
        {{ $lang.campaignOutreach_outreachEmailDescription }}
      </div>
      <v-text-field
        outlined
        dense
        class="campaign-outreach-email-subject"
        :value="email.subject"
        @input="update('subject', $event)"
        :label="$lang.campaignOutreach_outreachEmailSubjectPlaceholder"
        :error-messages="subjectErrors"></v-text-field>
      <v-lazy>
        <v-textarea
          outlined
          dense
          auto-grow
          :value="email.body"
          @input="update('body', $event)"
          :label="$lang.campaignOutreach_outreachEmailBodyLabel"
          :error-messages="bodyErrors"></v-textarea>
      </v-lazy>
    </div>
  </div>
</template>

<script>
  import { required } from "vuelidate/lib/validators";

  export default {
    name: "CampaignEmailTemplateStep",
    props: {
      data: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        email: {
          subject: "",
          body: "",
        },
      };
    },
    validations() {
      return {
        email: {
          subject: { required, variableValidator: this.variableValidator },
          body: {
            required,
            variableValidator: this.variableValidator,
          },
        },
      };
    },
    computed: {
      subjectErrors() {
        return !this.$v.email.subject.variableValidator &&
          this.$v.email.subject.required
          ? this.$lang.campaignOutreach_outreachEmailError
          : null;
      },
      bodyErrors() {
        return !this.$v.email.body.variableValidator &&
          this.$v.email.body.required
          ? this.$lang.campaignOutreach_outreachEmailError
          : null;
      },
    },
    watch: {
      "$v.$invalid": {
        immediate: true,
        handler(value) {
          this.$emit("complete", !value);
        },
      },
      data: {
        immediate: true,
        deep: true,
        handler(value) {
          if (value.campaign.email) {
            this.email.subject = value.campaign.email.subject;
            this.email.body = value.campaign.email.body;
          }
        },
      },
    },
    methods: {
      update(key, value) {
        this.email[key] = value;
        if (!this.$v.email.$invalid) {
          const data = {
            campaign: { ...this.data.campaign, email: this.email },
          };
          this.$emit("update", data);
        }
      },
      variableValidator(value) {
        const matches = value.match(/\{[^{^\s]+\}/g);
        if (matches?.length) {
          return matches.every((match) =>
            ["{AmbFirst}", "{AmbLast}"].includes(match)
          );
        }
        return true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .campaign-outreach-email {
    color: $grey-800;

    &-title {
      margin-bottom: 30px;
    }

    &-body-wrapper {
      max-width: 690px;
    }

    &-label {
      margin-bottom: 22px;
    }

    &-subject {
      margin-bottom: 30px;
    }
  }
</style>
