function isEmailValid(email) {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
  return email == "" ? false : reg.test(email) ? true : false;
}

function isPhoneNumberValid() {
  //number) {
  //Temporary fix for non US phone numbers until we get in sync with other products
  return true;
  // const reg = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  // return number == "" ? false : reg.test(number) ? true : false;
}

export default {
  isEmailValid,
  isPhoneNumberValid,
};
