import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { Ripple } from "vuetify/lib";
import icons from "./icons";

Vue.use(Vuetify, { directives: { Ripple } });

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#007BFF",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
  icons: {
    iconfont: "mdiSvg",
    values: icons,
  },
});
