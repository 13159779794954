import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"table-header"},[(_vm.headers)?_c(VMenu,{attrs:{"offset-y":"","close-on-content-click":false,"nudge-bottom":"10"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"depressed":"","outlined":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"left":""}},[_vm._v("$filter-outline")]),_vm._v(" "+_vm._s(_vm.$lang.outreachPage_Filters)+" "),_c(VIcon,{attrs:{"right":""}},[_vm._v("$chevron-down")])],1)]}}],null,false,1668349084)},[_c(VList,_vm._l((_vm.headers),function(header){return _c(VListItem,{key:header.value},[_c(VListItemContent,{staticClass:"filter-content"},[_c(VListItemTitle,{staticClass:"filter-title"},[_vm._v(" "+_vm._s(header.text)+" ")]),_c('ul',{staticClass:"filter-list"},_vm._l((header.map),function(item,prop){return _c('li',{key:item.text},[_c(VCheckbox,{attrs:{"value":_vm.typeCoercion(prop),"label":item.text,"dense":"","hide-details":""},on:{"change":function($event){return _vm.filterChange(header.value, $event)}},model:{value:(_vm.filters[header.value].value),callback:function ($$v) {_vm.$set(_vm.filters[header.value], "value", $$v)},expression:"filters[header.value].value"}})],1)}),0)],1)],1)}),1)],1):_vm._e(),_c(VSpacer),_c('SearchInput',{staticClass:"search-input",attrs:{"value":_vm.search},on:{"input":function($event){return _vm.$emit('input', $event)}}}),_c(VBtn,{attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.$emit('export')}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("$download")]),_vm._v(_vm._s(_vm.$lang.outreachPage_ExportButton)+" ")],1)],1),_c('div',{staticClass:"filter-chips"},[_vm._l((_vm.filters),function(filter,prop){return _vm._l((filter.value),function(item){return _c(VChip,{key:(prop + "-" + item),attrs:{"small":"","close":"","close-icon":"$close-circle"},on:{"click:close":function($event){return _vm.removeFilter(filter, item)}}},[_vm._v(" "+_vm._s(filter.map[item].text)+" ("+_vm._s(filter.text)+") ")])})})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }