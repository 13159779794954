<template>
  <!-- Feedback Received -->
  <div class="w-2/3 h-96 mr-4 rounded-lg border">
    <!-- Title + Options -->
    <div class="flex justify-between items-center h-16 px-8">
      <h3 class="text-lg font-medium">Feedback Received</h3>
      <button
        class="flex items-center"
        @click="exportConversationFeedback(feedback)">
        <img src="../../../assets/Download.svg" alt="" />
        <span class="text-sm text-blue-400 ml-2 font-medium">Export</span>
      </button>
    </div>
    <!-- List of Feedback -->
    <ul class="h-80 overflow-y-scroll pl-8">
      <li
        v-for="communication of feedback"
        :key="communication._id"
        class="grid grid-rows-1 my-3"
        style="grid-template-columns: 56px 1fr 1fr 1fr 80px 112px">
        <img
          v-if="communication.recipImageURL"
          :src="communication.recipImageURL"
          class="h-10 w-10 rounded-3xl object-cover" />
        <img
          v-else
          :src="greyProfileImgURL"
          alt=""
          class="h-10 w-10 rounded-3xl object-cover" />
        <p class="text-sm font-medium leading-10">
          {{ communication.recipName }} &#8594;
        </p>
        <p class="text-sm font-medium leading-10">
          {{ communication.author }}
        </p>
        <p class="text-sm font-medium leading-10">
          {{ communication.source }}
        </p>
        <common-button
          variant="secondary"
          size="sm"
          @click="viewFeedback(communication)"
          class="mt-1 mb-auto">
          View
        </common-button>
        <common-button
          variant="text"
          :class="{ 'send-message': true, disabled: !communication.recipID }"
          @click="openMessageModal(communication)">
          <img src="../../../assets/GreenMail.svg" alt="" />
        </common-button>
      </li>
    </ul>
    <FeedbackModal
      v-if="feedbackModalOpened"
      v-model="feedbackModalOpened"
      :conversationCallOrCallInvitationId="selectedFeedback.id"
      :prospectName="selectedFeedback.author"
      :ambassadorName="selectedFeedback.recipName"
      :source="selectedFeedback.source" />
  </div>
</template>

<script>
  import { exportCsvFile, toFullName } from "@/shared/utils.js";
  import organizationService from "@/services/api/organization.service.js";
  import FeedbackModal from "@/components/common/tailwind/modals/FeedbackModal.vue";

  export default {
    name: "Feedback",
    components: {
      FeedbackModal,
    },
    props: {
      conversations: {
        type: Object,
        default: () => {},
      },
      callOutreach: {
        type: Object,
        default: () => {},
      },

      calls: {
        type: Array,
        default: () => [],
      },

      organizationName: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        feedbackModalOpened: false,
        greyProfileImgURL:
          "https://res.cloudinary.com/peerpal/image/upload/v1579116680/GreyProfile_y1llco.png",
        selectedFeedback: null,
      };
    },
    computed: {
      feedback() {
        return this.enrichedConversations
          .concat(this.enrichedCallInvitations)
          .concat(this.enrichedCalls)
          .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
      },
      enrichedConversations() {
        if (!this.conversations?.messages?.length) {
          return [];
        }
        return this.conversations.messages
          .filter((conversation) => conversation.feedback?.answers?.length > 0)
          .map((conversation) => {
            conversation.source = this.$lang.homePage_ConvoFeedbackSource;
            return conversation;
          });
      },
      enrichedCallInvitations() {
        if (!this.callOutreach?.completedRequests?.length) {
          return [];
        }
        return this.callOutreach.completedRequests
          .filter((call) => call.feedback?.answers?.length > 0)
          .map((call) => {
            return {
              id: call._id,
              recipName: toFullName(
                call.inviterID?.first,
                call.inviterID?.last,
                this.$lang.general_DeletedUser
              ),
              recipImageURL: call.inviterID?.profileImageUrl,
              recipID: call.inviterID?._id,
              author: call.invitee.name,
              source: this.$lang.homePage_CallFeedbackSource,
              createdDate: call.createdDate,
              feedback: call.feedback,
            };
          });
      },

      enrichedCalls() {
        return this.calls
          .filter((call) => call.feedback)
          .map((call) => {
            let ambassador = call.amb;
            let prospect = call.prospect;

            return {
              id: call._id,
              recipName: toFullName(
                ambassador?.first,
                ambassador?.last,
                this.$lang.general_DeletedUser
              ),
              recipImageURL: ambassador?.profileImageUrl,
              recipID: ambassador?._id,
              author: toFullName(
                prospect?.first,
                prospect?.last,
                this.$lang.general_DeletedUser
              ),
              source: this.$lang.homePage_CallFeedbackSource,
              createdDate: call.createdDate,
              feedback: call.feedback,
            };
          });
      },
    },
    methods: {
      openMessageModal(communication) {
        if (communication.recipID) {
          this.$emit("openMessageModal", communication);
        }
      },
      viewFeedback(communication) {
        this.feedbackModalOpened = true;
        this.selectedFeedback = { ...communication };
      },
      async exportConversationFeedback() {
        const response = (
          await organizationService.getOrganizationAnswers(
            this.organizationName
          )
        ).data;

        const questions = {};
        response.forEach((communication) => {
          communication.feedback.answers.forEach((answer) => {
            questions[answer.question.id] = answer.question;
          });
        });

        const feedback = response.map((convo) => {
          const { inviterUser, feedback, conversationUsers } = convo;
          const reshape = {};
          if (conversationUsers) {
            const ambassador = conversationUsers.find(
              (cu) => cu.user?.organizationId
            );
            const prospect = conversationUsers.find(
              (cu) => cu.user?.signedUpOrganizationId
            );
            reshape.ambassadorName = toFullName(
              ambassador?.first,
              ambassador?.last,
              this.$lang.general_DeletedUser
            );
            reshape.prospectName = toFullName(
              prospect?.first,
              prospect?.last,
              this.$lang.general_DeletedUser
            );
            reshape.type = this.$lang.homePage_ConvoFeedbackSource;
          } else {
            reshape.ambassadorName = toFullName(
              inviterUser?.first,
              inviterUser?.last,
              this.$lang.general_DeletedUser
            );
            reshape.prospectName = toFullName(
              convo.inviteeFirst,
              convo.inviteeLast,
              this.$lang.general_DeletedUser
            );
            reshape.type = this.$lang.homePage_CallFeedbackSource;
          }
          feedback.answers.forEach((answer) => {
            reshape[`q${answer.question.id}`] = answer.question.text;
            reshape[`a${answer.question.id}`] = answer.text;
          });
          return reshape;
        });

        const fields = [
          { label: "Ambassador", value: "ambassadorName" },
          { label: "Prospect", value: "prospectName" },
          { label: "Source", value: "type" },
        ];
        Object.values(questions).forEach((question, index) => {
          const type =
            question.usage === "feedbackAmbassador" ? "Ambassador" : "Prospect";
          fields.push(
            {
              label: `${type} Question #${index + 1}`,
              value: `q${question.id}`,
            },
            {
              label: `${type} Answer #${index + 1}`,
              value: `a${question.id}`,
            }
          );
        });
        exportCsvFile(feedback, "PeerPal_Conversation_Feedback.csv", {
          fields,
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  .send-message {
    background-color: $green-100;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 2rem 0 2.5rem;

    &:hover:not(.disabled) {
      background-color: #a7f3d0;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
</style>
