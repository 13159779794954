<template>
  <div>
    <div class="messages-limit">
      <label id="limit-label">
        {{ $lang.settingsPage_MessagesLimit }}
      </label>
      <transition name="fade">
        <common-number-input
          v-if="convoLimitEnabled"
          v-model="convoLimit"
          aria-labelledby="limit-label"
          :min="1"
          :max="50" />
      </transition>
      <common-toggle
        v-model="convoLimitEnabled"
        aria-labelledby="limit-label"
        aria-describedby="limit-description"
        class="limit-toggle"></common-toggle>
    </div>
    <p id="limit-description" class="limit-description">
      {{ description }}
    </p>
  </div>
</template>

<script>
  export default {
    name: "MessagesLimit",
    props: {
      value: {
        type: Number,
      },
      description: {
        type: String,
      },
    },
    data() {
      return {
        convoLimit: this.value || 0,
        convoLimitEnabled: this.value > 0,
      };
    },
    watch: {
      value: {
        immediate: true,
        handler(val) {
          this.convoLimit = val || 0;
          this.convoLimitEnabled = val > 0;
        },
      },
      convoLimitEnabled(enabled) {
        this.convoLimit = enabled ? 3 : 0;
        this.$emit("input", this.convoLimit);
      },
      convoLimit(newVal) {
        this.$emit("input", newVal);
      },
    },
  };
</script>

<style scoped lang="scss">
  label {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: $grey-500;
    height: 3em;
  }

  .messages-limit {
    display: flex;
    gap: 1em;
    align-items: center;
    margin-bottom: 1em;
  }

  .limit-toggle {
    margin-left: auto;
  }

  .limit-description {
    font-size: 14px;
    padding-bottom: 1em;
    color: #999999;
  }
</style>
