<template>
  <OutreachRequestsTable :items="items" :columns="columns" v-on="$listeners" />
</template>

<script>
  import OutreachRequestsTable from "./OutreachRequestsTable.vue";

  export default {
    name: "ScheduledCampaigns",
    components: { OutreachRequestsTable },
    props: {
      campaigns: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        columns: [
          "title",
          "createdAt",
          "scheduledFor",
          "inviteType",
          "adminName",
          "actions",
        ],
      };
    },
    computed: {
      items() {
        return this.campaigns.filter(
          (campaign) => !campaign.sentAt && campaign.scheduledFor
        );
      },
    },
  };
</script>
