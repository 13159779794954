<template>
  <div>
    <h3>{{ $lang.campaign_StepSendOutreachRequests }}</h3>

    <div class="schedule-buttons">
      <CampaignTypeButton
        v-for="buttonType in types"
        :key="buttonType.text"
        :text="buttonType.text"
        :icon="buttonType.icon"
        @click="showModal(buttonType.name)" />
    </div>

    <!-- send confirmation modal -->
    <p-modal
      size="sm"
      v-model="sendModal"
      :title="$lang.campaign_SendNow"
      :saveButtonText="$lang.general_Yes"
      :cancelButtonText="$lang.general_No"
      @save="send"
      @cancel="sendModal = false">
      {{ $lang.campaign_SendNowConfirmation }}
    </p-modal>

    <!-- schedule modal -->
    <CampaignScheduleModal
      v-model="scheduleModal"
      :date="data.campaign.scheduledFor"
      @save="schedule" />

    <!-- success modal -->
    <p-modal
      success
      persistent
      v-model="successModal"
      :enableHeaderClose="false"
      :title="successModalContent.title"
      :showCancelButton="!!this.data.campaign.scheduledFor"
      :saveButtonText="$lang.campaign_SuccessDone"
      :cancelButtonText="$lang.campaign_EditSchedule"
      @cancel="editSchedule"
      @save="done">
      {{ successModalContent.body }}
      <img src="@/assets/send-success.svg" alt="" />
    </p-modal>
  </div>
</template>

<script>
  import CampaignTypeButton from "./CampaignTypeButton.vue";
  import CampaignScheduleModal from "./CampaignScheduleModal.vue";
  import emailIcon from "@/assets/email.svg";
  import scheduleIcon from "@/assets/schedule.svg";
  import dateService from "@/services/date.service";

  export default {
    name: "CampaignSend",
    components: { CampaignTypeButton, CampaignScheduleModal },
    props: {
      data: Object,
    },
    data() {
      return {
        sendModal: false,
        scheduleModal: false,
        successModal: false,

        types: [
          {
            name: "send",
            text: this.$lang.campaign_SendNow,
            icon: emailIcon,
          },
          {
            name: "schedule",
            text: this.$lang.campaign_ScheduleLater,
            icon: scheduleIcon,
          },
        ],
      };
    },
    computed: {
      scheduledDateFormatted() {
        return this.data.campaign.scheduledFor
          ? dateService.toDisplayDateTime(this.data.campaign.scheduledFor)
          : "";
      },
      successModalContent() {
        const ambassadors = new Set(
          this.data.pendingInvitations.map((invite) => invite.inviterID)
        );

        return this.data.campaign.scheduledFor
          ? {
              title: this.$lang.campaign_SuccessfullyScheduled,
              body: this.scheduledDateFormatted,
            }
          : {
              title: this.$lang.campaign_SuccessfullySent,
              body: this.$lang.campaign_SuccessfullySentMessage.format(
                this.data.campaign.title,
                ambassadors.size
              ),
            };
      },
    },

    watch: {
      "data.campaign": {
        immediate: true,
        deep: true,
        handler(campaign) {
          if (campaign.scheduledFor) {
            this.$emit("complete");
          }
        },
      },
    },

    methods: {
      showModal(name) {
        switch (name) {
          case "send":
            this.sendModal = true;
            break;
          case "schedule":
            this.scheduleModal = true;
            break;
        }
      },
      send() {
        this.sendModal = false;
        this.successModal = true;

        const campaign = {
          ...this.data.campaign,
          scheduledFor: null,
        };
        this.$emit("update", { campaign });
        this.$emit("complete", true);
        this.$emit("send");
      },
      schedule(date) {
        const campaign = {
          ...this.data.campaign,
          scheduledFor: date,
        };
        this.scheduleModal = false;
        this.successModal = true;

        this.$emit("update", { campaign });
        this.$emit("complete", true);
        this.$emit("save");
      },
      done() {
        this.successModal = false;
        const hash = this.data.campaign.scheduledFor
          ? "#ScheduledCampaigns"
          : "#Drafts";
        this.$router.push({ name: "OUTREACH_SCREEN", hash });
      },
      editSchedule() {
        this.successModal = false;
        this.scheduleModal = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .schedule-buttons {
    display: flex;
    gap: 5%;
    max-width: 800px;
    margin: 24px auto;
  }
</style>
