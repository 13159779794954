<template>
  <div v-if="header.sortProperty" class="table-headers-sort">
    <mdicon
      v-if="displayUp"
      class="table-headers-sort-icon up"
      name="chevron-up"
      size="12" />
    <mdicon
      v-if="displayDown"
      class="table-headers-sort-icon down"
      name="chevron-down"
      size="12" />
  </div>
</template>

<script>
  export default {
    name: "table-headers-sort",
    props: {
      header: {
        type: Object,
        required: true,
      },
      activeSort: {
        type: Object,
        required: true,
      },
    },
    computed: {
      displayUp() {
        return this.isChevronVisible(this.header, this.activeSort, true);
      },

      displayDown() {
        return this.isChevronVisible(this.header, this.activeSort, false);
      },
    },
    methods: {
      isChevronVisible(header, activeSort, isUp) {
        const isCurrentSort = activeSort.sortProperty === header.sortProperty;
        const isUpVisible = isUp !== activeSort.reverse;
        return !isCurrentSort || (isCurrentSort && isUpVisible);
      },
    },
  };
</script>

<style scoped lang="scss">
  .table-headers-sort {
    position: relative;
    align-self: center;
    width: 22px;
    height: 18px;
  }

  .table-headers-sort-icon {
    color: $grey-500;
    position: absolute;

    &.up {
      top: 0;
    }

    &.down {
      bottom: 0;
    }
  }

  .hide {
    visibility: hidden;
  }
</style>
