<template>
  <button
    class="btn"
    :class="[
      `btn-${variant}`,
      `btn-${size}`,
      width,
      { 'btn-icon-only': iconOnly },
    ]"
    @click="$emit('click')">
    <div
      v-if="(icon || iconOnly) && !loading"
      class="btn-icon"
      :class="{ 'btn-icon-right': iconRight }">
      <slot name="icon" />
    </div>
    <slot v-if="!iconOnly && !loading" />

    <div v-if="loading" class="btn-loading">
      <common-spinner />
    </div>
  </button>
</template>

<script>
  export default {
    name: "common-button",
    props: {
      variant: {
        type: String,
        validator(value) {
          const variants = [
            "primary",
            "secondary",
            "tertiary",
            "info",
            "danger",
            "outline",
            "text",
            "icon",
          ];
          return variants.includes(value);
        },
        default: "primary",
      },
      size: {
        type: String,
        validator(value) {
          const variants = ["sm", "md"];
          return variants.includes(value);
        },
        default: "md",
      },
      icon: {
        type: Boolean,
      },
      iconOnly: {
        type: Boolean,
      },
      iconRight: {
        type: Boolean,
      },
      // TODO: can be removed with tailwind
      width: {
        type: String,
        default: "w-full",
      },
      loading: {
        type: Boolean,
      },
    },
  };
</script>

<style scoped lang="scss">
  .btn {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0 1rem;
    border-radius: 28px;
    position: relative;
    transition: background-color var(--transition-duration);

    &:disabled {
      opacity: 0.7;
      cursor: auto;
    }
  }
  .btn-primary {
    color: $white;
    background-color: $blue-400;

    &:hover:enabled {
      background-color: $blue-500;
    }
  }
  .btn-secondary {
    color: $blue-400;
    background-color: $blue-100;

    &:hover:enabled {
      background-color: $blue-200;
    }
  }
  .btn-tertiary {
    color: $primary-text;
    background-color: $white;
    border: 1px solid $border-grey;

    &:hover:enabled {
      background: $grey-100;
    }
  }
  .btn-info {
    color: $primary-text;
    background-color: $grey-200;

    &:hover:enabled {
      background: $grey-300;
    }
  }
  .btn-danger {
    color: $white;
    background-color: $salmon-400;

    &:hover:enabled {
      background: $salmon-500;
    }
  }
  .btn-outline {
    background-color: none;
    border: 1px solid $grey-500;

    &:hover {
      background-color: $grey-200;
    }
  }
  .btn-icon {
    background-color: none;
  }
  .btn-text {
    padding: 0;
    color: $blue-400;
  }
  .btn-sm {
    height: 2rem;
  }
  .btn-md {
    height: 2.25rem;
  }
  .btn-icon {
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn-icon-right {
    order: 2;
  }
  .btn-icon-only {
    width: 2.25rem;
    height: 2.25rem;
    padding: 5px;
  }
  .btn-loading {
    width: 1.25rem;
    height: 1.25rem;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .grow {
    flex: 1;
  }
</style>
