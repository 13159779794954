<template>
  <div>
    <div v-if="loading" class="settings-loading">
      <common-spinner />
    </div>

    <div v-else-if="errorMsg">
      {{ errorMsg }}
    </div>

    <div v-else>
      <h2 class="settings-title">
        <input
          type="checkbox"
          class="checkbox"
          :checked="allSelected"
          :disabled="loading"
          @change="clickSelectAll" />{{
          $lang.settingsPage_NotificationSettings
        }}
      </h2>

      <template v-for="group in notifications">
        <label class="group-item" v-if="group.id !== 15510" :key="group.name">
          <!-- Temporarily hide admin digest hack -->
          <span>
            <input class="checkbox" type="checkbox" v-model="group.active" />
          </span>
          <div class="group-label">
            <div class="group-title">{{ group.name }}</div>
            <div class="group-description">{{ group.description }}</div>
          </div>
        </label>
      </template>

      <common-button
        variant="primary"
        class="btn-save"
        @click="save"
        :loading="submitting">
        {{ $lang.general_Save }}
      </common-button>
    </div>
  </div>
</template>

<script>
  import api from "@/services/api";

  export default {
    name: "NotificationSettings",
    props: {
      userId: {
        type: String,
      },
    },
    data() {
      return {
        loading: true,
        submitting: false,
        notifications: [],
        errorMsg: "",
      };
    },
    computed: {
      allSelected() {
        return (
          this.notifications.length > 0 &&
          this.notifications.every((group) => group.active)
        );
      },
    },
    created() {
      api.dashboard.getUnsubscribe({ id: this.userId }).then((res) => {
        if (res.status === 200) {
          const notifications = res.data.groups;
          for (let group of notifications) {
            group.active = !group.suppressed;
          }
          this.notifications = notifications;
        } else {
          this.errorMsg = this.$lang.general_ErrorMessage;
        }
        this.loading = false;
      });
    },
    methods: {
      clickSelectAll() {
        const active = !this.allSelected;
        for (const group of this.notifications) {
          group.active = active;
        }
      },
      save() {
        this.submitting = true;
        const data = {
          user: this.userId,
          pref: this.notifications.map((group) => {
            return {
              id: group.id,
              suppressed: !group.active,
            };
          }),
        };

        api.dashboard.updateUnsubscribe(data).then((res) => {
          if (res.status === 200) {
            this.$toasted.show(
              this.$lang.settingsPage_SettingsSaved,
              this.$toastedSuccess
            );
          } else {
            this.$toasted.show(
              this.$lang.general_ToastError,
              this.$toastedFailure
            );
          }
          this.submitting = false;
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  .settings-title {
    font-size: 24px;
    font-weight: 500;
    padding-bottom: 1em;
  }
  .settings-loading {
    display: flex;
    justify-content: center;
  }
  .group-item {
    display: flex;
    flex-direction: row;
    padding-bottom: 1.25em;
  }
  .group-label {
    font-size: 14px;
    text-align: left;
  }
  .group-title {
    font-weight: 500;
    line-height: 20px;
  }
  .checkbox {
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;
  }
  .btn-save {
    width: 10rem;
    margin-top: 1rem;
  }
</style>
