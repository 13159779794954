import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-vuetify":""}},[_c(VApp,{staticClass:"vuetify-off"},[_c(VSnackbar,{attrs:{"timeout":_vm.options.timeout,"color":_vm.options.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"icon":"","aria-label":_vm.$lang.general_Close},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c(VIcon,[_vm._v("$close")])],1)]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(_vm._s(_vm.options.icon))]),_vm._v(_vm._s(_vm.message)+" ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }