<template>
  <v-text-field
    class="phone-input"
    :value="value"
    :error-messages="errorMessages"
    :placeholder="placeholder"
    :disabled="disabled"
    @input="$emit('input', $event)"
    @blur="$emit('blur')"
    outlined
    dense
    ref="textField">
  </v-text-field>
</template>

<script>
  import intlTelInput from "intl-tel-input";
  import "intl-tel-input/build/css/intlTelInput.css";

  export default {
    name: "phone-input",
    props: {
      value: {
        type: String,
        required: true,
      },
      placeholder: {
        type: String,
        default: "",
      },
      errorMessages: {
        type: Array,
        default: () => [],
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        telephoneInput: null,
      };
    },

    mounted() {
      const input = this.$refs.textField.$refs.input;
      this.telephoneInput = intlTelInput(input, {
        utilsScript:
          "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.15/js/utils.min.js",
        initialCountry: "us",
        separateDialCode: true,
      });
    },

    beforeDestroy() {
      if (this.telephoneInput) {
        this.telephoneInput.destroy();
        this.telephoneInput = null;
      }
    },
  };
</script>

<style lang="scss">
  .phone-input {
    .iti {
      width: 100%;
      margin-left: -15px;

      &__flag-container {
        height: 39px;
        left: 4px;
        margin: auto;
        color: $grey-800;
        padding: 0.5rem;
      }

      &.iti--allow-dropdown.iti--separate-dial-code input {
        padding-left: 5.75rem !important;
      }
    }

    .iti__selected-flag {
      background-color: $grey-100;
      border-radius: 28px;
    }

    ul.iti__country-list {
      padding-left: 0;
    }

    .iti__country-list {
      max-width: 268px;

      @media only screen and (max-width: 768px) {
        max-width: 180px;
      }
    }
  }
</style>
