import Vue from "vue";
import Router from "vue-router";
import { initRouteGuard } from "./route-guard.js";
import store from "../store";
import LandingPage from "@/components/main/LandingPage.vue";
import Terms from "@/components/main/Terms.vue";
import Dashboard from "@/components/dashboard/Dashboard.vue";
import Ambassadors from "@/components/dashboard/ambassadors/Ambassadors.vue";
import Outreach from "@/components/dashboard/outreach/Outreach.vue";

import OutreachCampaign from "@/components/dashboard/outreach/OutreachCampaign.vue";

import Prospects from "@/components/dashboard/prospects/Prospects.vue";
import Profile from "@/components/dashboard/profile/Profile.vue";
import Settings from "@/components/dashboard/settings/Settings.vue";
import MyAccount from "@/components/dashboard/settings/settingsComponents/MyAccount";
import ManageAdministrators from "@/components/dashboard/settings/settingsComponents/ManageAdministrators";
import AmbassadorSetup from "@/components/dashboard/settings/settingsComponents/AmbassadorSetup";
import ProspectSetup from "@/components/dashboard/settings/settingsComponents/ProspectSetup";
import CustomizePlatform from "@/components/dashboard/settings/settingsComponents/CustomizePlatform";
import PageNotFound from "@/components/main/PageNotFound.vue";
import Maintenance from "@/components/main/Maintenance.vue";

import Home from "@/components/dashboard/home/Home.vue";
import Messaging from "@/components/dashboard/messaging/MessagingPage.vue";
import Pending from "@/components/main/PendingUser.vue";
import EmailVerification from "@/components/main/EmailVerification.vue";
import Unsubscribe from "@/components/main/Unsubscribe.vue";
import ResetPage from "@/components/login/ResetPassword.vue";
import ServiceAgreement from "@/components/main/ServiceAgreement.vue";
import SuperAdminPanel from "@/components/dashboard/superAdmin/SuperAdminPanel.vue";
import screens from "@/constants/screens";

const {
  HOME_SCREEN,
  AMBASSADORS_SCREEN,
  CONVERSATIONS_SCREEN,
  OUTREACH_SCREEN,
  PROSPECTS_SCREEN,
  SETTINGS_SCREEN,
  PROFILE_SCREEN,

  MESSAGING_SCREEN,
  SUPER_ADMIN_SCREEN,
} = screens;

Vue.use(Router);

const router = new Router({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: { name: "Login" },
    },
    {
      path: "/login",
      name: "Login",
      component: LandingPage,
    },
    {
      path: "/terms",
      name: "Terms",
      component: Terms,
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
      meta: { requiresAuth: true },
    },
    {
      path: "/dashboard/:orgName",
      component: Dashboard,
      meta: { requiresAuth: true },
    },
    {
      path: "/dashboard/:orgName/:year",
      component: Dashboard,
      meta: { requiresAuth: true },
      children: [
        {
          name: HOME_SCREEN,
          path: "",
          component: Home,
        },
        {
          name: AMBASSADORS_SCREEN,
          path: "ambassadors",
          component: Ambassadors,
        },
        {
          name: PROSPECTS_SCREEN,
          path: "prospects",
          component: Prospects,
        },
        {
          name: CONVERSATIONS_SCREEN,
          path: "ambassadors",
          component: Ambassadors,
        },
        {
          name: PROFILE_SCREEN,
          path: "ambassadors/:id",
          component: Profile,
        },

        {
          name: OUTREACH_SCREEN,
          path: "outreach",
          component: Outreach,
        },
        {
          name: "Outreach Campaign",
          path: "outreach/campaign/:campaignId?",
          component: OutreachCampaign,
        },
        {
          name: SETTINGS_SCREEN,
          path: "settings",
          component: Settings,
          meta: { requiresAuth: true },
          children: [
            {
              name: "My Account",
              path: "account",
              component: MyAccount,
            },
            {
              name: "Manage Administrators",
              path: "administrators",
              component: ManageAdministrators,
            },
            {
              name: "Ambassador Setup",
              path: "ambassadors",
              component: AmbassadorSetup,
            },
            {
              name: "Prospect Setup",
              path: "prospects",
              component: ProspectSetup,
            },
            {
              name: "Customize Platform",
              path: "platform",
              component: CustomizePlatform,
            },
          ],
        },
        {
          name: SUPER_ADMIN_SCREEN,
          path: "super",
          component: SuperAdminPanel,
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      name: MESSAGING_SCREEN,
      path: "/messaging",
      component: Messaging,
      meta: { requiresAuth: true },
    },
    {
      path: "/pending",
      name: "Pending",
      component: Pending,
      meta: { requiresAuth: true },
    },
    {
      path: "/emailVerification",
      name: "EmailVerification",
      component: EmailVerification,
    },
    {
      path: "/unsubscribeEmail",
      name: "Unsubscribe",
      component: Unsubscribe,
    },
    {
      path: "/resetPW",
      name: "Reset Password",
      component: ResetPage,
    },
    {
      path: "/sa",
      name: "ServiceAgreement",
      component: ServiceAgreement,
    },
    {
      path: "/maintenance",
      name: "Maintenance",
      component: Maintenance,
    },
    {
      path: "*",
      name: "404",
      component: PageNotFound,
    },
  ],
});

initRouteGuard(router, store);

export default router;
