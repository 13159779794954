<template>
  <div class="number-input">
    <common-button class="decrement" @click="number--" aria-hidden="true">
      <mdicon name="minus" />
    </common-button>
    <input
      type="text"
      role="spinbutton"
      v-model="number"
      v-bind="$attrs"
      :aria-valuemin="min"
      :aria-valuemax="max"
      :aria-valuenow="value"
      @keydown="onKeyDown" />
    <common-button class="increment" @click="number++" aria-hidden="true">
      <mdicon name="plus" />
    </common-button>
  </div>
</template>

<script>
  export default {
    name: "NumberInput",
    inheritAttrs: false,
    props: {
      value: {
        type: Number,
      },
      min: {
        type: Number,
        default: Number.MIN_SAFE_INTEGER,
      },
      max: {
        type: Number,
        default: Number.MAX_SAFE_INTEGER,
      },
    },
    data() {
      return {
        number: this.value,
      };
    },
    watch: {
      number(newVal, oldVal) {
        const num = +newVal;

        if (Number.isInteger(num) && num >= this.min && num <= this.max) {
          this.$emit("input", num);
        } else {
          this.number = oldVal;
        }
      },
    },
    methods: {
      onKeyDown(e) {
        switch (e.key) {
          case "ArrowUp":
            this.number++;
            break;
          case "ArrowDown":
            this.number--;
            break;
          case "Home":
            this.number = this.max;
            break;
          case "End":
            this.number = this.min;
            break;
          default:
            break;
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .number-input {
    display: flex;
    font-size: 14px;
    outline: 1px solid $grey-200;
    border-radius: 28px;
    overflow: hidden;
  }
  .number-input input {
    width: 3em;
    text-align: center;
  }
  .decrement,
  .increment {
    width: 36px;
    height: 36px;
    font-size: 16px;
    color: $white;
    background: $blue-400;
  }
</style>
