import Vue from "vue";
import CommonInput from "@/components/common/tailwind/Input.vue";
import CommonButton from "@/components/common/tailwind/Button.vue";
import CommonDropdown from "@/components/common/tailwind/Dropdown.vue";
import CommonPopup from "@/components/common/tailwind/Popup.vue";
import CommonModal from "@/components/common/tailwind/Modal.vue";
import CommonStepper from "@/components/common/tailwind/Stepper.vue";
import CommonTabs from "@/components/common/tailwind/Tabs.vue";
import CommonTextarea from "@/components/common/tailwind/Textarea.vue";
import CommonToggle from "@/components/common/Toggle.vue";
import CommonTextToggle from "@/components/common/tailwind/TextToggle.vue";
import CommonMultiToggle from "@/components/common/tailwind/MultiToggle.vue";
import CommonNumberInput from "@/components/common/NumberInput.vue";
import CommonTableFooter from "@/components/common/TableFooter.vue";
import CommonAccordion from "@/components/common/Accordion.vue";
import CommonLottie from "@/components/common/Lottie.vue";
import CommonSpinner from "@/components/common/Spinner.vue";
import SortIcon from "@/components/common/tailwind/icons/SortIcon.vue";
import { TippyComponent } from "vue-tippy";

Vue.component("common-input", CommonInput);
Vue.component("common-button", CommonButton);
Vue.component("common-dropdown", CommonDropdown);
Vue.component("common-popup", CommonPopup);
Vue.component("common-modal", CommonModal);
Vue.component("common-stepper", CommonStepper);
Vue.component("common-tabs", CommonTabs);
Vue.component("common-textarea", CommonTextarea);
Vue.component("common-toggle", CommonToggle);
Vue.component("common-multi-toggle", CommonMultiToggle);
Vue.component("common-text-toggle", CommonTextToggle);
Vue.component("common-number-input", CommonNumberInput);
Vue.component("common-accordion", CommonAccordion);
Vue.component("common-lottie", CommonLottie);
Vue.component("common-table-footer", CommonTableFooter);
Vue.component("common-spinner", CommonSpinner);
Vue.component("sort-icon", SortIcon);
Vue.component("tippy", TippyComponent);

import PModal from "@/components/common/PModal.vue";
import PMarkdown from "@/components/common/PMarkdown.vue";

Vue.component("p-modal", PModal);
Vue.component("p-markdown", PMarkdown);

Vue.prototype.$toastedSuccess = {
  position: "bottom-right",
  duration: 5000,
  singleton: true,
  className: "toast-success mb-5 w-1/2 md:mb-0",
  action: {
    text: "X",
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    },
  },
};
Vue.prototype.$toastedFailure = {
  position: "bottom-right",
  duration: 5000,
  singleton: true,
  className: "toast-failure mb-5 w-1/2 md:mb-0",
  action: {
    text: "X",
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    },
  },
};

Vue.prototype.$toastedWarning = {
  position: "bottom-right",
  duration: 5000,
  singleton: true,
  className: "toast-warning mb-5 w-1/2 md:mb-0",
  action: {
    text: "X",
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    },
  },
};
