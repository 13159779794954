<template>
  <div class="page">
    <NavBar></NavBar>
    <div class="flex-center">
      <NotificationSettings class="card" :userId="$route.query.tok" />
    </div>
  </div>
</template>
<script>
  import NavBar from "@/components/login/loginComponents/NavBar.vue";
  import NotificationSettings from "@/components/main/NotificationSettings.vue";

  export default {
    components: { NavBar, NotificationSettings },
  };
</script>

<style scoped lang="scss">
  .page {
    background-color: $grey-150;
    min-height: 100vh;
  }
  .flex-center {
    display: flex;
    justify-content: center;
  }
  .card {
    background: white;
    margin: 2rem;
    padding: 2rem;
    border-radius: 8px;
    width: $xs;
    box-shadow: $box-shadow-lg;
  }
</style>
