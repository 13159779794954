import APIService from "../../services/api/index";
import {
  FETCH_BRANDING,
  SET_BRANDING,
  SET_PROFILE_FIELDS,
} from "../actions.type.js";
import { UPDATE_BRANDING, UPDATE_PROFILE_FIELDS } from "../mutations.type.js";
import { GET_BRANDING } from "../getters.type.js";

const dashboardService = APIService.dashboard;

export const initialState = {
  branding: {},
};

export const state = { ...initialState };

export const actions = {
  async [FETCH_BRANDING]({ commit }, organization) {
    const data = {
      organization,
    };

    const response = await dashboardService.branding(data);
    commit(UPDATE_BRANDING, response.data);
    return response;
  },
  async [SET_BRANDING]({ commit, getters }, payload) {
    const data = {
      name: getters.getBranding.name,
      ...payload,
    };

    const response = await dashboardService.updateBranding(data);
    commit(UPDATE_BRANDING, payload);
    return response.status === 200;
  },
  async [SET_PROFILE_FIELDS]({ commit, getters }, payload) {
    const data = {
      organization: getters.getBranding.name,
      ...payload,
    };

    const response = await dashboardService.updateFields(data);
    commit(UPDATE_BRANDING, payload);
    return response.status === 200;
  },
};

export const mutations = {
  [UPDATE_BRANDING](state, updatedBranding) {
    state.branding = {
      ...state.branding,
      ...updatedBranding,
    };
  },
  [UPDATE_PROFILE_FIELDS](state, updatedProfileFields) {
    state.branding.profileFields = updatedProfileFields;
    state.branding.signUpFields = updatedProfileFields;
  },
};

export const getters = {
  [GET_BRANDING](state) {
    return state.branding;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
