<template>
  <div>
    <NavBar></NavBar>
    <div class="reset-password-container">
      <div class="reset-password-form" v-if="!resetSent">
        <h1 class="reset-password-title">{{ $lang.loginPage_ResetTitle }}</h1>
        <common-input
          :placeholder="$lang.loginPage_ResetPasswordPlaceholder"
          v-model="password"
          type="password"
          class="reset-password-password-input"></common-input>

        <common-input
          :placeholder="$lang.loginPage_ResetConfirmPasswordPlaceholder"
          v-model="confirmPassword"
          @keyup.enter.native="submitReset()"
          type="password"
          class="reset-password-confirm-input"></common-input>

        <common-button
          variant="primary"
          class="reset-password-confirm-button"
          @click="submitReset()"
          >{{ $lang.general_Save }}</common-button
        >
      </div>
      <div class="reset-password-form" v-else>
        <h1 class="reset-password-title">
          {{ $lang.loginPage_ResetSuccessTitle }}
        </h1>
        <p class="reset-password-success-message">
          {{ $lang.loginPage_ResetSuccessDescription }}
          <router-link
            :to="{ name: 'Login' }"
            class="reset-password-success-link"
            >{{ $lang.loginPage_ResetSuccessLink }}</router-link
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  const NavBar = () => import("./loginComponents/NavBar.vue");
  import APIService from "@/services/api/index";

  export default {
    components: {
      NavBar,
    },
    data() {
      return {
        password: null,
        confirmPassword: null,
        resetSent: false,
      };
    },
    methods: {
      submitReset: async function () {
        if (!this.password || !this.confirmPassword) {
          return;
        }

        if (this.password !== this.confirmPassword) {
          this.$toasted.show(
            this.$lang.loginPage_ResetPasswordsMustMatchError,
            this.$toastedFailure
          );
        }

        const data = {
          password: this.password,
          passMagic: this.$route.query.value,
        };

        const response = await APIService.dashboard.updatePwd(data);
        if (response?.status === 200) {
          this.resetSent = true;
        } else {
          this.$toasted.show(
            this.$lang.general_ToastError,
            this.$toastedFailure
          );
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .reset-password-container {
    display: flex;
    justify-content: center;
    margin-top: 6rem;
  }

  .reset-password-form {
    width: 490px;
  }

  .reset-password-title {
    // Replace with standard h1 styling
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.1px;

    margin-bottom: 1.5rem;
    color: $grey-800;
  }

  .reset-password-password-input {
    margin-bottom: 1rem;
  }

  .reset-password-confirm-input,
  .reset-password-confirm-button {
    margin-bottom: 2rem;
  }

  .reset-password-success-message {
    // replace with body 1 typography style
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1px;

    color: $grey-800;
    margin-bottom: 2rem;
  }

  .reset-password-success-link {
    color: $blue-400;
  }
</style>
