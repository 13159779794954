import APIService from "../../services/api/index";
import {
  CREATE_EMAIL_INVITE_REQUEST,
  CREATE_CALL_INVITE_REQUEST,
  CREATE_VIDEO_INVITE_REQUEST,
  FETCH_ALL_CALL_OUTREACH,
  FETCH_ALL_EMAIL_OUTREACH,
  FETCH_ALL_VIDEO_OUTREACH,
  FETCH_ALL_OUTREACH,
  DELETE_OUTREACH,
  REJECT_VIDEO_OUTREACH_SUBMISSION,
  DELETE_VIDEO_OUTREACH_SUBMISSION,
} from "../actions.type.js";
import {
  GET_CALL_OUTREACH,
  GET_EMAIL_OUTREACH,
  GET_VIDEO_OUTREACH,
  GET_VIDEO_REQUEST_OUTREACH,
  GET_VIDEO_SUBMISSION_OUTREACH,
  GET_PENDING_CALL_OUTREACH,
} from "../getters.type";
import {
  UPDATE_CALL_OUTREACH,
  UPDATE_EMAIL_OUTREACH,
  UPDATE_VIDEO_OUTREACH,
  REMOVE_OUTREACH,
  REMOVE_VIDEO_OUTREACH_SUBMISSION,
  ADD_OUTREACH,
} from "../mutations.type";

const dashboardService = APIService.dashboard;

export const initialState = {
  callOutreach: {},
  emailOutreach: [],
  videoOutreach: {},
};

export const state = { ...initialState };

export const actions = {
  async [CREATE_EMAIL_INVITE_REQUEST](context, payload) {
    const response = await dashboardService.createInvite(payload);
    if (response.status === 200) {
      const outreach = response.data.invites[0];
      const ambassador = context.getters.getCurrentParents.find(
        (amb) => amb._id === outreach.inviterID
      );
      outreach.inviterID = ambassador;
      context.commit(ADD_OUTREACH, {
        outreachType: "Email",
        outreach: outreach,
      });
    }

    return response.status === 200;
  },
  async [CREATE_CALL_INVITE_REQUEST](context, payload) {
    const response = await dashboardService.createCallRequest(payload);
    if (response.status === 200) {
      const outreach = response.data.invites[0];
      const ambassador = context.getters.getCurrentParents.find(
        (amb) => amb.id === outreach.inviterID
      );
      outreach.inviterID = ambassador;
      context.commit(ADD_OUTREACH, {
        outreachType: "Call",
        outreach: outreach,
      });
    }

    return response.status === 200;
  },
  async [CREATE_VIDEO_INVITE_REQUEST](context, payload) {
    const response = await dashboardService.createVideoInviteRequest(payload);
    if (response.status === 200) {
      const outreach = response.data.invites[0];
      const ambassador = context.getters.getCurrentParents.find(
        (amb) => amb._id === outreach.inviterID
      );
      outreach.inviterID = ambassador;
      context.commit(ADD_OUTREACH, {
        outreachType: "Video",
        outreach: outreach,
      });
    }

    return response.status === 200;
  },
  async [FETCH_ALL_EMAIL_OUTREACH]({ getters, commit }) {
    const data = {
      selectedYear: getters.getActiveYear,
      organization: getters.getBranding.name,
    };

    const response = await dashboardService.invitesOrg(data);
    commit(UPDATE_EMAIL_OUTREACH, response.data);
  },
  async [FETCH_ALL_CALL_OUTREACH]({ getters, commit }) {
    const data = {
      selectedYear: getters.getActiveYear,
      organization: getters.getBranding.name,
    };

    const response = await dashboardService.allCallInvitations(data);
    commit(UPDATE_CALL_OUTREACH, response.data);
  },
  async [FETCH_ALL_VIDEO_OUTREACH]({ getters, commit }) {
    const data = {
      selectedYear: getters.getActiveYear,
      organization: getters.getBranding.name,
    };

    const videoInvitesResponse = await dashboardService.activeVideoInvitesOrg(
      data
    );

    const respondedVideoInvitesResponse =
      await dashboardService.getRespondedInvitesOrg(data);

    const videoOutreachResponse = {
      videoInvites: videoInvitesResponse ? videoInvitesResponse.data : null,
      respondedVideoInvites: respondedVideoInvitesResponse
        ? respondedVideoInvitesResponse.data
        : null,
    };

    commit(UPDATE_VIDEO_OUTREACH, videoOutreachResponse);
  },
  async [FETCH_ALL_OUTREACH](context) {
    await context.dispatch(FETCH_ALL_CALL_OUTREACH);
    await context.dispatch(FETCH_ALL_EMAIL_OUTREACH);
    await context.dispatch(FETCH_ALL_VIDEO_OUTREACH);
  },
  async [DELETE_OUTREACH](context, payload) {
    const { id, outreachType } = payload;

    const data = {
      invitationID: id,
      invitationType: outreachType,
    };
    const response = await dashboardService.deleteInvite(data);
    const isSuccess = response?.status === 200;

    if (isSuccess) {
      context.commit(REMOVE_OUTREACH, payload);
    }

    return isSuccess;
  },
  async [REJECT_VIDEO_OUTREACH_SUBMISSION](context, payload) {
    const { submissionID, feedback } = payload;

    let data = {
      videoInvitationSubmission: submissionID,
      status: "rejected",
      feedback: feedback,
    };

    const response = await dashboardService.updateVideoInviteSubmission(data);
    const isSuccess = response?.status === 200;

    if (isSuccess) {
      context.commit(REMOVE_VIDEO_OUTREACH_SUBMISSION, submissionID);
    }

    return isSuccess;
  },
  async [DELETE_VIDEO_OUTREACH_SUBMISSION](context, payload) {
    const { requestID, submissionID } = payload;
    const response = await dashboardService.deleteVideoInvitationSubmission({
      requestID: requestID,
    });

    const isSuccess = response.status === 200;

    if (isSuccess) {
      context.commit(REMOVE_VIDEO_OUTREACH_SUBMISSION, submissionID);
    }

    return isSuccess;
  },
};

export const mutations = {
  [ADD_OUTREACH](state, newOutreach) {
    const { outreachType, outreach } = newOutreach;

    switch (outreachType) {
      case "Email":
        return state.emailOutreach.push(outreach);
      case "Call":
        return state.callOutreach.pendingRequests.push(outreach);
      case "Video":
        return state.videoOutreach.videoInvites.requests.push(outreach);
    }
  },
  [UPDATE_CALL_OUTREACH](state, updatedCallOutreach) {
    state.callOutreach = updatedCallOutreach;
  },
  [UPDATE_EMAIL_OUTREACH](state, updatedEmailOutreach) {
    state.emailOutreach = updatedEmailOutreach;
  },
  [UPDATE_VIDEO_OUTREACH](state, updatedVideoOutreach) {
    state.videoOutreach = updatedVideoOutreach;
  },
  [REMOVE_OUTREACH](state, outreachToRemove) {
    const { id, outreachType } = outreachToRemove;

    switch (outreachType) {
      case "Email":
        state.emailOutreach.find((outreach, index) => {
          if (outreach._id === id) {
            return state.emailOutreach.splice(index, 1);
          }
        });

        break;
      case "Call":
        state.callOutreach.pendingRequests.find((outreach, index) => {
          if (outreach._id === id) {
            return state.callOutreach.pendingRequests.splice(index, 1);
          }
        });

        break;
      case "Video":
        state.videoOutreach.videoInvites.requests.find((outreach, index) => {
          if (outreach._id === id) {
            return state.videoOutreach.videoInvites.requests.splice(index, 1);
          }
        });

        break;
    }
  },
  [REMOVE_VIDEO_OUTREACH_SUBMISSION](state, id) {
    state.videoOutreach.videoInvites.submissions.find((outreach, index) => {
      if (outreach._id === id) {
        return state.videoOutreach.videoInvites.submissions.splice(index, 1);
      }
    });
  },
};

export const getters = {
  [GET_CALL_OUTREACH](state) {
    return state.callOutreach;
  },
  [GET_PENDING_CALL_OUTREACH](state) {
    return state.callOutreach.pendingRequests;
  },
  [GET_EMAIL_OUTREACH](state) {
    return state.emailOutreach;
  },
  [GET_VIDEO_OUTREACH](state) {
    return state.videoOutreach;
  },
  [GET_VIDEO_REQUEST_OUTREACH](state) {
    if (state.videoOutreach) {
      return state.videoOutreach.videoInvites
        ? state.videoOutreach.videoInvites.requests
        : [];
    }
    return [];
  },
  [GET_VIDEO_SUBMISSION_OUTREACH](state) {
    if (state.videoOutreach) {
      return state.videoOutreach.videoInvites
        ? state.videoOutreach.videoInvites.submissions
        : [];
    }
    return [];
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
