<template>
  <div class="manage-administrators">
    <common-popup
      v-if="showDeleteAdminPopup"
      v-bind="deleteAdminProps"
      @first-button="showDeleteAdminPopup = false"
      @second-button="removeAdmin"
      @close="showDeleteAdminPopup = false"
      height="h-max" />

    <common-popup
      v-if="showEmailPassworResetPopup"
      v-bind="emailPasswordResetProps"
      @first-button="showEmailPassworResetPopup = false"
      @second-button="emailPasswordReset"
      @close="showEmailPassworResetPopup = false"
      height="h-max" />

    <InviteAdminModal
      v-if="inviteAdminModalOpened"
      @close="inviteAdminModalOpened = false" />

    <!-- Header -->
    <div
      as="div"
      class="w-full bg-white flex flex-col lg:flex-row justify-between mb-3 lg:mb-12 row-span-1">
      <span class="mr-10 mb-5 lg:mb-0 w-full lg:w-3/5">
        <h1 class="text-2xl font-semibold">
          {{ $lang.settingsPage_ManageAdmins }}
        </h1>
      </span>
      <div class="flex-none">
        <common-button
          class="lg:ml-3"
          variant="secondary"
          @click="inviteAdminModalOpened = true"
          icon>
          <template slot="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>
          </template>
          {{ $lang.settingsPage_InviteAdmin }}
        </common-button>
      </div>
    </div>

    <!-- Admin Table -->
    <div :class="{ 'h-96': !displayedAdmins.length }">
      <table class="w-full relative">
        <thead class="text-grey-500 text-left text-xs">
          <tr>
            <th class="sticky top-0 w-1/5 pl-2 bg-white py-2">
              {{ $lang.general_Name }}
            </th>
            <th class="sticky top-0 w-1/5 bg-white py-2">
              {{ $lang.general_Status }}
            </th>
            <th class="sticky top-0 w-1/5 bg-white py-2">
              {{ $lang.general_JoinedOn }}
            </th>
            <th class="sticky top-0 w-1/5 bg-white py-2">
              {{ $lang.general_Email }}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody class="font-normal text-sm">
          <!-- Admin Rows -->
          <tr
            class="border-b h-14 transition ease"
            v-for="(admin, index) of displayedAdmins"
            :key="admin.id">
            <td
              class="w-max pr-10 pl-2 items-center flex pt-3 justify-self-center">
              <div
                v-if="admin.status"
                class="w-8 h-8 mr-2 bg-blue-100 text-grey rounded-full flex items-center justify-center flex-none">
                {{ admin.firstName.slice(0, 1).toUpperCase()
                }}{{ admin.lastName.slice(0, 1).toUpperCase() }}
              </div>
              <div
                v-else
                class="w-8 h-8 mr-2 bg-grey-200 rounded-full whitespace-nowrap"></div>
              <div class="flex-none" :class="{ 'text-grey': !admin.status }">
                {{ admin.firstName }} {{ admin.lastName }}
              </div>
            </td>
            <td class="pr-10">
              <div
                v-if="admin.status"
                class="w-20 h-full py-1 px-3 bg-blue-100 text-blue rounded text-center font-medium">
                {{ $lang.status_Active }}
              </div>
              <div
                v-else
                class="w-20 h-full py-1 px-3 bg-green-100 text-green rounded text-center font-medium">
                {{ $lang.status_Invited }}
              </div>
            </td>
            <td class="pr-10">
              <div v-if="admin.status" class="whitespace-nowrap">
                {{ admin.signedUpDate }}
              </div>
            </td>
            <td class="pr-10">{{ admin.email }}</td>
            <td>
              <div class="flex justify-end relative">
                <div
                  class="h-7 w-7 hover:bg-blue-200 rounded-full flex items-center justify-center transition ease cursor-pointer"
                  @click="clickMenu(index)">
                  <img src="@/assets/ellipsis-icon.svg" class="h-4 w-4" />
                </div>
                <ul class="menu" :class="{ invisible: curRow !== index }">
                  <li
                    v-if="getCurrentUser.superAdmin"
                    class="menu-item"
                    @click="openEmailPasswordResetPopup(admin)">
                    {{ $lang.settingsPage_EmailPasswordResetPopupHeader }}
                  </li>
                  <li class="menu-item danger" @click="openDeletePopup(admin)">
                    {{ $lang.settingsPage_RemoveAdminPopupHeader }}
                  </li>
                </ul>
              </div>
            </td>
          </tr>

          <!-- Skeleton Loading -->
          <tr
            v-for="(n, index) in 10"
            class="border-b h-14 animate-pulse"
            :class="{ hidden: admins.length }"
            :key="`admin-skeleton-row-${index}`">
            <td class="pr-5">
              <div class="w-2/3 h-4 mx-2 bg-grey-200 rounded-lg" />
            </td>
            <td class="pr-5">
              <div class="w-2/3 h-4 mx-2 bg-grey-200 rounded-lg" />
            </td>
            <td class="pr-5">
              <div class="w-2/3 h-4 mx-2 bg-grey-200 rounded-lg" />
            </td>
            <td class="pr-5">
              <div class="w-2/3 h-4 mx-2 bg-grey-200 rounded-lg" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import { FETCH_ADMINS, DELETE_ADMIN } from "@/store/actions.type.js";
  import {
    GET_ADMINS,
    GET_BRANDING,
    GET_CURRENT_USER,
  } from "@/store/getters.type.js";
  import sortService from "@/services/sort.service.js";
  import dateService from "@/services/date.service.js";
  import APIService from "@/services/api";
  import InviteAdminModal from "@/components/common/tailwind/modals/InviteAdminModal.vue";

  export default {
    props: ["year"],

    components: {
      InviteAdminModal,
    },

    data() {
      return {
        admins: [],
        displayedAdmins: [],
        inviteAdminModalOpened: false,
        deleteAdminProps: {
          headerText: this.$lang.settingsPage_RemoveAdminPopupHeader,
          bodyText: "",
          firstButton: {
            variant: "info",
            text: this.$lang.general_Cancel,
          },
          secondButton: {
            variant: "danger",
            text: this.$lang.settingsPage_RemoveAdminPopupButton,
          },
        },
        emailPasswordResetProps: {
          headerText: this.$lang.settingsPage_EmailPasswordResetPopupHeader,
          bodyText: "",
          firstButton: {
            variant: "info",
            text: this.$lang.general_Cancel,
          },
          secondButton: {
            variant: "primary",
            text: this.$lang.settingsPage_EmailPasswordResetPopupButton,
          },
        },
        curRow: -1,
        curAdmin: null,
        showDeleteAdminPopup: false,
        showEmailPassworResetPopup: false,
      };
    },

    computed: {
      ...mapGetters({
        branding: GET_BRANDING,
        getAdmins: GET_ADMINS,
        getCurrentUser: GET_CURRENT_USER,
      }),
    },

    watch: {
      getAdmins(newVal) {
        this.admins = [];
        this.formatAdmins(newVal);
        const sortedAdmins = sortService.sortArrayOfObjects(
          this.admins,
          "status",
          "boolean"
        );
        this.displayedAdmins = sortedAdmins;
        this.curRow = -1;
      },
    },

    async created() {
      /* Checking if prospects exist in VueX store. If not, we call an action to fetch them and then store in store. */
      let admins;

      if (!this.getAdmins.length) {
        const response = await this.$store.dispatch(FETCH_ADMINS);
        admins = response.data;
      } else {
        admins = this.getAdmins;
      }

      this.formatAdmins(admins);
      const sortedAdmins = sortService.sortArrayOfObjects(
        this.admins,
        "status",
        "boolean"
      );

      this.displayedAdmins = sortedAdmins;
    },

    methods: {
      async removeAdmin() {
        // Check if current user is the admin that they are trying to remove.
        if (this.getCurrentUser._id === this.curAdmin.id) {
          this.$toasted.show(
            "You may not remove your own access.",
            this.$toastedFailure
          );
        } else {
          const deleteAdminData = {
            id: this.curAdmin.id,
            organization: this.branding.name,
          };

          const response = await this.$store.dispatch(
            DELETE_ADMIN,
            deleteAdminData
          );
          if (response) {
            this.$toasted.show(
              this.$lang.settingsPage_RemoveAdminSuccessMessage.format(
                `${this.curAdmin.firstName} ${this.curAdmin.lastName}`
              ),
              this.$toastedSuccess
            );
          } else {
            this.$toasted.show(
              this.$lang.general_ErrorMessage,
              this.$toastedFailure
            );
          }
        }
        this.showDeleteAdminPopup = false;
      },

      formatAdmins(admins) {
        this.admins = [];
        for (var i = admins.length - 1; i >= 0; i--) {
          const admin = admins[i];
          const date = dateService.toDisplayDate(
            admin.createdDate || new Date()
          );

          this.admins.push({
            id: admin._id,
            firstName: admin.first,
            lastName: admin.last,
            email: admin.email,
            status: !admin.emailMagic,
            signedUpDate: date,
          });
        }
      },
      async emailPasswordReset() {
        const response = await APIService.dashboard.forgotPassword({
          email: this.curAdmin.email,
        });
        if (response?.status === 200) {
          this.$toasted.show(
            this.$lang.settingsPage_EmailPasswordSuccessMessage.format(
              this.curAdmin.firstName
            ),
            this.$toastedSuccess
          );
        } else {
          this.$toasted.show(
            this.$lang.general_ErrorMessage,
            this.$toastedFailure
          );
        }
        this.showEmailPassworResetPopup = false;
      },

      clickMenu(row) {
        this.curRow = this.curRow === row ? -1 : row;
      },
      openDeletePopup(admin) {
        this.deleteAdminProps.bodyText =
          this.$lang.settingsPage_RemoveAdminPopupBody.format(admin.firstName);
        this.curRow = -1;
        this.curAdmin = admin;
        this.showDeleteAdminPopup = true;
      },
      openEmailPasswordResetPopup(admin) {
        this.emailPasswordResetProps.bodyText =
          this.$lang.settingsPage_EmailPasswordResetPopupBody.format(
            admin.firstName
          );
        this.curRow = -1;
        this.curAdmin = admin;
        this.showEmailPassworResetPopup = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .manage-administrators {
    width: 100%;
    height: 100vh;
  }

  .menu {
    position: absolute;
    top: 28px;
    right: 14px;
    background: white;
    border: 1px solid rgb(229, 231, 235);
    border-radius: 0.375rem;
    z-index: 100;
    overflow: hidden;
  }
  .menu-item {
    cursor: pointer;
    padding: 0.5rem 1rem;
    white-space: nowrap;
  }
  .menu-item + .menu-item {
    border-top: 1px solid rgb(229, 231, 235);
  }
  .menu-item:hover {
    color: white;
    background: rgb(2, 122, 255);
  }
  .menu-item.danger {
    color: red;
  }
  .menu-item.danger:hover {
    background: rgb(253, 236, 245);
  }
</style>
