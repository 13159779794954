<template>
  <div class="w-full h-screen flex">
    <section class="col-span-2 w-80 flex-none">
      <inbox
        @showChat="handleChat"
        v-bind:key="reloadInbox"
        v-bind:conversationID="convoID"></inbox>
    </section>
    <section v-if="showChat" class="flex-grow">
      <chat
        v-bind:key="selectedConvo"
        v-bind:conversationID="selectedConvo"
        @reloadInbox="reloadedInbox"
        @destroyConvoID="destroyConvoID"></chat>
    </section>
    <section v-else class="flex-grow">
      <blankchat></blankchat>
    </section>
  </div>
</template>

<script>
  import Inbox from "./messagingComponents/Inbox.vue";
  import Chat from "./messagingComponents/Chat.vue";
  import BlankChat from "./messagingComponents/BlankChat.vue";
  import { mapGetters } from "vuex";

  export default {
    components: {
      inbox: Inbox,
      chat: Chat,
      blankchat: BlankChat,
    },
    data() {
      return {
        showChat: null,
        selectedConvo: null,
        reloadInbox: 0,
        convoID: null,
      };
    },
    computed: {
      ...mapGetters({ currentUser: "getCurrentUser" }),
    },
    methods: {
      handleChat(data) {
        this.showChat = data[0];
        this.selectedConvo = data[1];
        this.reloadInbox += data[2];
      },
      reloadedInbox(data) {
        this.reloadInbox += data[0];
      },
      destroyConvoID() {
        this.$emit("destroyConvoID");
        //delete empty convo in inbox on destroyConvo event from chat
        this.reloadInbox += 1;
      },
    },
  };
</script>

<style scoped>
  #messagingContainer {
    width: 100%;
    height: 100%;
    padding: 79px 0px 0px 0px;
    box-sizing: border-box;
    background-color: white;
    display: grid;
    grid-template-columns: 300px auto;
    grid-template-rows: 100%;
    background-color: #f3fbfe;
  }
</style>
