import axios from "axios";
import Vue from "vue";

let token;

export default function (endpoint) {
  return async function (params) {
    const { data, method, requireToken, path = "" } = params;

    try {
      let config = {
        url: `${endpoint}${path}`,
        responseType: "json",
        method,
      };

      if (data) {
        if (method && method.toLowerCase() === "get") {
          config.params = data;
        } else {
          config.data = data;
        }
      }

      if (requireToken && token) {
        config.headers = { Authorization: `Bearer ${token}` };
      }

      return await axios(config);
    } catch (e) {
      return handleErrors(e);
    }
  };
}

export function setToken(userToken) {
  token = userToken;
}

export function getToken() {
  return token;
}

export function login(data) {
  if (data?.token) {
    token = data.token;
    Vue.$cookies.set("token", data.token);
    Vue.$cookies.set("uid", data._id);
  }
}

export function logout() {
  token = null;
  Vue.$cookies.remove("token");
  Vue.$cookies.remove("uid");
}

// https://axios-http.com/docs/handling_errors
function handleErrors(e) {
  // error response received (status >= 300)
  if (e.response) {
    console.log(e.response.data);
    return {
      status: e.response.status,
      message: e.response.data.message || e.response.data.error,
    };
  }
  // no response received
  else if (e.request) {
    console.error(e);
    return {
      status: 0,
    };
  }
  console.error(e);
}
