<template>
  <div>
    <div v-if="dashboardLoaded" class="dashboard">
      <Header class="dashboard-mobile-header" />
      <Sidebar class="dashboard-sidebar" />
      <div class="dashboard-content">
        <div v-if="currentUser.superAdmin" class="dashboard-header">
          <div class="super-admin-org">
            <router-link
              :to="{ name: 'SUPER_ADMIN_SCREEN' }"
              class="super-admin-link">
              Super Admin</router-link
            >: {{ orgName }}
          </div>
        </div>
        <router-view class="router-view" />
        <ZendeskWidget />
      </div>
    </div>

    <div v-else class="loading">
      <SplashAnimation class="logo"></SplashAnimation>
    </div>
  </div>
</template>

<script>
  import {
    FETCH_BRANDING,
    FETCH_ADMINS,
    FETCH_CURRENT_PARENTS,
    FETCH_PROSPECTS,
    FETCH_INVITED_AMBASSADORS,
    FETCH_ALL_OUTREACH,
    FETCH_CONVERSATIONS,
    SET_LAST_FETCHED,
    SET_ACTIVE_YEAR,
    RESET_HOME_STATE,
    LOGOUT,
  } from "@/store/actions.type";
  import screens from "@/constants/screens";
  import { GET_CURRENT_USER, GET_ACTIVE_YEAR } from "@/store/getters.type";
  import Sidebar from "@/components/dashboard/sidebar/Sidebar.vue";
  import Header from "@/components/main/Header.vue";
  import SplashAnimation from "@/components/dashboard/SplashAnimation.vue";
  import screenNames from "@/constants/screens";
  import { mapGetters, mapState } from "vuex";
  import { decodeUrl, encodeUrl } from "@/shared/utils";
  import ZendeskWidget from "./ZendeskWidget.vue";

  export default {
    name: "Dashboard",
    components: {
      Header,
      Sidebar,
      SplashAnimation,
      ZendeskWidget,
    },
    data() {
      return {
        orgName: null,
        dashboardLoaded: false,
        screens: screenNames,
      };
    },

    computed: {
      ...mapGetters({ user: GET_CURRENT_USER, activeYear: GET_ACTIVE_YEAR }),
      ...mapState({
        currentUser: (state) => state.mainDashboardModule.currentUser,
      }),
    },

    watch: {
      "$route.params.orgName": {
        immediate: true,
        handler(orgParam) {
          if (orgParam) {
            if (orgParam?.includes(" ")) {
              const orgName = encodeUrl(orgParam);
              const params = { ...this.$route.params, orgName };
              return this.$router.replace({ params });
            }
            this.orgName = decodeUrl(this.$route.params.orgName);
          }
          this.loadDashboard();
        },
      },
      "$route.params.year": {
        immediate: true,
        handler(year) {
          this.$store.dispatch(SET_ACTIVE_YEAR, year);
        },
      },
      activeYear: {
        immediate: true,
        handler(year) {
          if (this.$route.params.year != this.activeYear) {
            this.$router.replace({
              name: this.$route.name || screens.HOME_SCREEN,
              params: {
                ...this.$route.params,
                year,
              },
            });
          }
          this.loadOrgData();
        },
      },
    },

    beforeUpdate() {
      if (this.dashboardLoaded && !this.user) {
        this.$store.dispatch(LOGOUT);
        this.$router.push({
          name: "Login",
          params: { authenticationFailed: true },
        });
      }
    },

    methods: {
      async loadDashboard() {
        this.dashboardLoaded = false;

        this.$store.dispatch(RESET_HOME_STATE);

        const organizations = this.currentUser.organizations;

        // no org selected
        if (!this.orgName) {
          const orgName = encodeUrl(organizations[0]);
          this.$router.replace({
            name: "HOME_SCREEN",
            params: { orgName, year: this.activeYear.toString() },
          });
          return;
        }

        // user org access guard
        if (
          !this.currentUser.superAdmin &&
          organizations.indexOf(this.orgName) === -1
        ) {
          const orgName = encodeUrl(organizations[0]);
          this.$router.push({
            name: "HOME_SCREEN",
            params: { orgName, year: this.activeYear },
          });
        }

        await this.$store.dispatch(FETCH_BRANDING, this.orgName);
        this.$store.dispatch(SET_ACTIVE_YEAR, this.activeYear);
        this.dashboardLoaded = true;
        this.loadOrgData();
      },
      async loadOrgData() {
        if (!this.dashboardLoaded) {
          return;
        }
        this.dashboardLoaded = false;
        this.$store.dispatch(RESET_HOME_STATE);

        await Promise.any([
          this.$store.dispatch(FETCH_ADMINS),
          this.$store.dispatch(FETCH_CURRENT_PARENTS),
          this.$store.dispatch(FETCH_INVITED_AMBASSADORS),
          this.$store.dispatch(FETCH_PROSPECTS),
          this.$store.dispatch(FETCH_ALL_OUTREACH),
          this.$store.dispatch(FETCH_CONVERSATIONS),
        ]);

        const fetchDate = new Date();
        this.$store.dispatch(SET_LAST_FETCHED, {
          ambassadors: fetchDate,
          prospects: fetchDate,
          outreach: fetchDate,
        });
        this.dashboardLoaded = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .dashboard {
    display: flex;
  }

  .dashboard-mobile-header {
    display: none;
  }

  .dashboard-content {
    box-sizing: border-box;
    padding: 0 35px 0 35px;
    width: calc(100% - 13rem);
  }

  .loading {
    display: grid;
    place-items: center;
    height: 100vh;
  }
  .logo {
    max-width: 220px;
  }

  .dashboard-header {
    z-index: 50;
    background-color: $blue-500;
    border-radius: 0.25rem;
    margin-top: 0.5rem;
  }

  .router-view {
    height: calc(100vh - 28px);
  }

  .super-admin-org {
    font-size: 12px;
    line-height: 16px;
    color: white;
    padding: 0.125rem 0.5rem;
  }

  .super-admin-link {
    color: white;
    text-decoration: underline;
  }

  @media screen and (max-width: $sm) {
    .dashboard-mobile-header {
      display: block;
    }

    .dashboard-sidebar {
      display: none;
    }

    .dashboard-content {
      margin-top: 100px;
      width: 100%;
      padding: 0 1rem;
    }
  }
</style>
