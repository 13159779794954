<template>
  <div class="multi-user-select-search">
    <v-select
      class="multi-user-select-search-filter"
      :value="value.property"
      @input="(value) => onInput(value, 'property')"
      :items="filterOptions"
      :label="filterOptions[0].text"
      prepend-icon="$filter-variant"
      hide-details
      dense
      solo
      flat></v-select>
    <v-text-field
      class="multi-user-select-search-field"
      :value="value.searchString"
      @input="(value) => onInput(value, 'searchString')"
      :label="$lang.general_SearchPlaceholder"
      prepend-inner-icon="$magnify"
      hide-details
      dense
      solo
      flat
      clearable></v-text-field>
  </div>
</template>

<script>
  export default {
    name: "MultiUserSelectSearch",
    props: {
      value: {
        type: Object,
        required: true,
      },
      filterOptions: {
        type: Array,
        required: true,
      },
    },
    methods: {
      onInput(value, key) {
        const newValue = { ...this.value };
        newValue[key] =
          key === "searchString" ? (value || "").toLowerCase() : value;
        this.$emit("input", newValue);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .multi-user-select-search {
    display: flex;
    background-color: $grey-200;
    border-radius: 100px;
    padding-left: 0.8rem;

    ::v-deep {
      .v-input .v-input__control .v-input__slot {
        background-color: $grey-200;
        padding-left: 0 !important;
      }
    }

    &-filter {
      width: 3rem;
      flex: 0.35 1 auto;
    }

    &-field {
      flex: 1;

      &::before {
        content: "|";
        margin: auto 0.75rem auto 0;
        font-size: 1.25rem;
        font-weight: 300;
      }
    }
  }
</style>
