<template>
  <v-dialog v-model="dialog" :max-width="maxWidth" :persistent="persistent">
    <v-card>
      <v-card-title class="modal-title" :class="{ 'success-title': success }">
        {{ title }}

        <v-btn
          icon
          @click="dialog = false"
          v-if="enableHeaderClose"
          class="modal-close">
          <v-icon>$close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text
        class="modal-body text--primary"
        :class="{ 'success-body': success }">
        <slot></slot>
      </v-card-text>

      <v-card-actions
        v-if="$slots.actions || showSaveButton || showCancelButton">
        <v-spacer />

        <slot name="actions">
          <v-btn
            color="primary"
            outlined
            @click="$emit('cancel')"
            v-if="showCancelButton">
            {{ cancelButtonText }}
          </v-btn>

          <v-btn
            color="primary"
            depressed
            @click="$emit('save')"
            :disabled="isSaveDisabled"
            v-if="showSaveButton">
            {{ saveButtonText }}
          </v-btn>
        </slot>

        <v-spacer v-if="success" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: "PModal",
    props: {
      value: {
        type: Boolean,
        required: true,
      },

      title: String,

      size: {
        type: String,
        default: "md",
      },

      enableHeaderClose: {
        type: Boolean,
        default: true,
      },

      showCancelButton: {
        type: Boolean,
        default: true,
      },

      cancelButtonText: {
        type: String,
        default() {
          return this.$lang.general_Cancel;
        },
      },

      showSaveButton: {
        type: Boolean,
        default: true,
      },

      saveButtonText: {
        type: String,
        default() {
          return this.$lang.general_Save;
        },
      },

      isSaveDisabled: {
        type: Boolean,
        default: false,
      },

      // Whether click outside or esc closes modal
      persistent: {
        type: Boolean,
        default: false,
      },

      // success modal style
      success: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      dialog: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val);
        },
      },
      maxWidth() {
        switch (this.size) {
          default:
            return "";
          case "xs":
            return "360px";
          case "sm":
            return "500px";
          case "md":
            return "650px";
          case "lg":
            return "800px";
          case "xl":
            return "1100px";
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal-title {
    word-break: normal;
  }
  .modal-close {
    position: absolute;
    top: 4px;
    right: 4px;
  }

  .modal-body {
    max-height: calc(90vh - 127px);
    overflow: auto;
  }

  .success-title {
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 10px;
  }
  .success-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 46px;
    margin-bottom: 30px;
  }
</style>
