<template>
  <div data-vuetify>
    <v-app class="v-app-off">
      <v-btn
        v-show="showButton"
        fab
        color="primary"
        class="zendesk-btn"
        @click="showWidget"
        :aria-label="$lang.general_Help">
        <v-icon>$help-circle</v-icon>
      </v-btn>
    </v-app>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import api from "@/services/api/dashboard.service";

  export default {
    name: "ZendeskWidget",

    data() {
      return {
        showButton: false,
      };
    },

    computed: {
      ...mapGetters({ user: "getCurrentUser" }),
    },

    mounted() {
      window.zESettings = {
        webWidget: {
          color: { theme: "#0074e0" },
          authenticate: {
            jwtFn: (callback) => {
              api.getZendeskToken().then((res) => {
                if (res.data.token) {
                  callback(res.data.token);
                }
              });
            },
          },
        },
      };

      const script = document.createElement("script");
      script.setAttribute("id", "ze-snippet");
      script.setAttribute(
        "src",
        "https://static.zdassets.com/ekr/snippet.js?key=f77e6193-9e4c-4175-9bff-507635885d34"
      );
      script.onload = () => {
        this.setup();
      };
      document.head.appendChild(script);
    },

    beforeDestroy() {
      this.hideWidget();
      this.logout();
    },

    methods: {
      zendesk() {
        // need reference to window.zE as Zendesk script updates it
        return window.zE;
      },
      setup() {
        if (!this.zendesk()) {
          return;
        }

        this.hideWidget();
        this.authenticate();
        this.setLocale();
        this.prefill();
        this.showButton = true;

        this.zendesk()("webWidget:on", "close", () => {
          this.zendesk()("webWidget", "hide");
          this.showButton = true;
        });
      },
      showWidget() {
        this.zendesk()("webWidget", "show");
        this.zendesk()("webWidget", "open");
        this.showButton = false;
      },
      hideWidget() {
        this.zendesk()("webWidget", "hide");
      },
      authenticate() {
        this.zendesk()("webWidget", "helpCenter:reauthenticate");
      },
      logout() {
        this.zendesk()("webWidget", "logout");
      },
      setLocale() {
        this.zendesk()("webWidget", "setLocale", this.$locale);
      },
      prefill() {
        if (this.user) {
          this.zendesk()("webWidget", "prefill", {
            name: {
              value: this.user.first + " " + this.user.last,
            },
            email: {
              value: this.user.email,
            },
          });
        }
      },
    },
  };
</script>

<style scoped>
  .v-app-off {
    height: 0 !important;
  }
  .zendesk-btn {
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 100;
  }
</style>
