<template>
  <div class="sidebar-container">
    <div class="sidebar-content">
      <section>
        <div v-if="!hideHeader">
          <router-link
            class="peerpal-logo-link"
            :to="{ name: screens.HOME_SCREEN }">
            <img
              class="peerpal-logo"
              src="@/assets/images/logos/peerpal-gravyty-logo.svg" />
          </router-link>
          <admission-year
            :subMenuOpened="subMenuOpened"
            @toggle-submenu="toggleSubmenu" />
        </div>
        <!-- Menu Items -->
        <ul class="nav-bar-container" @click="toggleMobileNav">
          <!-- Dashboard -->
          <li>
            <router-link :to="{ name: screens.HOME_SCREEN }" class="link" exact>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                content="Dashboard"
                v-tippy="{
                  placement: 'bottom-end',
                  arrow: true,
                  arrowType: 'round',
                }">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
              <div>{{ $lang.sidebar_DashboardRoute }}</div>
            </router-link>
          </li>

          <!-- Ambassadors -->
          <li>
            <router-link
              :to="{ name: screens.AMBASSADORS_SCREEN }"
              class="link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mx-1 focus:outline-none"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                content="Ambassadors"
                v-tippy="{
                  placement: 'bottom-end',
                  arrow: true,
                  arrowType: 'round',
                }">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
              </svg>
              <div>{{ $lang.sidebar_AmbassadorsRoute }}</div>
            </router-link>
          </li>

          <!-- Prospects -->
          <li>
            <router-link :to="{ name: screens.PROSPECTS_SCREEN }" class="link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                content="Prospects"
                v-tippy="{
                  placement: 'bottom-end',
                  arrow: true,
                  arrowType: 'round',
                }">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
              </svg>
              <div class="link-badge">
                <div>{{ $lang.sidebar_ProspectsRoute }}</div>
                <div v-if="banRequests.length" class="badge">
                  {{ banRequests.length }}
                </div>
              </div>
            </router-link>
          </li>

          <!-- Outreach -->
          <li>
            <router-link :to="{ name: screens.OUTREACH_SCREEN }" class="link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                content="Outreach"
                v-tippy="{
                  placement: 'bottom-end',
                  arrow: true,
                  arrowType: 'round',
                }">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
              </svg>
              <div>{{ $lang.sidebar_OutreachRoute }}</div>
            </router-link>
          </li>
          <!-- Settings -->
          <li>
            <router-link :to="{ name: screens.SETTINGS_SCREEN }" class="link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                content="Settings"
                v-tippy="{
                  placement: 'bottom-end',
                  arrow: true,
                  arrowType: 'round',
                }">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
              <div>{{ $lang.sidebar_SettingsRoute }}</div>
            </router-link>
          </li>
        </ul>
      </section>

      <section>
        <!-- Tutorial Images -->
        <div class="helpdesk-container">
          <div class="helpdesk-description-container">
            <ppicon
              class="helpdesk-description-icon"
              name="question-mark-circle" />

            <label class="helpdesk-description">{{
              $lang.sidebar_ResourceCenterDescription
            }}</label>
          </div>

          <a
            class="helpdesk-resource-center-button helpdesk-button"
            rel="noreferrer"
            href="https://graduwayhelp.zendesk.com/hc/en-us/categories/4415643782290-PeerPal-Support-for-Admins"
            target="_blank"
            >{{ $lang.sidebar_ResourceCenterButton }}</a
          >

          <a
            class="helpdesk-button"
            rel="noreferrer"
            href="https://graduwayhelp.zendesk.com/hc/en-us/requests/new"
            target="_blank"
            >{{ $lang.sidebar_SubmitTicketButton }}</a
          >
        </div>

        <!-- Organization Menu Button -->
        <div class="org-menu-container">
          <!-- Expanded Button -->
          <common-button icon iconRight @click="toggleSubmenu('organization')">
            <p>{{ $lang.sidebar_OrganizationButton }}</p>
            <template slot="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7" />
              </svg>
            </template>
          </common-button>

          <!-- Organization Menu -->
          <div
            :class="[
              'sidebar-menu-wrapper',
              {
                hidden: subMenuOpened !== 'organization',
              },
            ]">
            <ul class="sidebar-menu" @click="subMenuOpened = ''">
              <li
                v-if="currentUser.superAdmin"
                class="org-menu-super-admin sidebar-menu-item"
                key="super-admin-panel">
                <router-link
                  :to="{ name: 'SUPER_ADMIN_SCREEN' }"
                  class="org-menu-super-admin sidebar-menu-link"
                  >{{ $lang.sidebar_SuperAdminPanelRoute }}</router-link
                >
              </li>

              <li
                v-for="(org, index) of userOrgs"
                :key="`organization-${index}`"
                class="sidebar-menu-item">
                <router-link
                  :to="{ params: { orgName: org.param, year: actualYear } }"
                  class="sidebar-menu-link"
                  >{{ org.displayName || org.name }}

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="current-org-checkmark"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7" />
                  </svg>
                </router-link>
              </li>
            </ul>
          </div>
        </div>

        <!-- Footer Section -->
        <div class="sidebar-footer" @click="toggleSubmenu('user')">
          <div class="sidebar-footer-user-container">
            <div class="sidebar-footer-user-info">
              <div
                class="sidebar-footer-user-image"
                src="https://randomuser.me/api/portraits/men/66.jpg">
                {{ currentUser.first[0] }}{{ currentUser.last[0] }}
              </div>
              <div>{{ currentUser.first }} {{ currentUser.last }}</div>
            </div>
          </div>

          <!-- User Menu -->
          <div
            :class="[
              'sidebar-menu-wrapper',
              {
                hidden: subMenuOpened !== 'user',
              },
            ]">
            <ul class="sidebar-menu">
              <li class="sidebar-menu-item">
                <router-link
                  :to="{ name: screens.MESSAGING_SCREEN }"
                  class="sidebar-menu-link">
                  {{ $lang.sidebar_UserMenuMessages }}
                </router-link>
              </li>
              <li class="sidebar-menu-item">
                <router-link
                  :to="{ name: 'My Account' }"
                  class="sidebar-menu-link">
                  {{ $lang.sidebar_UserMenuManageAccount }}
                </router-link>
              </li>
              <li class="sidebar-menu-item" @click="logout()">
                <router-link :to="{ name: 'Login' }" class="sidebar-menu-link">
                  {{ $lang.sidebar_UserMenuLogOut }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters } from "vuex";
  import { GET_ACTIVE_YEAR, GET_CURRENT_USER } from "@/store/getters.type";
  import { LOGOUT } from "@/store/actions.type";
  import screens from "@/constants/screens";
  import AdmissionYear from "./AdmissionYear.vue";
  import { encodeUrl } from "@/shared/utils";

  export default {
    components: {
      AdmissionYear,
    },

    props: {
      hideHeader: {
        type: Boolean,
        default: false,
      },

      parentSubMenuOpened: {
        type: String,
        default: null,
      },
    },

    data() {
      return {
        sidebarOpened: true,
        screens,
        subMenuOpened: null,
      };
    },

    computed: {
      actualYear() {
        return this.$route.params.year || this.activeYear;
      },

      ...mapGetters({
        currentUser: GET_CURRENT_USER,
        activeYear: GET_ACTIVE_YEAR,
      }),

      userOrgs() {
        return (
          this.currentUser?.organizationNames.map((org) => {
            return { ...org, param: encodeUrl(org.name) };
          }) ?? []
        );
      },

      ...mapState("notifications", ["banRequests"]),
    },

    watch: {
      parentSubMenuOpened: {
        immediate: true,
        handler(val) {
          this.subMenuOpened = val;
        },
      },
    },

    created() {
      this.$store.dispatch("notifications/getBanRequests");
    },

    methods: {
      logout() {
        this.$store.dispatch(LOGOUT);

        if (process.env.VUE_APP_API_KEY) {
          this.$amplitude.getInstance().setUserId(null);
          this.$amplitude.getInstance().regenerateDeviceId();
        }
      },

      toggleMobileNav() {
        this.$emit("toggle-mobile-nav");
      },

      toggleSubmenu(menu) {
        this.subMenuOpened = this.subMenuOpened === menu ? null : menu;
        this.$emit("set-submenu", menu);
      },
    },
  };
</script>

<style scoped lang="scss">
  .sidebar-container {
    height: 100vh;
    width: 13rem;
    flex-shrink: 0;
  }

  .sidebar-content {
    padding: 1rem;
    width: 13rem;
    background-color: $grey-100;
    border-right: 1px solid $grey-200;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 21;
    height: 100%;
    position: fixed;
  }

  .peerpal-logo-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .peerpal-logo {
    width: 80%;
    margin-left: 0.5rem;
    margin-bottom: 1.25rem;
    margin-top: 0.75rem;
    vertical-align: middle;
  }

  .nav-bar-container {
    font-weight: 600;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    display: grid;

    li {
      margin-top: 0.5rem;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .org-menu-container {
    position: relative;
  }

  .sidebar-menu-wrapper {
    position: absolute;
    z-index: 50;
    bottom: 0;
    font-size: 0.75rem;
    line-height: 1rem;
    border: 1px solid $grey-200;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    white-space: nowrap;
    overflow: auto;
    max-height: 15rem;
    left: 12rem;
    min-width: 13rem;
    box-shadow: $box-shadow-lg;
    background-color: $white;
  }

  .sidebar-menu {
    display: flex;
    flex-direction: column;
  }

  .sidebar-menu-item + .sidebar-menu-item {
    border-top: 2px solid $grey-200;
  }

  .sidebar-menu-link {
    height: 3.5rem;
    cursor: pointer;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.org-menu-super-admin {
      color: $blue-400;
      font-weight: 700;
    }

    &:hover {
      background-color: $grey-100;
    }

    &.router-link-active {
      cursor: default;
    }
  }

  .current-org-checkmark {
    display: none;
  }

  .router-link-active .current-org-checkmark {
    display: block;
    margin-left: 1.5rem;
    width: 1.25rem;
    height: 1.25rem;
    color: $blue-400;
  }

  .sidebar-footer {
    position: relative;
    cursor: pointer;
    transition: color 0.3s;
  }

  .sidebar-footer-user-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.25rem;

    &:hover {
      color: $blue-500;
    }
  }

  .sidebar-footer-user-info {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
  }

  .sidebar-footer-user-image {
    margin-right: 10px;
    background-color: $blue-250;
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $grey-600;
  }

  .link {
    display: flex;
    gap: 0.5rem;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    padding: 0.75rem;
    align-items: center;
    cursor: pointer;
    color: #757e8c;
  }
  .link:hover {
    color: unset;
  }
  .link.router-link-active {
    background: #fff;
    color: unset;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .link svg {
    width: 1.25rem;
    height: 1.25rem;
    margin: 0 0.25rem;
    flex: 0 0 auto;
  }
  .link.router-link-active svg {
    color: #037aff;
  }

  .helpdesk-container {
    margin-bottom: 1rem;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-shadow: $box-shadow-sm;
    border-radius: 8px;
  }

  .helpdesk-description-container {
    padding: 18px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .helpdesk-description {
    color: $grey-500;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    margin-top: 10px;
  }

  .helpdesk-description-icon.pp-icon:before {
    color: $blue-400;
  }

  .helpdesk-description-icon {
    font-size: 20px;
  }

  .helpdesk-resource-center-button {
    border-bottom: 1px solid $grey-200;
    border-top: 1px solid $grey-200;
  }

  .helpdesk-button {
    padding: 15px;
    width: 100%;
    color: $blue-400;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    text-align: center;
    transition: background-color var(--transition-duration);

    &:hover {
      background-color: $button-hover-background;
    }
  }
  .link-badge {
    --badge-size: 24px;
    justify-content: space-between;
    align-content: space-between;
    display: flex;
    width: 100%;
    line-height: var(--badge-size);
  }
  .badge {
    display: inline-block;
    color: $white;
    background: $blue-500;
    width: var(--badge-size);
    height: var(--badge-size);
    line-height: var(--badge-size);
    border-radius: 12px;
    text-align: center;
    margin-left: 5px;
  }
</style>
