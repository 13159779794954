<template>
  <p-modal
    :value="value"
    @input="$emit('input', !value)"
    size="sm"
    :title="$lang.campaign_ScheduleCampaign"
    :saveButtonText="$lang.campaign_EditScheduleSaveButton"
    @save="save"
    :showCancelButton="false">
    <p v-if="currentSchedule">
      {{ $lang.campaign_ScheduledFor.format(currentSchedule) }}
    </p>
    <p v-else>{{ $lang.campaign_NotScheduled }}</p>

    <div class="schedule-datetime">
      <!-- Date -->
      <v-menu
        v-model="dateMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="scheduleDateFormatted"
            :label="$lang.general_Date"
            readonly
            outlined
            dense
            hide-details
            v-bind="attrs"
            v-on="on"></v-text-field>
        </template>
        <v-date-picker
          v-model="scheduleDate"
          no-title
          :min="toDateString(new Date())"
          @input="dateMenu = false" />
      </v-menu>

      <!-- Time -->
      <v-combobox
        v-model="scheduleDateTime"
        :items="timeOptions"
        :label="$lang.general_Time"
        :disabled="!this.scheduleDate"
        outlined
        dense
        hide-details />

      {{ timezone }}
    </div>
  </p-modal>
</template>

<script>
  import dateService from "@/services/date.service";

  const CUTOFF_MINUTES = 10; // minutes before next hour
  const INTERVAL_MINUTES = 30; // schedule interval (must match background-service)

  export default {
    name: "CampaignScheduleModal",
    props: {
      value: Boolean, // open/close modal
      date: Date,
    },
    data() {
      return {
        scheduleDateTime: null,
        timeOptions: [],
        timezone: dateService.timezone,
        dateMenu: false,
        timeMenu: false,
      };
    },
    computed: {
      currentSchedule() {
        return dateService.toDisplayDateTimeTz(this.date);
      },
      scheduleDate: {
        get() {
          return this.scheduleDateTime
            ? this.toDateString(this.scheduleDateTime.value)
            : null;
        },
        set(date) {
          this.setTimeOptions(date);
          const selectedTime = this.scheduleDateTime.text;

          // choose the same time if possible
          this.scheduleDateTime =
            this.timeOptions.find((time) => time.text === selectedTime) ??
            this.timeOptions[0];
        },
      },
      scheduleDateFormatted() {
        if (!this.scheduleDate) {
          return "";
        }
        // ISO date YYYY-mm-dd to local date
        const [year, month, day] = this.scheduleDate.split("-");
        const date = new Date(year, month - 1, day);
        return dateService.toDisplayDate(date);
      },
    },
    created() {
      if (this.date) {
        const datetime = new Date(this.date);

        this.scheduleDateTime = {
          text: dateService.toDisplayTime(datetime),
          value: datetime,
        };
        this.setTimeOptions(datetime);
      } else {
        this.setTimeOptions();
        this.scheduleDateTime = this.timeOptions[0];
      }
    },
    methods: {
      toDateString(date) {
        const YYYY = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, "0");
        const dd = String(date.getDate()).padStart(2, "0");
        return `${YYYY}-${mm}-${dd}`;
      },
      setTimeOptions(dateString) {
        let date = new Date();

        if (dateString && typeof dateString === "string") {
          const [year, month, day] = dateString.split("-");
          date = new Date(year, month - 1, day);
        } else if (dateString && dateString instanceof Date) {
          date = dateString;
        }

        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);

        // end of day
        const endTime = new Date(date);
        endTime.setHours(24);

        const earliest = new Date();
        earliest.setMinutes(earliest.getMinutes() + CUTOFF_MINUTES);

        const timeOptions = [];

        while (date < endTime) {
          if (date >= earliest) {
            timeOptions.push({
              text: dateService.toDisplayTime(date),
              value: new Date(date),
            });
          }
          date.setMinutes(date.getMinutes() + INTERVAL_MINUTES);
        }
        this.timeOptions = timeOptions;
        return timeOptions;
      },
      save() {
        this.$emit("save", this.scheduleDateTime.value);
      },
    },
  };
</script>

<style scoped>
  .schedule-datetime {
    display: flex;
    gap: 16px;
    align-items: center;
  }
</style>
