import papa from "papaparse";
import dashboardApiService from "@/services/api/dashboard.service.js";
import dateService from "../services/date.service";
import Vue from "vue";

export function exportSafeguardingReport(
  primaryId,
  secondaryId,
  org,
  fromDate
) {
  return dashboardApiService
    .getConversations({
      primaryId,
      secondaryId,
      org,
      fromDate,
    })
    .then((response) => {
      if (response?.status === 200 && response.data) {
        let messages = [];

        //Flatten messages for prettier export
        if (response.data.conversations) {
          for (let convo of response.data.conversations) {
            let participants = convo.participants;

            for (let message of convo.messages) {
              const author = message.author
                ? message.author.first + " " + message.author.last
                : Vue.prototype.$lang.general_DeletedUser;

              const ambassador = participants[1]
                ? participants[1].first + " " + participants[1].last
                : Vue.prototype.$lang.general_DeletedUser;

              const prospect = participants[0]
                ? participants[0].first + " " + participants[0].last
                : Vue.prototype.$lang.general_DeletedUser;

              const processedMessage = {
                date: dateService.toLocalDateTimeString(message.date),
                ambassador,
                prospect,
                author,
                body: message.body,
              };
              messages.push(processedMessage);
            }
            messages.sort((a, b) => {
              return a.date > b.date ? 1 : -1;
            });
          }
        }
        if (messages.length > 0) {
          const days = fromDate
            ? "-" + Math.round((Date.now() - fromDate) / 86400000) + "Day_"
            : "";
          exportCsvFile(messages, `PeerPal_Conversation_${days}Export.csv`);
          return true;
        }
        return false;
      }
    })
    .catch((err) => {
      console.error(err);
      alert(err);
    });
}

export function exportFile(content, fileName, type) {
  const a = document.createElement("a");
  const blob = new Blob([content], { type });
  const url = window.URL.createObjectURL(blob);

  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
}

export function exportCsvFile(content, fileName, options = {}) {
  // support json2csv fields
  if (options.fields) {
    content = content.map((row) => {
      const mapped = {};

      for (const field of options.fields) {
        if (typeof field.value === "function") {
          mapped[field.label] = field.value(row);
        } else {
          mapped[field.label] = row[field.value];
        }
      }
      return mapped;
    });
  }

  const csv = papa.unparse(content, options);
  exportFile(csv, fileName, "text/csv");
}

export function importCsvFile(content, options = {}) {
  options = {
    header: true,
    transform: (val) => val.trim(),
    ...options,
  };
  return papa.parse(content, options);
}

export function toFullName(first, last, fallback) {
  first = first || "";
  last = last || "";
  const name = (first + " " + last).trim() || fallback;
  return name;
}

export async function resizeImage(arrayBuffer, size, type) {
  const blob = new Blob([arrayBuffer]);
  const blobURL = window.URL.createObjectURL(blob);
  const image = new Image();
  image.src = blobURL;

  return new Promise((resolve, reject) => {
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // dimensions to minimum of SIZE
      if (image.width > size && image.height > size) {
        if (image.width < image.height) {
          let ratio = size / image.width;
          canvas.width = size;
          canvas.height = ratio * image.height;
        } else {
          let ratio = size / image.height;
          canvas.width = ratio * image.width;
          canvas.height = size;
        }
      } else {
        canvas.width = image.width;
        canvas.height = image.height;
      }
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      const result = canvas.toDataURL(type);
      resolve(result);
    };
    image.onerror = () => {
      reject();
    };
  });
}

export function encodeUrl(text) {
  return text.replace(/ /g, "+");
}

export function decodeUrl(text) {
  return text.replace(/\+/g, " ");
}
