function calculateCallOutreachRequests(userID, outreachRequests = []) {
  const outreach = [];
  let combinedOutreachRequests = [];

  if (outreachRequests.completedRequests)
    combinedOutreachRequests = [...outreachRequests.completedRequests];
  if (outreachRequests.pendingRequests)
    combinedOutreachRequests = [
      ...combinedOutreachRequests,
      ...outreachRequests.pendingRequests,
    ];
  for (let request of combinedOutreachRequests) {
    if (request.inviterID) {
      if (request.inviterID._id !== userID) continue;
      outreach.push({
        _id: request._id,
        prospectFirst: request.inviteeFirst,
        prospectLast: request.inviteeLast,
        outreachType: "Call",
        requestDate: request.createdDate,
        sent: request.hadCall,
        prospectAction: request.accountMade,
      });
    }
  }

  return outreach;
}

function calculateVideoOutreachRequests(userID, outreachRequests) {
  const { respondedVideoInvites, videoInvites } = outreachRequests;
  const outreach = [];

  // Filtering through video invitations
  if (videoInvites && videoInvites.invitations) {
    for (let invite of videoInvites.invitations) {
      if (
        !invite.videoInvitationSubmission ||
        !invite.videoInvitationSubmission.videoInvitationRequest ||
        !invite.videoInvitationSubmission.videoInvitationRequest.inviterID
      )
        continue;

      const request = invite.videoInvitationSubmission.videoInvitationRequest;
      if (request.inviterID?._id !== userID) continue;

      outreach.push({
        _id: invite._id,
        prospectFirst: request.invitee.first,
        prospectLast: request.invitee.last,
        outreachType: "Video",
        sent: true,
        requestDate: request.createdAt,
        prospectAction: false,
      });
    }
  }

  // Filtering through video requests
  if (videoInvites && videoInvites.requests) {
    for (let request of videoInvites.requests) {
      if (!request.inviterID) continue;
      if (request.inviterID._id !== userID) continue;

      outreach.push({
        _id: request._id,
        prospectFirst: request.invitee.first,
        prospectLast: request.invitee.last,
        outreachType: "Video",
        requestDate: request.createdAt,
        prospectAction: false,
      });
    }
  }

  // Filtering through video submissions
  if (videoInvites && videoInvites.submissions.length) {
    for (let invite of videoInvites.submissions) {
      if (!invite.videoInvitationRequest) continue;
      const request = invite.videoInvitationRequest;
      if (request.inviterID?._id !== userID) continue;

      outreach.push({
        _id: request._id,
        prospectFirst: request.invitee.first,
        prospectLast: request.invitee.last,
        outreachType: "Video",
        requestDate: request.createdAt,
        prospectAction: false,
        isVideoSubmission: true,
        submissionID: invite._id,
      });
    }
  }

  // Filtering through responded video invitations
  if (respondedVideoInvites) {
    for (let invite of respondedVideoInvites) {
      if (
        !invite.videoInvitationSubmission ||
        !invite.videoInvitationSubmission.videoInvitationRequest ||
        !invite.videoInvitationSubmission.videoInvitationRequest.inviterID
      )
        continue;

      const request = invite.videoInvitationSubmission.videoInvitationRequest;
      if (request.inviterID._id !== userID) continue;

      outreach.push({
        _id: invite._id,
        prospectFirst: request.invitee.first,
        prospectLast: request.invitee.last,
        outreachType: "Video",
        requestDate: request.createdAt,
        sent: true,
        prospectAction: true,
      });
    }
  }

  return outreach;
}

function calculateEmailOutreachRequests(userID, outreachRequests) {
  const outreach = [];

  for (let request of outreachRequests) {
    if (request.inviterID && request.inviterID._id === userID) {
      outreach.push({
        _id: request._id,
        prospectFirst: request.invitee.first,
        prospectLast: request.invitee.last,
        outreachType: "Email",
        requestDate: request.createdAt,
        sent: request.inviteSent,
        prospectAction: request.accountMade,
      });
    }
  }

  return outreach;
}

export default {
  calculateCallOutreachRequests,
  calculateVideoOutreachRequests,
  calculateEmailOutreachRequests,
};
