<template>
  <div>
    <TableHeader
      :search="search"
      :headers="headers.filter((header) => header.map)"
      @input="search = $event"
      @filter="filter"
      @export="exportCsv" />

    <v-data-table
      class="outreach-table"
      :headers="headers"
      :items="items"
      item-key="_id"
      :search="search"
      show-select
      sort-by="sentAt"
      sort-desc
      :options="{
        page: $route.query.page || 1,
        itemsPerPage: parseInt($route.query.itemsPerPage, 10) || 10,
      }"
      :hide-default-footer="!items || items.length === 0"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: '$chevron-double-left',
        lastIcon: '$chevron-double-right',
        prevIcon: '$chevron-left',
        nextIcon: '$chevron-right',
        'items-per-page-options': [10, 25, 50, 100, -1],
      }"
      @input="checkedItems = $event"
      @pagination="handlePagination">
      <template v-slot:item.title="{ item }">
        <router-link
          class="outreach-table-campaign"
          :style="campaignColor(item.title && item.createdAt)"
          :to="{
            name: 'Outreach Campaign',
            params: { campaignId: item.campaignId || item.id },
          }"
          v-if="(item.campaignId || item.id) && item.title">
          {{ item.title }}
        </router-link>
      </template>

      <template v-slot:item.inviterName="{ item }">
        <Avatar
          :imgSrc="item.inviterID ? item.inviterID.imageURL : null"
          left />
        <router-link
          v-if="item.inviterID"
          :to="{
            name: 'PROFILE_SCREEN',
            params: { id: item.inviterID._id || item.inviterID },
          }">
          {{ item.inviterName }}
        </router-link>
        <template v-else>{{ $lang.general_DeletedUser }}</template>
      </template>

      <template v-slot:item.inviteeName="{ item }">
        {{ item.inviteeName }}
      </template>

      <template v-slot:item.inviteType="{ item, header }">
        <div class="outreach-table-type" v-if="header.map[item.inviteType]">
          <v-icon left>{{ header.map[item.inviteType].icon }}</v-icon>
          <a v-if="item.message" @click="openInvitationModal(item)">
            {{ header.map[item.inviteType].text }}
            <v-icon
              v-if="item.inviteType === inviteTypes.CALL"
              color="primary"
              right>
              $alert-circle
            </v-icon>
          </a>
          <span v-else class="outreach-table-type">
            {{ header.map[item.inviteType].text }}
          </span>
        </div>
      </template>

      <template v-slot:item.status="{ item, header }">
        <v-icon :color="header.map[item.status].color">$circle-small</v-icon>
        {{ header.map[item.status].text }}
      </template>

      <template v-slot:item.accountMade="{ item, header }">
        {{ header.map[item.accountMade].text }}
      </template>

      <template v-slot:item.invites="{ item }">
        {{ item.invites.length }}
      </template>

      <template v-slot:item.sentAt="{ item }">
        {{ toDisplayDate(item.sentAt) }}
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ toDisplayDate(item.createdAt) }}
      </template>
      <template v-slot:item.scheduledFor="{ item }">
        {{ toDisplayDateTimeTz(item.scheduledFor) }}
      </template>

      <template v-slot:item.adminName="{ item }">
        {{ item.adminName }}
      </template>

      <!-- Actions -->
      <template v-slot:item.actions="{ item }">
        <!-- View Request -->
        <v-tooltip bottom v-if="item.inviterID">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="openRequestModal(item)"
              v-bind="attrs"
              v-on="on"
              :aria-label="$lang.outreachPage_ViewRequest">
              <v-icon>$email-open</v-icon>
            </v-btn>
          </template>
          <span>{{ $lang.outreachPage_ViewRequest }}</span>
        </v-tooltip>

        <template v-else>
          <!-- Copy Campaign -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :to="{
                  name: 'Outreach Campaign',
                  params: { campaignId: item.id },
                  query: { copy: 1 },
                }"
                v-bind="attrs"
                v-on="on"
                :aria-label="$lang.outreachPage_CopyCampaign">
                <v-icon>$file-multiple</v-icon>
              </v-btn>
            </template>
            <span>{{ $lang.outreachPage_CopyCampaign }}</span>
          </v-tooltip>

          <!-- Delete Campaign -->
          <v-tooltip bottom v-if="!item.sentAt">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="showDeleteCampaignModal(item)"
                v-bind="attrs"
                v-on="on"
                :aria-label="$lang.outreachPage_DeleteCampaign">
                <v-icon>$delete</v-icon>
              </v-btn>
            </template>
            <span>{{ $lang.outreachPage_DeleteCampaign }}</span>
          </v-tooltip>
        </template>

        <!-- View Feedback -->
        <v-tooltip bottom v-if="item.feedback">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="openFeedbackModal(item)"
              v-bind="attrs"
              v-on="on"
              :aria-label="$lang.outreachPage_Feedback">
              <v-icon>$comment-quote</v-icon>
            </v-btn>
          </template>
          <span>{{ $lang.outreachPage_Feedback }}</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <FeedbackModal
      v-if="feedbackModal"
      v-model="feedbackModal"
      :conversationCallOrCallInvitationId="selectedItem.id"
      :source="inviteTypeMap[selectedItem.inviteType].text"
      :ambassadorName="selectedItem.inviterName"
      :prospectName="selectedItem.inviteeName" />

    <p-modal
      v-if="requestModal"
      v-model="requestModal"
      size="sm"
      :title="
        $lang.outreachPage_ViewRequestModalTitle.format(
          inviteTypeMap[selectedItem.inviteType].text,
          selectedItem.inviterName
        )
      "
      :showCancelButton="false"
      :showSaveButton="false">
      <h5 class="modal-label">{{ $lang.outreachPage_ModalMessage }}</h5>
      <div class="modal-body">{{ selectedItem.notes }}</div>
    </p-modal>

    <p-modal
      v-if="invitationModal"
      v-model="invitationModal"
      size="sm"
      :title="
        selectedItem.inviteType === inviteTypes.CALL
          ? $lang.outreachPage_CallMessageModalTitle.format(
              selectedItem.inviterID && selectedItem.inviterID.first
                ? selectedItem.inviterID.first
                : $lang.general_DeletedUser,
              selectedItem.invitee.first || $lang.general_DeletedUser
            )
          : $lang.outreachPage_InvitationModalTitle.format(
              selectedItem.inviterID && selectedItem.inviterID.first
                ? selectedItem.inviterID.first
                : $lang.general_DeletedUser,
              inviteTypeMap[selectedItem.inviteType].text,
              selectedItem.invitee.first || $lang.general_DeletedUser
            )
      "
      :showCancelButton="false"
      :showSaveButton="false">
      <video
        v-if="selectedItem.submission"
        :src="selectedItem.submission.video.mp4Url"
        class="modal-video"
        controls></video>
      <h5 class="modal-label">{{ $lang.outreachPage_ModalMessage }}</h5>
      <div class="modal-body">{{ selectedItem.message.body }}</div>
      <hr />
      <h5 class="modal-label">{{ $lang.outreachPage_ModalDateSent }}</h5>
      <div>
        {{ toDisplayDateTime(selectedItem.message.createdAt) }}
      </div>
    </p-modal>

    <p-modal
      v-if="deleteCampaignModal"
      v-model="deleteCampaignModal"
      size="sm"
      :title="$lang.outreachPage_DeleteCampaignTitle"
      :saveButtonText="$lang.general_Yes"
      :cancelButtonText="$lang.general_No"
      @save="deleteCampaign"
      @cancel="deleteCampaignModal = false">
      {{ $lang.outreachPage_DeleteCampaignMessage.format(selectedItem.title) }}
    </p-modal>
  </div>
</template>

<script>
  import dateService from "@/services/date.service";
  import inviteTypes from "@/constants/inviteTypes";
  import campaignColors from "@/constants/campaignColors";
  import { exportCsvFile } from "../../../../shared/utils";

  import TableHeader from "./TableHeader.vue";
  import FeedbackModal from "@/components/common/tailwind/modals/FeedbackModal.vue";
  import Avatar from "@/components/common/vuetify/Avatar.vue";

  export default {
    components: { FeedbackModal, TableHeader, Avatar },
    props: {
      items: {
        type: Array,
        required: true,
      },
      // columns to display from allHeaders.value
      columns: {
        type: Array,
        required: false,
      },
    },
    data() {
      return {
        /**
         * Available headers/columns used in table
         * @property {string}   text      displayed header
         * @property {string}   value     property name
         * @property {function} csvFormat formats value for CSV
         * @property {object}   map       map value to displayed text, icon, etc.; text used in CSV and filters
         */
        allHeaders: [
          {
            text: this.$lang.outreachPage_TableCampaignName,
            value: "title",
          },
          {
            text: this.$lang.outreachPage_TableAmbassador,
            value: "inviterName",
          },
          {
            text: this.$lang.outreachPage_TableRecipient,
            value: "inviteeName",
          },
          {
            text: this.$lang.outreachPage_TableDateSent,
            value: "sentAt",
            csvFormat(value) {
              return dateService.toLocalDateTimeString(value);
            },
          },
          {
            text: this.$lang.outreachPage_TableDateCreated,
            value: "createdAt",
            csvFormat(value) {
              return dateService.toLocalDateTimeString(value);
            },
          },
          {
            text: this.$lang.outreachPage_tableDateScheduled,
            value: "scheduledFor",
            csvFormat(value) {
              return dateService.toLocalDateTimeString(value);
            },
          },
          {
            text: this.$lang.outreachPage_TableInviteType,
            value: "inviteType",
            map: {
              email: {
                icon: "$email-outline",
                text: this.$lang.outreachPage_InviteTypeEmail,
              },
              call: {
                icon: "$phone-outline",
                text: this.$lang.outreachPage_InviteTypeCall,
              },
              video: {
                icon: "$video-outline",
                text: this.$lang.outreachPage_InviteTypeVideo,
              },
            },
          },
          {
            text: this.$lang.outreachPage_TableRecipientCount,
            value: "invites",
            csvFormat(value) {
              return value.length;
            },
          },
          {
            text: this.$lang.outreachPage_TableAmbassadorCount,
            value: "ambassadorCount",
          },
          {
            text: this.$lang.outreachPage_TableStatus,
            value: "status",
            map: {
              true: {
                color: "green",
                text: this.$lang.outreachPage_StatusDone,
              },
              false: {
                color: "red",
                text: this.$lang.outreachPage_StatusPending,
              },
            },
          },
          {
            text: this.$lang.outreachPage_TableProspectRegistered,
            value: "accountMade",
            map: {
              true: {
                text: this.$lang.general_Yes,
              },
              false: {
                text: this.$lang.general_No,
              },
            },
          },
          {
            text: this.$lang.outreachPage_TableCreatedBy,
            value: "adminName",
          },
          {
            text: "",
            value: "actions",
            sortable: false,
            filterable: false,
          },
          {
            // hidden column for filters
            text: "Feedback",
            value: "hasFeedback",
            class: "d-none",
            cellClass: "d-none",
            sortable: false,
            map: {
              true: {
                text: this.$lang.general_Yes,
              },
              false: {
                text: this.$lang.general_No,
              },
            },
          },
        ],
        inviteTypes,
        requestModal: false,
        feedbackModal: false,
        invitationModal: false,
        deleteCampaignModal: false,
        selectedItem: null,
        checkedItems: [],
        search: "",
        filters: {},
      };
    },
    computed: {
      headers() {
        if (this.columns) {
          return this.columns
            .map((column) => {
              const found = this.allHeaders.find(
                (header) => header.value === column
              );
              if (found) {
                const header = { ...found };

                if (header?.map) {
                  const filter = this.filters[header.value];
                  if (filter?.length) {
                    header.filter = (value) => {
                      return filter.indexOf(value) >= 0;
                    };
                  }
                }
                return header;
              }
            })
            .filter((header) => header);
        }
        return this.allHeaders;
      },
      inviteTypeMap() {
        return this.allHeaders.find((header) => header.value === "inviteType")
          .map;
      },
    },
    methods: {
      campaignColor(date) {
        let color = "transparent";

        if (date) {
          const num = new Date(date).getTime();
          color = campaignColors[num % campaignColors.length];
        }

        return {
          "border-color": color,
        };
      },
      toDisplayDate(date) {
        return dateService.toDisplayDate(date);
      },
      toDisplayDateTime(date) {
        return dateService.toDisplayDateTime(date);
      },
      toDisplayDateTimeTz(date) {
        return dateService.toDisplayDateTimeTz(date);
      },
      openRequestModal(item) {
        this.selectedItem = item;
        this.requestModal = true;
      },
      openFeedbackModal(item) {
        // email invitations don't have feedback, but conversations do
        item.id = item.conversationId || item._id;
        this.selectedItem = item;
        this.feedbackModal = true;
      },
      openInvitationModal(item) {
        this.selectedItem = item;
        this.invitationModal = true;
      },
      showDeleteCampaignModal(item) {
        this.deleteCampaignModal = true;
        this.selectedItem = item;
      },
      deleteCampaign() {
        this.$emit("delete-campaign", this.selectedItem.id);
        this.deleteCampaignModal = false;
      },
      exportCsv() {
        const csv = [];

        const toExport = this.checkedItems?.length
          ? this.checkedItems
          : this.items;

        for (const item of toExport) {
          const row = {};
          for (const header of this.headers) {
            if (header.csvFormat) {
              row[header.text] = header.csvFormat(item[header.value]);
            } else if (header.map) {
              row[header.text] = header.map[item[header.value]].text;
            } else if (header.text) {
              row[header.text] = item[header.value];
            }
          }
          csv.push(row);
        }
        exportCsvFile(csv, "Outreach_Requests.csv");
      },
      filter({ header, filter }) {
        this.$set(this.filters, header, filter);
      },

      handlePagination({ page, itemsPerPage }) {
        if (
          this.$route.query.page !== page.toString() ||
          this.$route.query.itemsPerPage !== itemsPerPage.toString()
        ) {
          this.$router.replace({
            hash: this.$route.hash,
            query: { ...this.$route.query, page, itemsPerPage },
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .outreach-table a {
    font-weight: 500;
  }
  .outreach-table-campaign {
    display: flex;
    border-left: 6px solid;
    padding: 0 16px;
    min-height: 32px;
    align-items: center;
  }
  @media (min-width: $sm) {
    ::v-deep tbody > tr {
      height: 75px;
    }
  }
  .outreach-table-type {
    display: flex;
    align-items: center;

    a {
      display: flex;
      align-items: center;
    }
  }
  .modal-label {
    font-weight: 500;
    margin-top: 0.5rem;
  }
  .modal-body {
    display: block;
    white-space: pre-wrap;
    margin-bottom: 1rem;
  }
  .modal-video {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 1rem;
  }
</style>
