<template>
  <div class="campaign-step-page" v-if="campaign">
    <h3>{{ $lang.campaign_StepImportList }}</h3>

    <v-card outlined rounded="xl">
      <v-card-title>{{ $lang.campaign_UploadCsv }}</v-card-title>
      <v-card-text>
        <p>
          <p-markdown>
            {{ $lang.campaign_UploadCsvInstructions }}
          </p-markdown>
        </p>

        <p>
          {{ $lang.campaign_UploadCsvInstructions1 }}
        </p>

        <ul class="instructions-list">
          <li>
            <p-markdown>{{ $lang.campaign_UploadCsvInstructions2 }}</p-markdown>
          </li>
          <li>
            <p-markdown>{{ $lang.campaign_UploadCsvInstructions3 }}</p-markdown>
          </li>
          <li>
            <p-markdown>{{ $lang.campaign_UploadCsvInstructions4 }}</p-markdown>
          </li>
          <li>
            <p-markdown>{{ $lang.campaign_UploadCsvInstructions5 }}</p-markdown>
            <a role="button" class="link" @click="downloadAmbassadors">
              {{ $lang.campaign_ButtonDownloadAmbassadors }}
            </a>
          </li>
        </ul>
      </v-card-text>
      <div class="card-footer">
        <div class="import-buttons">
          <v-btn
            color="primary"
            depressed
            outlined
            @click="downloadCsvTemplate">
            <v-icon left>$pencil</v-icon>
            {{ $lang.campaign_ButtonDownloadCsvTemplate }}
          </v-btn>
          <v-btn color="primary" depressed @click="$refs.fileUpload.click()">
            <v-icon left>$upload</v-icon>
            <template v-if="this.campaign.csvFilename">
              {{ $lang.campaign_ButtonReplaceCsvFile }}
            </template>
            <template v-else>{{ $lang.campaign_ButtonUploadCsvFile }}</template>
            <input
              type="file"
              ref="fileUpload"
              @change="uploadCsv"
              accept=".csv"
              name="file"
              class="hidden" />
          </v-btn>
        </div>
        <div v-if="errors.length">
          <v-alert
            v-for="(error, i) of errors"
            :key="error.row + '-' + i"
            type="error"
            dense
            text
            icon="$alert-circle-outline">
            <template v-if="error.row">
              {{
                $lang.campaign_UploadCsvRowError.format(
                  error.row,
                  error.message
                )
              }}
            </template>
            <template v-else>
              {{ error.message }}
            </template>
          </v-alert>
        </div>
      </div>
    </v-card>

    <template v-if="errors.length === 0">
      <v-alert
        v-if="campaign.csvFilename"
        rounded="xl"
        color="grey lighten-3"
        class="file-upload-alert">
        {{ $lang.campaign_UploadCsvSuccessful }}
        <a role="button" class="link" small @click="downloadUploadedCsv">
          {{ $lang.campaign_DownloadCsv.format(campaign.csvFilename) }}
        </a>
      </v-alert>

      <v-card outlined rounded="xl" v-if="campaign.dynamicColumns.length">
        <v-card-title>
          {{ $lang.campaign_FieldVisibilityTitle }}
        </v-card-title>
        <v-card-text>
          <p>{{ $lang.campaign_FieldVisibilityInstructions }}</p>
          <v-checkbox
            v-for="col of campaign.dynamicColumns"
            :key="col.name"
            :label="col.name"
            v-model="col.isVisible"
            on-icon="$eye"
            off-icon="$eye-off"
            hide-details></v-checkbox>
        </v-card-text>
      </v-card>
    </template>

    <p-modal
      :title="$lang.campaign_CsvKeepAmbassadors"
      persistent
      size="sm"
      :enableHeaderClose="false"
      v-model="showKeepAmbassadorsModal"
      :cancelButtonText="$lang.general_No"
      :saveButtonText="$lang.general_Yes"
      @cancel="keepAmbassadors(false)"
      @save="keepAmbassadors(true)">
      {{ $lang.campaign_CsvKeepAmbassadorsMessage }}
    </p-modal>
    <p-modal
      :title="$lang.campaign_CsvNoAmbassadors"
      persistent
      size="sm"
      :enableHeaderClose="false"
      v-model="showNoAmbassadorsModal"
      :cancelButtonText="$lang.general_No"
      :saveButtonText="$lang.general_Yes"
      @cancel="populateAmbassadors(false)"
      @save="populateAmbassadors(true)">
      {{ $lang.campaign_CsvNoAmbassadorsMessage }}

      <v-checkbox
        v-model="includeVacationMode"
        hide-details
        :label="$lang.campaign_IncludeVacationMode"></v-checkbox>
      <v-checkbox
        v-model="includeInactive"
        hide-details
        :label="$lang.campaign_IncludeInactive"></v-checkbox>
    </p-modal>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import {
    exportCsvFile,
    importCsvFile,
    exportFile,
    toFullName,
  } from "@/shared/utils";

  export default {
    name: "CampaignImportStep",
    props: {
      data: {
        type: Object,
        required: true,
      },
      allAmbassadors: {
        type: Array,
        default: () => [],
      },
      csvReplaced: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        campaign: null,
        pendingInvitations: [],
        defaultFields: [
          { name: "Ambassador First Name" },
          { name: "Ambassador Last Name" },
          { name: "Ambassador Email" },
          { name: "Recipient First Name", required: true },
          { name: "Recipient Last Name", required: true },
          { name: "Recipient Email", required: true },
          { name: "Recipient Phone" },
        ],
        errors: [],
        showKeepAmbassadorsModal: false,
        showNoAmbassadorsModal: false,
        includeVacationMode: false,
        includeInactive: false,
      };
    },
    computed: {
      ...mapGetters({
        branding: "getBranding",
      }),
      requiredFields() {
        return this.defaultFields
          .filter((field) => field.required)
          .map((field) => field.name);
      },
    },
    watch: {
      data: {
        immediate: true,
        deep: true,
        handler() {
          if (this.data.campaign) {
            this.campaign = { ...this.data.campaign, dynamicColumns: [] };

            const phoneField = this.defaultFields.find(
              (field) => field.name === "Recipient Phone"
            );
            phoneField.required = this.campaign.inviteType === "phone";

            if (this.data.campaign.dynamicColumns) {
              let needsUpdate = false;

              this.campaign.dynamicColumns =
                this.data.campaign.dynamicColumns.map((col) => {
                  // support for legacy campaigns
                  if (typeof col === "string") {
                    needsUpdate = true;
                    return {
                      isVisible: true,
                      name: col,
                    };
                  }
                  return col;
                });
              if (needsUpdate) {
                this.$emit("update", { campaign: this.campaign });
              }
            }

            if (this.data.pendingInvitations) {
              this.pendingInvitations = [...this.data.pendingInvitations];
            }

            if (this.pendingInvitations.length > 0) {
              this.$emit("complete", true);
            }
          }
        },
      },
    },
    methods: {
      downloadAmbassadors() {
        const csv = this.allAmbassadors.map((amb) => {
          return {
            "First Name": amb.first,
            "Last Name": amb.last,
            Email: amb.email,
            "Profile Visible": amb.isActive,
            "Call Requests": amb.requestCall,
            "On Vacation": amb.isOnVacation,
          };
        });
        exportCsvFile(csv, "ambassadors.csv");
      },
      downloadCsvTemplate() {
        const content = this.defaultFields.map((field) => field.name).join(",");
        exportFile(content, "outreach-campaign.csv");
      },
      uploadCsv(e) {
        const csvFile = e.target.files[0];
        const mimeTypes = ["text/csv", "application/vnd.ms-excel"];

        if (mimeTypes.includes(csvFile.type)) {
          e.target.value = ""; // reset so same file can be uploaded again
          const reader = new FileReader();
          reader.readAsText(csvFile);

          reader.onload = async (event) => {
            const csv = event.target.result.trim();
            const result = await importCsvFile(csv);
            this.errors = [];

            if (result.errors.length) {
              for (const error of result.errors) {
                this.addError(
                  error.row,
                  this.$lang["campaign_UploadCsvError" + error.code] ||
                    this.$lang.campaign_UploadCsvErrorMalformed
                );
              }
            }

            // non-default fields are dynamic columns
            this.campaign.dynamicColumns = result.meta.fields
              .filter((field) => {
                return !this.defaultFields.some(
                  (defaultField) => field === defaultField.name
                );
              })
              .map((field) => {
                return { name: field, isVisible: false };
              });

            const recipientEmails = new Set();
            let row = 1;

            this.pendingInvitations = result.data.map((line) => {
              row++;

              // default fields must not be empty
              const missingFields = this.requiredFields.filter(
                (field) => !line[field]?.length
              );
              if (missingFields.length) {
                this.addError(
                  row,
                  "Missing required field(s): " + missingFields.join(", ")
                );
              }

              if (recipientEmails.has(line["Recipient Email"])) {
                this.addError(
                  row,
                  this.$lang.campaign_UploadCsvErrorDuplicateEmail
                );
              } else {
                recipientEmails.add(line["Recipient Email"]);
              }

              const ambassador = this.matchAmbassador(
                line["Ambassador Email"],
                line["Ambassador First"],
                line["Ambassador Last"]
              );
              if (ambassador === undefined) {
                this.addError(row, this.$lang.campaign_UploadCsvErrorNoMatch);
              }

              const dynamicColumns = {};
              for (const col of this.campaign.dynamicColumns) {
                dynamicColumns[col.name] = line[col.name];
              }

              return {
                inviteType: this.campaign.inviteType,
                inviterID: ambassador?.id || ambassador?._id,
                invitee: {
                  first: line["Recipient First Name"],
                  last: line["Recipient Last Name"],
                  name: toFullName(
                    line["Recipient First Name"],
                    line["Recipient Last Name"]
                  ),
                  email: line["Recipient Email"],
                  phone: line["Recipient Phone"],
                },
                dynamicColumns,
              };
            });

            if (this.errors.length > 0) {
              this.errors.sort((a, b) => a.row - b.row);
              this.$emit("complete", false);
            } else if (this.pendingInvitations.length) {
              this.campaign.csvFilename = csvFile.name;
              this.campaign.csv = csv;

              const hasImportAmbassadors = this.pendingInvitations.some(
                (invite) => !!invite.inviterID
              );
              const hasCachedAmbassadors = this.data.pendingInvitations.some(
                (invite) => !!invite.inviterID
              );
              if (!hasImportAmbassadors && hasCachedAmbassadors) {
                this.showKeepAmbassadorsModal = true;
                return;
              }
              if (!hasImportAmbassadors && !hasCachedAmbassadors) {
                this.showNoAmbassadorsModal = true;
                return;
              }

              this.$emit("set-csv-replaced", !this.csvReplaced);

              this.$emit("update", {
                campaign: this.campaign,
                pendingInvitations: this.pendingInvitations,
              });
              this.$emit("complete", true);
            } else {
              this.addError(null, this.$lang.campaign_UploadCsvErrorEmptyFile);
              this.$emit("complete", false);
            }
          };
        }
      },
      keepAmbassadors(keep) {
        if (keep) {
          const ambassadors = new Set();

          // add previous set of ambassadors to list
          for (const invite of this.data.pendingInvitations) {
            if (invite.inviterID && !ambassadors.has(invite.inviterID)) {
              ambassadors.add(invite.inviterID);
              this.pendingInvitations.push({
                inviteType: this.campaign.inviteType,
                inviterID: invite.inviterID,
              });
            }
          }
        }
        this.$emit("update", {
          campaign: this.campaign,
          pendingInvitations: this.pendingInvitations,
        });
        this.$emit("set-csv-replaced", !this.csvReplaced);
        this.$emit("complete", true);
        this.showKeepAmbassadorsModal = false;
      },

      downloadUploadedCsv() {
        exportFile(this.campaign.csv, this.campaign.csvFilename);
      },
      matchAmbassador(email, first, last) {
        if (email) {
          return this.allAmbassadors.find((user) => {
            return user.email === email;
          });
        }
        if (first && last) {
          return this.allAmbassadors.find((user) => {
            return user.first === first && user.last === last;
          });
        }
        return null;
      },
      addError(row, message) {
        this.errors.push({ row, message });
      },
      async populateAmbassadors(populate) {
        let invites = [];

        if (populate) {
          const ambassadors = this.allAmbassadors.filter(
            ({ isOnVacation, isActive }) =>
              (this.includeVacationMode || !isOnVacation) &&
              (this.includeInactive || isActive)
          );

          for (const ambassador of ambassadors) {
            // Create blank matches
            invites.push({
              campaignID: this.data.campaign._id,
              inviteType: this.data.campaign.inviteType,
              inviterID: ambassador._id,
              invitee: null,
              dynamicColumns: null,
            });
          }
        }
        this.$emit("set-csv-replaced", !this.csvReplaced);
        this.$emit("update", {
          campaign: this.campaign,
          pendingInvitations: [...this.pendingInvitations, ...invites],
        });
        this.$emit("complete");

        this.showNoAmbassadorsModal = false;
        this.includeVacationMode = false;
        this.includeInactive = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .campaign-step-page {
    display: flex;
    flex-direction: column;
    gap: 18px;

    .file-upload-alert {
      margin: 0;
      font-weight: 500;
    }
  }
  ul.instructions-list {
    list-style: disc;

    li {
      padding-bottom: 0.5em;
    }
  }
  .card-footer {
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 0 16px 16px 16px;
  }
  .import-buttons {
    display: flex;
    gap: 18px;
    justify-content: flex-end;
  }
  .link {
    color: var(--v-anchor-base);
  }
</style>
