<template>
  <div class="ban-wrapper" v-if="banRequests.length">
    <div class="ban-title">
      <ppicon name="account-cancel" class="title-icon" />
      {{ $lang.prospectsPage_BanRequestsTitle }}
    </div>
    <div v-for="banRequest of enrichedBanRequests" :key="banRequest._id">
      <div
        class="ban-row ban-summary"
        @click="clickShowDetails(banRequest._id)">
        <div>{{ banRequest.summary }}</div>
        <ppicon
          v-if="!showDetails[banRequest._id]"
          name="chevron-down"
          class="chevron" />
        <ppicon v-else name="chevron-up" class="chevron" />
      </div>

      <div
        class="ban-row ban-details"
        :class="{ show: showDetails[banRequest._id] }">
        <div>
          <span class="label">
            {{ $lang.prospectsPage_BanRequestsReason }}:
          </span>
          {{ banRequest.ban.reason }}
          <div class="ban-users">
            <div class="label user-label">{{ $lang.general_Ambassador }}</div>
            <div class="user-details">
              <div>
                <span class="label">{{ $lang.general_Name }}:</span>
                {{ banRequest.user.name }}
              </div>
              <div>
                <span class="label">{{ $lang.general_Email }}:</span>
                {{ banRequest.user.email }}
              </div>
              <div>
                <span class="label">{{ $lang.general_SignUpDate }}:</span>
                {{ formatDate(banRequest.user.createdDate) }}
              </div>
            </div>

            <div class="label user-label">{{ $lang.general_Prospect }}</div>
            <div class="user-details">
              <div>
                <span class="label">{{ $lang.general_Name }}:</span>
                {{ banRequest.ban.user.name }}
              </div>
              <div>
                <span class="label">{{ $lang.general_Email }}:</span>
                {{ banRequest.ban.user.email }}
              </div>
              <div>
                <span class="label">{{ $lang.general_SignUpDate }}:</span>
                {{ formatDate(banRequest.user.createdDate) }}
              </div>
            </div>
          </div>
        </div>
        <div class="ban-actions">
          <common-button
            variant="text"
            class="ban-action-btn"
            size="sm"
            @click="clickDownloadChatHistory(banRequest)">
            <ppicon name="download" class="btn-icon" />
            {{ $lang.prospectsPage_ActionDownloadChatHistory }}
          </common-button>
          <common-button
            variant="text"
            class="ban-action-btn"
            size="sm"
            @click="onDenyBan(banRequest)">
            <ppicon name="close" class="btn-icon" />
            {{ $lang.prospectsPage_ActionDenyBan }}
          </common-button>
          <common-button
            variant="text"
            class="ban-action-btn btn-ban"
            size="sm"
            @click="onApproveBan(banRequest)">
            <ppicon name="check" class="btn-icon" />
            {{ $lang.prospectsPage_ActionApproveBan }}
          </common-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from "vuex";
  import { exportSafeguardingReport } from "@/shared/utils.js";
  import resolutions from "@/constants/resolutions";
  import dateService from "@/services/date.service.js";
  import { toFullName } from "@/shared/utils";

  export default {
    name: "BanRequests",
    data() {
      return {
        showDetails: {},
      };
    },
    computed: {
      ...mapState("notifications", ["banRequests"]),

      enrichedBanRequests() {
        return this.banRequests.map((banRequest) => {
          if (!banRequest.user) {
            banRequest.user = {};
          }
          if (!banRequest.ban.user) {
            banRequest.ban.user = {};
          }
          banRequest.user.name = toFullName(
            banRequest.user.first,
            banRequest.user.last,
            this.$lang.general_DeletedUser
          );
          banRequest.ban.user.name = toFullName(
            banRequest.ban.user.first,
            banRequest.ban.user.last,
            this.$lang.general_DeletedUser
          );
          banRequest.summary =
            this.$lang.prospectsPage_BanRequestSummary.format(
              banRequest.user.name,
              banRequest.ban.user.name
            );
          return banRequest;
        });
      },
    },
    methods: {
      ...mapActions("notifications", ["completeBanRequest"]),

      formatDate(val) {
        return dateService.toDisplayDate(val);
      },
      clickShowDetails(id) {
        this.$set(this.showDetails, id, !this.showDetails[id]);
      },
      async clickDownloadChatHistory(banRequest) {
        const result = await exportSafeguardingReport(
          banRequest.user._id,
          banRequest.ban.user._id
        );
        if (!result) {
          this.$toasted.show(
            this.$lang.prospectsPage_NoMessagesToDownload,
            this.$toastedFailure
          );
        }
      },
      onDenyBan(banRequest) {
        this.completeBanRequest({
          id: banRequest._id,
          resolution: resolutions.DENIED,
        });
      },
      onApproveBan(banRequest) {
        this.completeBanRequest({
          id: banRequest._id,
          resolution: resolutions.APPROVED,
        })
          .then(() => {
            this.$store.dispatch("updateProspect", {
              id: banRequest.ban.user._id,
              updates: {
                isBanned: true,
              },
            });
            const name =
              banRequest.ban.user.first + " " + banRequest.ban.user.last;

            this.$toasted.show(
              this.$lang.prospectsPage_BannedUserMessage.format(name),
              this.$toastedSuccess
            );
          })
          .catch((e) => {
            console.error(e);
            this.$toasted(
              this.$lang.general_ErrorMessage,
              this.$toastedFailure
            );
          });
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "@/scss/variables.scss";

  .ban-wrapper {
    border: 1px solid $border-grey;
    border-left: 4px solid $blue-400;
    border-radius: 8px;
    margin-bottom: 1rem;
    overflow: hidden;
  }

  .ban-title {
    font-size: 18px;
    font-weight: 500;
    background: $grey-100;
    padding: 16px 12px;
  }
  .title-icon {
    margin-right: 0.5em;
  }
  .ban-row {
    font-size: 14px;
    border-top: 1px solid $border-grey;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1em;
    padding: 16px;
  }
  .ban-summary {
    padding: 16px 12px;
    cursor: pointer;
  }
  .ban-details {
    display: none;
    font-size: 12px;
  }
  .ban-details.show {
    display: grid;
  }
  .ban-users {
    display: grid;
    grid-template-columns: auto 1fr auto 1fr;
    gap: 16px;
    padding-top: 16px;
  }
  .user-label {
    padding-right: 16px;
    border-right: 1px solid $border-grey;
  }
  .user-details {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }
  .label {
    font-weight: 500;
  }
  .ban-actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .ban-action-btn.btn-text {
    background: $grey-200;
    font-weight: 500;
    text-align: left;
    padding: 0.5em 1em;
    color: $primary-text;
    font-size: 12px;

    .btn-icon {
      margin-right: 0px;
    }
  }
  .btn.ban-action-btn:hover {
    filter: brightness(95%);
  }
  .btn-ban.ban-action-btn.btn-text {
    color: $white;
    background: $blue-400;
  }
  .btn-icon {
    margin-right: 0.5em;
    font-size: 16px;
  }
  .chevron {
    font-size: 16px;
  }
  @media (max-width: $lg) {
    .ban-users {
      grid-template-columns: auto 1fr;
      grid-auto-rows: 1fr 1fr;
    }
  }
  @media (max-width: $sm) {
    .ban-details {
      grid-template-columns: 1fr;
    }
  }
</style>
