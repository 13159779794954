import {
  SET_VISITOR_VIEWS,
  SET_ENGAGEMENT_STATS,
  SET_ENGAGEMENT_GRAPH,
  SET_PENDING_VIDEO_INVITATIONS,
  SET_PENDING_VIDEO_STORIES,
  SET_ENGAGEMENT_STATS_VIDEO,
  SET_CONVERSATION_GRAPH,
  RESET_HOME_STATE,
} from "../actions.type.js";
import {
  GET_VISITOR_VIEWS,
  GET_ENGAGEMENT_STATS,
  GET_ENGAGEMENT_GRAPH,
  GET_PENDING_VIDEO_INVITATIONS,
  GET_PENDING_VIDEO_STORIES,
  GET_CONVERSATION_GRAPH,
} from "../getters.type";
import {
  UPDATE_VISITOR_VIEWS,
  UPDATE_ENGAGEMENT_STATS,
  UPDATE_ENGAGEMENT_GRAPH,
  UPDATE_PENDING_VIDEO_INVITATIONS,
  UPDATE_PENDING_VIDEO_STORIES,
  UPDATE_ENGAGEMENT_STATS_VIDEO,
  UPDATE_CONVERSATION_GRAPH,
} from "../mutations.type";

export const initialState = {
  visitorStats: {
    macro: null,
    landingPage: 0,
    widget: 0,
  },
  engagementTotals: {
    convos: null,
    messages: null,
    landingPage: null,
    widget: null,
    videoProfiles: null,
    ctaButtons: null,
  },
  engagementTotalsPast: {
    convos: null,
    messages: null,
    landingPage: null,
    widget: null,
    videoProfiles: null,
    ctaButtons: null,
  },
  engagementGraph: {
    data: [],
    labels: [],
  },
  pendingVideoInviteSubmissions: null,
  pendingVideoStories: null,
  conversationGraph: {
    data: [],
    labels: [],
  },
};
export const state = { ...initialState };

export const actions = {
  [SET_VISITOR_VIEWS](context, payload) {
    context.commit(UPDATE_VISITOR_VIEWS, payload);
    return payload;
  },
  [SET_ENGAGEMENT_STATS](context, payload) {
    context.commit(UPDATE_ENGAGEMENT_STATS, payload);
    return payload;
  },
  [SET_ENGAGEMENT_STATS_VIDEO](context, payload) {
    context.commit(UPDATE_ENGAGEMENT_STATS_VIDEO, payload);
    return payload;
  },
  [SET_ENGAGEMENT_GRAPH](context, payload) {
    context.commit(UPDATE_ENGAGEMENT_GRAPH, payload);
    return payload;
  },
  [SET_PENDING_VIDEO_INVITATIONS](context, payload) {
    context.commit(UPDATE_PENDING_VIDEO_INVITATIONS, payload);
    return payload;
  },
  [SET_PENDING_VIDEO_STORIES](context, payload) {
    context.commit(UPDATE_PENDING_VIDEO_STORIES, payload);
    return payload;
  },
  [SET_CONVERSATION_GRAPH](context, payload) {
    context.commit(UPDATE_CONVERSATION_GRAPH, payload);
    return payload;
  },
  [RESET_HOME_STATE](context) {
    context.commit(RESET_HOME_STATE);
  },
};

export const mutations = {
  [UPDATE_VISITOR_VIEWS](state, stats) {
    state.visitorStats = stats;
  },
  [UPDATE_ENGAGEMENT_STATS](state, stats) {
    state.engagementTotals = stats.engagementTotals;
    state.engagementTotalsPast = stats.engagementTotalsPast;
  },
  [UPDATE_ENGAGEMENT_STATS_VIDEO](state, stats) {
    state.engagementTotals.videoProfiles = stats.present;
    state.engagementTotalsPast.videoProfiles = stats.past;
  },
  [UPDATE_ENGAGEMENT_GRAPH](state, data) {
    state.engagementGraph = data;
  },
  [UPDATE_PENDING_VIDEO_INVITATIONS](state, data) {
    state.pendingVideoInviteSubmissions = [...data];
  },
  [UPDATE_PENDING_VIDEO_STORIES](state, data) {
    state.pendingVideoStories = [...data];
  },
  [UPDATE_CONVERSATION_GRAPH](state, data) {
    state.conversationGraph = data;
  },
  [RESET_HOME_STATE](state) {
    Object.assign(state, initialState);
  },
};

export const getters = {
  [GET_VISITOR_VIEWS](state) {
    return state.visitorStats;
  },
  [GET_ENGAGEMENT_STATS](state) {
    return {
      engagementTotals: state.engagementTotals,
      engagementTotalsPast: state.engagementTotalsPast,
    };
  },
  [GET_ENGAGEMENT_GRAPH](state) {
    return state.engagementGraph;
  },
  [GET_PENDING_VIDEO_INVITATIONS](state) {
    return state.pendingVideoInviteSubmissions;
  },
  [GET_PENDING_VIDEO_STORIES](state) {
    return state.pendingVideoStories;
  },
  [GET_CONVERSATION_GRAPH](state) {
    return state.conversationGraph;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
