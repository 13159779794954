import APIService from "../../services/api/index";
import { FETCH_CONVERSATIONS } from "../actions.type.js";
import {
  UPDATE_CONVERSATIONS,
  UPDATE_CONVERSATION,
} from "../mutations.type.js";
import { GET_CONVERSATIONS } from "../getters.type.js";

const dashboardService = APIService.dashboard;

export const initialState = {
  conversations: {
    messages: [],
    numOfConvos: 0,
  },
};

export const state = { ...initialState };

export const actions = {
  async [FETCH_CONVERSATIONS]({ getters, commit }) {
    const data = {
      selectedYear: getters.getActiveYear,
      organization: getters.getBranding.name,
    };

    const response = await dashboardService.allConvos(data);
    if (response) commit(UPDATE_CONVERSATIONS, response.data);
    return response;
  },
};

export const mutations = {
  [UPDATE_CONVERSATIONS](state, updatedConversations) {
    state.conversations = updatedConversations;
  },
  [UPDATE_CONVERSATION](state, conversationID) {
    // Will be using this mutation for individual conversation updates eventually.
  },
};

export const getters = {
  [GET_CONVERSATIONS](state) {
    return state.conversations;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
