<template>
  <div class="campaign-outreach-type">
    <h3 class="title">{{ $lang.campaignOutreach_outreachTypeTitle }}</h3>
    <div class="label">
      {{ $lang.campaignOutreach_outreachTypeInputLabel }}
    </div>
    <v-text-field
      outlined
      hide-details
      dense
      class="input"
      :value="campaign.title"
      @input="update('title', $event)"
      :label="$lang.campaignOutreach_outreachTypeInputPlaceholder"
      maxlength="50"></v-text-field>
    <div class="description">
      {{ $lang.campaignOutreach_outreachTypeDescription }}
    </div>
    <div class="type-buttons">
      <CampaignTypeButton
        v-for="buttonType in types"
        :key="buttonType.name"
        :text="buttonType.text"
        :icon="buttonType.icon"
        :isActive="buttonType.name === campaign.inviteType"
        :disabled="buttonType.disabled"
        :disabled-message="buttonType.disabledMessage"
        @click="update('inviteType', buttonType.name)" />
    </div>
    <div class="type-footer">
      <v-checkbox
        v-if="campaign.inviteType === 'video'"
        v-model="campaign.autoAccept"
        :true-value="false"
        :false-value="true"
        :label="$lang.individualOutreach_ReviewLabel"
        @change="update('autoAccept', campaign.autoAccept)"
        hide-details />
    </div>
  </div>
</template>

<script>
  import CampaignTypeButton from "./CampaignTypeButton.vue";
  import { required } from "vuelidate/lib/validators";
  import { mapGetters } from "vuex";
  import { GET_BRANDING, GET_CAMPAIGNS } from "@/store/getters.type";
  import email from "@/assets/email.svg";
  import phone from "@/assets/phone.svg";
  import video from "@/assets/video.svg";

  export default {
    name: "CampaignOutreachTypeStep",
    components: {
      CampaignTypeButton,
    },
    props: {
      data: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        campaign: {
          title: null,
          inviteType: null,
        },
      };
    },

    computed: {
      types() {
        let types = [
          {
            name: "email",
            text: this.$lang.general_Email,
            icon: email,
          },
          {
            name: "call",
            text: this.$lang.outreachPage_InviteTypeCall,
            icon: phone,
          },
          {
            name: "video",
            text: this.$lang.general_Video,
            icon: video,
            disabled: !this.branding?.features?.allowVideoOutreach,
            disabledMessage:
              this.$lang.campaignOutreach_VideoOutreachDisabledMessage,
          },
        ];

        return types;
      },

      ...mapGetters({ getCampaigns: GET_CAMPAIGNS, branding: GET_BRANDING }),
    },

    watch: {
      "$v.$invalid": {
        immediate: true,
        handler(value) {
          this.$emit("complete", !value);
        },
      },
      data: {
        immediate: true,
        handler(value) {
          this.campaign = value.campaign;
          this.$v.$touch();
        },
      },
    },

    validations() {
      return {
        campaign: {
          title: { required, unique: this.uniqueValidation },
          inviteType: { required },
        },
      };
    },

    methods: {
      update(key, value) {
        this.$set(this.campaign, key, value);
        if (!this.$v.campaign.$invalid) {
          this.$emit("update", { campaign: this.campaign });
        }
      },
      uniqueValidation(value) {
        return !this.getCampaigns.some((campaign) => {
          return (
            campaign._id !== this.data.campaign._id && campaign.title === value
          );
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  $gap: 5%;

  .campaign-outreach-type {
    color: $grey-800;

    .title {
      margin-bottom: 30px;
    }

    .label {
      margin-bottom: 22px;
    }

    .input {
      margin-bottom: 30px;
      width: 64%;
    }

    .description {
      margin-bottom: 18px;
    }

    .type-buttons {
      display: flex;
      gap: 5%;
    }

    .type-footer {
      height: 44px;
      display: flex;
      margin-left: 70%;
    }
  }
</style>
