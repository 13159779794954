<template>
  <OutreachRequestsTable :items="outreachRequests" :columns="columns" />
</template>

<script>
  import OutreachRequestsTable from "./OutreachRequestsTable.vue";

  export default {
    components: { OutreachRequestsTable },
    props: {
      campaigns: {
        type: Array,
        required: true,
      },
      outreachRequests: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        columns: [
          "title",
          "inviterName",
          "inviteeName",
          "inviteType",
          "sentAt",
          "status",
          "accountMade",
          "actions",
          "hasFeedback",
        ],
      };
    },
  };
</script>
