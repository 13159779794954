<template>
  <common-modal @close="$emit('close')" height="auto">
    <div class="super-admin-modal-header">{{ $lang.superAdminPanel_Add }}</div>

    <div>
      <common-input
        :placeholder="$lang.superAdminModal_EmailPlaceholder"
        v-model="adminEmail" />

      <div class="super-admin-modal-button-row">
        <common-button
          @click="addSuperAdmin"
          :loading="adding"
          :disabled="adding"
          >{{ $lang.superAdminModal_AddButton }}</common-button
        >
      </div>
    </div>
  </common-modal>
</template>
<script>
  import APIService from "@/services/api/index";
  import validationService from "@/services/validations.service";

  export default {
    name: "add-super-admin-modal",
    data() {
      return {
        adminEmail: "",
        adding: false,
        allowedDomains: ["graduway.com", "gravyty.com"],
      };
    },

    methods: {
      validateEmail() {
        if (!validationService.isEmailValid(this.adminEmail)) {
          return false;
        }
        const domain = this.adminEmail.split("@")[1].toLowerCase();
        return this.allowedDomains.includes(domain);
      },

      async addSuperAdmin() {
        if (!this.validateEmail()) {
          this.$toasted.show(
            this.$lang.superAdminModal_InvalidEmailFailure,
            this.$toastedFailure
          );

          return;
        }

        this.adding = true;

        let payload = {
          email: this.adminEmail,
        };

        const response = await APIService.dashboard.addSuperAdmin(payload);
        if (response?.status === 200) {
          this.$toasted.show(
            this.$lang.superAdminModal_AddSuperAdminSuccess.format(
              this.adminEmail
            ),
            this.$toastedSuccess
          );
          this.adding = false;
          this.$emit("close");
        } else {
          this.$toasted.show(
            this.$lang.superAdminModal_AddSuperAdminFailure.format(
              this.adminEmail
            ),
            this.$toastedFailure
          );
          this.adding = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .super-admin-modal-header {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-bottom: 2rem;
  }

  .super-admin-modal-button-row {
    margin: 1rem 0 0 auto;
    width: 33%;

    @media screen and (max-width: $sm) {
      width: 100%;
    }
  }
</style>
