import * as notificationService from "@/services/api/notification.service.js";

const state = {
  banRequests: [],
};

const actions = {
  async getBanRequests({ commit, rootGetters }) {
    const orgName = rootGetters.getBranding?.name;
    if (orgName) {
      const res = await notificationService.getBanRequests(orgName);
      if (res.data) {
        commit("SET_BAN_REQUESTS", res.data);
      }
    }
  },
  completeBanRequest({ commit }, params) {
    return notificationService.completeBanRequest(params).then((res) => {
      commit("DELETE_BAN_REQUESTS", params);
      return res.data;
    });
  },
};

const mutations = {
  SET_BAN_REQUESTS(state, banRequests) {
    state.banRequests = banRequests;
  },
  DELETE_BAN_REQUESTS(state, banRequest) {
    const banRequests = state.banRequests;
    const index = banRequests.findIndex((x) => x._id === banRequest._id);
    banRequests.splice(index, 1);
    state.banRequests = banRequests;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
