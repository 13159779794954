import httpService from "./http.service";

const request = httpService(process.env.VUE_APP_ENDPOINT);

function addNote(organizationId, note) {
  return request({
    data: note,
    requireToken: true,
    method: "post",
    path: `organizations/${organizationId}/notes`,
  });
}
function updateNote(organizationId, note) {
  return request({
    data: note,
    requireToken: true,
    method: "put",
    path: `organizations/${organizationId}/notes/${note.id}`,
  });
}
function deleteNote(organizationId, noteId) {
  return request({
    requireToken: true,
    method: "delete",
    path: `organizations/${organizationId}/notes/${noteId}`,
  });
}

function postConversationFeedbackQuestions(
  organizationId,
  ambassadorQuestions,
  prospectQuestions
) {
  return request({
    data: { ambassadorQuestions, prospectQuestions },
    requireToken: true,
    method: "post",
    path: `dashboard/${organizationId}/conversationFeedback`,
  });
}

function getConversationFeedbackQuestions(organizationId) {
  return request({
    requireToken: true,
    method: "get",
    path: `dashboard/${organizationId}/conversationFeedback`,
  });
}

function getAnswers(conversationCallOrCallInvitationId) {
  return request({
    requireToken: true,
    method: "get",
    path: `dashboard/${conversationCallOrCallInvitationId}/answers`,
  });
}

function getOrganizationAnswers(organizationName) {
  return request({
    requireToken: true,
    method: "get",
    path: `dashboard/${organizationName}/allAnswers`,
  });
}

export default {
  addNote,
  updateNote,
  deleteNote,
  postConversationFeedbackQuestions,
  getConversationFeedbackQuestions,
  getAnswers,
  getOrganizationAnswers,
};
