<template>
  <p-modal
    :title="$lang.addManuallyModal_title"
    :value="value"
    @input="$emit('input', $event)"
    size="lg"
    :saveButtonText="$lang.general_Continue"
    :cancelButtonText="$lang.stepper_Back"
    @save="save"
    @cancel="$emit('back')"
    persistent>
    <transition-group name="shrink" tag="div">
      <div v-for="(recipient, i) in recipients" :key="`recipients${i}`">
        <div class="add-manually-modal-recipient">
          {{ `${$lang.outreachPage_TableRecipient} ${i + 1}` }}
          <v-btn v-if="recipients.length > 1" icon @click="deleteRecipient(i)">
            <v-icon>$delete</v-icon>
          </v-btn>
        </div>
        <div class="add-manually-modal-form">
          <v-text-field
            class="add-manually-modal-field"
            v-model="recipient.first.value"
            :label="recipient.first.label"
            :error-messages="errors[i].first"
            required
            outlined
            dense
            @input="$v.recipients.$each[i].first.value.$touch()"
            @blur="$v.recipients.$each[i].first.value.$touch()"></v-text-field>
          <v-text-field
            class="add-manually-modal-field"
            v-model="recipient.last.value"
            :label="recipient.last.label"
            :error-messages="errors[i].last"
            required
            outlined
            dense
            @input="$v.recipients.$each[i].last.value.$touch()"
            @blur="$v.recipients.$each[i].last.value.$touch()"></v-text-field>
          <v-text-field
            class="add-manually-modal-field"
            v-model="recipient.email.value"
            :label="recipient.email.label"
            :error-messages="errors[i].email"
            required
            outlined
            dense
            @input="$v.recipients.$each[i].email.value.$touch()"
            @blur="$v.recipients.$each[i].email.value.$touch()"></v-text-field>
          <v-text-field
            class="add-manually-modal-field"
            v-for="(field, additionalIndex) in recipient.formFields"
            :key="i.toString() + additionalIndex"
            v-model="field.value"
            :label="field.label"
            outlined
            dense></v-text-field>
        </div>
      </div>
    </transition-group>
    <v-btn color="primary" @click="addRecipient()" outlined>
      <v-icon color="blue lighten-1">$plus</v-icon>
      {{ $lang.addManuallyModal_addButton }}
    </v-btn>
  </p-modal>
</template>

<script>
  import { required, email } from "vuelidate/lib/validators";

  export default {
    name: "AddManuallyModal",
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      fields: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        recipients: [],
        errorMap: {
          first: {
            required: this.$lang.individualOutreach_FirstNameErrorRequired,
          },
          last: {
            required: this.$lang.individualOutreach_LastNameErrorRequired,
          },
          email: {
            required: this.$lang.individualOutreach_EmailErrorRequired,
            email: this.$lang.general_ErrorEmailFormat,
          },
        },
      };
    },
    validations: {
      recipients: {
        required,
        $each: {
          first: { value: { required } },
          last: { value: { required } },
          email: { value: { required, email } },
        },
      },
    },
    computed: {
      errors() {
        return this.recipients.map((_, recipientIndex) => {
          return this.getErrors(
            ["first", "last", "email"],
            this.$v.recipients.$each[recipientIndex],
            this.errorMap
          );
        });
      },
    },

    watch: {
      value: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.addRecipient();
            this.$v.$reset();
          } else {
            this.recipients = [];
          }
        },
      },
    },
    methods: {
      getErrors(properties, vuelidateRecipient, errorMap) {
        const recipientErrors = {};
        properties.forEach((property) => {
          if (!vuelidateRecipient[property].value.$dirty) {
            recipientErrors[property] = [];
            return;
          }
          const errors = [];
          Object.entries(errorMap[property]).forEach(([key, value]) => {
            !vuelidateRecipient[property].value[key] && errors.push(value);
          });
          recipientErrors[property] = errors;
        });
        return recipientErrors;
      },
      save() {
        if (this.$v.$invalid) {
          this.$v.$touch();
        } else {
          this.$emit(
            "add",
            this.recipients.map(({ first, last, email, formFields }) => {
              const dynamicColumns = {};
              formFields.forEach(
                ({ label, value }) => (dynamicColumns[label] = value)
              );
              return {
                invitee: {
                  first: first.value,
                  last: last.value,
                  email: email.value,
                },
                dynamicColumns,
              };
            })
          );
        }
      },
      getEmptyRecipient() {
        return {
          first: {
            key: "first",
            label: this.$lang.general_FirstName,
            value: "",
          },
          last: {
            key: "last",
            label: this.$lang.general_LastName,
            value: "",
          },
          email: {
            key: "email",
            label: this.$lang.general_Email,
            value: "",
          },
        };
      },
      addRecipient() {
        this.recipients.push({
          ...this.getEmptyRecipient(),
          formFields:
            this.fields?.map((columnName) => ({
              label: columnName,
              value: "",
            })) ?? [],
        });
      },
      deleteRecipient(index) {
        this.recipients.splice(index, 1);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .add-manually-modal-recipient {
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    margin-bottom: 1rem;
  }

  .add-manually-modal-form {
    display: flex;
    gap: 1.25rem;
    flex-wrap: wrap;
    margin-bottom: 2.5rem;
  }

  .add-manually-modal-field {
    width: calc(50% - 1.25rem);
  }

  /* 1. declare transition */
  .shrink-move,
  .shrink-enter-active,
  .shrink-leave-active {
    transition: all var(--transition-duration) cubic-bezier(0.55, 0, 0.1, 1);
  }

  /* 2. declare enter from and leave to state */
  .shrink-enter-from,
  .shrink-leave-to {
    opacity: 0;
    transform: scaleY(0.01) translateY(-100px);
  }
</style>
