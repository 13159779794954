<template>
  <div class="flex items-center gap-1">
    <!-- <img src="#" alt="Back" class="img mr5" :class="{ disabledArrow: stepperCurrentStep === 1 }" @click="goBackStep"> -->
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-4 w-4 cursor-pointer hover:text-blue"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      @click="goBackStep"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M15 19l-7-7 7-7"
      />
    </svg>
    <div
      v-for="index in displayedNumberOfSteps(currentStep, numberOfSteps)"
      :key="`common-stepper-step-${index}`"
    >
      <!-- <div :class="circleClasses(index)" @click="clickedStep(index)"></div> -->
      <div
        class="h-7 w-7 rounded m-1 flex items-center justify-center text-sm font-medium cursor-pointer"
        :class="{ 'bg-blue text-white': index === stepperCurrentStep }"
        @click="clickedStep(index)"
      >
        {{ index }}
      </div>
    </div>
    <!-- <img src="#" alt="Back" class="img ml5" @click="goForwardStep"> -->
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-4 w-4 cursor-pointer hover:text-blue"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      @click="goForwardStep"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M9 5l7 7-7 7"
      />
    </svg>
  </div>
</template>

<script>
  export default {
    name: "common-stepper",
    props: {
      numberOfSteps: {
        required: true,
        type: Number,
      },
      currentStep: {
        type: Number,
      },
      completedSteps: {
        type: Number,
      },
    },
    data() {
      return {
        stepperCurrentStep: this.currentStep,
      };
    },
    methods: {
      clickedStep(indexClicked) {
        if (indexClicked <= this.completedSteps) {
          this.$emit("change-step", indexClicked);
        }
        this.displayedNumberOfSteps(this.currentStep, this.numberOfSteps);
      },
      goBackStep() {
        if (this.currentStep > 1) {
          this.$emit("change-step", this.currentStep - 1);
        }
      },
      goForwardStep() {
        if (this.currentStep < this.completedSteps) {
          this.$emit("change-step", this.currentStep + 1);
        }
      },
      displayedNumberOfSteps(currentPage, pageCount) {
        let delta;
        // alert(`${currentPage} ${pageCount} ${this.numberOfSteps}`)
        switch (currentPage) {
          case 1:
            delta = 4;
            break;
          case pageCount:
            delta = 4;
            break;
          case 2:
            delta = 3;
            break;
          case pageCount - 1:
            delta = 3;
            break;
          default:
            delta = 2;
        }

        let left = currentPage - delta,
          right = currentPage + delta + 1;

        const result = Array.from(
          { length: pageCount },
          (v, k) => k + 1
        ).filter((i) => i && i >= left && i < right);

        return result;
      },
    },
    watch: {
      currentStep() {
        this.stepperCurrentStep = this.currentStep;
      },
    },
  };
</script>
