<template>
  <div data-vuetify>
    <v-app>
      <v-main class="landing-page">
        <div class="landing-page-quadrilateral"></div>
        <div class="landing-page-content">
          <div class="landing-page-logo-wrapper">
            <img
              class="landing-page-logo"
              src="@/assets/images/logos/peerpal-gravyty-logo.svg" />
          </div>
          <div class="landing-page-login-wrapper">
            <div class="landing-page-lottie">
              <common-lottie :animationData="animationData" />
            </div>
            <div class="landing-page-divider"></div>
            <Login />
          </div>
        </div>
      </v-main>
    </v-app>
  </div>
</template>

<script>
  import animationData from "@/assets/lottie/login.json";
  import Login from "@/components/login/Login.vue";

  export default {
    name: "LandingPage",
    components: {
      Login,
    },
    data() {
      return {
        animationData: animationData,
      };
    },
  };
</script>

<style lang="scss" scoped>
  // Temporary overrides for tailwind conflicts
  // Needs to be in every Vuetify parent page
  [data-vuetify] {
    overflow-y: auto;
    height: 100%;
  }

  ::v-deep {
    [data-vuetify] .v-application--wrap {
      min-height: calc(100vh - 28px);
    }
  }
  // End Vuetify overrides
  .landing-page {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: $blue-100;

    &-quadrilateral {
      height: 100%;
      width: 100%;
      background: linear-gradient(#00b0fa, $blue-400);
      clip-path: polygon(
        0% 0%,
        calc(50% - 164px) 0%,
        calc(50% - 420px) 100%,
        0% 100%
      );
    }

    &-content {
      position: absolute;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }

    &-logo {
      height: 8vh;
      max-height: 77px;
      margin-bottom: 6vh;
      vertical-align: middle;
    }

    &-login-wrapper {
      background-color: $white;
      border-radius: 20px;
      display: flex;
      box-shadow: 0px 2.5px 10px rgba(0, 0, 0, 0.08);
      max-height: 560px;
      height: 80vh;
    }

    &-lottie {
      width: 50vh;
      max-width: 500px;
      margin: auto;
      @media (max-width: $md) {
        display: none;
      }
    }

    &-divider {
      height: 70%;
      width: 0;
      border-left: 1px solid $grey-400;
      margin: auto 0 auto 0;
      @media (max-width: $md) {
        display: none;
      }
    }
  }
</style>
