<template>
  <div class="accordion font-primary">
    <div :class="['accordion-header', { active: isOpen }]">
      <slot name="header" :isOpen="isOpen">
        <span>{{ title }}</span>
      </slot>
      <div :class="['accordion-button', { active: isOpen }]">
        <ppicon name="chevron-down" />
      </div>
    </div>

    <Transition name="expand" @enter="open" @leave="close">
      <div v-if="isOpen" class="accordion-body" ref="accordionBody">
        <slot>
          <p>{{ content }}</p>
        </slot>
      </div>
    </Transition>
  </div>
</template>

<script>
  export default {
    name: "common-accordion",
    props: {
      title: String,
      content: String,
      isOpen: Boolean,
    },
    data() {
      return {
        observersSupported: true,
        resizeObserver: null,
        mutationObserver: null,
      };
    },
    created() {
      try {
        this.mutationObserver = new MutationObserver(this.setHeight);
        this.resizeObserver = new ResizeObserver(this.setHeight);
      } catch {
        this.observersSupported = false;
      }
    },
    destroyed() {
      this.resizeObserver?.disconnect();
      this.mutationObserver?.disconnect();
    },
    methods: {
      open() {
        this.setHeight();

        const accordionBody = this.$refs.accordionBody;

        this.resizeObserver?.observe(accordionBody);
        this.mutationObserver?.observe(accordionBody, {
          attributes: true,
          childList: true,
          subtree: true,
        });
      },
      close() {
        const accordionBody = this.$refs.accordionBody;
        accordionBody.style.maxHeight = 0;

        this.resizeObserver?.disconnect();
        this.mutationObserver?.disconnect();
      },
      setHeight() {
        const accordionBody = this.$refs.accordionBody;
        if (accordionBody) {
          if (this.observersSupported) {
            accordionBody.style.maxHeight = accordionBody.scrollHeight + "px";
          } else {
            accordionBody.style.maxHeight = "fit-content";
          }
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .accordion-header {
    display: flex;
    justify-content: space-between;
    border: solid 1px $grey-100;
    transition: background-color 0.1s ease-in;
    cursor: pointer;
    position: relative;

    &:hover,
    &.active {
      background-color: $grey-100;
    }
  }

  .accordion-button {
    display: flex;
    align-items: center;
    position: absolute;
    right: 14px;
    height: 100%;
    pointer-events: none;
    transform: rotate(0deg);
    transition: transform var(--transition-duration) ease-out;

    &.active {
      transform: rotate(180deg);
    }
  }

  .accordion-body {
    max-height: 0px;
    overflow: hidden;
    background-color: $grey-50;
    transition: max-height var(--transition-duration) ease-out;
  }
</style>
