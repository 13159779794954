<template>
  <div class="pt-8 pb-12 h-full">
    <div class="h-full w-full flex flex-col lg:flex-row">
      <div class="lg:w-72 flex-none lg:pr-10">
        <div class="w-full lg:h-full lg:border-r">
          <!-- Desktop Sidebar -->
          <div class="hidden lg:block">
            <h1 class="text-2xl font-semibold mb-14">Settings</h1>
            <ul class="w-full text-grey-500">
              <li v-for="option of options" :key="option" class="">
                <router-link
                  :to="{ name: option }"
                  class="w-full flex items-center py-2 my-2 cursor-pointer">
                  <span class="text-sm w-full font-semibold">
                    {{ option }}
                  </span>
                  <div class="pr-10">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 5l7 7-7 7" />
                    </svg>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>

          <!-- Responsive Breadcrumbs -->
          <nav class="font-medium mb-4 lg:hidden" aria-label="Breadcrumb">
            <ol class="list-none p-0 inline-flex">
              <li
                class="flex items-center bg-grey-100 hover:bg-grey-200 m-1 py-1 px-2 rounded cursor-pointer"
                @click="showDropMenu = !showDropMenu">
                <a href="javascript: void(0)">Settings</a>
                <svg
                  class="fill-current w-3 h-3 ml-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512">
                  <path
                    d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" />
                </svg>
              </li>
              <li class="flex items-center">
                {{ $route.name }}
              </li>
            </ol>
          </nav>

          <!-- Responsive Dropdown Nav -->
          <div v-if="showDropMenu" class="z-10 relative w-full lg:hidden">
            <ul
              class="absolute top-0 h-max bg-white w-full text-grey-500 border-b-2 border-blue-100">
              <li
                v-for="option of options"
                :key="option"
                @click="showDropMenu = false"
                class="w-full flex items-center my-8 cursor-pointer">
                <router-link
                  class="text-sm w-full font-semibold"
                  :to="{ name: option }">
                  {{ option }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  import screens from "@/constants/screens";

  export default {
    name: "SettingsScreen",
    watch: {
      $route: {
        immediate: true,
        handler() {
          if (this.$route.name === screens.SETTINGS_SCREEN) {
            this.$router.replace({ name: this.options[0] });
          }
        },
      },
    },
    data() {
      return {
        showDropMenu: false,
        options: [
          "My Account",
          "Manage Administrators",
          "Ambassador Setup",
          "Prospect Setup",
          "Customize Platform",
        ],
      };
    },
  };
</script>

<style lang="scss">
  .router-link-active {
    color: $blue-400;
  }
</style>
