<template>
  <div id="app">
    <div>
      <router-view v-if="ready" />
      <p-toast v-if="ready"></p-toast>
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  import dateService from "@/services/date.service";
  import englishLanguageFile from "@/localization/en.js";
  import PToast from "@/components/common/PToast.vue";

  export default {
    name: "app",

    components: { PToast },

    data() {
      return {
        ready: false,
      };
    },

    watch: {
      "$route.query.language": {
        immediate: true,
        deep: true,
        handler(val) {
          this.setLanguageFile(val);
        },
      },
    },

    methods: {
      setLanguageFile(lang) {
        // user selected language > browser language > English
        const locale = navigator.language;
        const userLang = locale?.split("-")[0];
        let languageCode = lang || userLang || "en";

        import(`@/localization/${languageCode}.js`)
          .then((module) => {
            Vue.prototype.$lang = module.default;
          })
          .catch((err) => {
            console.error(
              `Failed to find language file matching code, ${lang}, loading English. Error message: ${err}`
            );

            Vue.prototype.$lang = englishLanguageFile;
            languageCode = "en";
          })
          .finally(() => {
            const locale = userLang === languageCode ? locale : languageCode;
            Vue.prototype.$locale = locale;
            dateService.setLocale(locale);
            this.ready = true;
          });
      },
    },
  };
</script>

<style lang="scss">
  @import "@/scss/application";

  * {
    --transition-duration: 0.3s;

    @media (prefers-reduced-motion) {
      --transition-duration: 0s !important;
    }
  }

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    margin: 0;
    color: $grey-800;
  }

  body {
    margin: 0;
    height: 100%;
  }

  html {
    height: 100%;
    scroll-behavior: smooth;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  @-webkit-keyframes hvr-wobble-vertical {
    16.65% {
      -webkit-transform: translateY(8px);
      transform: translateY(8px);
    }

    33.3% {
      -webkit-transform: translateY(-6px);
      transform: translateY(-6px);
    }

    49.95% {
      -webkit-transform: translateY(4px);
      transform: translateY(4px);
    }

    66.6% {
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px);
    }

    83.25% {
      -webkit-transform: translateY(1px);
      transform: translateY(1px);
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  @keyframes hvr-wobble-vertical {
    16.65% {
      -webkit-transform: translateY(8px);
      transform: translateY(8px);
    }

    33.3% {
      -webkit-transform: translateY(-6px);
      transform: translateY(-6px);
    }

    49.95% {
      -webkit-transform: translateY(4px);
      transform: translateY(4px);
    }

    66.6% {
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px);
    }

    83.25% {
      -webkit-transform: translateY(1px);
      transform: translateY(1px);
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  .hvr-wobble-vertical {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  }

  .hvr-wobble-vertical:hover,
  .hvr-wobble-vertical:focus,
  .hvr-wobble-vertical:active {
    -webkit-animation-name: hvr-wobble-vertical;
    animation-name: hvr-wobble-vertical;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
  }

  .hvr-float {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: var(--transition-duration);
    transition-duration: var(--transition-duration);
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }

  .hvr-float:hover,
  .hvr-float:focus,
  .hvr-float:active {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }

  .toast-success {
    /* have to add !important to override default styling of toast from package */
    /* not ideal but it works */
    background-color: #00c98d !important;
    color: white !important;
    border: 0 !important;
    border-radius: 10px !important;
    text-align: center;
  }
  .toast-failure {
    background-color: #757e8c !important;
    color: white !important;
    border: 0 !important;
    border-radius: 10px !important;
    text-align: center;
  }
  .toast-warning {
    background-color: #ffdb45 !important;
    color: black !important;
    border: 0 !important;
    border-radius: 10px !important;
    text-align: center;
  }
  .toast-success > a {
    color: white !important;
    text-decoration: none !important;
    font-family: Arial !important;
  }
  .toast-loading {
    /* have to add !important to override default styling of toast from package */
    /* not ideal but it works */
    background-color: #fff !important;
    color: #007eb6 !important;
    border: 0 !important;
    border-radius: 10px !important;
  }
  .toast-loading > a {
    color: #007eb6 !important;
    text-decoration: underline !important;
    font-family: Arial !important;
  }

  .icon-button {
    cursor: pointer;
    opacity: 0.5;
    transition: opacity var(--transition-duration);
  }

  .icon-button:hover {
    opacity: 1;
  }

  .text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
