const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const now = new Date();
let locale = navigator.language;
let timezone;
setLocale(locale);

function setLocale(code) {
  locale = code;
  const timeZoneOpts = {
    second: "numeric", // at least one component is necessary
    timeZoneName: "short",
  };
  timezone = now.toLocaleTimeString(locale, timeZoneOpts).split(" ")[1];
}

function toLocaleDateString(date, opts) {
  date = new Date(date || undefined);

  if (isNaN(date.getTime())) {
    return "";
  }
  return date.toLocaleString(locale, opts);
}

function toDisplayDate(date) {
  const opts = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return toLocaleDateString(date, opts);
}

function toDisplayDateTime(date) {
  const opts = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return toLocaleDateString(date, opts);
}

function toDisplayDateTimeTz(date) {
  const opts = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  };
  return toLocaleDateString(date, opts);
}

function toDisplayTime(date) {
  const opts = {
    hour: "numeric",
    minute: "numeric",
  };
  return toLocaleDateString(date, opts);
}

function isSameDay(a, b) {
  if (!a || !b) return false;
  return (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
  );
}

/**
 * Gets the number of days in a month
 * @param {Number} month
 * @param {Number} [year] Optional (defaults to non-leap year)
 * @returns
 */
function daysInMonth(month, year = 2022) {
  return new Date(year, month, 0).getDate();
}

// tells you if date is older than amountOfDays before today's date
function isOlderThan(date, amountOfDays) {
  const today = new Date();
  return new Date(date) > today.setDate(today.getDate() - amountOfDays);
}

function getDifferenceInDays(a, b) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

function amplitudeDate(date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return year + month + day;
}

function toLocalDateTimeString(date) {
  date = new Date(date);
  const yyyy = date.getFullYear();
  const MM = (date.getMonth() + 1).toString().padStart(2, "0");
  const dd = date.getDate().toString().padStart(2, "0");
  const HH = date.getHours().toString().padStart(2, "0");
  const mm = date.getMinutes().toString().padStart(2, "0");
  const ss = date.getSeconds().toString().padStart(2, "0");
  return `${yyyy}-${MM}-${dd} ${HH}:${mm}:${ss}`;
}

export default {
  months,
  setLocale,
  toLocaleDateString,
  toDisplayDateTime,
  toDisplayDateTimeTz,
  toDisplayDate,
  toDisplayTime,
  timezone,
  isSameDay,
  daysInMonth,
  isOlderThan,
  getDifferenceInDays,
  amplitudeDate,
  toLocalDateTimeString,
};
