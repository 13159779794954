<template>
  <div class="page">
    <!-- Header -->
    <h1>
      {{ $lang.settingsPage_AmbassadorSetup }}
    </h1>

    <!-- Profile Fields Tab -->
    <h2>{{ $lang.settingsPage_ProfileFields }}</h2>

    <p>{{ $lang.settingsPage_ProfileFieldsInstructions }}</p>

    <ul class="profile-fields">
      <li
        v-for="(profileField, index) of signUpFields"
        :key="`profile-field-${index}`">
        <label>
          {{ $lang.settingsPage_ProfileQuestion }} {{ index + 1 }}
          <common-input
            :placeholder="`Profile field question #${index + 1}`"
            :value="profileField"
            @keyup="updateProfileField(index, $event)" />
        </label>
      </li>
    </ul>

    <h2>{{ $lang.settingsPage_Restrictions }}</h2>
    <MessagesLimit
      v-model="convoLimit"
      :description="
        $lang.settingsPage_MessagesLimitAmbassadorInstructions
      "></MessagesLimit>

    <common-button variant="primary" class="btn-save" @click="save">
      {{ $lang.general_Save }}
    </common-button>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import { SET_BRANDING } from "@/store/actions.type.js";
  import { GET_BRANDING } from "@/store/getters.type.js";

  import MessagesLimit from "./MessagesLimit";

  export default {
    name: "AmbassadorSetup",
    components: { MessagesLimit },
    data() {
      return {
        signUpFields: [],
        convoLimit: 0,
      };
    },
    computed: {
      ...mapGetters({ branding: GET_BRANDING }),
    },
    watch: {
      branding: {
        immediate: true,
        handler() {
          this.signUpFields = this.branding.signUpFields;
          this.convoLimit = this.branding.convoLimit;
        },
      },
    },
    methods: {
      updateProfileField(index, value) {
        this.signUpFields[index] = value.trim();
      },
      async save() {
        const data = {
          signUpFields: this.signUpFields,
          profileFields: this.signUpFields,
          convoLimit: this.convoLimit,
        };

        const response = await this.$store.dispatch(SET_BRANDING, data);
        if (response) {
          this.$toasted.show(
            this.$lang.settingsPage_SettingsSaved,
            this.$toastedSuccess
          );
        } else {
          this.$toasted.show(
            this.$lang.general_ToastError,
            this.$toastedFailure
          );
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  h1,
  h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.25em;
    padding: 0 0 1em;
  }
  p {
    font-size: 14px;
    padding-bottom: 1em;
    color: $grey-500;
  }
  .page {
    max-width: 600px;
  }
  .profile-fields {
    padding: 1em 0;
  }
  .profile-fields li {
    padding-bottom: 1.25em;
  }
  label {
    font-size: 14px;
    font-weight: 500;
    color: $primary-text;
    padding-bottom: 0.5em;
  }
  .btn-save {
    width: 10rem;
    margin: 2rem 0;
  }
</style>
