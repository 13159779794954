import Vue from "vue";
import APIService from "@/services/api/index";
import {
  FETCH_ADMINS,
  DELETE_ADMIN,
  REGISTER_ADMIN,
  VERIFY_SUPER_ADMIN,
  SUPER_ADMIN_GET_ALL_ORGANIZATIONS,
} from "../actions.type.js";
import { UPDATE_ADMINS } from "../mutations.type.js";
import { GET_ADMINS } from "../getters.type.js";

const dashboardService = APIService.dashboard;

export const initialState = {
  admins: [],
};

export const state = { ...initialState };

export const actions = {
  async [FETCH_ADMINS]({ getters, commit }) {
    const data = {
      organization: getters.getBranding.name,
    };
    const response = await dashboardService.getAdmins(data);
    commit(UPDATE_ADMINS, response.data);
    return response;
  },
  async [VERIFY_SUPER_ADMIN](context) {
    const response = await dashboardService.verifySuperAdmin();
    return response.data;
  },
  async [SUPER_ADMIN_GET_ALL_ORGANIZATIONS](context) {
    const response = await dashboardService.superAdminGetAllOrganizations();
    if (response) return response.data;
  },
  async [DELETE_ADMIN](context, payload) {
    const response = await dashboardService.deleteAdmin(payload);
    context.commit(UPDATE_ADMINS, response.data);
    return response.status === 200;
  },
  async [REGISTER_ADMIN]({ getters, commit }, payload) {
    const organization = getters.getBranding.name;
    payload.organizations = [organization];
    const registerResponse = await APIService.dashboard.register(payload);
    if (registerResponse?.status !== 200) {
      return false;
    }

    const response = await dashboardService.getAdmins({
      organization: organization,
    });
    commit(UPDATE_ADMINS, response.data);
    return response.status === 200;
  },
};

export const mutations = {
  [UPDATE_ADMINS](state, updatedAdmins, newAdmin = false) {
    if (!newAdmin) {
      state.admins = updatedAdmins;
    } else {
      state.admins = [state.admins, ...updatedAdmins];
    }
  },
};

export const getters = {
  [GET_ADMINS](state) {
    return state.admins;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
