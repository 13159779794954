import store from "@/store";
import APIService from "@/services/api/index";

function calculateVideoOutreachCount(videoOutreach) {
  const videoOutreachRequests = {};

  if (videoOutreach?.videoInvites?.requests) {
    for (const invite of videoOutreach.videoInvites.requests) {
      if (invite.inviterID) {
        videoOutreachRequests[invite.inviterID]
          ? (videoOutreachRequests[invite.inviterID] += 1)
          : (videoOutreachRequests[invite.inviterID] = 1);
      }
    }
  }

  if (videoOutreach?.videoInvites?.submissions) {
    for (const invite of videoOutreach.videoInvites.submissions) {
      if (invite?.videoInvitationRequest?.inviterID) {
        const inv = invite.videoInvitationRequest.inviterID;
        videoOutreachRequests[inv]
          ? (videoOutreachRequests[inv] += 1)
          : (videoOutreachRequests[inv] = 1);
      }
    }
  }

  if (videoOutreach?.videoInvites?.invitations) {
    for (const invite of videoOutreach.videoInvites.invitations) {
      if (
        invite.videoInvitationRequestSubmission.videoInvitationRequest.inviterID
      ) {
        const inv =
          invite.videoInvitationRequestSubmission.videoInvitationRequest
            .inviterID;
        videoOutreachRequests[inv]
          ? (videoOutreachRequests[inv] += 1)
          : (videoOutreachRequests[invite.inv] = 1);
      }
    }
  }

  return videoOutreachRequests;
}

async function checkIfEmailExists(emails) {
  const data = { emails: emails };
  const response = await APIService.dashboard.checkEmail(data);
  return response.data;
}

async function getIndexedAmbassadors() {
  let indexedAmbassadors, filteredIndexedAmbassadors;

  const response = await APIService.dashboard.indexedParents({
    organization: store.getters.getBranding.name,
  });
  if (response?.status === 200) {
    indexedAmbassadors = response.data;
    filteredIndexedAmbassadors = indexedAmbassadors
      .filter((ambassador) => {
        return ambassador.isActive === true;
      })
      .map((ambassador, index) => {
        ambassador.index = index + 1;
        return ambassador;
      });

    return { indexedAmbassadors, filteredIndexedAmbassadors };
  }
}

async function updateIndexedAmbassadors(ambassadorIDs) {
  const response = await APIService.dashboard.updateParentsIndexBatch({
    ids: ambassadorIDs,
    organization: store.getters.getBranding.name,
  });
  return response.status === 200;
}

export default {
  calculateVideoOutreachCount,
  checkIfEmailExists,
  getIndexedAmbassadors,
  updateIndexedAmbassadors,
};
