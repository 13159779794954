<template>
  <common-modal @close="$emit('close')" height="height-auto" width="width-auto">
    <div :class="`${classPrefix}-wrapper`">
      <h1 :class="`${classPrefix}-header`">
        {{ $lang.modal_DeleteUserTitle }}
      </h1>

      <div>
        <p :class="`${classPrefix}-body`">
          {{ $lang.modal_DeleteUserConfirmation.format(user.email) }}
        </p>
      </div>

      <!-- Save Button -->
      <div :class="`${classPrefix}-controls`">
        <div :class="`${classPrefix}-cancel`">
          <common-button variant="tertiary" @click="$emit('close')">
            {{ $lang.general_Cancel }}
          </common-button>
        </div>

        <div :class="`${classPrefix}-save`">
          <common-button variant="primary" @click="deleteUser">
            {{ $lang.general_Save }}
          </common-button>
        </div>
      </div>
    </div>
  </common-modal>
</template>

<script>
  export default {
    props: {
      user: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        classPrefix: "delete-modal",
      };
    },

    methods: {
      deleteUser() {
        this.$emit("delete-user", this.user);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .delete-modal-wrapper {
    display: flex;
    flex-direction: column;
    max-height: 24rem;
  }

  .delete-modal-header {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 24px;
  }

  .delete-modal-body {
    color: $grey-500;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 38px;
  }

  .delete-modal-controls {
    display: flex;
    justify-content: center;
  }

  .delete-modal-cancel {
    margin-right: 30px;
  }
</style>
