<template>
  <div
    @click="onClick"
    href="javascript:void(0)"
    tabindex="2"
    :class="['common-dropdown-option', option.class, toggle ? '' : 'select']"
    role="menuitem">
    <ppicon
      class="common-dropdown-option-icon"
      :name="option.icon"
      v-if="option.icon" />

    {{ option.label }}
    <common-toggle v-if="toggle" :value="option.value" @input="onToggle" />
  </div>
</template>

<script>
  export default {
    props: {
      option: {
        type: Object,
        required: true,
      },
      toggle: {
        type: Boolean,
        default: false,
      },
    },

    methods: {
      onClick() {
        if (!this.toggle) {
          this.$emit("change", this.option);
        }
      },
      onToggle(value) {
        const newOption = { ...this.option };
        newOption.value = value;
        this.$emit("change", newOption);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .common-dropdown-option {
    display: flex;
    line-height: 1.25rem;
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
    width: 100%;
    align-items: center;
    cursor: pointer;

    &.select:hover {
      color: $white;
      background-color: $blue-400;
    }
  }

  .common-dropdown-option-icon {
    margin-right: 11px;
  }

  .toggle {
    margin-left: 11px;
  }
</style>
