//English JSON
export default {
  ambassadorPage_AccountStatusTableHeader: "Account Status",
  ambassadorPage_ActionOptionMessage: "Send Message",
  ambassadorPage_ActionOptionOutreach: "Request Outreach",
  ambassadorPage_ActionOptionEditProfile: "Edit Profile",
  ambassadorPage_ActionOptionEditStory: "Edit Story",
  ambassadorPage_ActionOptionArchive: "Archive Account",
  ambassadorPage_ActionOptionActivate: "Activate Account",
  ambassadorPage_ActionOptionInviteReminder: "Send Invite Reminder",
  ambassadorPage_ActionOptionPrioritize: "Prioritize on Landing Page",
  ambassadorPage_ActionOptionViewProfile: "View Profile Card",
  ambassadorPage_BulkActionNoRowsSelected: "No rows were selected.",
  ambassadorPage_BulkActionOptionVisible: "Set profile to visible",
  ambassadorPage_BulkActionOptionVisibleClick:
    "{0} ambassador(s) profiles have been set to visible.",
  ambassadorPage_BulkActionOptionInvisible: "Set profile to invisible",
  ambassadorPage_BulkActionOptionInvisibleClick:
    "{0} ambassador(s) profiles have been set to invisible.",
  ambassadorPage_BulkActionOptionEnableCalls: "Enable call requests",
  ambassadorPage_BulkActionOptionEnableCallsClick:
    "{0} ambassador(s) have been set to to receive to call requests.",
  ambassadorPage_BulkActionOptionDisableCalls: "Disable call requests",
  ambassadorPage_BulkActionOptionDisableCallsClick:
    "{0} ambassador(s) have been set to to not receive to call requests.",
  ambassadorPage_BulkActionOptionArchive: "Mark account as archived",
  ambassadorPage_BulkActionOptionMessage: "Send message",
  ambassadorPage_BulkActionOptionInvite: "Invite Ambassadors",
  ambassadorPage_BulkActionOptionViewLanding: "View Landing Page",
  ambassadorPage_BulkActionOptionManageOrder: "Manage Ambassador Order",
  ambassadorPage_BulkActionOptionExport: "Export Ambassador List",
  ambassadorPage_BulkActionTooltip:
    "You cannot perform bulk action(s) on an invited or archived ambassador.",
  ambassadorPage_CallRequestsTableHeader: "Call Requests",
  ambassadorPage_DeleteModalHeader: "Delete account?",
  ambassadorPage_DeleteModalBody:
    "WARNING: all messages and any conversation history associated with this profile will also be deleted. This action cannot be undone.",
  ambassadorPage_DeleteModalInviteHeader: "Delete invite for {0}?",
  ambassadorPage_DeleteModalInviteBody:
    "{0} will not be able to create an ambassador account unless a new invitation is sent.",
  ambassadorPage_DeleteModalSpecificAccountHeader: "Delete account for {0}?",
  ambassadorPage_DeleteModalButtonCancel: "Cancel",
  ambassadorPage_DeleteModalButtonConfirm: "Delete",
  ambassadorPage_FetchDisplay: "Last updated {0} at {1}",
  ambassadorPage_FetchingDisplay: "Currently fetching ambassadors...",
  ambassadorPage_IsOnVacationProfileDisabledTitle:
    "User has turned on vacation mode. They cannot be made visible until they turn it off",
  ambassadorPage_HeaderTitle: "Manage Ambassadors",
  ambassadorPage_NoAmbassadors:
    "There are no ambassadors for your organization.",
  ambassadorPage_NoSearchResults: "No matches found.",
  ambassadorPage_PaginationDisplayText: "Showing {0}-{1} of {2} Ambassadors",
  ambassadorPage_ProspectConnectionsTableHeader: "Prospect Connections",
  ambassadorPage_ProspectConnectionsTooltip: "Total Outreach Requests",
  ambassadorPage_ProfileVisibleTableHeader: "Profile Visible",
  ambassadorPage_StatusConvoLimited: "Limited",
  ambassadorPage_StatusNew: "New!",
  ambassadorPage_StatusActive: "Active",
  ambassadorPage_StatusArchived: "Archived",
  ambassadorPage_StatusInvited: "Invited",
  ambassadorPage_StatusVacation: "Vacation",
  ambassadorPage_ToastArchivalToggle: "Status updated for {0} ambassador(s).",
  ambassadorPage_ToastAmbassadorUpdateCalls: "Call request updated.",
  ambassadorPage_ToastAmbassadorUpdateOrder: "Updated Ambassador order.",
  ambassadorPage_ToastAmbassadorUpdateOrderFailure:
    "Failed to update Ambassador order.",
  ambassadorPage_ToastAmbassadorUnableToPrioritize:
    "Ambassador must have a visible profile to prioritize on landing page.",
  ambassadorPage_ToastAmbassadorUpdateVisibility: "Profile visibility updated.",
  ambassadorPage_ToastInviteSent: "Invite reminder sent.",
  ambassadorPage_SafeguardingNoMessages: "No conversations to export",
  ambassadorPage_UserBlocked: "User blocked",

  ambassadorProspectPage_ToastTableRefresh: "Table data refreshed.",

  campaignPage_BulkAssign: "Bulk Assign",
  campaignPage_ImportData: "Import Data",
  campaignPage_DeleteRows: "Delete",
  campaignPage_OutreachType: "Outreach Type",
  campaignPage_TableProspectLastNameHeader: "Prospect Last Name",
  campaignPage_TableProspectLastNameTooltip:
    "Required. Last name of the person who will receive communication from the ambassador.",
  campaignPage_TableProspectFirstNameHeader: "Prospect First Name",
  campaignPage_TableProspectFirstNameTooltip:
    "Required. First name of the person who will receive communication from the ambassador.",
  campaignPage_TableProspectEmailHeader: "Prospect Email",
  campaignPage_TableProspectEmailTooltip:
    "Required. Email address of the person who will receive communication from the ambassador.",
  campaignPage_TableProspectPhoneHeader: "Prospect Phone",
  campaignPage_TableProspectPhoneTooltip:
    "Required. Phone number of the person who will receive communication from the ambassador.",
  campaignPage_TableAmbassadorHeader: "Ambassador",
  campaignPage_TableAmbassadorTooltip:
    "Required. Name of the ambassador who you would like to connect with the prospect. Assignments can be made individually or in bulk when multiple rows are selected. This data must be manually entered and therefore cannot be imported.",
  campaignPage_TableNotesHeader: "Notes",
  campaignPage_TableNotesTooltip:
    "Optional. Message that the ambassador will receive from you about the prospective student/family. This data can be manually entered for each request or you can create a template to personalize messages en masse. A template enables you to merge in any data from the campaign builder table while still allowing for further customization if need be.",
  campaignPage_TableAddNotesButton: "+ Add",
  campaignPage_TableEditNotesButton: "Edit",
  campaignPage_TableAddColumnTooltip:
    "Optional. Additional information about the prospective student/family (e.g. Student Name, School, Grade, City/Town, Interests). Additional fields can be especially useful when making ambassador assignments and because any information in the campaign builder table can also be used to personalize the outreach note that the ambassador receives.",
  campaignPage_TableAssignmentsHeader: "Assignments",
  campaignPage_TableRecipientHeader: "Recipient Name",
  campaignPage_TablePersonalMessageHeader: "Personal Message to Ambassador",
  campaignPage_TablePersonalMessagePlaceholder:
    "You can use this field to customize a message to {0}.",
  campaignPage_ValidationModalHeader: "Missing something? 👀",
  campaignPage_ValidationModalBody:
    "Please review the highlighted cells to move on with the campaign.",
  campaignPage_ValidationModalOkayButton: "Okay",
  campaignPage_AmbassadorUnavailableForOutreach:
    "Ambassador may be unavailable for outreach - They may be on vacation or inactive",

  emailVerificationPage_RegisterTitle: "Register",
  emailVerificationPage_FirstNamePlaceholder: "Your first name",
  emailVerificationPage_LastNamePlaceholder: "Your last name",
  emailVerificationPage_EmailPlaceholder: "Your email address",
  emailVerificationPage_PasswordPlaceholder: "Choose a password",
  emailVerificationPage_CreateAccountButton: "Create Account",
  emailVerificationPage_LinkExpired: "Sorry, this link has expired.",

  feedbackModal_ExportButton: "Export",
  feedbackModal_CloseButton: "Close",
  feedbackModal_HeaderText: "{0} Feedback",
  feedbackModal_AuthorHeader: "Feedback from {0}:",
  feedbackModal_ConversationRatingQuestion: "Conversation rating:",
  feedbackModal_InterestRatingQuestion: "Interest in school:",
  feedbackModal_FeedbackQuestion: "Feedback:",
  feedbackModal_MostInterestedQuestion: "Most interested in:",
  feedbackModal_ConcernsQuestion: "Concerns or questions:",
  feedbackModal_NotYetAnswered: "Not yet answered",

  inviteAdminModal_Title: "Invite an admin to your Peerpal account",
  inviteAdminModal_Subtitle:
    "An admin account is marked as 'Invited' until the user accepts the invitation.",
  inviteAdminModal_Button: "Send Invite",

  homePage_CallFeedbackSource: "Call",
  homePage_ConvoFeedbackSource: "Conversation",

  inviteAmbassadorsModal_Title: "Invite Ambassadors",
  inviteAmbassadorsModal_To: "To:",
  inviteAmbassadorsModal_Import: "Import CSV",
  inviteAmbassadorsModal_DownloadTemplate: "Download CSV Template",
  inviteAmbassadorsModal_AddMore: "Add More",
  inviteAmbassadorsModal_Send: "Send Invites",
  inviteAmbassadorsModal_NothingToImportError: "Nothing to import",
  inviteAmbassadorsModal_RequiredFieldsError: "All fields are required.",
  inviteAmbassadorsModal_InvalidEmailError: "{0} is not a valid email.",
  inviteAmbassadorsModal_EmailsInUseError:
    "Highlighted emails are already in use.",
  inviteAmbassadorsModal_DuplicateEmailEntryError:
    "{0} appears more than once.",
  inviteAmbassadorsModal_InviteMessage:
    "{0} {1} has invited you to create a PeerPal account for {2}. Use the links below to set up your account in less than 5 minutes.",
  inviteAmbassadorsModal_InviteSentMessage: "{0} invites sent.",

  videoStoryApprovalModal_Title: "Profile Video Story Approval",
  videoStoryApprovalModal_Approve: "Approve",
  videoStoryApprovalModal_Reject: "Reject and Delete",

  loginPage_ResetTitle: "Enter a New Password",
  loginPage_ResetPasswordPlaceholder: "Password",
  loginPage_ResetConfirmPasswordPlaceholder: "Confirm Password",
  loginPage_ResetPasswordsMustMatchError: "Make sure both passwords match!",
  loginPage_ResetSuccessTitle: "All set! 😎",
  loginPage_ResetSuccessDescription:
    "The password on your account has been updated successfully.",
  loginPage_ResetSuccessLink: "Log back in here.",

  prospectsPage_DeleteUser: "Delete User",
  prospectsPage_BanUser: "Ban User",
  prospectsPage_UnbanUserSuccess: "User {0} successfully unbanned",
  prospectsPage_BanUserSuccess: "User {0} successfully banned",
  prospectsPage_UnbanUser: "Unban User",
  prospectsPage_FetchingProspects: "Currently fetching prospects...",
  prospectsPage_LastUpdated: "Last updated {0} at {1}",
  prospectsPage_TableRefreshed: "Table data refreshed.",
  prospectsPage_DateTableHeader: "Date",
  prospectsPage_AccountStatusTableHeader: "Account Status",
  prospectsPage_NoSearchResults: "No matches found.",
  prospectsPage_NoProspects: "There are no prospects for your organization.",
  prospectsPage_ScrollInstructions:
    "Scroll this table horizontally to view all of the prospects.",
  prospectsPage_Export: "Export",
  prospectsPage_ListDescription:
    "This list only includes prospect accounts that were created in response to an outreach request or a prospect-initiated conversation on your school's platform.",
  prospectsPage_Title: "Prospect Sign Ups",
  prospectsPage_BanRequestsTitle: "Ban Requests",
  prospectsPage_BanRequestSummary:
    "{0} has requested to ban {1} from the platform",
  prospectsPage_BanRequestsReason: "Reason",
  prospectsPage_ActionDownloadChatHistory: "Download Chat History",
  prospectsPage_ActionDenyBan: "Deny Ban",
  prospectsPage_ActionApproveBan: "Approve Ban",
  prospectsPage_NoMessagesToDownload: "No messages to download.",
  prospectsPage_BannedUserMessage: "You have banned {0}.",

  outreachPage_OutreachRequestsPageTitle: "Outreach Requests",
  outreachPage_AllOutreachRequestsTab: "All Outreach Requests",
  outreachPage_IndividualOutreachRequestsTab: "Individual Outreach Requests",
  outreachPage_CampaignsTab: "Campaigns",
  outreachPage_ScheduledCampaignsTab: "Scheduled Campaigns",
  outreachPage_CampaignDraftsTab: "Campaign Drafts",
  outreachPage_BulkOutreachBtn: "Bulk Outreach Campaign",
  outreachPage_IndividualOutreachBtn: "Individual Outreach",
  outreachPage_StatusDone: "Done",
  outreachPage_StatusPending: "Pending",
  outreachPage_InviteTypeEmail: "Email",
  outreachPage_InviteTypeCall: "Call",
  outreachPage_InviteTypeVideo: "Video",
  outreachPage_CopyCampaign: "Copy Campaign",
  outreachPage_Feedback: "Feedback",
  outreachPage_ViewRequest: "View Request",
  outreachPage_ViewRequestModalTitle: "{0} Outreach Request for {1}",
  outreachPage_InvitationModalTitle: "{0}'s {1} to {2}",
  outreachPage_CallMessageModalTitle:
    "{0} couldn't call {1} and left a message",
  outreachPage_ModalMessage: "Message:",
  outreachPage_ModalDateSent: "Date Sent:",
  outreachPage_TableCampaignName: "Campaign Name",
  outreachPage_TableAmbassador: "Ambassador",
  outreachPage_TableRecipient: "Recipient",
  outreachPage_TableDateSent: "Date",
  outreachPage_TableDateCreated: "Date Created",
  outreachPage_tableDateScheduled: "Date Scheduled",
  outreachPage_TableInviteType: "Type",
  outreachPage_TableRecipientCount: "Recipients",
  outreachPage_TableAmbassadorCount: "Ambassadors",
  outreachPage_TableStatus: "Status",
  outreachPage_TableProspectRegistered: "Prospect Registered",
  outreachPage_TableCreatedBy: "Created By",
  outreachPage_Filters: "Filters",
  outreachPage_ExportButton: "Export",
  outreachPage_DeleteCampaign: "Delete Campaign",
  outreachPage_DeleteCampaignTitle: "Delete Campaign?",
  outreachPage_DeleteCampaignMessage:
    "Are you sure you want to delete campaign, “{0}”? This action is irreversible.",
  outreachPage_DeleteCampaignSuccess: "Campaign deleted.",

  addManuallyModal_title: "Add Recipient Manually",
  addManuallyModal_addButton: "Add Recipient",

  individualOutreach_Title: "Outreach Request",
  individualOutreach_AmbassadorErrorRequired: "Ambassador is required.",
  individualOutreach_FirstNameLabel: "Recipient First Name",
  individualOutreach_FirstNameErrorRequired: "First name is required.",
  individualOutreach_LastNameLabel: "Recipient Last Name",
  individualOutreach_LastNameErrorRequired: "Last name is required.",
  individualOutreach_EmailLabel: "Recipient Email",
  individualOutreach_EmailErrorRequired: "Email is required.",
  individualOutreach_MessageLabel: "Message to Ambassador",
  individualOutreach_PhoneNumberErrorRequired: "Phone number is required.",
  individualOutreach_ErrorInvalidNumber: "Invalid phone number",
  individualOutreach_ReviewLabel: "Review required?",
  individualOutreach_ButtonSave: "Send Request",

  campaign_Save: "Save Campaign",
  campaign_Exit: "Exit Campaign",
  campaign_New: "New Campaign",
  campaign_StepSelectType: "Select Outreach Type",
  campaign_StepEmailTemplate: "Email Template",
  campaign_StepImportList: "Import List",
  campaign_StepCreateMatches: "Create Matches",
  campaign_StepReviewMatches: "Review Matches",
  campaign_StepSendOutreachRequests: "Send Outreach Request",

  campaign_UploadCsv: "Upload a CSV file",
  campaign_UploadCsvInstructions:
    "Upload a new CSV file or use the CSV template available below **(recommended)**.",
  campaign_UploadCsvInstructions1: "Here are a couple of notes to consider:",
  campaign_UploadCsvInstructions2: "The file must be formatted as a .csv",
  campaign_UploadCsvInstructions3:
    "File must contain column headers in the first row",
  campaign_UploadCsvInstructions4:
    "Additional columns may be added to the template as required",
  campaign_UploadCsvInstructions5:
    "If you choose to include the ambassadors' names and email addresses, they need to be **exact matches** of the listings in your PeerPal database. To ensure spelling and case matching, we recommend copying the exact information from your ambassador list.",
  campaign_ButtonDownloadAmbassadors: "Download ambassador list",
  campaign_ButtonDownloadCsvTemplate: "Download CSV Template",
  campaign_ButtonUploadCsvFile: "Upload CSV File",
  campaign_ButtonReplaceCsvFile: "Replace CSV File",
  campaign_UploadCsvRowError: "[Row {0}] {1}",
  campaign_UploadCsvErrorTooFewFields: "Too few fields",
  campaign_UploadCsvErrorTooManyFields: "Too many fields",
  campaign_UploadCsvErrorMissingQuotes: "Missing quotes",
  campaign_UploadCsvErrorDuplicateEmail: "Duplicate recipient email",
  campaign_UploadCsvErrorMalformed: "Malformed",
  campaign_UploadCsvErrorNoMatch: "Could not match ambassador",
  campaign_UploadCsvErrorEmptyFile: "CSV file does not contain any recipients",
  campaign_UploadCsvSuccessful: "Your file was uploaded successfully!",
  campaign_DownloadCsv: "Download {0}",
  campaign_UploadCsvTooltip: "Upload a CSV file to order to continue",
  campaign_FieldVisibilityTitle: "Field Visibility",
  campaign_FieldVisibilityInstructions:
    "Please select which of the fields should be visible to ambassadors in their outreach requests:",
  campaign_CsvKeepAmbassadors: "Keep Ambassadors",
  campaign_CsvKeepAmbassadorsMessage:
    "You already have ambassadors in your campaign, would you like to keep them?",
  campaign_CsvNoAmbassadors: "No Ambassadors",
  campaign_CsvNoAmbassadorsMessage:
    "The file you have added does not contain ambassadors. Would you like to add available ambassadors from your organization?",
  campaign_IncludeVacationMode: "Include ambassadors on vacation",
  campaign_IncludeInactive: "Include ambassadors with profile not visible",
  campaign_AutoMatchTitle: "Auto Match",
  campaign_AutoMatchDescription:
    "Auto Match will pair prospects with the available ambassadors from your platform",

  campaign_CreateMatches: "Create Matches",
  campaign_CreateMatchesStartupInstructions:
    "To begin matching, please select one of the options below.",
  campaign_CreateMatchesInstructions:
    "In order to create a match select one ambassador and at least one recipient in the box below.",
  campaign_AmbassadorsMatched: "Ambassadors matched",
  campaign_RecipientsMatched: "Recipients matched",
  campaign_MatchManually: "Match Manually",
  campaign_AllMatched:
    "You already matched all your ambassadors and recipients",
  campaign_AutoMatch: "Auto Match",
  campaign_SelectAmbassadors: "Select Ambassador(s)",
  campaign_SelectRecipients: "Select Recipient(s)",
  campaign_RecipientName: "Recipient Name",
  campaign_Recipients: "Recipients",
  campaign_UserOnVacation: "User is on vacation",
  campaign_UnsavedChangesModalTitle: "Save Campaign?",
  campaign_UnsavedChangesModalBody:
    "You have unsaved changes, would you like to save?",
  campaign_NonMatchedUsersModalTitle: "Are you sure you want to continue?",
  campaign_NonMatchedUsersModalBody:
    "You did not match all the available recipients",
  campaign_SendNow: "Send Now",
  campaign_SendNowConfirmation:
    "Are you sure you would like to send this campaign?",
  campaign_ScheduleLater: "Schedule for Later",
  campaign_ScheduleCampaign: "Schedule Campaign",
  campaign_ScheduledFor: "Scheduled for {0}",
  campaign_NotScheduled: "Not yet scheduled",
  campaign_EditSchedule: "Edit Schedule",
  campaign_EditScheduleTime: "Time",
  campaign_EditScheduleSaveButton: "Continue",
  campaign_SuccessfullySent: "Bulk Campaign was successfully sent",
  campaign_SuccessfullySentMessage:
    "{0} was successfully sent to {1} ambassadors",
  campaign_SuccessfullyScheduled: "Bulk Campaign was successfully scheduled",
  campaign_SuccessDone: "Great!",
  campaign_InfoBackLink: "Back to all campaigns",
  campaign_InfoSentOn: "Sent on {0}",
  campaign_InfoCreatedBy: "Created By {0}",
  campaign_InfoAmbassadorCount: "{0} Ambassadors",
  campaign_InfoRecipientCount: "{0} Recipients",
  campaign_SaveSuccessfully: "Campaign saved",
  campaign_AutoSave: "Autosaving campaign",
  campaign_SentSuccessfully: "Campaign is being sent",
  campaign_EmailSubject: "Welcoming new students",
  campaign_EmailBody:
    "Dear {AmbFirst} {AmbLast},\n\nThank you for being a great ambassador.\n\nPlease reach out to the families you have been matched with to see if they have any questions.\n\nWarm Regards,\n{0}",
  campaignOutreach_VideoOutreachDisabledMessage:
    "This feature is disabled. Contact your account manager to enhance your experience",
  campaignOutreach_SelectUserModalSubtitle: "{0} / {1} {2} are matched",
  campaignOutreach_SelectUserModalNameColumn: "Ambassador Name",
  campaignOutreach_SelectUserModalConversationsColumn: "Conversations",
  campaignOutreach_SelectUserModalProfileVisibleColumn: "Profile Visible",
  campaignOutreach_SelectUserModalStatusColumn: "Status",
  campaignOutreach_SelectUserModalRecipientNameColumn: "Recipient Name",
  campaignOutreach_outreachTypeTitle: "Set Up Your Campaign",
  campaignOutreach_outreachTypeInputLabel:
    "Choose a title for your Campaign. Ex: Bulk Campaign May",
  campaignOutreach_outreachTypeInputPlaceholder: "Campaign Title",
  campaignOutreach_outreachTypeDescription: "Choose your campaign type",
  campaignOutreach_outreachTypeTooltip:
    "Give your campaign a unique name and choose an outreach type in order to continue",
  campaignOutreach_outreachTypeNameUniqueError: "Campaign name already taken",
  campaignOutreach_outreachTypeNameRequiredError: "Campaign name required",
  campaignOutreach_outreachEmailTitle: "Create Email Template",
  campaignOutreach_outreachEmailDescription:
    "Personalize the message that your ambassadors receive when they are invited to reach out. You are able to further personalize the message by using merge fields such as {AmbFirst} and {AmbLast}.",
  campaignOutreach_outreachEmailTooltip:
    "Subject line and Body are required in order to continue",
  campaignOutreach_outreachEmailSubjectPlaceholder: "Subject Line",
  campaignOutreach_outreachEmailError:
    "Only {AmbFirst} and {AmbLast} are usable variables in the email",
  campaignOutreach_outreachEmailBodyLabel: "Message Body",

  notFoundPage_Header:
    "Whoops! The page you were looking for could not be found. Would you like to head back to your dashboard?",
  notFoundPage_RerouteLink: "Yes, take me to my dashboard.",

  settingsPage_FaviconHeader: "Favicon",
  settingsPage_FaviconSubHeader:
    "File should be 16x16 or 32x32 px and .ico or .png format",
  settingsPage_BrandingSubHeader:
    "Customize your landing page, messaging platform, and email messages.",
  settingsPage_LogoHeader: "Logo",
  settingsPage_UploadButton: "Upload",
  settingsPage_ColorsHeader: "Colors",
  settingsPage_ColorsPrimarySectionHeader: "Primary",
  settingsPage_ColorsPrimarySectionSubHeader:
    "Landing page header/footer, widget, banner on email and messaging platform",
  settingsPage_ColorsSecondarySectionHeader: "Secondary",
  settingsPage_ColorsSecondarySectionSubHeader:
    "Call-to-action buttons, message bubbles",
  settingsPage_ColorsAccentSectionHeader: "Accent",
  settingsPage_ColorsAccentSectionSubHeader: "Return to main site button",
  settingsPage_ColorsHexLabel: "Hex",
  settingsPage_ColorsOrLabel: "Or",
  settingsPage_ColorsRLabel: "R",
  settingsPage_ColorsGLabel: "G",
  settingsPage_ColorsBLabel: "B",
  settingsPage_InvalidHexError:
    "A full 6-digit hex code must be provided for each color.",
  settingsPage_BrandingSavedSuccess: "Branding saved.",
  settingsPage_ManageAdmins: "Manage Administrators",
  settingsPage_InviteAdmin: "Invite Admin",
  settingsPage_RemoveAdminPopupHeader: "Remove Administrator",
  settingsPage_RemoveAdminPopupBody:
    "{0} will no longer have access to the platform.",
  settingsPage_RemoveAdminSuccessMessage:
    "Successfully removed {0} as an administrator.",
  settingsPage_RemoveAdminPopupButton: "Remove",
  settingsPage_EmailPasswordResetPopupHeader: "Email Password Reset",
  settingsPage_EmailPasswordResetPopupBody:
    "Are you sure you want to email a password reset to {0}?",
  settingsPage_EmailPasswordResetPopupButton: "Email",
  settingsPage_EmailPasswordSuccessMessage: "Password reset emailed to {0}!",
  settingsPage_AmbassadorSetup: "Ambassador Setup",
  settingsPage_ProspectSetup: "Prospect Setup",
  settingsPage_ProfileFields: "Profile Fields",
  settingsPage_ProfileFieldsInstructions:
    "Choose what information is visible on all ambassador profile cards.",
  settingsPage_ProfileQuestion: "Profile Question",
  settingsPage_Restrictions: "Restrictions",
  settingsPage_MessagesLimit: "Conversation Limit",
  settingsPage_MessagesLimitAmbassadorInstructions:
    "Limit the number of conversations an ambassador can have simultaneously.",
  settingsPage_MessagesLimitProspectInstructions:
    "Limit the number of ambassadors each prospect can reach out to.",
  settingsPage_SignUpForm: "Sign-Up Form",
  settingsPage_SignUpInterestTitle: "Interest Slider",
  settingsPage_SignUpInterestDescription:
    "Include interest rating (1-10) scale question",
  settingsPage_SignUpFormInstructions:
    "PeerPal asks prospects to provide a first name, last name, and email when they sign up for an account. Customize any additional questions you would like prospects to answer at this time.",
  settingsPage_SignUpFormExample:
    "Example: Student/Applicant Name, Current School",
  settingsPage_SettingsSaved: "Settings saved.",
  settingsPage_PopupCancelButton: "Cancel",
  settingsPage_LandingPageRandomizeHeader: "Randomize Ambassadors",
  settingsPage_LandingPageRandomizeDescription:
    "Randomize the order of ambassadors on the landing page during a user's visit.",
  settingsPage_LandingPageTitle: "Landing Page",
  settingsPage_LandingPageInstructions:
    "Customize how your landing page looks to visitors.",
  settingsPage_LandingPageFieldsTitle: "Title Text",
  settingsPage_LandingPageFieldsBody: "Body Text",
  settingsPage_LandingPageFieldsFooter: "Footer Text",
  settingsPage_LandingPageCallToActionButtons: "Call to Action Buttons",
  settingsPage_LandingPageCallToActionInstructions:
    "These optional buttons enable quick access to other websites. They are displayed at the top of the landing page between the body text and search bar.",
  settingsPage_LandingPageCallToActionExamples:
    "Examples: Schedule a Tour, Start an Application, Visit Campus",
  settingsPage_LandingPageCallToActionButtonPlaceholder: "Button Label #{0}",
  settingsPage_LandingPageCallToActionLinkPlaceholder: "Website Link #{0}",
  settingsPage_LandingPageContactAmbassadors: "Contact Ambassadors",
  settingsPage_LandingPageHideSendMessage:
    "Disable option to message ambassadors",
  settingsPage_LandingPageHideRequestCall:
    "Disable option to request a call from ambassadors",
  settingsPage_LandingPageSaveSuccess: "Landing Page saved.",
  settingsPage_LandingPageMissingCTAFields:
    "You must fill out all of the Call-to-Action Buttons fields.",

  settingsPage_WidgetText: "Widget Text",
  settingsPage_WidgetTextInstructions:
    "Customize how your widget looks to visitors.",
  settingsPage_WidgetHeaderMinimized: "Header (when minimized)",
  settingsPage_WidgetHeaderExpanded: "Header (when expanded)",
  settingsPage_WidgetSubHeaderExpanded: "Sub-header (when expanded)",
  settingsPage_WidgetOptions: "Widget Options",
  settingsPage_WidgetSide: "Side of screen",
  settingsPage_WidgetDistanceFromSide: "Distance from side (px)",
  settingsPage_WidgetDistanceFromBottom: "Distance from bottom (px)",
  settingsPage_WidgetCode: "Implement Widget",
  settingsPage_WidgetInstructions:
    "Select your website host for instructions on how to add the PeerPal widget to your school's website.",
  settingsPage_WidgetHost: "Widget Host",
  settingsPage_WidgetBlackbaudInstructions:
    "Edit Website > Select page you add widget to and Edit Page Content > Drag Embed widget into page > Gear icon > Copy and Paste > Save",
  settingsPage_WidgetFinalsiteInstructions:
    "Go to Composer > Tool icon bottom right on the page (Page Settings) > SEO > Custom head > Copy and Paste > Save",
  settingsPage_WidgetWordPressInstructions:
    "Install PeerPal plugin with the following shortcode",
  settingsPage_WidgetSquarespaceInstructions:
    "Go to the page > Settings > Advanced > Copy and Paste",
  settingsPage_WidgetWixInstructions:
    "Go to Settings > Advanced > Custom Code > Body - Start > Copy and Paste > Apply",
  settingsPage_WidgetOtherInstructions:
    "Add this code to the <body> tag of whatever page you want the widget on",

  settingsPage_SeasonRollover: "Season Rollover",
  settingsPage_SeasonRolloverInstructions:
    "Indicate when you would like your PeerPal data to refresh annually. All engagement metrics, conversation history, and prospect sign ups for the previous year will be archived on this date.",
  settingsPage_SeasonRolloverSaveSuccess: "Season Rollover date saved.",

  settingsPage_InboxWelcomeMessageHeader: "Welcome Message",
  settingsPage_InboxWelcomeMessageAmbassadorsHeader: "Ambassadors",
  settingsPage_InboxWelcomeMessageProspectsHeader: "Prospects",
  settingsPage_InboxAmbassadorWelcomeMessageDescription:
    "Customize the first message your ambassadors will see in their inbox. The tags {FirstName} and {LastName} will be automatically replaced.",
  settingsPage_InboxProspectWelcomeMessageDescription:
    "Customize the first message your prospects will see in their inbox. The tags {FirstName} and {LastName} will be automatically replaced.",
  settingsPage_InboxWelcomeMessageInvalidTag: "{0} is/are invalid tags.",

  settingsPage_FeedbackHeaderAmbassador: "Ambassador Feedback Questions",
  settingsPage_FeedbackHeaderProspect: "Prospect Feedback Questions",
  settingsPage_FeedbackRating: "Rate your conversation question",
  settingsPage_FeedbackRatingDescription:
    "The answer will show as a slider where the users can rate from 1 to 10.",
  settingsPage_FeedbackAdditional: "Additional questions",
  settingsPage_FeedbackAdditionalDescription:
    "The answer will show as a field to answer with free text.",
  settingsPage_FeedbackError: "You must not submit empty Feedback Questions",

  settingsPage_InboxContactUsHeader: "Contact Us",
  settingsPage_InboxContactUsLabel: "Title",
  settingsPage_InboxContactUsEmail: "Contact Email",

  settingsPage_NotificationSettings: "Notification Settings",
  settingsPage_Inbox: "Inbox",
  settingsPage_ImplementWidget: "Implement Widget",
  settingsPage_Widget: "Widget",
  settingsPage_LandingPage: "Landing Page",
  settingsPage_Branding: "Branding",
  settingsPage_CustomizePlatform: "Customize Platform",

  superAdminPanel_ConversationExport: "Conversation Export",
  superAdminPanel_VideoOutreach: "Video Outreach",
  superAdminPanel_ProfileVideo: "Profile Video",
  superAdminPanel_Title: "Super Admin Portal",
  superAdminPanel_Subtext:
    "Select the organization name or landing page you would like to view.",
  superAdminPanel_Add: "Add Super Admin",
  superAdminPanel_FeatureTitle: "Premium Features",
  superAdminPanel_ButtonSwitch: "Switch to:",
  superAdminPanel_TableHeaderLogo: "Organization Logo",
  superAdminPanel_TableHeaderName: "Organization",
  superAdminPanel_TableHeaderUrl: "Landing Page",
  superAdminPanel_TableHeaderPremiumFeatures: "Active Premium Features",
  superAdminPanel_ViewDashboard: "View Dashboard",
  superAdminPanel_NotesTitle: "Admin Notes",
  superAdminPanel_NotesSubtext:
    "Notes are internal and visible only to super admins",
  superAdminPanel_DisplayName: "Display Name",
  superAdminPanel_DisplayNameSaveSuccess: "Display name saved",

  superAdminModal_EmailPlaceholder:
    "Must be an @graduway.com or @gravyty.com email address",
  superAdminModal_AddButton: "Add",
  superAdminModal_InvalidEmailFailure:
    "You must enter a valid Graduway email address.",
  superAdminModal_AddSuperAdminSuccess:
    "{0} has been designated as a super admin.",
  superAdminModal_AddSuperAdminFailure:
    "There was an issue designating {0} as a super admin.",

  superAdminAccordion_NoteFailure: "Notes were not saved properly",

  sidebar_ResourceCenterDescription:
    "Ask questions, leave feedback, or find tutorials",
  sidebar_ResourceCenterButton: "Resource Center",
  sidebar_SubmitTicketButton: "Submit a Ticket",
  sidebar_DashboardRoute: "Dashboard",
  sidebar_AmbassadorsRoute: "Ambassadors",
  sidebar_ProspectsRoute: "Prospects",
  sidebar_OutreachRoute: "Outreach",
  sidebar_SettingsRoute: "Settings",
  sidebar_OrganizationButton: "Organization",
  sidebar_OrganizationMenuCurrent: "Current: {0}",
  sidebar_SuperAdminPanelRoute: "Super Admin Panel",
  sidebar_UserMenuMessages: "Messages",
  sidebar_UserMenuManageAccount: "Manage Account",
  sidebar_UserMenuLogOut: "Log Out",

  conversationTable_Close: "Close",

  editProfile_watchMyStoryTitle: "Watch my Story",
  editProfile_watchMyStoryDescription:
    "Video Stories allow you to share more about yourself. Anything specific you'd like to go more into detail about, you can include here.",

  editProfile_Processing: "Processing...",
  editProfile_Success: "Successfully updated profile",
  editProfile_ReadMyStory: "Read My Story",
  editProfile_ReadMyStoryInstructions:
    "Stories allow you to share more about yourself. Anything specific you’d like to go more into detail about, you can include here.",
  editProfile_ReadMyStoryExample:
    "Ex: Why did you choose PeerPal from Gravyty? What was the most important factor when you were considering PeerPal from Gravyty?",
  editProfile_ReadMyStoryQuestionPlaceholder: "Enter a question here",
  editProfile_ReadMyStoryAnswerPlaceholder: "Enter the answer here",

  videoRecording_Record: "Record Video",
  videoRecording_RecordNew: "Record New Video",
  videoRecording_Divider: "or",
  videoRecording_Note:
    "(.mp4, .ogg, and .webm file types supported. Maximum file size: {0}MB)",
  videoRecording_Start: "Start Recording",
  videoRecording_Timeout: "Recording in...",
  videoRecording_Stop: "Stop Recording",
  videoRecording_Delete: "Delete Recording",
  videoRecording_ChangeCameras: "Change Cameras",
  videoRecording_Upload: "Upload Video",
  videoRecording_UploadNew: "Upload New Video",
  videoRecording_ErrorMaxFileSize: "File size must not exceed {0}MB",
  videoRecording_UserHidden: "Make Visible on Profile",
  videoRecording_CameraError: "Unable to load camera.",

  login_SignIn: "Sign In",
  login_ForgotPassword: "Forgot Password",
  login_ResetPassword: "Reset Password",
  login_ResetPasswordInstructions:
    "Enter your email and we’ll send you a link to reset your password.",
  login_ResetPasswordSubmitBtn: "Send",
  login_ResetPasswordBackBtn: "Back to Sign In",
  login_ResetPasswordSuccess: "Email coming your way!",
  login_ResetPasswordSuccessInstructions:
    "An email has been sent from us with steps on how to reset your password. Thanks!",
  login_TOSPrefix: "By signing in, you agree to to our ",
  login_InvalidLogin: "You have entered an invalid username or password",
  login_NoGoogleAccount: "No account exists for this Google user",
  login_WrongSiteMessage: "Your account exists on another site",
  login_WrongSiteLink: "Sign in here",

  maintenance_Title: "Be a pal!",
  maintenance_Banner: "UNDER CONSTRUCTION",
  maintenance_DescriptionPart1:
    "Please be patient while we work on some routine maintenance.",
  maintenance_DescriptionPart2: "We should be back up ",

  general_Conversations: "Conversations",
  general_Email: "Email",
  general_Password: "Password",
  general_FirstName: "First Name",
  general_LastName: "Last Name",
  general_Name: "Name",
  general_Phone: "Phone",
  general_Profile: "Profile",
  general_Ambassador: "Ambassador",
  general_Prospect: "Prospect",
  general_QuickMenuLabel: "Quick Menu",
  general_SearchPlaceholder: "Search",
  general_Status: "Status",
  general_Video: "Video",
  general_JoinedOn: "Joined On",
  general_SignUpDate: "Sign Up Date",
  general_ErrorMessage: "There was a problem, please try again later!",
  general_ToastError: "Uh oh! There was an issue!",
  general_InvalidEmail: "Please enter an valid email.",
  general_Save: "Save",
  general_Cancel: "Cancel",
  general_Yes: "Yes",
  general_No: "No",
  general_Copy: "Copy",
  general_DeletedUser: "Deleted User",
  general_Month: "Month",
  general_Day: "Day",
  general_Date: "Date",
  general_Time: "Time",
  general_TermsOfService: "Terms of Service",
  general_Close: "Close",
  general_Delete: "Delete",
  general_Continue: "Continue",
  general_TableNoContent: "No content to display",
  general_ErrorEmailFormat: "Invalid email address.",
  general_ErrorMaxLength: "Max {0} characters.",
  general_Select: "Select",
  general_SelectVar: "Select {0}",
  general_ExportSafeguarding: "Export Safeguarding Report",
  general_Help: "Help",

  modal_DeleteUserConfirmation: "Are you sure you want to delete {0}?",
  modal_DeleteUserTitle: "Delete User",
  modal_AccountDeleted: "User Account Deleted Successfully",

  stepper_Back: "Back",
  stepper_Next: "Continue",

  status_Banned: "Banned",
  status_ConvoLimited: "Limited",
  status_Active: "Active",
  status_Closed: "Closed",
  status_Invited: "Invited",
  status_Archived: "Archived",
  status_Vacation: "Vacation",
};
