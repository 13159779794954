<template>
  <div class="customize-platform">
    <!-- Header -->
    <div class="w-full flex flex-col border-b">
      <h1 class="text-2xl font-semibold">
        {{ $lang.settingsPage_CustomizePlatform }}
      </h1>
      <div class="mt-10 mb-2">
        <common-tabs :tabs="tabs" @tab-click="selectedTab = $event" />
      </div>
    </div>

    <!-- Sign-Up Fields Tab -->
    <div class="mt-12">
      <transition name="fade" mode="out-in">
        <component :is="activeComponent" :branding="branding" />
      </transition>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import { GET_BRANDING } from "@/store/getters.type.js";

  export default {
    data() {
      return {
        tabs: [
          this.$lang.settingsPage_Branding,
          this.$lang.settingsPage_LandingPage,
          this.$lang.settingsPage_Widget,
          this.$lang.settingsPage_SeasonRollover,
          this.$lang.settingsPage_Inbox,
        ],

        components: [
          "Branding",
          "LandingPage",
          "Widget",
          "SeasonRollover",
          "Inbox",
        ],

        selectedTab: 0,
      };
    },

    computed: {
      activeComponent() {
        const selectedComponent = this.components[this.selectedTab];
        return () => import(`./customize_platform/${selectedComponent}`);
      },

      ...mapGetters({ branding: GET_BRANDING }),
    },
  };
</script>

<style scoped>
  .customize-platform {
    height: 100%;
    width: 100%;
  }
</style>
