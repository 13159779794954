import Vue from "vue";
import Vuex from "vuex";
import campaignOutreachModule from "./dashboard/campaignOutreach.module";
import mainDashboardModule from "./dashboard/main.module";
import brandingModule from "./dashboard/branding.module";
import outreachModule from "./dashboard/outreach.module";
import prospectModule from "./dashboard/prospect.module";
import ambassadorModule from "./dashboard/ambassador.module";
import administratorModule from "./dashboard/administrator.module";
import conversationModule from "./dashboard/conversation.module";
import homeModule from "./dashboard/home.module";
import notifications from "./dashboard/notifications.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    mainDashboardModule,
    brandingModule,
    prospectModule,
    ambassadorModule,
    administratorModule,
    conversationModule,
    outreachModule,
    campaignOutreachModule,
    homeModule,
    notifications,
  },
});
