<template>
  <common-modal @close="$emit('close')">
    <div class="invite-admin">
      <div class="invite-admin-title">
        {{ $lang.inviteAdminModal_Title }}
      </div>
      <div class="invite-admin-subtitle">
        {{ $lang.inviteAdminModal_Subtitle }}
      </div>
      <div class="invite-admin-row">
        <div class="invite-admin-row-item">
          <p>
            {{ $lang.general_FirstName }}
          </p>
          <common-input v-model="firstName" />
        </div>
        <div class="invite-admin-row-item">
          <p>
            {{ $lang.general_LastName }}
          </p>
          <common-input v-model="lastName" />
        </div>
      </div>
      <div class="invite-admin-row">
        <div class="invite-admin-row-item">
          <p>
            {{ $lang.general_Email }}
          </p>
          <common-input v-model="email" />
        </div>
      </div>
      <div class="invite-admin-footer">
        <common-button
          variant="primary"
          @click="inviteAdmin"
          :loading="sending"
          :disabled="!firstName || !lastName || !email || sending"
          >{{ $lang.inviteAdminModal_Button }}</common-button
        >
      </div>
    </div>
  </common-modal>
</template>

<script>
  import { mapGetters } from "vuex";
  import CommonModal from "../Modal.vue";
  import validationService from "@/services/validations.service";
  import { REGISTER_ADMIN } from "@/store/actions.type";
  import { GET_CURRENT_USER } from "@/store/getters.type";

  export default {
    name: "invite-admin-modal",
    components: {
      CommonModal,
    },
    data() {
      return {
        firstName: null,
        lastName: null,
        email: null,
        sending: false,
      };
    },
    methods: {
      inviteAdmin: async function () {
        this.sending = true;

        if (!validationService.isEmailValid(this.email)) {
          this.$toasted.show(
            `Please enter a valid email.`,
            this.$toastedFailure
          );
          return;
        }

        if (
          !this.firstName ||
          !this.firstName.length ||
          !this.lastName ||
          !this.lastName.length
        ) {
          this.$toasted.show(
            `Please enter both first and last name.`,
            this.$toastedFailure
          );
          return;
        }

        let userData = {
          first: this.firstName,
          last: this.lastName,
          email: this.email,
          main: false,
          emailMagic: true,
          url: this.getCurrentUser.url,
        };

        const response = await this.$store.dispatch(REGISTER_ADMIN, userData);

        if (response) {
          this.$toasted.show(
            `You have successfully invited ${this.firstName} ${this.lastName} to become an admin.`,
            this.$toastedSuccess
          );
          this.$emit("close");
        } else {
          this.$toasted.show(
            `Uh oh! There was an issue sending this invite.`,
            this.$toastedFailure
          );
        }

        this.sending = false;
      },
    },
    computed: {
      ...mapGetters({ getCurrentUser: GET_CURRENT_USER }),
    },
  };
</script>

<style lang="scss" scoped>
  .invite-admin {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    color: $grey-600;

    &-title {
      color: $grey-800;

      font-weight: 600;
      font-size: 1.5rem;
      line-height: 2rem;
    }

    &-subtitle {
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin: 1.25rem 0 2rem 0;
    }

    &-row {
      display: flex;
      gap: 2rem;

      &-item {
        flex-grow: 1;

        p {
          font-weight: 500;
          font-size: 0.75rem;
          line-height: 1rem;
          margin-bottom: 0.5rem;
        }
      }
    }
    &-footer {
      margin-top: 8rem;
    }
  }
</style>
