import Vue from "vue";
import APIService from "../../services/api/index";
import {
  CREATE_BULK_INVITE,
  DELETE_AMBASSADOR,
  FETCH_CURRENT_PARENTS,
  FETCH_INVITED_AMBASSADORS,
  REMIND_INVITED_AMBASSADOR,
  SET_AMBASSADORS,
  SET_AMBASSADOR,
  DELETE_INVITED_AMBASSADOR,
  ARCHIVE_AMBASSADORS,
  RESTORE_AMBASSADORS,
  FIND_AMBASSADOR,
} from "../actions.type.js";

import {
  UPDATE_CURRENT_PARENTS,
  UPDATE_INVITED_AMBASSADORS,
  UPDATE_AMBASSADOR,
  UPDATE_AMBASSADORS,
  REMOVE_AMBASSADOR,
  REMOVE_INVITED_AMBASSADOR,
} from "../mutations.type.js";

import {
  GET_CURRENT_PARENTS,
  GET_INVITED_AMBASSADORS,
  GET_AMBASSADORS_AVAILABLE,
} from "../getters.type.js";

const dashboardService = APIService.dashboard;

export const initialState = {
  currentParents: [],
  invitedAmbassadors: [],
  ambassadorsAvailable: true,
};

export const state = { ...initialState };

export const actions = {
  async [FETCH_CURRENT_PARENTS]({ getters, commit }) {
    const data = {
      organization: getters.getBranding.name,
    };

    const response = await dashboardService.currentParents(data);
    commit(UPDATE_CURRENT_PARENTS, response.data);
    return response;
  },

  async [FETCH_INVITED_AMBASSADORS]({ getters, commit }) {
    const data = {
      organization: getters.getBranding.name,
    };

    const response = await dashboardService.getInvitedAmbs(data);
    commit(UPDATE_INVITED_AMBASSADORS, response.data);
    return response;
  },

  async [SET_AMBASSADOR](context, payload) {
    const response = await dashboardService.updateParents(payload);
    if (response.status === 200) context.commit(UPDATE_AMBASSADOR, payload);
    return response.status === 200;
  },

  async [SET_AMBASSADORS](context, payload) {
    const response = await dashboardService.updateParentsBatch(payload);
    if (response.status === 200) context.commit(UPDATE_AMBASSADORS, payload);
    return response.status === 200;
  },

  async [ARCHIVE_AMBASSADORS](context, payload) {
    const data = {
      ambIDs: payload.map((amb) => amb.id),
    };

    const response = await dashboardService.archiveAmbsBatch(data);
    if (response.status === 200) context.commit(UPDATE_AMBASSADORS, payload);
    return response.status === 200;
  },

  async [RESTORE_AMBASSADORS](context, payload) {
    const data = {
      ambIDs: payload.map((amb) => amb.id),
    };

    const response = await dashboardService.restoreAmbsBatch(data);
    if (response.status === 200) context.commit(UPDATE_AMBASSADORS, payload);
    return response.status === 200;
  },

  async [DELETE_AMBASSADOR](context, payload) {
    const data = {
      userId: payload,
    };

    const response = await dashboardService.deleteUser(data);
    if (response.status === 200) context.commit(REMOVE_AMBASSADOR, payload);
    return response.status === 200;
  },

  async [DELETE_INVITED_AMBASSADOR](context, payload) {
    const data = {
      ambInvitationID: payload,
    };

    const response = await dashboardService.deleteAmbInvite(data);
    if (response.status === 200)
      context.commit(REMOVE_INVITED_AMBASSADOR, payload);
    return response.status === 200;
  },

  async [REMIND_INVITED_AMBASSADOR](context, payload) {
    const data = {
      ambInvitationID: payload,
    };

    const response = await dashboardService.remindInvitedAmb(data);
    return response.status === 200;
  },

  async [CREATE_BULK_INVITE]({ getters, commit }, payload) {
    const data = {
      organization: getters.getBranding.name,
      ...payload,
    };

    const response = await dashboardService.bulkInviteAmb(data);

    if (response.status === 200) {
      const invitedAmbassadors = getters.getInvitedAmbassadors;
      const updatedInvitedAmbassadors = invitedAmbassadors.concat(
        response.data
      );
      commit(UPDATE_INVITED_AMBASSADORS, updatedInvitedAmbassadors);
    }

    return response.status === 200;
  },

  [FIND_AMBASSADOR](context, idToFind) {
    return context.state.currentParents.find((amb) => amb._id === idToFind);
  },
};

export const mutations = {
  [UPDATE_CURRENT_PARENTS](state, currentParents) {
    state.currentParents = currentParents;
    state.ambassadorsAvailable = !!currentParents?.length;
  },

  [UPDATE_INVITED_AMBASSADORS](state, updatedInvitedAmbassadors) {
    state.invitedAmbassadors = updatedInvitedAmbassadors;
  },

  [UPDATE_AMBASSADORS](state, updatedAmbassadors) {
    for (let updatedAmb of updatedAmbassadors) {
      state.currentParents.find((amb, index) => {
        if (amb._id === updatedAmb.id) {
          delete updatedAmb.id;
          Vue.set(state.currentParents, index, { ...amb, ...updatedAmb });
        }
      });
    }
  },

  [UPDATE_AMBASSADOR](state, updatedAmbassador) {
    state.currentParents.find((amb, index) => {
      if (amb._id === updatedAmbassador.id) {
        delete updatedAmbassador.id;
        Vue.set(state.currentParents, index, { ...amb, ...updatedAmbassador });
      }
    });
  },

  [REMOVE_AMBASSADOR](state, idToDelete) {
    let indexToDelete;
    state.currentParents.find((ambassador, index) => {
      if (ambassador._id === idToDelete) {
        return (indexToDelete = index);
      }
    });

    state.currentParents.splice(indexToDelete, 1);
  },

  [REMOVE_INVITED_AMBASSADOR](state, idToDelete) {
    let indexToDelete;
    state.invitedAmbassadors.find((ambassador, index) => {
      if (ambassador._id === idToDelete) {
        return (indexToDelete = index);
      }
    });

    state.invitedAmbassadors.splice(indexToDelete, 1);
  },
};

export const getters = {
  [GET_CURRENT_PARENTS](state) {
    return state.currentParents;
  },

  [GET_INVITED_AMBASSADORS](state) {
    return state.invitedAmbassadors;
  },

  [GET_AMBASSADORS_AVAILABLE](state) {
    return state.ambassadorsAvailable;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
