<template>
  <div ref="animation" />
</template>

<script>
  import lottie from "lottie-web";

  export default {
    name: "common-lottie",
    props: {
      animationData: {
        type: Object,
        required: true,
      },
    },
    mounted() {
      const lottieAnimation = lottie.loadAnimation({
        container: this.$refs.animation,
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: this.animationData,
      });
      lottieAnimation.addEventListener("DOMLoaded", () =>
        lottieAnimation.play()
      );
    },
  };
</script>
