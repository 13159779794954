<template>
  <p-modal
    :title="title"
    size="xl"
    :value="value"
    @input="closeModal"
    :saveButtonText="$lang.general_Select"
    :isSaveDisabled="!validSelections.length"
    @cancel="closeModal"
    @save="selectUsers">
    <p>{{ modalSubtitle }}</p>

    <MultiUserSelectSearch
      class="multi-user-select-search"
      :filterOptions="headers"
      v-model="filter" />

    <v-data-table
      class="multi-user-select-table"
      v-model="selectedUsers"
      :headers="headers"
      :items="filteredUsers"
      item-key="email"
      :item-class="getItemClass"
      :items-per-page="-1"
      :singleSelect="isSingleSelect"
      @click:row="select"
      show-select
      hide-default-footer
      fixed-header>
      <template v-slot:[`header.data-table-select`]="{ props, on }">
        <v-simple-checkbox
          v-if="!isSingleSelect"
          color="primary"
          :value="props.value || props.indeterminate"
          :indeterminate="props.indeterminate"
          @input="on.input"
          hide-details />
      </template>
      <template
        v-slot:[`item.data-table-select`]="{ isSelected, select, item }">
        <v-simple-checkbox
          v-if="isAutoMatch || !isMatchedUser(item) || isClickedUser(item)"
          color="primary"
          :value="isSelected"
          @input="select">
        </v-simple-checkbox>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <Avatar :imgSrc="item.imageURL" left />
        {{ item.name }}
      </template>
      <template v-slot:[`item.isActive`]="{ item }">
        {{ item.isActive ? $lang.general_Yes : $lang.general_No }}
      </template>
    </v-data-table>
    <template v-slot:actions v-if="!isAmbassador">
      <v-btn color="primary" outlined @click="$emit('addManually')">
        <v-icon color="blue lighten-1">$plus</v-icon>
        {{ $lang.addManuallyModal_title }}
      </v-btn>

      <v-btn
        color="primary"
        depressed
        @click="selectUsers"
        :disabled="!validSelections.length">
        {{ $lang.general_Select }}
      </v-btn>
    </template>
  </p-modal>
</template>

<script>
  import MultiUserSelectSearch from "@/components/dashboard/outreach/outreachComponents/MultiUserSelectSearch.vue";
  import Avatar from "@/components/common/vuetify/Avatar.vue";

  export default {
    name: "MultiUserSelectModal",
    components: {
      MultiUserSelectSearch,
      Avatar,
    },
    props: {
      value: {
        type: Boolean,
        default: false,
      },

      isAmbassador: {
        type: Boolean,
        default: true,
      },

      users: {
        type: Array,
        default: () => [],
      },

      matchedUsers: {
        type: Array,
        default: () => [],
      },

      clickedUsers: {
        type: Array,
        default: () => [],
      },

      headers: {
        type: Array,
        required: true,
      },

      isSingleSelect: {
        type: Boolean,
        default: true,
      },

      isAutoMatch: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        singleSelect: false,
        selectedUsers: [],
        filter: { property: "name", searchString: null },
      };
    },

    computed: {
      title() {
        return this.isAmbassador
          ? this.$lang.general_SelectVar.format(this.$lang.general_Ambassador)
          : this.$lang.general_SelectVar.format(this.$lang.campaign_Recipients);
      },

      modalSubtitle() {
        return this.$lang.campaignOutreach_SelectUserModalSubtitle.format(
          this.matchedUsers.length,
          this.users.length,
          this.isAmbassador
            ? this.$lang.outreachPage_TableAmbassadorCount.toLowerCase()
            : this.$lang.campaign_Recipients.toLowerCase()
        );
      },

      filteredUsers() {
        const { property, searchString } = this.filter;
        const sortedUsers = [...this.users].sort(
          (userA, userB) =>
            this.isDisabledUser(userA) - this.isDisabledUser(userB)
        );
        if (property && searchString) {
          return sortedUsers.filter((user) => {
            if (user[property]) {
              return user[property]
                .toString()
                .toLowerCase()
                .includes(searchString);
            } else {
              return false;
            }
          });
        }
        return sortedUsers;
      },

      validSelections() {
        return this.isAutoMatch
          ? this.selectedUsers
          : this.selectedUsers.filter(
              (user) =>
                !this.matchedUsers.some(
                  (matchedUser) => matchedUser.email === user.email
                )
            );
      },
    },

    watch: {
      clickedUsers(newValue) {
        if (newValue.length) {
          this.selectedUsers = [...newValue];
        }
      },
    },

    methods: {
      selectUsers() {
        this.$emit("selected", this.validSelections);
        this.selectedUsers = [];
        this.closeModal(false);
      },
      getItemClass(item) {
        return this.isDisabledUser(item) ? "current-user" : "selectable-user";
      },
      isMatchedUser(item) {
        return this.matchedUsers.some(
          (user) => JSON.stringify(user) === JSON.stringify(item)
        );
      },
      isClickedUser(item) {
        return this.clickedUsers.some(
          (user) => JSON.stringify(user) === JSON.stringify(item)
        );
      },
      isDisabledUser(item) {
        return (
          !this.isAutoMatch &&
          this.isMatchedUser(item) &&
          !this.isClickedUser(item)
        );
      },
      select(item) {
        let users = this.isAutoMatch ? this.users : this.selectedUsers;

        const index = users.findIndex(
          (user) => JSON.stringify(user) === JSON.stringify(item)
        );
        if (index < 0) {
          this.selectedUsers.push(item);
        } else {
          this.selectedUsers.splice(index, 1);
        }
      },
      closeModal(value) {
        this.singleSelect = false;
        this.selectedUsers = [];
        this.filter = { property: "name", searchString: null };
        this.$emit("input", value);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .multi-user-select {
    height: 80%;

    &-search {
      margin-top: 1.25rem;
    }

    &-table {
      margin-top: 1.625rem;

      ::v-deep {
        .v-data-table__wrapper {
          height: 50vh;
          border-radius: 20px;
          background-color: $grey-50;

          table {
            thead.v-data-table-header th {
              background-color: $grey-200;
            }
          }

          tbody {
            tr {
              &.v-data-table__selected {
                background-color: $blue-250;
              }

              &.current-user {
                background-color: $grey-200;
                cursor: not-allowed;
                &:hover {
                  background-color: $grey-200 !important;
                }
              }

              &.selectable-user {
                cursor: pointer;
              }

              &:not(:last-child) {
                border-bottom: thin dotted $black-100;
              }
            }
          }
        }

        .v-data-table__wrapper
          table
          tbody
          tr:not(:last-child)
          td:not(.v-data-table__mobile-row).text-start {
          border-bottom: none;
        }
      }
    }
  }
</style>
