<template>
  <div>
    <div data-vuetify>
      <v-app>
        <v-main>
          <v-container fluid>
            <v-row>
              <v-col class="outreach-page-container">
                <div class="outreach-header">
                  <h1>
                    {{ $lang.outreachPage_OutreachRequestsPageTitle }}
                  </h1>

                  <v-spacer />

                  <div class="outreach-buttons">
                    <v-btn
                      color="primary"
                      outlined
                      depressed
                      @click="$router.push({ name: 'Outreach Campaign' })">
                      <v-icon left>$account-multiple</v-icon>
                      {{ $lang.outreachPage_BulkOutreachBtn }}
                    </v-btn>
                    <v-btn
                      color="primary"
                      outlined
                      depressed
                      @click="individualOutreachModal = true">
                      <v-icon left>$account</v-icon>
                      {{ $lang.outreachPage_IndividualOutreachBtn }}
                    </v-btn>
                  </div>
                </div>

                <v-tabs v-model="tab" center-active show-arrows>
                  <v-tab
                    :href="tab.href"
                    v-for="tab in tabs"
                    :key="`tab-${tab.title}`">
                    {{ tab.title }}
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab" v-if="ready">
                  <v-tab-item
                    class="outreach-tab-item"
                    :value="tab.value"
                    v-for="tab in tabs"
                    :key="`tab-item-${tab.title}`">
                    <component
                      :is="tab.component"
                      :campaigns="allCampaigns"
                      :outreachRequests="allOutreachRequests"
                      @delete-campaign="deleteCampaign" />
                  </v-tab-item>
                </v-tabs-items>

                <v-skeleton-loader
                  v-else
                  type="table"
                  class="outreach-tab-item" />

                <RequestOutreachModal v-model="individualOutreachModal" />
              </v-col>
            </v-row>
          </v-container>
        </v-main>
      </v-app>
    </div>
  </div>
</template>

<script>
  import AllOutreachTab from "./outreachComponents/AllOutreachRequestsTab.vue";
  import IndividualOutreachTab from "./outreachComponents/IndividualOutreachRequestsTab.vue";
  import CampaignsTab from "./outreachComponents/CampaignsTab.vue";
  import CampaignDraftsTab from "./outreachComponents/CampaignDraftsTab.vue";
  import ScheduledCampaignsTab from "./outreachComponents/ScheduledCampaignsTab.vue";
  import RequestOutreachModal from "@/components/common/tailwind/modals/RequestOutreachModal.vue";
  import api from "@/services/api/index.js";
  import inviteTypes from "@/constants/inviteTypes";
  import { toFullName } from "@/shared/utils";
  import { mapGetters } from "vuex";

  const dashboardApi = api.dashboard;

  export default {
    components: {
      AllOutreachTab,
      IndividualOutreachTab,
      CampaignsTab,
      CampaignDraftsTab,
      RequestOutreachModal,
    },

    data() {
      return {
        ready: false,
        tabs: [
          {
            title: this.$lang.outreachPage_CampaignsTab,
            href: "#Campaigns",
            value: "Campaigns",
            component: CampaignsTab,
          },
          {
            title: this.$lang.outreachPage_IndividualOutreachRequestsTab,
            href: "#IndividualOutreach",
            value: "IndividualOutreach",
            component: IndividualOutreachTab,
          },
          {
            title: this.$lang.outreachPage_AllOutreachRequestsTab,
            href: "#AllOutreach",
            value: "AllOutreach",
            component: AllOutreachTab,
          },
          {
            title: this.$lang.outreachPage_CampaignDraftsTab,
            href: "#CampaignDrafts",
            value: "CampaignDrafts",
            component: CampaignDraftsTab,
          },
          {
            title: this.$lang.outreachPage_ScheduledCampaignsTab,
            href: "#ScheduledCampaigns",
            value: "ScheduledCampaigns",
            component: ScheduledCampaignsTab,
          },
        ],
        allOutreachRequests: [],
        allCampaigns: [],
        individualOutreachModal: false,
      };
    },

    computed: {
      tab: {
        get() {
          const hash = this.$route.hash.slice(1);
          return hash || this.tabs[0].href;
        },
        set(val) {
          this.$router.replace({ hash: "#" + val });
        },
      },

      ...mapGetters({ branding: "getBranding", activeYear: "getActiveYear" }),
    },

    async mounted() {
      await Promise.all([this.getOutreachRequests(), this.getCampaigns()]);

      const campaignsMap = new Map();
      for (const campaign of this.allCampaigns) {
        campaign.adminName = this.toFullName(campaign.adminID);
        campaignsMap.set(campaign.id, campaign);
      }

      for (const outreach of this.allOutreachRequests) {
        if (outreach.campaignID) {
          const campaign = campaignsMap.get(outreach.campaignID);
          if (campaign) {
            outreach.campaignId = campaign._id;
            outreach.title = campaign.title;

            // set campaign outreach invites to same date as campaign
            outreach.sentAt = campaign.sentAt;
            outreach.createdAt = campaign.createdAt;
          }
        } else {
          outreach.sentAt = outreach.createdAt;
        }
        outreach.inviterName = this.toFullName(outreach.inviterID);
        outreach.inviteeName = this.toFullName(outreach.invitee);

        /*
         * status
         * email = inviteSent
         * call = hadCall
         * video = invitation
         */
        outreach.status = !!(
          outreach.inviteSent ??
          outreach.hadCall ??
          outreach.invitation
        );

        outreach.hasFeedback = !!outreach.feedback;
      }
      this.ready = true;
    },

    methods: {
      getOutreachRequests() {
        return dashboardApi
          .getAllOutreachRequests({
            selectedYear: this.activeYear,
            orgName: this.branding.name,
          })
          .then((response) => {
            if (response?.status === 200) {
              const data = response.data;

              const outreachRequests = [];

              for (const invite of data.emailInvitations) {
                invite.inviteType = inviteTypes.EMAIL;
                outreachRequests.push(invite);
              }
              for (const invite of data.callInvitations) {
                invite.inviteType = inviteTypes.CALL;
                outreachRequests.push(invite);
              }

              for (const invite of data.videoInvitations) {
                invite.inviteType = inviteTypes.VIDEO;
                invite.accountMade = !!invite.invitation?.acceptedUserId;
                outreachRequests.push(invite);
              }

              // deleted users
              for (const outreach of outreachRequests) {
                outreach.inviterID = outreach.inviterID || {};
                outreach.invitee = outreach.invitee || {};
              }

              this.allOutreachRequests = outreachRequests;
            }
          });
      },
      getCampaigns() {
        return dashboardApi
          .getCampaigns(this.branding.name, this.activeYear)
          .then((response) => {
            if (response?.status === 200) {
              this.allCampaigns = response.data;
            }
          });
      },
      async deleteCampaign(id) {
        const response = await dashboardApi.deleteCampaign({
          id,
        });
        this.deleteCampaignModal = false;

        if (response?.status === 200) {
          const index = this.allCampaigns.findIndex(
            (campaign) => campaign.id === id
          );
          this.allCampaigns.splice(index, 1);

          this.$toasted.show(
            this.$lang.outreachPage_DeleteCampaignSuccess,
            this.$toastedSuccess
          );
        } else {
          this.$toasted.show(
            this.$lang.general_ToastError,
            this.$toastedFailure
          );
        }
      },
      toFullName(user) {
        user = user || {};
        return toFullName(
          user.first,
          user.last,
          this.$lang.general_DeletedUser
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  .outreach-page-container {
    margin: 40px 10px;
  }

  .outreach-header {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 48px;

    @media (max-width: $md) {
      flex-direction: column;
    }
  }
  .outreach-buttons {
    display: flex;
    gap: 10px;

    @media (max-width: $xs) {
      flex-direction: column;
    }
  }

  .outreach-tab-item {
    border-top: 1px solid $grey-200;
    padding-top: 17px;
    margin-top: 5px;
  }
</style>
