import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"data-vuetify":""}},[_c(VApp,[_c(VMain,[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"outreach-page-container"},[_c('div',{staticClass:"outreach-header"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$lang.outreachPage_OutreachRequestsPageTitle)+" ")]),_c(VSpacer),_c('div',{staticClass:"outreach-buttons"},[_c(VBtn,{attrs:{"color":"primary","outlined":"","depressed":""},on:{"click":function($event){return _vm.$router.push({ name: 'Outreach Campaign' })}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("$account-multiple")]),_vm._v(" "+_vm._s(_vm.$lang.outreachPage_BulkOutreachBtn)+" ")],1),_c(VBtn,{attrs:{"color":"primary","outlined":"","depressed":""},on:{"click":function($event){_vm.individualOutreachModal = true}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("$account")]),_vm._v(" "+_vm._s(_vm.$lang.outreachPage_IndividualOutreachBtn)+" ")],1)],1)],1),_c(VTabs,{attrs:{"center-active":"","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab){return _c(VTab,{key:("tab-" + (tab.title)),attrs:{"href":tab.href}},[_vm._v(" "+_vm._s(tab.title)+" ")])}),1),(_vm.ready)?_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab){return _c(VTabItem,{key:("tab-item-" + (tab.title)),staticClass:"outreach-tab-item",attrs:{"value":tab.value}},[_c(tab.component,{tag:"component",attrs:{"campaigns":_vm.allCampaigns,"outreachRequests":_vm.allOutreachRequests},on:{"delete-campaign":_vm.deleteCampaign}})],1)}),1):_c(VSkeletonLoader,{staticClass:"outreach-tab-item",attrs:{"type":"table"}}),_c('RequestOutreachModal',{model:{value:(_vm.individualOutreachModal),callback:function ($$v) {_vm.individualOutreachModal=$$v},expression:"individualOutreachModal"}})],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }