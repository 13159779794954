<template>
  <div :class="['admission-year-container', { 'open-left': openLeft }]">
    <!-- Admission Year Button -->
    <div class="admission-year-button" @click="toggleSubmenu('year')">
      <span class="year-selection-button">
        <p>{{ activeYear }}</p>
        <p></p>
        <p class="year-selection-separator">-</p>
        <p>{{ activeYear + 1 }}</p>
      </span>

      <ppicon class="caret-icon" name="chevron-right" />
    </div>

    <!-- Admission Year Menu -->
    <div
      :class="[
        'admission-year-menu-wrapper',
        {
          hidden: subMenuOpened !== 'year',
        },
      ]">
      <ul class="admission-year-menu">
        <li
          v-for="year of years"
          :key="year"
          :class="[
            'admission-year-menu-item',
            { 'active-year': activeYear === year },
          ]"
          @click="updateActiveYear(year)">
          {{ year }} - {{ year + 1 }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import { GET_ACTIVE_YEAR, GET_BRANDING } from "@/store/getters.type";
  import { SET_ACTIVE_YEAR } from "@/store/actions.type";

  export default {
    props: {
      subMenuOpened: {
        type: String,
        default: null,
      },

      openLeft: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        years: [],
      };
    },

    computed: {
      ...mapGetters({
        activeYear: GET_ACTIVE_YEAR,
        getBranding: GET_BRANDING,
      }),
    },

    watch: {
      getBranding() {
        this.setAdmissionYears();
      },
    },

    created() {
      this.setAdmissionYears();
    },

    methods: {
      setAdmissionYears() {
        const today = new Date();
        let endYear = today.getFullYear();

        const changeOver = this.getBranding.changeOverDate;
        const changeOverDate = new Date(
          today.getFullYear(),
          changeOver[0] - 1,
          changeOver[1]
        );

        if (today < changeOverDate) {
          endYear--;
        }

        this.years = [];
        for (let year = 2019; year <= endYear; year++) {
          this.years.push(year);
        }
      },

      toggleSubmenu(menu) {
        this.$emit("toggle-submenu", menu);
      },

      async updateActiveYear(year) {
        if (this.activeYear === year) {
          this.toggleSubmenu(null);
          return;
        }

        this.$store.dispatch(SET_ACTIVE_YEAR, year);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .admission-year-container {
    display: flex;
    align-items: center;

    &.open-left .admission-year-menu-wrapper {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }
  }

  .admission-year-button {
    margin-left: 1rem;
    margin-right: 1rem;
    font-weight: 700;
    color: $blue-400;
    font-size: 0.75rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    .caret-icon {
      height: 1rem;
      width: 1rem;
      display: flex;
      align-items: center;
    }
  }

  .year-selection-button {
    display: flex;
  }

  .year-selection-separator {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }

  .admission-year-menu-wrapper {
    position: absolute;
    left: 12rem;
    box-shadow: $box-shadow-lg;
    font-size: 0.75rem;
    line-height: 1rem;
    background-color: $white;
    border: 1px solid $grey-200;
    width: 7rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    z-index: 50;
    top: 5.5rem;

    @media screen and (max-width: $sm) {
      left: initial;
      right: 1rem;
      top: 3.5rem;
    }
  }

  .admission-year-menu {
    display: flex;
    flex-direction: column;
  }

  .admission-year-menu-item {
    padding-left: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 2.5rem;

    &:hover {
      background-color: $grey-100;
    }
    &.active-year {
      color: $blue-400;
      font-weight: 500;
      cursor: default;
    }
  }
</style>
