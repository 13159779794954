<template>
  <div class="super-admin">
    <AddSuperAdminModal
      v-if="addSuperAdminModalOpened"
      @close="addSuperAdminModalOpened = false" />
    <!-- Header -->
    <div class="super-admin-header">
      <div class="super-admin-header-tools">
        <div>
          <h1 class="super-admin-title h0">
            {{ $lang.superAdminPanel_Title }}
          </h1>
          <p class="font-secondary">
            {{ $lang.superAdminPanel_Subtext }}
          </p>
        </div>

        <div class="super-admin-tools">
          <common-button
            variant="primary"
            class="super-admin-add"
            @click="addSuperAdminModalOpened = true"
            icon>
            <template slot="icon">
              <ppicon name="plus" />
            </template>
            {{ $lang.superAdminPanel_Add }}
          </common-button>
          <common-input
            variant="search"
            :placeholder="$lang.general_SearchPlaceholder"
            @input="debounceSearch"
            icon>
            <img :src="require('@/assets/search-icon.svg')" class="h-4 w-4" />
          </common-input>
        </div>
      </div>

      <TableHeaders
        v-if="displayedOrganizations.length"
        :headers="headers"
        v-model="activeSort" />
    </div>

    <!-- Table -->
    <div v-if="displayedOrganizations.length >= 0">
      <SuperAdminAccordion
        v-for="organization of displayedOrganizations"
        :key="organization.id"
        :features="features"
        :organization="organization"
        @feature-toggle="handleFeatureToggle($event)" />
    </div>
    <div v-else>
      <div class="super-admin-load-row">
        <div class="super-admin-load-cell" />
        <div class="super-admin-load-cell" />
      </div>
      <div class="super-admin-load-row">
        <div class="super-admin-load-cell body" />
        <div class="super-admin-load-cell body" />
      </div>
    </div>
  </div>
</template>

<script>
  const SEARCH_DEBOUNCE = 150;

  import {
    VERIFY_SUPER_ADMIN,
    SUPER_ADMIN_GET_ALL_ORGANIZATIONS,
    FETCH_BRANDING,
  } from "@/store/actions.type";
  import screens from "@/constants/screens";
  import sortService from "@/services/sort.service";
  import AddSuperAdminModal from "./AddSuperAdminModal.vue";
  import TableHeaders from "@/components/common/TableHeaders.vue";
  import SuperAdminAccordion from "@/components/dashboard/superAdmin/SuperAdminAccordion.vue";
  import apiService from "@/services/api/dashboard.service.js";

  export default {
    components: {
      AddSuperAdminModal,
      TableHeaders,
      SuperAdminAccordion,
    },

    data() {
      return {
        organizations: [],
        searchParameter: "",
        addSuperAdminModalOpened: false,
        features: [
          {
            label: this.$lang.superAdminPanel_ConversationExport,
            key: "allowConversationExport",
          },
          {
            label: this.$lang.superAdminPanel_ProfileVideo,
            key: "allowProfileVideo",
          },
          {
            label: this.$lang.superAdminPanel_VideoOutreach,
            key: "allowVideoOutreach",
          },
        ],
        headers: [
          { name: this.$lang.superAdminPanel_TableHeaderLogo },
          {
            name: this.$lang.superAdminPanel_TableHeaderName,
            sortProperty: "name",
            type: "string",
          },
          {
            name: this.$lang.superAdminPanel_TableHeaderPremiumFeatures,
            sortProperty: "activePremiumFeatures",
            type: "number",
          },
        ],
        activeSort: {
          sortProperty: "name",
          type: "string",
          reverse: false,
        },
        note: "",
      };
    },

    computed: {
      searchedOrganizations() {
        if (!this.searchParameter) {
          return this.organizations;
        }

        const search = this.searchParameter.toLowerCase();

        return this.organizations.filter((org) => {
          return (
            org.name.toLowerCase().includes(search) ||
            org.url.toLowerCase().includes(search) ||
            org.displayName?.toLowerCase().includes(search) ||
            org.notes?.some((note) => note.text.toLowerCase().includes(search))
          );
        });
      },

      displayedOrganizations() {
        const enrichedOrganizations = this.searchedOrganizations.map(
          (organization) => ({
            ...organization,
            activePremiumFeatures: this.getActivePremiumFeatures(organization),
          })
        );
        return sortService.sortArrayOfObjects(
          enrichedOrganizations,
          this.activeSort.sortProperty,
          this.activeSort.type,
          this.activeSort.reverse
        );
      },
    },

    beforeCreate() {
      this.$store.dispatch(VERIFY_SUPER_ADMIN).then((response) => {
        if (!response) {
          this.$router.push({ name: screens.HOME_SCREEN });
        }
      });
    },

    async created() {
      this.$store
        .dispatch(SUPER_ADMIN_GET_ALL_ORGANIZATIONS)
        .then((response) => {
          this.organizations = response || [];
        });
    },

    methods: {
      handleFeatureFlagUpdate(orgName, features) {
        //Set feature flags, update branding to pull feature configuration
        for (let i = 0; i < this.organizations.length; i++) {
          if (this.organizations[i].name === orgName) {
            let org = this.organizations[i];
            let featureList = {};
            for (const feature of features) {
              featureList[feature] = true;
            }
            org.features = featureList;
            this.$set(this.organizations, i, org);
            this.$store.dispatch(FETCH_BRANDING, orgName);
            return;
          }
        }
      },

      handleFeatureToggle(event) {
        const params = {
          orgName: event.organization.name,
          features: {},
        };
        params.features[event.option.key] = event.option.value;
        apiService.upsertFeatureFlags(params).then((response) => {
          this.handleFeatureFlagUpdate(params.orgName, response.data.features);
        });
      },

      getActivePremiumFeatures(organization) {
        return organization.features
          ? Object.values(organization.features).filter((value) => value).length
          : 0;
      },

      debounceSearch(value) {
        clearTimeout(this.searchTimeout);

        this.searchTimeout = setTimeout(() => {
          this.searchParameter = value;
        }, SEARCH_DEBOUNCE);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .super-admin {
    height: 100%;
    display: flex;
    flex-direction: column;
    --table-columns: 216px 400px auto 164px;
  }

  .super-admin-header {
    z-index: 1;
    background-color: $white;
    padding-top: 2rem;
    position: sticky;
    top: 0;
  }

  .super-admin-header-tools {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 30px;
  }

  .super-admin-title {
    margin-bottom: 16px;
  }

  .super-admin-tools {
    display: flex;
  }

  .super-admin-add {
    margin-right: 12px;
  }

  .super-admin-load-row {
    width: 100%;
    padding: 14px;
    display: inline-grid;
    grid-template-columns: 100px 100px 400px;
  }

  .super-admin-load-cell {
    display: flex;
    height: 1rem;
    width: 80%;
    -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    background-color: rgba(209, 216, 227, 1);
    border-radius: 9999px;

    &.body {
      border: solid 1px $grey-100;
      margin: 1rem 0 1rem 0;
      grid-column-start: span 3;
    }
  }

  @media (max-width: $xl) {
    .super-admin {
      --table-columns: 136px 400px auto 164px;
    }
  }
  @media (max-width: $lg) {
    .super-admin {
      --table-columns: 136px 230px auto 164px;
    }
  }
  @media (max-width: $md) {
    .super-admin {
      --table-columns: 80px 130px auto 132px;
    }
  }
  @media (max-width: $sm) {
    .super-admin {
      --table-columns: 80px auto 70px 164px;
    }

    .super-admin-header {
      top: 68px;
    }
  }

  @media (max-width: $xs) {
    .super-admin {
      --table-columns: 0px auto 0px 132px;
    }
  }
</style>
