<!-- Wrapper for Chart.js https://www.chartjs.org/docs/latest/ -->
<!-- Data Labels plugin https://chartjs-plugin-datalabels.netlify.app/ -->
<template>
  <canvas ref="chart"></canvas>
</template>

<script>
  // treeshaking only saves about 10 KB, but make this component harder to use
  import Chart from "chart.js/auto";
  import ChartDataLabels from "chartjs-plugin-datalabels";

  Chart.register(ChartDataLabels);

  export default {
    name: "Chart",
    props: {
      type: String,
      options: Object,
      data: Object,
    },
    data() {
      return {
        instance: null,
      };
    },
    watch: {
      data: {
        deep: true,
        handler() {
          if (this.instance) {
            this.instance.data = this.data;
            this.instance.update();
          }
        },
      },
    },
    mounted() {
      this.instance = new Chart(this.$refs.chart, {
        type: this.type,
        options: this.options,
        data: this.data,
      });
    },
    destroyed() {
      this.instance?.destroy();
    },
  };
</script>
