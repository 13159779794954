<template>
  <v-tooltip
    :open-on-click="false"
    :open-on-focus="false"
    top
    :disabled="disableTooltip">
    <template v-slot:activator="{ on }">
      <div
        class="type-button-wrapper"
        :class="{ disabled: disabled }"
        v-on="on">
        <button
          class="type-button"
          :class="{ active: isActive }"
          :disabled="disabled"
          @click="$emit('click')">
          <img :src="icon" class="image" :class="[text.toLowerCase()]" />
          <div class="title">
            {{ text }}
          </div>
        </button>
      </div>
    </template>
    <span>{{ title }}</span>
  </v-tooltip>
</template>

<script>
  export default {
    name: "CampaignTypeButton",
    props: {
      text: {
        type: String,
        required: true,
      },
      icon: {
        type: String,
        required: true,
      },
      isActive: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      disabledMessage: {
        type: String,
        default: "",
      },
    },

    computed: {
      disableTooltip() {
        return !this.disabled || !this.title;
      },

      title() {
        return this.disabled ? this.disabledMessage : "";
      },
    },
  };
</script>
<style lang="scss" scoped>
  .type-button-wrapper {
    flex-grow: 1;
    display: flex;

    .type-button {
      background-color: $grey-100;
      border-radius: 20px;
      flex-grow: 1;
      padding: 80px 8% 60px 8%;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      border: solid 1px $grey-100;
      user-select: none;
      transition: background-color var(--transition-duration) ease-in;

      &:hover:not([disabled]),
      &.active {
        border-color: $blue-300;
      }

      &.active {
        background-color: $blue-100;
      }

      .title {
        margin: 0 auto;
        font-weight: 500;
      }

      &:disabled {
        opacity: 0.7;
        cursor: default;
      }
    }
  }
</style>
