import * as JsSearch from "js-search";
function trimString(s) {
  let l = 0,
    r = s.length - 1;
  while (l < s.length && s[l] == " ") l++;
  while (r > l && s[r] == " ") r -= 1;
  return s.substring(l, r + 1);
}

function searchObjectValues(array, searchParameter, primaryKey = "_id") {
  if (!searchParameter) return [];
  const query = trimString(searchParameter);
  let options;

  // Create list of object keys automatically from first object. This assumes that all objects in array have same structure.
  if (array[0]) {
    options = Object.keys(array[0]);
  }

  var search = new JsSearch.Search(primaryKey);
  for (let option of options) {
    search.addIndex(option);
  }

  search.addDocuments(array);

  return search.search(query);
}

function searchObjectValuesByKeys(
  array,
  searchParameter,
  keysToSearchBy,
  primaryKey = "_id"
) {
  if (!searchParameter) return [];
  const query = trimString(searchParameter);
  let options = keysToSearchBy;

  var search = new JsSearch.Search(primaryKey);
  for (let option of options) {
    search.addIndex(option);
  }

  search.addDocuments(array);

  return search.search(query);
}

export function sortArrayOfObjects(
  data,
  propertyToSortBy,
  dataType,
  reverseSort = false
) {
  const sortedData = data.sort((a, b) => {
    let fa, fb;

    // A property allocation
    if (a[propertyToSortBy]) {
      switch (dataType) {
        case "number":
          fa = parseInt(a[propertyToSortBy]);
          break;
        case "boolean":
          fa = 0;
          break;
        case "date":
          fa = new Date(a[propertyToSortBy]);
          break;
        default:
          fa = a[propertyToSortBy].toLowerCase();
      }
    } else {
      switch (dataType) {
        case "number":
          fa = 0;
          break;
        case "boolean":
          fa = 1;
          break;
        case "date":
          const date = new Date();
          date.setFullYear(1000);
          fa = date;
          break;
        default:
          fa = "";
      }
    }

    // B property allocation
    if (b[propertyToSortBy]) {
      switch (dataType) {
        case "number":
          fb = parseInt(b[propertyToSortBy]);
          break;
        case "boolean":
          fb = 0;
          break;
        case "date":
          fb = new Date(b[propertyToSortBy]);
          break;
        default:
          fb = b[propertyToSortBy].toLowerCase();
      }
    } else {
      switch (dataType) {
        case "number":
          fb = 0;
          break;
        case "boolean":
          fb = 1;
          break;
        case "date":
          const date = new Date();
          date.setFullYear(1000);
          fb = date;
          break;
        default:
          fb = "";
      }
    }

    if (reverseSort) {
      if (fa < fb) return 1;
      if (fa > fb) return -1;
      return 0;
    } else {
      if (fa < fb) return -1;
      if (fa > fb) return 1;
      return 0;
    }
  });

  return sortedData;
}

export default {
  searchObjectValues,
  searchObjectValuesByKeys,
  sortArrayOfObjects,
};
