<template>
  <div class="w-full h-full">
    <!-- Header -->
    <div class="w-full flex flex-col border-b">
      <h1 class="text-2xl font-semibold">My Account</h1>
      <div class="mt-10 mb-2">
        <common-tabs :tabs="tabs" @tab-click="selectedTab = $event" />
      </div>
    </div>

    <!-- Profile Fields Tab -->
    <div v-if="selectedTab === 0" class="mt-12">
      <div class="flex flex-col lg:flex-row justify-between">
        <h1 class="text-2xl font-semibold">Account Information</h1>
      </div>
      <ul class="lg:w-3/4 xl:w-1/2 mt-6">
        <li class="mb-8">
          <p class="text-grey-500 text-sm font-semibold mb-2">First Name</p>
          <common-input v-model="firstName" />
        </li>

        <li class="mb-8">
          <p class="text-grey-500 text-sm font-semibold mb-2">Last Name</p>
          <common-input v-model="lastName" />
        </li>
      </ul>

      <common-button
        variant="primary"
        class="btn-save"
        @click="save"
        :loading="saving"
        :disabled="saving">
        Save
      </common-button>
    </div>

    <!-- Welcome Message Tab -->
    <div v-if="selectedTab === 1" class="mt-12">
      <div class="flex flex-col lg:flex-row justify-between">
        <h1 class="text-2xl font-semibold">Change Password</h1>
      </div>
      <form @submit.prevent="save">
        <ul class="lg:w-3/4 xl:w-1/2 mt-6 mb-4 flex flex-col">
          <li class="pb-8 border-b">
            <p class="text-grey-500 text-sm font-semibold">Current Password</p>
            <common-input
              type="password"
              v-model="currentPassword"
              key="current-password"
              autocomplete="current-password" />
          </li>
          <div class="pt-8">
            <li class="mb-8">
              <p class="text-grey-500 text-sm font-semibold mb-2">
                New Password
              </p>
              <common-input type="password" v-model="newPassword" />
            </li>

            <li class="mb-2">
              <p class="text-grey-500 text-sm font-semibold mb-2">
                Confirm New Password
              </p>
              <common-input type="password" v-model="confirmedPassword" />
            </li>

            <div class="h-2">
              <p
                v-if="newPassword !== confirmedPassword"
                class="text-xs text-red-400">
                Passwords do not match
              </p>
            </div>
          </div>
        </ul>
        <common-button variant="primary" class="btn-save"> Save </common-button>
      </form>
    </div>

    <div v-if="selectedTab === 2" class="mt-12">
      <NotificationSettings :userId="getCurrentUser._id" />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import APIService from "@/services/api/index";
  import { GET_CURRENT_USER } from "@/store/getters.type";
  import NotificationSettings from "@/components/main/NotificationSettings.vue";

  export default {
    components: { NotificationSettings },
    data() {
      return {
        tabs: ["Account Information", "My Password", "Notification Settings"],
        selectedTab: 0,
        firstName: "",
        lastName: "",
        email: "",
        currentPassword: "",
        newPassword: "",
        confirmedPassword: "",
        saving: false,
      };
    },
    methods: {
      async save() {
        this.saving = true;

        switch (this.selectedTab) {
          case 0:
            await this.saveProfile();
            break;
          case 1:
            await this.authenticate();
            break;
        }

        this.saving = false;
      },
      async saveProfile() {
        if (!this.firstName.length || !this.lastName.length)
          return this.$toasted.show(
            "A first and last name is required before saving.",
            this.$toastedFailure
          );
        let updatedCurrentUser = JSON.parse(
          JSON.stringify(this.getCurrentUser)
        );
        updatedCurrentUser = {
          ...updatedCurrentUser,
          first: this.firstName,
          last: this.lastName,
        };

        const response = await APIService.dashboard.update(updatedCurrentUser);
        if (response?.status === 200) {
          this.$toasted.show(
            "Admin account details updated.",
            this.$toastedSuccess
          );
        } else {
          this.$toasted.show(
            "Uh oh! There was an issue updating your admin account details.",
            this.$toastedFailure
          );
        }
      },
      async authenticate() {
        if (!this.newPassword.length)
          return this.$toasted.show(
            "You must enter a new password.",
            this.$toastedFailure
          );
        if (this.newPassword !== this.confirmedPassword)
          return this.$toasted.show(
            "Your passwords do not match.",
            this.$toastedFailure
          );
        const data = {
          email: this.email,
          password: this.currentPassword,
        };
        const response = await APIService.dashboard.authenticate(data);
        if (response?.status === 200) {
          this.changePassword();
        } else {
          this.$toasted.show(
            "The current password you entered was incorrect.",
            this.$toastedFailure
          );
        }
      },
      async changePassword() {
        const data = { password: this.newPassword };
        const response = await APIService.dashboard.updatePassword(data);
        if (response?.status === 200) {
          this.$toasted.show(
            "Password changed successfully.",
            this.$toastedSuccess
          );
          (this.currentPassword = ""),
            (this.newPassword = ""),
            (this.confirmedPassword = "");
        } else {
          this.$toasted.show(
            "Uh oh! There was an issue changing your password.",
            this.$toastedFailure
          );
        }
      },
    },
    computed: {
      ...mapGetters({ getCurrentUser: GET_CURRENT_USER }),
    },
    created() {
      this.firstName = this.getCurrentUser.first;
      this.lastName = this.getCurrentUser.last;
      this.email = this.getCurrentUser.email;
    },
  };
</script>

<style scoped>
  .btn-save {
    width: 10rem;
    margin: 2rem 0;
  }
</style>
