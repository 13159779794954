<template>
  <v-avatar :size="size" color="grey" :class="{ 'avatar-left': left }">
    <img v-if="imgSrc" :src="imgSrc" alt="avatar" class="avatar-img" />
    <v-icon v-else color="white" size="24">$account</v-icon>
  </v-avatar>
</template>

<script>
  export default {
    name: "common-avatar",
    props: {
      imgSrc: {
        type: String,
        required: false,
      },
      size: {
        type: String,
        default: "34",
      },
      left: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style scoped>
  .avatar-img {
    object-fit: cover;
  }
  .avatar-left {
    margin-right: 8px;
  }
</style>
