export default Object.freeze({
  HOME_SCREEN: "HOME_SCREEN",
  AMBASSADORS_SCREEN: "AMBASSADORS_SCREEN",
  PROSPECTS_SCREEN: "PROSPECTS_SCREEN",
  CONVERSATIONS_SCREEN: "CONVERSATIONS_SCREEN",
  OUTREACH_SCREEN: "OUTREACH_SCREEN",
  SETTINGS_SCREEN: "SETTINGS_SCREEN",
  PROFILE_SCREEN: "PROFILE_SCREEN",
  MESSAGING_SCREEN: "MESSAGING_SCREEN",
  SUPER_ADMIN_SCREEN: "SUPER_ADMIN_SCREEN",
});
