<template>
  <div class="email-verification-container">
    <navbar />

    <div class="email-verification-page" v-if="signupEmail">
      <h2>{{ $lang.emailVerificationPage_RegisterTitle }}</h2>
      <div class="form">
        <input
          type="text"
          :placeholder="$lang.emailVerificationPage_FirstNamePlaceholder"
          class="input"
          v-model="first" />
        <br />
        <input
          type="text"
          :placeholder="$lang.emailVerificationPage_LastNamePlaceholder"
          class="input"
          v-model="last" />
        <br />
        <input
          type="text"
          :placeholder="$lang.emailVerificationPage_EmailPlaceholder"
          class="input"
          v-model="email"
          disabled />
        <br />
        <input
          type="password"
          :placeholder="$lang.emailVerificationPage_PasswordPlaceholder"
          class="input"
          v-model="password"
          @keyup.enter="handleSignup" />
        <br />
        <common-button class="ls" @click="handleSignup">{{
          $lang.emailVerificationPage_CreateAccountButton
        }}</common-button>
      </div>
    </div>
    <div class="email-verification-page" v-else>
      {{ $lang.emailVerificationPage_LinkExpired }}
    </div>
  </div>
</template>

<script>
  import APIService from "@/services/api/index";
  import screens from "@/constants/screens";
  import Navbar from "@/components/login/loginComponents/NavBar.vue";
  import { GET_ACTIVE_YEAR } from "@/store/getters.type";
  import { mapGetters } from "vuex";

  export default {
    components: {
      Navbar,
    },
    data() {
      return {
        first: "",
        last: "",
        email: "",
        password: "",
        errorMessage: "",
        signupEmail: true,
        token: "",
        uid: "",
        emailMagic: null,
        expired: false,
      };
    },

    computed: {
      actualYear() {
        return this.$route.params.year || this.activeYear;
      },

      ...mapGetters({
        activeYear: GET_ACTIVE_YEAR,
      }),
    },

    async created() {
      this.emailMagic = this.$route.query.value;
      const data = { emailMagic: this.emailMagic };
      const response = await APIService.dashboard.adminVerify(data);
      if (response?.status === 200) {
        this.email = response.data.email;
        this.first = response.data.first;
        this.last = response.data.last;
      } else {
        this.signupEmail = false;
      }
    },

    methods: {
      isEmailValid(email) {
        const reg =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
        return email == "" ? false : reg.test(email) ? true : false;
      },
      switchSignupEmail() {
        this.signupEmail = !this.signupEmail;
      },
      handleSignup: async function () {
        //insert authentication and signup method here
        if (this.first && this.last && this.email && this.password) {
          const data = {
            password: this.password,
            emailMagic: this.emailMagic,
          };
          const response = await APIService.dashboard.handleSignup(data);
          if (response?.status === 200) {
            this.token = response.data.token;
            this.uid = response.data.id;
            const orgName = response.data.organizations[0];
            if (process.env.VUE_APP_API_KEY) {
              this.$amplitude.getInstance().setUserId(this.uid);
              let identify = new this.$amplitude.Identify()
                .set("org", this.org)
                .set("currParent", false)
                .set("newProcess", true);
              this.$amplitude.getInstance().identify(identify);
              this.$amplitude.getInstance().logEvent("SIGNED_UP");
            }
            this.$router.push(
              { name: screens.HOME_SCREEN },
              { params: { orgName } }
            );
          }
        }
      },
    },
  };
</script>

<style scoped>
  .email-verification-container {
    background-color: #f3fbfe;
    height: 100%;
  }

  .email-verification-page {
    max-width: 500px;
    min-width: 300px;
    background-color: white;
    margin: 100px auto 5px auto;
    padding: 25px 50px;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  }
  .email-verification-page h2 {
    font-size: 2em;
    margin: 20px 0 0 0;
  }
  .email-verification-page h3 {
    margin: 0 0 20px 0;
    font-weight: 500;
  }
  .form {
    box-sizing: border-box;
    clear: both;
    width: 100%;
  }
  .input {
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    margin-bottom: 10px;
    border: 2px solid white;
    border-radius: 5px;
    font-size: 15px;
    outline: none;
    transition: all 0.5s ease;
    background-color: #eeeeee;
  }
  .input:focus {
    border: 2px solid grey;
  }
  .ls {
    width: 40%;
    background: #007aff;
    height: 50px;
    border: 0;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 450;
    color: white;
    border-radius: 25px;
    margin-top: 20px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  }
  .ls:hover {
    transform: scale(1.06);
  }
  .ls:focus {
    outline: none;
  }
  .ls:active {
    opacity: 0.5;
  }
  @media (max-width: 460px) {
    .email-verification-page {
      margin-left: 10px;
      margin-right: 10px;
      padding: 25px 25px;
    }
  }
</style>
