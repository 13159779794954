<template>
  <div v-if="loginScreen" class="login-page">
    <h1>{{ $lang.login_SignIn }}</h1>
    <div class="login-page-body">
      <v-text-field
        class="login-page-input"
        v-model="email"
        :label="$lang.general_Email"
        placeholder=" "
        outlined
        hide-details
        dense
        autofocus
        :persistent-placeholder="autofill"
        @focus="autofill = false"
        ref="emailInput"></v-text-field>
      <v-text-field
        class="login-page-input"
        v-model="password"
        :label="$lang.general_Password"
        placeholder=" "
        @keyup.enter.native="handleLogin()"
        type="password"
        outlined
        hide-details
        dense
        :persistent-placeholder="autofill"
        @focus="autofill = false"
        ref="passwordInput"></v-text-field>
      <div class="login-page-google" ref="googleLoginBtn"></div>
    </div>
    <div class="login-page-footer">
      <v-btn
        class="login-page-button"
        color="primary"
        @click="handleLogin()"
        :loading="authenticating"
        :disabled="authenticating">
        {{ $lang.login_SignIn }}
        <v-icon>$chevron-right</v-icon>
      </v-btn>
      <v-btn
        class="login-page-button"
        text
        color="primary"
        @click="loginScreen = false">
        {{ $lang.login_ForgotPassword }}
      </v-btn>
      <p class="caption">
        {{ $lang.login_TOSPrefix }}
        <router-link :to="{ name: 'Terms' }" class="link">
          {{ $lang.general_TermsOfService }}
        </router-link>
      </p>
    </div>
  </div>
  <div v-else-if="!resetSent" class="login-page">
    <h1>{{ $lang.login_ResetPassword }}</h1>
    <div class="login-page-body">
      <p class="body-1">
        {{ $lang.login_ResetPasswordInstructions }}
      </p>
      <v-text-field
        class="login-page-input"
        :placeholder="$lang.general_Email"
        v-model="email"
        @keyup.enter.native="handleReset()"
        outlined
        hide-details
        dense />
    </div>
    <div class="login-page-reset-footer">
      <v-btn
        class="login-page-button"
        color="primary"
        @click="handleReset()"
        :loading="authenticating"
        :disabled="authenticating">
        {{ $lang.login_ResetPasswordSubmitBtn }}
      </v-btn>
      <v-btn
        class="login-page-button"
        text
        color="primary"
        @click="renderLogin()">
        {{ $lang.login_ResetPasswordBackBtn }}
      </v-btn>
    </div>
  </div>
  <div v-else class="login-page">
    <h1>{{ $lang.login_ResetPasswordSuccess }}</h1>
    <div class="login-page-body">
      <p class="body-1">
        {{ $lang.login_ResetPasswordSuccessInstructions }}
      </p>
    </div>
    <div class="login-page-footer">
      <v-btn
        class="login-page-button"
        text
        color="primary"
        @click="renderLogin()">
        {{ $lang.login_ResetPasswordBackBtn }}
      </v-btn>
    </div>
  </div>
</template>

<script>
  import APIService from "@/services/api/index";
  import screens from "@/constants/screens";
  import { GET_ACTIVE_YEAR } from "@/store/getters.type";
  import { mapGetters } from "vuex";

  export default {
    name: "Login",
    data() {
      return {
        email: null,
        password: null,
        loginScreen: true,
        resetSent: false,
        authenticating: false,
        autofill: false,
        autofillInterval: null,
      };
    },

    computed: {
      actualYear() {
        return this.$route.params.year || this.activeYear;
      },

      ...mapGetters({
        activeYear: GET_ACTIVE_YEAR,
      }),
    },

    mounted() {
      if (!window.google) {
        const googleClient = document.createElement("script");
        googleClient.setAttribute(
          "src",
          "https://accounts.google.com/gsi/client"
        );
        googleClient.async = true;
        googleClient.defer = true;
        googleClient.onload = this.loadGoogleSignIn;
        document.head.appendChild(googleClient);
      } else {
        this.loadGoogleSignIn();
      }

      // Hack to avoid label appearing on top of autofill text in chrome
      let i = 0;
      const emailInput = this.$refs.emailInput.$refs.input;
      const passwordInput = this.$refs.passwordInput.$refs.input;

      this.autofillInterval = setInterval(() => {
        if (
          emailInput.matches(":-webkit-autofill") ||
          passwordInput.matches(":-webkit-autofill")
        ) {
          this.autofill = true;
          clearInterval(this.autofillInterval);
        }
        if (i++ > 100) {
          clearInterval(this.autofillInterval);
        }
      }, 100);
    },

    beforeDestroy() {
      clearInterval(this.autofillInterval);
    },

    methods: {
      async loadGoogleSignIn() {
        const google = window.google.accounts.id;

        google.initialize({
          client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
          callback: (response) => {
            this.handleLogin(response.credential);
          },
        });
        google.renderButton(
          this.$refs.googleLoginBtn,
          { theme: "outline", size: "large", width: 320, shape: "pill" } // customization attributes
        );

        // One Tap dialog
        google.prompt();
      },
      async handleLogin(googleToken) {
        this.authenticating = true;

        if (!googleToken && (!this.email || !this.password)) {
          return (this.authenticating = false);
        }

        const response = await APIService.dashboard.authenticate({
          googleToken,
          email: this.email,
          password: this.password,
        });

        if (response?.status === 200) {
          this.uid = response.data._id;
          this.token = response.data.token;
          this.organization = response.data.organizations[0];
          this.url = response.data.url;

          this.amplitudeIdentify(response.data.main);

          if (this.token) {
            this.$router
              .push(
                this.$route.query.redirect || {
                  name: screens.HOME_SCREEN,
                  params: { orgName: this.organization, year: this.actualYear },
                }
              )
              // this catch suppresses the redirect error
              .catch(() => {});
          } else {
            this.$toasted.show(
              this.$lang.general_ErrorMessage,
              this.$toastedFailure
            );
          }
        } else if (response?.status === 401) {
          if (googleToken) {
            this.$toasted.show(
              this.$lang.login_NoGoogleAccount,
              this.$toastedFailure
            );
          } else {
            this.$toasted.show(
              this.$lang.login_InvalidLogin,
              this.$toastedFailure
            );
          }
        } else if (response?.status === 303 && response?.message) {
          const toastOpts = {
            ...this.$toastedFailure,
            duration: 30000,
            action: [
              {
                text: this.$lang.login_WrongSiteLink,
                href: response.message,
              },
            ],
          };
          this.$toasted.show(this.$lang.login_WrongSiteMessage, toastOpts);
        } else {
          this.$toasted.show(
            this.$lang.general_ErrorMessage,
            this.$toastedFailure
          );
        }

        this.authenticating = false;
      },
      // login helper functions to initialize identify admin
      amplitudeIdentify(mainAdmin) {
        if (process.env.VUE_APP_API_KEY) {
          this.$amplitude.getInstance().setUserId(this.uid);
          let identify = new this.$amplitude.Identify()
            .set("org", this.organization)
            .set("mainAdmin", mainAdmin);
          this.$amplitude.getInstance().identify(identify);
          this.$amplitude.getInstance().logEvent("LOGGED_IN");
        }
      },
      async handleReset() {
        if (!this.email) {
          return;
        }

        const response = await APIService.dashboard.forgotPassword({
          email: this.email,
        });
        if (response?.status === 200) {
          this.resetSent = true;
        } else {
          this.$toasted.show(
            this.$lang.general_ErrorMessage,
            this.$toastedFailure
          );
        }
      },
      renderLogin() {
        this.loginScreen = true;
        this.$nextTick(() => this.loadGoogleSignIn());
      },
    },
  };
</script>

<style scoped lang="scss">
  .login-page,
  .login-page-reset {
    box-sizing: content-box;
    width: 320px;
    margin: auto;
    padding: 5rem;
    display: flex;
    flex-direction: column;

    @media (max-width: $xs) {
      margin: 0.5rem auto 0;
    }

    & h1 {
      text-align: center;
    }

    &-body {
      margin-top: 1.75rem;
      display: flex;
      flex-direction: column;
      gap: 2vh;

      p {
        margin: 0;
      }
    }

    &-input {
      box-sizing: border-box;
    }

    &-button {
      margin: 0.25rem auto 0 auto;
    }

    &-footer {
      margin-top: 2vh;
      display: flex;
      flex-direction: column;
    }

    &-google {
      height: 2.5rem;
    }

    & p {
      text-align: center;
    }
  }

  .forgot-password-btn {
    color: $blue-400;
    font-size: 0.875rem;
  }

  .link {
    color: $blue-400;
  }
</style>
