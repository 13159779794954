// Dashboard Main Getters
export const GET_ACTIVE_YEAR = "getActiveYear";
export const GET_CURRENT_USER = "getCurrentUser";
export const GET_LAST_FETCHED = "getLastFetched";

// Dashboard Prospect Getters
export const GET_PROSPECTS = "getProspects";

// Dashboard Ambassador Getters
export const GET_CURRENT_PARENTS = "getCurrentParents";
export const GET_INVITED_AMBASSADORS = "getInvitedAmbassadors";
export const GET_AMBASSADORS_AVAILABLE = "getAmbassadorsAvailable";

// Dashboard Administrator Getters
export const GET_ADMINS = "getAdmins";

// Dashboard Branding Getters
export const GET_BRANDING = "getBranding";

// Dashboard Conversation Getters
export const GET_CONVERSATIONS = "getConversations";

// Outreach Getters
export const GET_CALL_OUTREACH = "getCallOutreach";
export const GET_EMAIL_OUTREACH = "getEmailOutreach";
export const GET_VIDEO_OUTREACH = "getVideoOutreach";
export const GET_VIDEO_REQUEST_OUTREACH = "getVideoRequestOutreach";
export const GET_VIDEO_SUBMISSION_OUTREACH = "getVideoSubmissionOutreach";
export const GET_PENDING_CALL_OUTREACH = "getPendingCallOutreach";

// Campaign Outreach Getters
export const GET_CAMPAIGNS = "getCampaigns";

// Home Screen Getters
export const GET_VISITOR_VIEWS = "getVisitorViews";
export const GET_ENGAGEMENT_STATS = "getEngagementStats";
export const GET_ENGAGEMENT_GRAPH = "getEngagementGraph";
export const GET_PENDING_VIDEO_INVITATIONS = "getPendingVideoInvitations";
export const GET_PENDING_VIDEO_STORIES = "getPendingVideoStories";
export const GET_CONVERSATION_GRAPH = "getConversationGraph";
