<template>
  <div class="h-screen flex flex-col">
    <div
      class="h-max bg-blue flex items-center text-white space-x-7 sticky top-0">
      <router-link :to="{ name: 'Dashboard' }">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="ml-4 h-6 w-6 hover:bg-blue-500 rounded cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 19l-7-7 7-7" />
        </svg>
      </router-link>
      <h2 class="h-16 flex items-center text-lg font-bold">Messages</h2>
    </div>
    <ul class="border border-r flex-grow overflow-y-auto">
      <li
        class="py-3.5 hover:bg-grey-100 cursor-pointer"
        :class="{ 'active:bg-grey-100': convo.id === conversationID }"
        v-for="convo in conversations"
        v-bind:key="convo.message.conversationID"
        @click="cellPressed(convo.message.conversationID)">
        <div class="flex items-center space-x-2 overflow-hidden mr-4">
          <div class="mx-4 flex-none">
            <div
              v-if="convo.recipientImage"
              class="flex-shrink-0 overflow-hidden">
              <img
                :src="convo.recipientImage"
                class="w-12 h-12 object-cover rounded-full" />
            </div>
            <div
              v-else
              class="w-12 h-12 flex items-center justify-center text-sm text-grey bg-blue-200 rounded-full whitespace-nowrap">
              {{ convo.initials }}
            </div>
          </div>

          <div class="flex flex-col">
            <h4 class="mb-1 cursor-pointer">{{ convo.recipientName }}</h4>
            <label
              class="text-xs text-grey whitespace-nowrap mr-2 cursor-pointer"
              >{{ convo.message.body }}</label
            >
            <label class="text-xs text-grey cursor-pointer">{{
              convo.message.displayDateTime
            }}</label>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import APIService from "@/services/api/index";
  import dateService from "@/services/date.service";

  export default {
    props: ["conversationID"],
    data() {
      return {
        token: null,
        conversations: [],
      };
    },
    methods: {
      cellPressed(convoID) {
        this.$emit("showChat", [true, convoID, 0]);
      },
    },
    async created() {
      const response = await APIService.chat.getChat();
      if (response?.status === 200) {
        const convos = response.data.convos;

        convos.forEach((convo) => {
          const [first, last] = convo.recipientName.split(" ");
          const initials = (first[0] + last[0]).toLocaleUpperCase();

          const options = {
            hour: "numeric",
            minute: "numeric",
            month: "numeric",
            day: "numeric",
            year: "numeric",
          };

          const displayDateTime = dateService.toLocaleDateString(
            convo.message.createdDate,
            options
          );

          const cell = {
            message: {
              conversationID: convo.message.conversationId,
              body: convo.message.body,
              timestamp: convo.message.createdDate,
              displayDateTime,
            },
            recipientName: convo.recipientName,
            recipientImage: convo.recipientImage,
            initials: initials,
          };
          this.conversations.push(cell);
        });
        //sort convos by time
        let n = this.conversations.length;
        let i;

        for (i = 0; i < n - 1; i++) {
          // Find the max element in unsorted array
          let maxIndex = i;
          let j;
          for (j = i + 1; j < n; j++) {
            if (
              this.conversations[j].message.timestamp >
              this.conversations[maxIndex].message.timestamp
            ) {
              maxIndex = j;
            }
          }
          // Swap the found max element with the first element
          let temp = this.conversations[maxIndex];
          this.conversations[maxIndex] = this.conversations[i];
          this.conversations[i] = temp;
        }
      }
    },
  };
</script>
