<template>
  <label for="toggle" class="flex items-center">
    <div class="relative cursor-pointer border rounded" @click="emitToggle">
      <input
        type="checkbox"
        class="sr-only"
        :value="toggled"
        :checked="toggled"
      />
      <div
        class="block w-20 h-11 rounded transition ease"
        :class="{ 'bg-green': toggled, 'bg-gray-200': !toggled }"
      ></div>
      <div
        class="z-10 dot absolute left-0 top-0 bg-white w-10 h-11 transition rounded shadow-sm transition delay-125 ease"
      ></div>
      <p class="text-sm text-white font-semibold absolute top-3.5 left-2.5">
        AM
      </p>
      <p class="text-sm text-grey-500 font-semibold absolute top-3.5 right-2.5">
        PM
      </p>
    </div>
  </label>
</template>

<script>
  export default {
    name: "common-text-toggle",
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        toggled: this.value,
      };
    },
    methods: {
      emitToggle() {
        this.toggled = !this.toggled;
        this.$emit("toggle", this.toggled);
      },
    },
    watch: {
      value(newVal) {
        this.toggled = newVal;
      },
    },
  };
</script>

<style scoped>
  input:checked ~ .dot {
    transform: translateX(104%);
  }
</style>
