<template>
  <div>
    <div data-vuetify>
      <v-app>
        <v-main class="campaign-page">
          <v-skeleton-loader v-if="!data.campaign" type="table" />
          <ViewCampaign
            v-else-if="data.campaign.sentAt"
            :campaign="data.campaign"
            :invites="campaignInvitations"
            :prevRoute="prevRoute" />
          <CampaignStepper v-else :campaignData="data" />
        </v-main>
      </v-app>
    </div>
  </div>
</template>

<script>
  import CampaignStepper from "./CampaignSteps/CampaignStepper.vue";
  import ViewCampaign from "./outreachComponents/ViewCampaign.vue";
  import dashboardService from "@/services/api/dashboard.service";
  import { mapGetters } from "vuex";

  export default {
    name: "OutreachCampaign",
    components: { CampaignStepper, ViewCampaign },
    data() {
      return {
        loading: false,
        data: {
          campaign: null,
          pendingInvitations: [],
          invites: [],
          prevRoute: null,
        },
      };
    },
    computed: {
      campaignInvitations() {
        // Only get pending invitations that haven't been sent
        let additionalPendingInvitations = this.data.pendingInvitations.filter(
          (invitation) => {
            let inviterId = invitation.inviterID;

            if (
              invitation.inviterID &&
              typeof invitation.inviterID !== "string"
            ) {
              inviterId = invitation.inviterID._id;
            }

            return !this.data.invites.some(
              (sentInvite) => sentInvite.inviterID?._id === inviterId
            );
          }
        );
        return [...this.data.invites, ...additionalPendingInvitations];
      },

      ...mapGetters({ branding: "getBranding" }),
    },

    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.prevRoute = from;
      });
    },

    async created() {
      const campaignId = this.$route.params.campaignId;

      if (campaignId === undefined) {
        this.data.campaign = {
          title: this.$lang.campaign_New,
          organizationId: this.branding.id,
          sentAt: null,
          email: {
            subject: this.$lang.campaign_EmailSubject,
            body: this.$lang.campaign_EmailBody.format(
              this.branding.displayName || this.branding.name
            ),
          },
          autoAccept: false,
        };
      } else {
        dashboardService.getAllCampaignInfo(campaignId).then((response) => {
          if (response.status === 200) {
            if (this.$route.query.copy) {
              const data = response.data;
              let campaign = data.campaign;
              if (typeof campaign.scheduledFor === "string") {
                campaign.scheduledFor = new Date(campaign.scheduledFor);
              }

              // support legacy dynamicColumns
              const dynamicColumns = campaign.dynamicColumns.map((column) => {
                if (typeof column === "string") {
                  return {
                    isVisible: true,
                    name: column,
                  };
                }
                return column;
              });

              this.data.campaign = {
                title: campaign.title + " Copy",
                sentAt: null,
                inviteType: campaign.inviteType,
                email: campaign.email,
                dynamicColumns,
                organizationId: campaign.organizationId,
                csv: campaign.csv,
                csvFilename: campaign.csvFilename,
              };

              const invites = [];

              for (const invite of data.pendingInvitations) {
                invites.push({
                  inviterID:
                    typeof invite.inviterID === "string"
                      ? invite.inviterID
                      : invite.inviterID._id,
                  invitee: invite.invitee,
                  dynamicColumns: invite.dynamicColumns,
                });
              }
              this.data.pendingInvitations = invites;

              // treat like a new campaign
              this.$router.replace({
                params: { campaignId: undefined },
                query: undefined,
              });
            } else {
              if (typeof response.data.campaign.scheduledFor === "string") {
                response.data.campaign.scheduledFor = new Date(
                  response.data.campaign.scheduledFor
                );
              }

              response.data.pendingInvitations =
                response.data.pendingInvitations.map((invite) => {
                  return {
                    ...invite,
                    inviterID:
                      typeof invite.inviterID === "string"
                        ? invite.inviterID
                        : invite.inviterID._id,
                  };
                });
              this.data = response.data;
            }
          }
        });
      }
    },
  };
</script>

<style lang="scss" scoped>
  .campaign-page {
    margin-top: 40px;
  }
</style>
