<template>
  <div
    ref="modal"
    class="z-30 flex items-center justify-center absolute top-0 left-0 h-screen w-full"
    @keyup.esc="$emit('close')"
    tabindex="0">
    <div
      class="flex items-center justify-center bg-gray-800 bg-opacity-50 h-full w-full fixed">
      <div class="max-h-screen overflow-auto w-max">
        <div
          class="bg-white rounded-lg p-12 relative card"
          :class="[width, height]">
          <div class="absolute right-5 top-5" @click="closeModal">
            <svg
              class="ml-auto fill-current text-grey w-5 h-5 cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 18 18">
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
            </svg>
          </div>
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "common-modal",
    props: {
      headerText: {
        type: String,
      },
      height: {
        type: String,
        default: "h-common-modal",
      },
      width: {
        type: String,
        default: "md:w-common-modal",
      },
    },
    methods: {
      emitButtonClick(emitLabel) {
        this.$emit(emitLabel);
      },
      closeModal() {
        this.$emit("close");
      },
    },
    mounted() {
      document.body.style.overflow = "hidden";
      document.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          this.$emit("close");
        }
      });
    },
    beforeDestroy() {
      document.body.style.overflow = null;
      document.removeEventListener("keydown", () => {});
    },
    unmounted() {
      document.body.style.overflow = null;
    },
  };
</script>

<style lang="scss">
  @media (max-width: $sm) {
    .card {
      margin-top: 88px;
    }
  }
</style>
