<template>
  <span v-html="html()"></span>
</template>

<script>
  // entities to be escaped to prevent XSS
  // https://cheatsheetseries.owasp.org/cheatsheets/Cross_Site_Scripting_Prevention_Cheat_Sheet.html#output-encoding-for-html-contexts
  const entities = {
    "&": "&amp",
    "<": "&lt",
    ">": "&gt",
    '"': "&quot",
    "'": "&#x27",
  };
  const escapeRegex = new RegExp(
    "[" + Object.keys(entities).join("") + "]",
    "g"
  );

  // supported formatting
  const formats = [
    {
      // **bold**
      regex: /\*\*([^*]+?)\*\*/g,
      replace: (match, bold) => {
        return "<strong>" + bold + "</strong>";
      },
    },
    {
      // *italic*
      regex: /\*(.+?)\*/g,
      replace: (match, italic) => {
        return "<em>" + italic + "</em>";
      },
    },
  ];

  export default {
    methods: {
      // $slots is not reactive so a computed property will not re-evaluate
      // this must be a method so it re-evalutes when $slots changes
      // https://github.com/vuejs/vue/issues/3517
      html() {
        const text = this.$slots.default[0].text;
        if (typeof text.replace !== "function") return;

        let html = text.replace(escapeRegex, function (match) {
          return entities[match];
        });

        for (const format of formats) {
          html = html.replace(format.regex, format.replace);
        }
        return html;
      },
    },
  };
</script>
