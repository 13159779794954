<template>
  <div id="profile">
    <RequestOutreachModal
      v-model="requestOutreachModalOpened"
      :user="userAccount" />

    <SendMessageModal
      v-if="sendMessageModalOpened"
      @close="sendMessageModalOpened = false"
      :user="userAccount"
      :placeholder="sendMessagePlaceholder" />

    <EditStoryModal
      v-if="storyModalOpened"
      @close="storyModalOpened = false"
      @updateTextStory="updateAmbassadorStory"
      :user="userAccount" />

    <common-popup
      v-if="deleteAmbassadorPopupOpened"
      v-bind="deleteAmbassadorPopupProps"
      @first-button="deleteAmbassadorPopupOpened = false"
      @second-button="deleteAmbassador"
      @close="deleteAmbassadorPopupOpened = false"
      height="h-max" />

    <div class="w-full h-full lg:px-5 space-y-6 mb-12">
      <!-- Header -->
      <section class="w-full flex flex-col lg:flex-row pt-8 justify-between">
        <div class="flex items-center flex-none">
          <!-- <div class="flex space-x-4 items-center flex-none"> -->
          <div @click="goBack" class="cursor-pointer hover:text-grey">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7" />
            </svg>
          </div>

          <!-- hidden profile picture upload input -->
          <input
            type="file"
            @change="selectFile($event)"
            accept="image/png,image/jpg,image/jpeg"
            name="file"
            id="file"
            ref="fileupload"
            class="w-0 h-0 hidden" />

          <!-- Profile picture OR grey circle -->
          <div
            v-if="profileImage"
            class="flex-shrink-0 rounded-full overflow-hidden group hover:opacity-50 relative ml-4">
            <img :src="profileImage" class="w-20 h-20 object-cover" />
            <div
              class="absolute top-0 left-0 w-full h-full flex items-center justify-center invisible group-hover:visible text-white cursor-pointer text-sm"
              @click="profilePictureClick">
              <p>Edit</p>
            </div>
          </div>
          <div
            v-else
            class="w-20 h-20 bg-grey-300 rounded-full whitespace-nowrap group hover:opacity-50 relative ml-4">
            <div
              class="absolute top-0 left-0 w-full h-full flex items-center justify-center invisible group-hover:visible cursor-pointer text-sm"
              @click="profilePictureClick">
              <p>Edit</p>
            </div>
          </div>

          <div class="px-2 mx-2">
            <span class="flex items-center mb-1">
              <h3
                class="text-lg font-medium focus:outline-none border border-white border-dashed hover:border-grey-300 focus-within:border-grey-300 focus-within:border-solid cursor-text"
                contenteditable="true"
                ref="name-field"
                @click="focusName"
                @blur="updateName($event)">
                {{ firstName }} {{ lastName }}
              </h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="text-grey h-2 w-2 ml-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
              </svg>
            </span>
            <span class="flex items-center">
              <p
                v-if="emailEditable"
                class="text-xs focus:outline-none border border-white border-dashed hover:border-grey-300 focus-within:border-grey-300 focus-within:border-solid cursor-text"
                contenteditable="true"
                ref="email-field"
                @click="focusEmail"
                @blur="updateEmail($event)">
                {{ email }}
              </p>
              <p
                v-else
                contenteditable="false"
                class="text-xs focus:outline-none">
                {{ email }}
              </p>
              <svg
                v-if="emailEditable"
                xmlns="http://www.w3.org/2000/svg"
                class="text-grey h-2 w-2 ml-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
              </svg>
            </span>
          </div>
        </div>

        <div
          class="flex flex-col space-y-4 lg:flex-row lg:items-center lg:space-y-0 lg:space-x-6">
          <!-- Toggle Buttons -->
          <div
            class="flex lg:flex-col my-4 lg:my-0 text-xs text-grey-500 space-x-4 lg:space-x-0 lg:space-y-3 flex-none">
            <span class="flex items-center space-x-2">
              <p>Profile Visible</p>
              <common-toggle
                v-if="!userAccount.archived"
                :value="userAccount.isActive"
                @input="setAmbassador({ isActive: !userAccount.isActive })" />

              <img :src="require('@/assets/lock-icon.svg')" v-else />
            </span>
            <span class="flex items-center space-x-2">
              <p>Call Requests</p>
              <common-toggle
                v-if="!userAccount.archived"
                :value="userAccount.requestCall"
                @input="
                  setAmbassador({ requestCall: !userAccount.requestCall })
                " />

              <img :src="require('@/assets/lock-icon.svg')" v-else />
            </span>
          </div>

          <!-- CTA Buttons -->
          <div class="flex space-x-2 flex-none">
            <common-dropdown
              placeholder="Bulk Actions"
              variant="secondary"
              :button="{
                label: 'Filters',
                width: 'w-32',
                variant: 'tertiary',
                icon: true,
                iconOnly: true,
              }"
              maxHeight
              width="w-40"
              groups
              :options="
                userAccount.archived ? archivedDropdownOptions : dropdownOptions
              "
              @input="handleDropdownClick($event)">
              <template slot="icon">
                <img :src="require('@/assets/ellipsis-icon.svg')" />
              </template>
            </common-dropdown>
            <common-button
              width="auto"
              variant="secondary"
              @click="storyModalOpened = true"
              >Edit Story</common-button
            >
            <common-button
              width="auto"
              variant="primary"
              @click="requestOutreachModalOpened = true"
              >Request Outreach</common-button
            >
          </div>
        </div>
      </section>

      <!-- Profile Fields -->
      <section class="profile-fields">
        <div
          v-for="(field, index) of visibleFields"
          :key="field.question"
          class="profile-fields-field"
          @click="focusProfileFields(index)">
          <div class="flex items-center space-x-1">
            <p class="text-grey-500">{{ field.question }}</p>
            <ppicon name="pencil" class="profile-fields-icon" />
          </div>
          <div
            v-if="!profileLoading"
            :ref="`profile-field`"
            class="max-h-12 overflow-auto focus:outline-none"
            @blur="updateFieldAnswer(index, $event)"
            v-text="field.answer"
            contenteditable />
          <div v-else class="w-1/2 h-3 bg-grey rounded-full animate-pulse" />
        </div>
      </section>

      <!-- Save/Cancel options -->
      <section class="grid grid-cols-4">
        <div class="col-span-3 mx-2 py-4 lg:py-0" v-if="!saved">
          <div class="flex space-x-4 items-center flex-none">
            <common-button variant="secondary" @click="saveProfile"
              >Save Changes</common-button
            >
            <common-button variant="tertiary" @click="cancelProfileChanges"
              >Dismiss</common-button
            >
            <p class="text-xs text-grey-400 italic">
              Changes will not be applied until you save them
            </p>
          </div>
        </div>
      </section>

      <!-- Ambassador Insights -->
      <section>
        <p class="text-xs text-grey-500 font-bold mb-4">Ambassador Insights</p>
        <ProfileDataCards :data="dataCards" />
      </section>

      <!-- Conversations Table -->
      <section class="grid">
        <p class="text-xs text-grey-500 font-bold mb-4">Conversations</p>
        <ConversationsTable
          :rows="conversations"
          :userAccount="userAccount"
          :branding="getBranding"
          @close="closeConversation($event)" />
      </section>

      <!-- Outreach Requests Table -->
      <section class="grid">
        <p class="text-xs text-grey-500 font-bold mb-4">Outreach Requests</p>
        <OutreachRequestsTable
          :rows="outreachRequests"
          @send-message="
            openSendMessageModal(
              `Hi, ${userAccount.first}. I wanted to send a friendly reminder about the outreach request that I had sent last week. Thanks again for your help!`
            )
          "
          @delete-outreach="deleteOutreach($event)" />
      </section>
    </div>
  </div>
</template>

<script>
  import APIService from "@/services/api/index";
  import dateService from "@/services/date.service";
  import sortService from "@/services/sort.service";
  import profileService from "./profile.service";
  import validationService from "@/services/validations.service";
  import { mapGetters } from "vuex";
  import {
    FIND_AMBASSADOR,
    SET_AMBASSADOR,
    SET_AMBASSADORS,
    ARCHIVE_AMBASSADORS,
    DELETE_AMBASSADOR,
    RESTORE_AMBASSADORS,
    DELETE_OUTREACH,
    DELETE_VIDEO_OUTREACH_SUBMISSION,
  } from "@/store/actions.type";
  import {
    GET_CURRENT_PARENTS,
    GET_BRANDING,
    GET_CURRENT_USER,
    GET_CALL_OUTREACH,
    GET_EMAIL_OUTREACH,
    GET_VIDEO_OUTREACH,
    GET_VIDEO_REQUEST_OUTREACH,
    GET_VIDEO_SUBMISSION_OUTREACH,
    GET_PENDING_CALL_OUTREACH,
    GET_LAST_FETCHED,
  } from "@/store/getters.type";
  import { GET_AMBASSADORS_AVAILABLE } from "../../../store/getters.type";
  import screens from "@/constants/screens";
  import { resizeImage, toFullName } from "@/shared/utils";

  const { HOME_SCREEN, AMBASSADORS_SCREEN } = screens;

  const ConversationsTable = () =>
    import("./profileComponents/ConversationsTable");
  const OutreachRequestsTable = () =>
    import("./profileComponents/OutreachRequestsTable");
  const ProfileDataCards = () =>
    import("./profileComponents/ProfileDataCards.vue");
  const RequestOutreachModal = () =>
    import("@/components/common/tailwind/modals/RequestOutreachModal.vue");
  const SendMessageModal = () =>
    import("@/components/common/tailwind/modals/SendMessageModal.vue");
  const EditStoryModal = () =>
    import("@/components/common/tailwind/modals/EditStoryModal.vue");

  export default {
    name: "profile-screen",
    components: {
      ConversationsTable,
      OutreachRequestsTable,
      ProfileDataCards,
      RequestOutreachModal,
      SendMessageModal,
      EditStoryModal,
    },
    data() {
      return {
        userAccount: {},
        firstName: "",
        lastName: "",
        email: "",
        profileImage: "",
        uploadedProfilePicture: "",
        profileFields: [],
        profileAnswers: [],
        conversations: [],
        outreachRequests: [],
        dataCards: {
          conversationCount: 0,
          messagesSentCount: 0,
          outreachRequestsCount: 0,
          profileViewsCount: 0,
        },
        requestOutreachModalOpened: false,
        sendMessageModalOpened: false,
        deleteAmbassadorPopupOpened: false,
        storyModalOpened: false,
        profileLoading: true,
        saved: true,
        dropdownOptions: [
          [
            {
              label: "Send Message",
              value: 0,
            },
            {
              label: "Archive Account",
              value: 1,
            },
          ],
          [
            {
              label: "Delete Account",
              value: 2,
              color: "text-red-400",
            },
          ],
        ],
        archivedDropdownOptions: [
          [
            {
              label: "Send Message",
              value: 0,
            },
            {
              label: "Activate Account",
              value: 1,
            },
          ],
          [
            {
              label: "Delete Account",
              value: 2,
              color: "text-red-400",
            },
          ],
        ],
        deleteAmbassadorPopupProps: {
          headerText: "Delete account?",
          bodyText:
            "WARNING: all messages and conversation history associated with this ambassador's profile will be deleted. This action cannot be undone.",
          firstButton: {
            variant: "info",
            text: "Cancel",
          },
          secondButton: {
            variant: "danger",
            text: "Remove",
          },
        },
      };
    },
    computed: {
      ...mapGetters({
        getBranding: GET_BRANDING,
        getCurrentUser: GET_CURRENT_USER,
        getAmbassadors: GET_CURRENT_PARENTS,
        getCallOutreach: GET_CALL_OUTREACH,
        getPendingCallOutreach: GET_PENDING_CALL_OUTREACH,
        getVideoOutreach: GET_VIDEO_OUTREACH,
        getEmailOutreach: GET_EMAIL_OUTREACH,
        getVideoRequestOutreach: GET_VIDEO_REQUEST_OUTREACH,
        getVideoSubmissionOutreach: GET_VIDEO_SUBMISSION_OUTREACH,
        getAmbassadorsAvailable: GET_AMBASSADORS_AVAILABLE,
        getLastFetched: GET_LAST_FETCHED,
      }),
      emailEditable() {
        return !this.userAccount.msaId;
      },
      visibleFields() {
        return Object.entries(this.profileAnswers)
          .filter((entry, index) => this.profileFields[index] !== "")
          .map(([key, answer]) => {
            return {
              key,
              answer,
              question: this.profileFields[key],
            };
          });
      },
    },
    watch: {
      getAmbassadors() {
        this.getAmbassadorInfo();
      },
      getEmailOutreach() {
        this.getOutreachData();
      },
      getVideoRequestOutreach() {
        this.getOutreachData();
      },
      getVideoSubmissionOutreach() {
        this.getOutreachData();
      },
      getCallOutreach() {
        this.getOutreachData();
      },
      getPendingCallOutreach() {
        this.getOutreachData();
      },
      async getAmbassadorsAvailable() {
        await this.getAmbassadorInfo();
      },
      getLastFetched(fetched) {
        if (fetched.ambassadors && !this.getAmbassadorsAvailable)
          return this.$router.push({ name: AMBASSADORS_SCREEN });
      },
      userAccount(updatedUserAccount) {
        this.profileImage =
          updatedUserAccount.profileImage || updatedUserAccount.imageURL;
      },
      uploadedProfilePicture() {
        this.saveProfileImage();
      },
    },
    async created() {
      if (this.getBranding) this.profileFields = this.getBranding.profileFields;
      this.profileImage =
        this.userAccount.profileImage || this.userAccount.imageURL;
      await this.getAmbassadorInfo();
    },
    methods: {
      onClose() {
        console.log("close");
      },
      goBack() {
        this.$router.push({ name: AMBASSADORS_SCREEN });
      },
      updateFieldAnswer(index, e) {
        if (this.profileAnswers[index] !== e.target.innerText) {
          this.saved = false;
          this.profileAnswers[index] = e.target.innerText;
        }
      },
      updateName(e) {
        if (`${this.firstName} ${this.lastName}` !== e.target.innerText) {
          this.saved = false;
          const nameArr = e.target.innerText.split(" ");
          this.firstName = nameArr[0];
          this.lastName = nameArr[1];
        }
      },
      updateEmail(e) {
        if (this.email !== e.target.innerText) {
          this.saved = false;
          this.email = e.target.innerText;
        }
      },
      focusProfileFields(num) {
        this.$refs[`profile-field`][num].focus();
      },
      focusName() {
        this.$refs[`name-field`].focus();
      },
      focusEmail() {
        this.$refs[`email-field`].focus();
      },
      cancelProfileChanges() {
        this.saved = true;
        this.profileAnswers = Object.assign(
          this.profileAnswers,
          this.userAccount.profile
        );
        this.firstName = this.userAccount.first;
        this.lastName = this.userAccount.last;
        this.email = this.userAccount.email;
      },
      openSendMessageModal(placeholder) {
        this.sendMessagePlaceholder = placeholder;
        this.sendMessageModalOpened = true;
      },
      saveDisabled() {
        for (const [i, field] of this.profileFields.entries()) {
          const question = field.trim();
          const answer = this.profileAnswers[i]?.trim();

          if (question.length && !answer?.length) {
            return "You may not save empty fields.";
          }
        }

        if (!(this.firstName && this.lastName && this.email)) {
          return "You need a first name, last name, and email to save.";
        } else if (!validationService.isEmailValid(this.email)) {
          return "You need a valid email to save.";
        }

        return false;
      },
      profilePictureClick() {
        this.$refs["fileupload"].click();
      },
      selectFile(event) {
        if (!event.target.files) {
          return;
        }

        const image = event.target.files[0];
        const fileSize = event.target.files[0].size;
        const reader = new FileReader();

        const PROFILE_IMAGE_SIZE = 200;

        // downsize and compress if image > 100 KB
        if (fileSize > 100000) {
          reader.readAsArrayBuffer(image);
          reader.onload = async () => {
            this.uploadedProfilePicture = await resizeImage(
              reader.result,
              PROFILE_IMAGE_SIZE,
              "image/jpeg"
            );
          };
        } else {
          reader.readAsDataURL(image);
          reader.onload = (event) => {
            this.uploadedProfilePicture = event.target.result;
          };
        }
      },
      handleDropdownClick(option) {
        switch (option) {
          case 0:
            return this.openSendMessageModal("Type something here...");
          case 1:
            return this.archiveRestoreAmbassadors({
              archived: !this.userAccount.archived,
              isActive: false,
              requestCall: false,
            });
          case 2:
            return (this.deleteAmbassadorPopupOpened = true);
        }
      },
      saveProfile: async function () {
        if (this.saveDisabled()) {
          return this.$toasted.show(this.saveDisabled(), this.$toastedFailure);
        }

        const data = {
          id: this.userAccount._id,
          first: this.firstName,
          last: this.lastName,
          email: this.email,
          profile: this.profileAnswers,
        };

        const response = await this.$store.dispatch(SET_AMBASSADOR, data);
        if (response) {
          this.$toasted.show(`Profile saved.`, this.$toastedSuccess);
          this.saved = true;
        } else {
          this.$toasted.show(
            `There was an issue saving this profile.`,
            this.$toastedFailure
          );
        }
      },
      saveProfileImage: async function () {
        const data = {
          id: this.userAccount._id,
          profileImage: this.uploadedProfilePicture,
        };

        const response = await this.$store.dispatch(SET_AMBASSADOR, data);
        if (response) {
          this.profileImage = this.uploadedProfilePicture;
          this.$toasted.show(`Profile image saved.`, this.$toastedSuccess);
        } else {
          this.$toasted.show(
            `There was an issue saving this profile.`,
            this.$toastedFailure
          );
        }
      },
      async deleteOutreach(outreach) {
        if (outreach.isVideoSubmission) {
          const data = {
            requestID: outreach._id,
            submissionID: outreach.submissionID,
          };

          const response = await this.$store.dispatch(
            DELETE_VIDEO_OUTREACH_SUBMISSION,
            data
          );

          if (response) {
            this.$toasted.show(
              `${outreach.outreachType} outreach deleted.`,
              this.$toastedSuccess
            );
          } else {
            this.$toasted.show(
              `Uh oh! There was an issue deleting that outreach.`,
              this.$toastedFailure
            );
          }
        }

        const data = {
          id: outreach._id,
          outreachType: outreach.outreachType,
        };
        const response = await this.$store.dispatch(DELETE_OUTREACH, data);
        if (response) {
          this.$toasted.show(
            `${outreach.outreachType} outreach deleted.`,
            this.$toastedSuccess
          );
        } else {
          this.$toasted.show(
            `Uh oh! There was an issue deleting that outreach.`,
            this.$toastedFailure
          );
        }
      },
      setAmbassador: async function (properties) {
        const alertMessages = {
          isActive: "Profile visiblity updated.",
          requestCall: "Call request updated.",
        };

        const data = [
          {
            id: this.userAccount._id,
            ...properties,
          },
        ];

        const response = await this.$store.dispatch(SET_AMBASSADORS, data);

        if (response) {
          this.$toasted.show(
            alertMessages[Object.keys(properties)[0]],
            this.$toastedSuccess
          );
          return this.getAmbassadorInfo();
        } else {
          return this.$toasted.show(
            `Uh oh! There was an issue!`,
            this.$toastedFailure
          );
        }
      },
      archiveRestoreAmbassadors: async function (properties) {
        let response;
        const archive = !this.userAccount.archived;
        const data = [
          {
            id: this.userAccount._id,
            ...properties,
          },
        ];

        if (archive) {
          response = await this.$store.dispatch(ARCHIVE_AMBASSADORS, data);
        } else {
          response = await this.$store.dispatch(RESTORE_AMBASSADORS, data);
        }

        if (response) {
          this.$toasted.show(`Account status updated.`, this.$toastedSuccess);
          return this.getAmbassadorInfo();
        } else {
          return this.$toasted.show(
            `Uh oh! There was an issue!`,
            this.$toastedFailure
          );
        }
      },
      deleteAmbassador: async function () {
        const ambassador = this.userAccount;
        const response = await this.$store.dispatch(
          DELETE_AMBASSADOR,
          ambassador._id
        );

        if (response) {
          this.deleteAmbassadorPopupOpened = false;
          this.$toasted.show(
            "Ambassador account deleted.",
            this.$toastedSuccess
          );
          this.$router.push({ name: HOME_SCREEN });
        } else {
          return this.$toasted.show(
            `Uh oh! There was an issue and that ambassador account was not deleted.`,
            this.$toastedFailure
          );
        }
      },
      closeConversation: async function ({ id, type }) {
        let response;
        if (type === "call") {
          response = await APIService.dashboard.closeCall(id);
        } else {
          response = await APIService.chat.closeConversation(id);
        }
        await this.getAmbassadorInfo();

        if (response.status === 200) {
          this.$toasted.show("Conversation closed.", this.$toastedSuccess);
        } else {
          this.$toasted.show(
            "There was an issue closing this conversation.",
            this.$$toastedFailure
          );
        }
      },
      getAmbassadorData: async function () {
        const ambassadorID = this.$route.params.id;
        const ambassadorObj = await this.$store.dispatch(
          FIND_AMBASSADOR,
          ambassadorID
        );
        if (ambassadorObj) {
          this.userAccount = JSON.parse(JSON.stringify(ambassadorObj));
          this.firstName = this.userAccount.first;
          this.lastName = this.userAccount.last;
          this.email = this.userAccount.email;
          if (this.userAccount.profile)
            this.profileAnswers = JSON.parse(
              JSON.stringify(this.userAccount.profile)
            );
          else this.profileAnswers = ["", "", "", ""];
          this.profileLoading = false;
        } else {
          return (
            this.getAmbassadors.length &&
            this.$router.push({ name: AMBASSADORS_SCREEN })
          );
        }
      },
      getConversationsData: async function () {
        if (!this.userAccount._id) return;

        const userData = {
          id: this.userAccount._id,
          organization: this.getBranding.name,
        };

        const userConvoResponse = await APIService.dashboard.userData(userData);
        if (!userConvoResponse || !userConvoResponse.data) return;

        this.dataCards.conversationCount = userConvoResponse.data.numConvos;
        this.dataCards.messagesSentCount = userConvoResponse.data.numMessages;
        return userConvoResponse.data;
      },
      getOutreachData() {
        const callOutreach = profileService.calculateCallOutreachRequests(
          this.userAccount._id,
          this.getCallOutreach
        );
        const videoOutreach = profileService.calculateVideoOutreachRequests(
          this.userAccount._id,
          this.getVideoOutreach
        );
        const emailOutreach = profileService.calculateEmailOutreachRequests(
          this.userAccount._id,
          this.getEmailOutreach
        );

        this.outreachRequests = [
          ...callOutreach,
          ...emailOutreach,
          ...videoOutreach,
        ];
        this.dataCards.outreachRequestsCount = this.outreachRequests.length;
      },
      getAmbassadorInfo: async function () {
        await this.getAmbassadorData();
        const convoData = await this.getConversationsData();

        const conversations = [];
        const now = new Date();

        // Gets the general conversation data and stores it in our parsed conversations object.
        if (convoData) {
          for (const convo of convoData.convos) {
            sortService.sortArrayOfObjects(
              convo.messages,
              "createdDate",
              "date"
            );

            const lastMessage = dateService.getDifferenceInDays(
              new Date(convo.messages[convo.messages.length - 1].createdDate),
              now
            );

            let initialResponse;
            const firstMessage = convo.messages.find(
              (message) => message.authorUserId !== this.userAccount.id
            );
            const response = convo.messages.find(
              (message) => message.authorUserId === this.userAccount.id
            );

            if (firstMessage && response) {
              initialResponse = dateService.getDifferenceInDays(
                new Date(firstMessage.createdDate),
                new Date(response.createdDate)
              );
            }

            conversations.push({
              id: convo.id,
              prospectName: convo.prospectName,
              prospectID: convo.prospectID,
              recipientID: convo.recipientID,
              status: convo.convoActive,
              lastMessage: lastMessage,
              blocked: this.userAccount.blockedUsers?.includes(
                convo.prospectID
              ),
              type: "conversation",
              messages: convo.messages,
              messagesSent: convo.messages.length,
              initialResponse,
              feedback: convo.feedbackId,
            });
          }

          for (const call of convoData.calls) {
            conversations.push({
              id: call._id,
              prospectName: toFullName(
                call.recipient?.first,
                call.recipient?.last,
                "Deleted User"
              ),
              prospectID: call.recipient?.id,
              status: call.isActive,
              lastMessage: "Pending",
              type: "call",
              feedback: call.feedbackId,
            });
          }
        }

        sortService.sortArrayOfObjects(conversations, "lastMessage", "number");
        this.conversations = conversations;
        this.getOutreachData();
      },
      updateAmbassadorStory(data) {
        this.userAccount.FAQ = data;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .profile-fields {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 1%;

    &-field {
      flex: 1 1 48%;
      border: 1px dashed white;
      padding: 0.1rem;
      cursor: text;

      &:hover,
      :focus {
        border-color: $grey-300;
      }
    }

    &-icon {
      font-size: 8px;
    }
  }
</style>
