<template>
  <div class="input-wrapper relative flex" :class="inputWidth">
    <span
      v-if="inputIcon"
      class="z-10 absolute flex w-10 h-full justify-center items-center">
      <slot />
    </span>

    <input
      class="input w-full rounded-lg"
      :class="[
        getVariantStyle,
        { icon, characterCount, 'text-grey': disabled },
      ]"
      :value="inputValue"
      :type="type"
      :placeholder="inputPlaceholder"
      @keyup="emitKeyUp"
      @input="emitInput"
      :disabled="disabled"
      :maxlength="maxLength"
      ref="input" />
    <span v-if="characterCount" class="after font-primary">{{
      characterCount
    }}</span>
  </div>
</template>

<script>
  import intlTelInput from "intl-tel-input";
  import "intl-tel-input/build/css/intlTelInput.css";

  export default {
    name: "common-input",

    props: {
      value: {
        type: [String, Number],
      },

      placeholder: {
        type: String,
      },

      variant: {
        type: String,
        default: "input",
        validator(value) {
          const variants = ["input", "search"];
          return variants.includes(value);
        },
      },

      icon: {
        type: Boolean,
        default: false,
      },

      type: {
        type: String,
        default: "text",
      },

      width: {
        type: String,
        default: "w-full",
      },

      disabled: {
        type: Boolean,
        default: false,
      },

      maxLength: {
        type: Number,
        default: null,
      },
    },

    data() {
      return {
        inputValue: this.value || "",
        inputPlaceholder: this.placeholder,
        inputIcon: this.icon,
        inputWidth: this.width,
        telephoneInput: null,
      };
    },

    computed: {
      getVariantStyle() {
        if (this.variant === "input") {
          return "bg-white border border-grey-200";
        }
        return "bg-grey-100 focus:outline-none";
      },
      characterCount() {
        if (!this.maxLength || this.type !== "text") {
          return "";
        }
        return `${this.value.length}/${this.maxLength}`;
      },
    },

    watch: {
      value(newVal) {
        this.inputValue = newVal;
      },

      type: {
        immediate: true,
        handler(val) {
          if (val === "tel") {
            this.initializeTelephoneInput();
          } else {
            this.destroyTelephoneInput();
          }
        },
      },
    },

    created() {
      if (this.value === 0) this.inputValue = 0;
    },

    beforeDestroy() {
      this.destroyTelephoneInput();
    },

    methods: {
      emitKeyUp(e) {
        const inputValue = e.target.value;
        this.$emit("keyup", inputValue);
      },

      emitInput(e) {
        if (this.value === undefined) {
          this.inputValue = e.target.value;
        }
        this.$emit("input", e.target.value);

        if (this.type === "tel") {
          this.$emit("input-validation", this.telephoneInput.isValidNumber());
        }
      },

      initializeTelephoneInput() {
        this.$nextTick(() => {
          const input = this.$refs.input;
          this.telephoneInput = intlTelInput(input, {
            utilsScript:
              "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.15/js/utils.min.js",
            initialCountry: "us",
          });
        });
      },

      destroyTelephoneInput() {
        if (this.telephoneInput) {
          this.telephoneInput.destroy();
          this.telephoneInput = null;
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
    margin: 0;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .input-wrapper {
    height: 36px;
    margin: auto;
  }

  .input {
    position: relative;
    font-size: 14px;
    line-height: 18px;
    padding: 14px;

    &.icon {
      padding-left: 40px;
    }

    &.characterCount {
      padding-right: 75px;
    }
  }

  .after {
    position: absolute;
    right: 15px;
    top: 0;
    height: 100%;
    line-height: 1;
    display: flex;
    align-items: center;
  }

  ::v-deep .iti {
    width: 100%;

    .iti--allow-dropdown .iti__flag-container,
    .iti--separate-dial-code .iti__flag-container {
      left: 4px;
      height: 38px;
    }

    .iti__country-list {
      max-width: 268px;

      @media only screen and (max-width: 768px) {
        max-width: 180px;
      }
    }
  }
</style>
