import httpService from "./http.service";

const request = httpService(process.env.VUE_APP_ENDPOINT_TWO);

export function getBanRequests(orgName) {
  return request({
    requireToken: true,
    method: "get",
    path: "notifications/bans",
    data: { org: orgName },
  });
}

export function completeBanRequest(data) {
  return request({
    requireToken: true,
    method: "put",
    path: `notifications/bans`,
    data,
  });
}
