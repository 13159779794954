import Icon from "@/components/common/Icon";
//import { datadogLogs } from "@datadog/browser-logs";
import {
  mdiAccount,
  mdiAlertCircle,
  mdiCameraFlip,
  mdiChevronDown,
  mdiChevronRight,
  mdiChevronUp,
  mdiClipboardCheck,
  mdiContentSave,
  mdiDelete,
  mdiMinus,
  mdiPause,
  mdiPencil,
  mdiPlus,
  mdiRecord,
  mdiRestart,
  mdiStop,
  mdiTrayArrowDown,
  mdiTrayArrowUp,
  mdiUpload,
} from "@mdi/js";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";
import { FocusTrap } from "focus-trap-vue";
import mdiVue from "mdi-vue/v2";
import Vue from "vue";
import VueCookies from "vue-cookies";
import TextareaAutosize from "vue-textarea-autosize";
import VueTippy from "vue-tippy";
import Toasted from "vue-toasted";
import Vuelidate from "vuelidate";
import App from "./App.vue";
import "./assets/styles/index.css";
import vuetify from "./plugins/vuetify";
import router from "./router/router.js";
import amplitude from "./services/amplitude.service.js";
import { setToken } from "./services/api/http.service";
import "./services/registrations.service.js";
import store from "./store";
//import { datadogRum } from "@datadog/browser-rum";
const dotenv = require("dotenv");
dotenv.config();

// if (
//   process.env.VUE_APP_DD_RUM_CLIENT_TOKEN &&
//   process.env.VUE_APP_DD_APPLICATION_ID
// ) {
//   datadogRum.init({
//     applicationId: process.env.VUE_APP_DD_APPLICATION_ID,
//     clientToken: process.env.VUE_APP_DD_RUM_CLIENT_TOKEN,
//     site: "datadoghq.com",
//     service: "peerpal-admin-dashboard",
//     env: process.env.NODE_ENV || "production",
//     // Specify a version number to identify the deployed version of your application in Datadog
//     // version: '1.0.0',
//     sessionSampleRate: 100,
//     sessionReplaySampleRate: 20,
//     trackUserInteractions: true,
//     trackResources: true,
//     trackLongTasks: true,
//     defaultPrivacyLevel: "mask-user-input",
//     allowedTracingUrls: [
//       "https://chatapi.peerpal.com",
//       "https://adminapi.peerpal.com",
//     ],
//   });

//   datadogRum.startSessionReplayRecording();
// }

// if (process.env.VUE_APP_DD_CLIENT_TOKEN) {
//   datadogLogs.init({
//     clientToken: process.env.VUE_APP_DD_CLIENT_TOKEN,
//     site: "datadoghq.com",
//     forwardErrorsToLogs: true,
//     sessionSampleRate: 100,
//   });
// }

Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(VueCookies);
Vue.$cookies.config("7d", "", "", true, "Lax");
Vue.use(amplitude);
Vue.component("ppicon", Icon);
Vue.use(TextareaAutosize);
Vue.use(Toasted, { duration: 10000, keepOnHover: true });
Vue.use(VueTippy);
Vue.component("FocusTrap", FocusTrap);

Vue.use(mdiVue, {
  icons: {
    mdiAccount,
    mdiAlertCircle,
    mdiCameraFlip,
    mdiChevronDown,
    mdiChevronRight,
    mdiChevronUp,
    mdiClipboardCheck,
    mdiContentSave,
    mdiDelete,
    mdiMinus,
    mdiPause,
    mdiPencil,
    mdiPlus,
    mdiRecord,
    mdiRestart,
    mdiStop,
    mdiTrayArrowDown,
    mdiTrayArrowUp,
    mdiUpload,
  },
});

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    Vue,
    dsn: "https://c9e5b17859c8459b930b8483af2f40cb@o227651.ingest.sentry.io/5951663",
    integrations: [
      new TracingIntegrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          "localhost",
          process.env.VUE_APP_ENDPOINT,
          process.env.VUE_APP_ENDPOINT_TWO,
        ],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

// set login token as early as possible
setToken(Vue.$cookies.get("token"));

new Vue({
  el: "#app",
  router,
  store,
  vuetify,
  render: (h) => h(App),
});

// General event bus meant for passing emitted data from child -> child component.
export const eventBus = new Vue();

if (!String.prototype.format) {
  String.prototype.format = function () {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != "undefined" ? args[number] : match;
    });
  };
}
