// Dashboard Main Mutations
export const UPDATE_CURRENT_USER = "updateCurrentUser";
export const UPDATE_START = "updateStart";
export const UPDATE_CAMPAIGN_INVITE_TYPE = "updateCampaignInviteType";
export const UPDATE_ACTIVE_YEAR = "updateActiveYear";
export const UPDATE_LAST_FETCHED = "updateLastFetched";

// Dashboard Prospect Mutations
export const UPDATE_PROSPECTS = "updateProspects";

// Dashboard Ambassador Mutations
export const UPDATE_CURRENT_PARENTS = "updateCurrentParents";
export const UPDATE_INVITED_AMBASSADORS = "updateInvitedAmbassadors";
export const UPDATE_AMBASSADOR = "updateAmbassador";
export const UPDATE_AMBASSADORS = "updateAmbassadors";
export const REMOVE_AMBASSADOR = "removeAmbassador";
export const REMOVE_INVITED_AMBASSADOR = "removeInvitedAmbassador";

// Dashboard Administrator Mutations
export const UPDATE_ADMINS = "updateAdmins";

// Dashboard Branding Mutations
export const UPDATE_BRANDING = "updateBranding";
export const UPDATE_PROFILE_FIELDS = "updateProfileQuestions";

// Dashboard Conversation Mutations
export const UPDATE_CONVERSATIONS = "updateConversations";
export const UPDATE_CONVERSATION = "updateConversation";

// Outreach Mutations
export const UPDATE_CALL_OUTREACH = "updateCallOutreach";
export const UPDATE_EMAIL_OUTREACH = "updateEmailOutreach";
export const UPDATE_VIDEO_OUTREACH = "updateVideoOutreach";
export const REMOVE_OUTREACH = "removeOutreach";
export const REMOVE_VIDEO_OUTREACH_SUBMISSION = "removeVideoOutreachSubmission";
export const ADD_OUTREACH = "addOutreach";

// Campaign Outreach Mutations
export const UPDATE_CAMPAIGNS = "setCampaignList";

// Home Screen Mutations
export const UPDATE_VISITOR_VIEWS = "updateVisitorViews";
export const UPDATE_ENGAGEMENT_STATS = "updateEngagementStats";
export const UPDATE_ENGAGEMENT_STATS_VIDEO = "updateEngagementStatsVideo";
export const UPDATE_ENGAGEMENT_GRAPH = "updateEngagementGraph";
export const UPDATE_PENDING_VIDEO_INVITATIONS = "updatePendingVideoInvitations";
export const UPDATE_PENDING_VIDEO_STORIES = "updatePendingVideoStories";
export const UPDATE_CONVERSATION_GRAPH = "updateConversationGraph";
