<template>
  <div class="page">
    <!-- Header -->
    <h1>{{ $lang.settingsPage_ProspectSetup }}</h1>

    <!-- Sign-Up Fields Tab -->

    <h2>{{ $lang.settingsPage_SignUpForm }}</h2>

    <div class="interest">
      <label id="interest-label">
        {{ $lang.settingsPage_SignUpInterestTitle }}
      </label>
      <common-toggle
        v-model="signUpSlider"
        aria-labelledby="interest-label"
        aria-describedby="interest-description"
        class="limit-toggle"></common-toggle>
    </div>
    <p id="interest-description" class="interest-description">
      {{ $lang.settingsPage_SignUpInterestDescription }}
    </p>

    <div>
      <p>{{ $lang.settingsPage_SignUpFormInstructions }}</p>
      <p class="example">{{ $lang.settingsPage_SignUpFormExample }}</p>
    </div>

    <div class="profile-fields">
      <ul>
        <li
          v-for="(profileField, index) of inquiryQs"
          :key="`signup-field-${index}`">
          <label>
            {{ index + 1 }}.

            <common-input
              :placeholder="`Sign-Up field question #${index + 1}`"
              v-model="profileField.question" />
            <common-button variant="icon" @click="deleteField(index)">
              <ppicon name="close-circle" />
            </common-button>
          </label>
        </li>
      </ul>

      <div v-if="inquiryQs.length < 4">
        <common-button variant="primary" @click="addField" icon iconOnly>
          <template slot="icon">
            <ppicon name="plus" size="20" />
          </template>
        </common-button>
      </div>
    </div>

    <h2>{{ $lang.settingsPage_Restrictions }}</h2>
    <MessagesLimit
      v-model="prospLimit"
      :description="
        $lang.settingsPage_MessagesLimitProspectInstructions
      "></MessagesLimit>

    <common-button variant="primary" class="btn-save" @click="save">
      {{ $lang.general_Save }}
    </common-button>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import { GET_BRANDING } from "@/store/getters.type.js";
  import { SET_BRANDING } from "../../../../store/actions.type";

  import MessagesLimit from "./MessagesLimit";

  export default {
    name: "ProspectSetup",
    components: { MessagesLimit },
    data() {
      return {
        inquiryQs: [],
        signUpSlider: true,
        prospLimit: 0,
      };
    },

    computed: {
      ...mapGetters({ branding: GET_BRANDING }),
    },

    watch: {
      branding: {
        immediate: true,
        handler(updatedBranding) {
          if (updatedBranding?.inquiryQs?.length) {
            this.inquiryQs = updatedBranding.inquiryQs.filter(
              (field) => field.question.length
            );
            this.prospLimit = updatedBranding.prospLimit || 0;
          } else {
            this.inquiryQs = [];
          }
          this.signUpSlider = updatedBranding?.signUpSlider ?? true;
        },
      },
    },
    methods: {
      addField() {
        this.inquiryQs.push({
          question: "",
        });
      },

      deleteField(index) {
        if (this.inquiryQs.length > 1) this.inquiryQs.splice(index, 1);
      },

      async save() {
        const response = await this.$store.dispatch(SET_BRANDING, {
          inquiryQs: this.inquiryQs,
          prospLimit: this.prospLimit,
          signUpSlider: this.signUpSlider,
        });
        if (response) {
          this.$toasted.show(
            this.$lang.settingsPage_SettingsSaved,
            this.$toastedSuccess
          );
        } else {
          this.$toasted.show(
            this.$lang.general_ToastError,
            this.$toastedFailure
          );
        }
      },
      prospLimitToggle(val) {
        this.prospLimitEnabled = val;
      },
    },
  };
</script>

<style scoped lang="scss">
  h1,
  h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.25em;
    padding: 0 0 1em;
  }
  p {
    font-size: 14px;
    padding-bottom: 1em;
    color: $grey-500;
  }
  .page {
    max-width: 600px;
  }
  .profile-fields {
    padding: 1rem 0;
    margin-bottom: 1rem;
  }
  .profile-fields li {
    padding-bottom: 1.25rem;
  }
  label {
    display: flex;
    align-items: center;
    gap: 1em;
    font-size: 14px;
    font-weight: 500;
    color: $primary-text;
  }
  .example {
    font-style: italic;
  }
  .btn-save {
    width: 10rem;
    margin: 2rem 0;
  }
  .interest {
    display: flex;
    justify-content: space-between;
  }
</style>
