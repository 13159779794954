<template>
  <div data-vuetify>
    <v-app class="vuetify-off">
      <v-snackbar
        v-model="snackbar"
        :timeout="options.timeout"
        :color="options.color">
        <v-icon left>{{ options.icon }}</v-icon
        >{{ message }}
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            @click="snackbar = false"
            :aria-label="$lang.general_Close">
            <v-icon>$close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-app>
  </div>
</template>

<script>
  import Vue from "vue";

  const defaultOptions = {
    timeout: 5000,
  };

  export default {
    data() {
      return {
        snackbar: false,
        message: "",
        options: defaultOptions,
      };
    },
    mounted() {
      const vm = this;

      Vue.prototype.$toast = {
        show(message, options) {
          vm.options = {
            ...defaultOptions,
            ...options,
          };
          vm.message = message;
          vm.snackbar = true;
        },
        success(message, options) {
          options = {
            icon: "$check-circle",
            color: "success",
            ...options,
          };
          this.show(message, options);
        },
        error(message, options) {
          options = {
            icon: "$alert-circle",
            color: "error",
            ...options,
          };
          this.show(message, options);
        },
      };
    },
  };
</script>

<style lang="scss" scoped>
  // can be removed once v-app can be applied to root
  .vuetify-off {
    height: 0 !important;
  }
</style>
