<template>
  <div :class="classPrefix">
    <!-- Header -->
    <div :class="`${classPrefix}-header`">
      <div :class="`${classPrefix}-header-details`">
        <span :class="`${classPrefix}-header-title-container`">
          <h1 :class="`${classPrefix}-header-title`">
            {{ $lang.prospectsPage_Title }}
          </h1>
          <p :class="`${classPrefix}-header-description`">
            {{ $lang.prospectsPage_ListDescription }}
          </p>
        </span>
        <div :class="`${classPrefix}-header-controls`">
          <!-- Search Input -->
          <div :class="`${classPrefix}-header-search`">
            <common-input
              :placeholder="$lang.general_SearchPlaceholder"
              variant="search"
              v-model="searchParameter"
              icon>
              <img
                :src="require('@/assets/search-icon.svg')"
                :class="`${classPrefix}-search-icon`" />
            </common-input>
          </div>

          <!-- Export Button -->
          <div :class="`${classPrefix}-header-export-container`">
            <common-button
              :class="`${classPrefix}-header-export-button`"
              variant="secondary"
              icon
              @click="exportTableData">
              <template slot="icon">
                <img :src="require('@/assets/download-icon.svg')" />
              </template>
              {{ $lang.prospectsPage_Export }}
            </common-button>
          </div>
        </div>
      </div>

      <!-- Data Refresh -->
      <div :class="`${classPrefix}-header-refresh-container`">
        <div :class="`${classPrefix}-header-refresh-text mobile`">
          {{ $lang.prospectsPage_ScrollInstructions }}
        </div>

        <p
          :class="[
            `${classPrefix}-header-refresh-text desktop`,
            { 'animate-pulse': !lastFetched },
          ]">
          {{ dataFetchDisplayText }}
        </p>

        <img
          :src="require('@/assets/spinner-icon.svg')"
          :class="[
            `${classPrefix}-header-refresh-icon`,
            { 'animate-spin': fetching },
          ]"
          content="Fetch most recent data"
          v-tippy="{ placement: 'bottom', arrow: true, maxWidth: '500px' }"
          @click="fetchMostRecent" />
      </div>
    </div>

    <BanRequests></BanRequests>

    <!-- Prospect Table -->
    <section :class="[`${classPrefix}-section-table`]">
      <div :class="[`${classPrefix}-table-container`]">
        <table :class="`${classPrefix}-table`">
          <thead :class="`${classPrefix}-table-header`">
            <tr>
              <th
                scope="col"
                :class="`${classPrefix}-table-header-col`"
                v-for="header in tableHeaders"
                :key="header.label">
                <div
                  :class="[
                    `${classPrefix}-table-header-label`,
                    { sortable: header.sortable },
                  ]"
                  @click="
                    header.sortable
                      ? sortByColumn(
                          header.sortable.property,
                          header.sortable.dataType
                        )
                      : null
                  ">
                  {{ header.label }}
                  <div
                    :class="`${classPrefix}-table-header-sort-icon`"
                    v-if="header.sortable">
                    <sort-icon
                      :active="activeSort === header.sortable.property" />
                  </div>
                </div>
              </th>
            </tr>
          </thead>

          <tbody :class="`${classPrefix}-table-body`">
            <tr
              :class="[
                `${classPrefix}-table-row`,
                { hidden: emptySearchResults },
              ]"
              v-for="prospect of displayedProspects"
              :key="prospect.email"
              @click="
                goToProspectProfile(
                  prospect.id,
                  `${prospect.firstName} ${prospect.lastName}`,
                  prospect.email
                )
              ">
              <td>{{ prospect.lastName }}</td>
              <td>{{ prospect.firstName }}</td>
              <td>{{ prospect.email }}</td>

              <td>
                <common-dropdown
                  :placeholder="getStatusDisplayText(prospect)"
                  fixedPlaceholder
                  :class="[
                    `status-dropdown status-${getStatus(
                      prospect
                    ).toLowerCase()}`,
                  ]"
                  variant=""
                  :options="getStatusDropdownOptions(prospect)"
                  @input="handleStatusClick($event, prospect)" />
              </td>

              <td>
                <div :class="`${classPrefix}-table-date-cell`">
                  {{ prospect.signedUpDate }}
                </div>
              </td>

              <td>
                <common-dropdown
                  alignDropdownLeft
                  :class="`${classPrefix}-action-dropdown`"
                  :options="getActionsDropdownOptions()"
                  :button="{
                    variant: 'icon',
                    icon: true,
                    iconOnly: true,
                  }"
                  @input="handleActionClick($event, prospect)">
                  <template v-slot:icon>
                    <img
                      :src="require('@/assets/ellipsis-icon.svg')"
                      :class="`${classPrefix}-action-dropdown-icon`" />
                  </template>
                </common-dropdown>
              </td>
            </tr>

            <!-- Skeleton Loading -->
            <tr
              v-for="(n, index) in 6"
              :class="[
                `${classPrefix}-table-row skeleton`,
                { hidden: prospects.length || noProspectsToDisplay },
              ]"
              :key="`skeleton-row-${index}`">
              <td
                :class="`${classPrefix}-skeleton-cell`"
                v-for="header in tableHeaders"
                :key="`${header.label}-skeleton`">
                <div :class="`${classPrefix}-skeleton-row-content`" />
              </td>
            </tr>

            <!-- No prospects for this organization -->
            <tr
              :class="[
                `${classPrefix}-empty-row`,
                { hidden: !noProspectsToDisplay },
              ]">
              <td>
                <div :class="`${classPrefix}-empty-text`">
                  {{ $lang.prospectsPage_NoProspects }}
                </div>
              </td>
            </tr>

            <!-- Empty search -->
            <tr
              :class="[
                `${classPrefix}-empty-search-row`,
                { hidden: !emptySearchResults },
              ]">
              <td :class="`${classPrefix}-empty-search-cell`">
                {{ $lang.prospectsPage_NoSearchResults }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <delete-modal
      :user="prospectToDelete"
      @close="prospectToDelete = null"
      @delete-user="deleteUser"
      v-if="prospectToDelete"></delete-modal>

    <!-- Footer -->
    <common-table-footer
      valueLabel="Prospects"
      :itemCount="prospects.length"
      :numberOfPages="numberOfPages"
      :currentPage="currentPage"
      :firstIndex="firstIndex"
      :lastIndex="lastIndex"
      @updateRowsPerPage="updateRowsPerPage"
      @updateCurrentPage="updateCurrentPage" />
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import { FETCH_PROSPECTS, SET_LAST_FETCHED } from "@/store/actions.type.js";
  import {
    GET_PROSPECTS,
    GET_LAST_FETCHED,
    GET_ACTIVE_YEAR,
    GET_BRANDING,
  } from "@/store/getters.type.js";
  import sortService from "@/services/sort.service.js";
  import dateService from "@/services/date.service.js";
  import dashboardApiService from "@/services/api/dashboard.service.js";
  import DeleteModal from "./DeleteConfirmationModal.vue";
  import BanRequests from "./BanRequests.vue";
  import { exportCsvFile } from "@/shared/utils.js";

  export default {
    name: "prospects-screen",
    components: {
      DeleteModal,
      BanRequests,
    },

    data() {
      return {
        classPrefix: "prospect-page",
        currentPage: 1,
        lastFetched: "",
        fetching: false,
        searchParameter: "",
        rowsPerPage: 100,
        sortDirection: {},
        mostRecentSortType: "",
        activeSort: "",
        year: "",
        tableHeaders: [
          {
            label: this.$lang.general_LastName,
            sortable: {
              property: "lastName",
            },
          },

          {
            label: this.$lang.general_FirstName,
            sortable: {
              property: "firstName",
            },
          },

          {
            label: this.$lang.general_Email,
          },

          {
            label: this.$lang.prospectsPage_AccountStatusTableHeader,
            sortable: {
              property: "isBanned",
              dataType: "date",
            },
          },

          {
            label: this.$lang.prospectsPage_DateTableHeader,
            sortable: {
              property: "signedUpDate",
              dataType: "date",
            },
          },

          {
            label: "",
          },
        ],

        prospectToDelete: null,
      };
    },

    computed: {
      firstIndex() {
        return (this.currentPage - 1) * this.rowsPerPage;
      },

      lastIndex() {
        return this.firstIndex + this.rowsPerPage <= this.sortedProspects.length
          ? this.firstIndex + this.rowsPerPage
          : this.firstIndex + (this.sortedProspects.length - this.firstIndex);
      },

      numberOfPages() {
        return Math.ceil(this.sortedProspects.length / this.rowsPerPage);
      },

      dataFetchDisplayText() {
        if (this.lastFetched) {
          return this.$lang.prospectsPage_LastUpdated.format(
            this.lastFetched.toLocaleDateString(),
            this.lastFetched.toLocaleTimeString()
          );
        } else {
          return this.$lang.prospectsPage_FetchingProspects;
        }
      },

      //Prospects are searched on to create searchedProspects
      //searchedProspects are sorted on to create sortedProspects
      //sortedProspects are paginated to create displayedProspects
      prospects() {
        if (this.fetching) {
          return [];
        }

        let allProspects = [];
        let prospects = this.getProspects;

        if (prospects) {
          for (let prospect of prospects) {
            allProspects.push({
              id: prospect._id,
              firstName: prospect.first,
              lastName: prospect.last,
              email: prospect.email,
              interestScore: prospect.q1,
              signedUpDate: dateService.toDisplayDate(prospect.createdDate),
              inquiryAs: prospect.inquiryAs,
              status: prospect.isBanned
                ? this.$lang.status_Banned
                : this.$lang.status_Active,
              isBanned: prospect.isBanned,
              isConvoLimited: prospect.isConvoLimited,
            });
          }
        }

        return [...allProspects];
      },

      searchedProspects() {
        let prospects = this.prospects;

        if (this.searchParameter.length) {
          prospects = sortService.searchObjectValues(
            prospects,
            this.searchParameter,
            "id"
          );
        }

        return prospects;
      },

      //Current searched/sorted ambassador list
      sortedProspects() {
        return sortService.sortArrayOfObjects(
          this.searchedProspects,
          this.activeSort,
          this.mostRecentSortType,
          this.sortDirection[this.activeSort]
        );
      },

      //Current paginated/searched/sorted ambassador list
      displayedProspects() {
        return this.sortedProspects.slice(
          this.firstIndex,
          this.firstIndex + this.rowsPerPage
        );
      },

      emptySearchResults() {
        return this.searchParameter.length && !this.searchedProspects.length;
      },

      paginationDisplayText() {
        return this.$lang.ambassadorPage_PaginationDisplayText.format(
          this.firstIndex + 1,
          this.lastIndex,
          this.sortedProspects.length
        );
      },

      noProspectsToDisplay() {
        return (
          !this.emptySearchOrFilterResults &&
          this.lastFetched &&
          !this.fetching &&
          !this.displayedProspects.length
        );
      },

      ...mapGetters({
        getProspects: GET_PROSPECTS,
        getLastFetched: GET_LAST_FETCHED,
        getActiveYear: GET_ACTIVE_YEAR,
        getBranding: GET_BRANDING,
      }),
    },

    watch: {
      getLastFetched(recentFetch) {
        this.lastFetched = recentFetch.prospects;

        if (this.fetching) {
          this.$toasted.show(
            this.$lang.ambassadorPage_ToastTableRefresh,
            this.$toastedSuccess
          );
        }

        this.fetching = false;
      },

      searchParameter() {
        this.updateCurrentPage(1);
      },
    },

    created() {
      this.year = this.getActiveYear;
      this.fetchMostRecent();

      this.lastFetched = this.getLastFetched.prospects;
    },

    methods: {
      updateCurrentPage(updatedPage) {
        this.currentPage = updatedPage;
      },

      updateRowsPerPage(rowsNumber) {
        this.currentPage = 1;
        this.rowsPerPage = rowsNumber;
      },

      sortByColumn(propertyName, dataType = "string") {
        this.activeSort = propertyName;
        this.mostRecentSortType = dataType;
        this.$set(
          this.sortDirection,
          propertyName,
          !this.sortDirection[propertyName]
        );
      },

      exportTableData() {
        const inquiryQs = this.getBranding.inquiryQs;

        const csv = this.prospects.map((prospect) => {
          const user = {
            [this.$lang.general_LastName]: prospect.lastName,
            [this.$lang.general_FirstName]: prospect.firstName,
            [this.$lang.general_Email]: prospect.email,
            [this.$lang.general_Date]: prospect.signedUpDate,
          };
          if (inquiryQs?.length) {
            let index = 0;
            for (const q of inquiryQs) {
              user[index + 1 + ". " + q.question] = prospect.inquiryAs?.[index];
              index++;
            }
          }
          return user;
        });

        exportCsvFile(csv, "PeerPal_Prospects.csv");
      },

      goToProspectProfile(prospectID, prospectName, prospectEmail) {
        this.$emit("showProfile", [
          false,
          {
            id: prospectID,
            name: prospectName,
            email: prospectEmail,
          },
          1,
        ]);
      },

      fetchMostRecent() {
        this.fetching = true;
        this.$store.dispatch(FETCH_PROSPECTS).then(() => {
          this.$store.dispatch(SET_LAST_FETCHED, { prospects: new Date() });
        });
        this.$store.dispatch("notifications/getBanRequests");
      },

      upsertUserBan(prospect) {
        dashboardApiService
          .upsertUserBan({
            email: prospect.email,
            ban: !prospect.isBanned,
          })
          .then(() => {
            this.$store.dispatch("updateProspect", {
              id: prospect.id,
              updates: {
                isBanned: !prospect.isBanned,
              },
            });

            this.$toasted.show(
              !prospect.isBanned
                ? this.$lang.prospectsPage_BanUserSuccess.format(prospect.email)
                : this.$lang.prospectsPage_UnbanUserSuccess.format(
                    prospect.email
                  ),
              this.$toastedSuccess
            );
          })
          .catch((err) => {
            this.$toasted.show(err, this.$toastedFailure);
          });
      },

      getStatusDropdownOptions(prospect) {
        if (prospect.isBanned) {
          return [
            {
              label: this.$lang.prospectsPage_UnbanUser,
              icon: "check-circle",
            },
          ];
        } else {
          return [
            {
              label: this.$lang.prospectsPage_BanUser,
              icon: "cancel",
            },
          ];
        }
      },

      getStatusPlaceholder(prospect) {
        if (prospect.isBanned) {
          return this.$lang.status_Banned;
        }
        return this.$lang.status_Active;
      },

      handleStatusClick(option, prospect) {
        //TODO: Make this more coherent by adding onclicks to options in dropdown
        if (
          option === this.$lang.prospectsPage_BanUser ||
          option === this.$lang.prospectsPage_UnbanUser
        ) {
          this.upsertUserBan(prospect);
        }
      },

      openDeleteUser(prospect) {
        this.prospectToDelete = prospect;
      },

      getActionsDropdownOptions() {
        return [
          {
            label: this.$lang.prospectsPage_DeleteUser,
            class: `${this.classPrefix}-delete-user-option`,
            icon: "delete",
          },
        ];
      },

      handleActionClick(option, prospect) {
        //TODO: Make this more coherent by adding onclicks to options in dropdown
        if (option === this.$lang.prospectsPage_DeleteUser) {
          this.openDeleteUser(prospect);
        }
      },

      deleteUser(prospect) {
        const data = {
          userId: prospect.id,
        };

        dashboardApiService
          .deleteUser(data)
          .then((response) => {
            if (response.status === 200) {
              this.$store.dispatch("deleteProspect", prospect);
              this.$toasted.show(
                this.$lang.modal_AccountDeleted,
                this.$toastedSuccess
              );
            } else {
              this.$toasted.show(
                this.$lang.general_ToastError,
                this.$toastedFailure
              );
            }

            this.prospectToDelete = null;
          })
          .catch((err) => {
            console.error(err);

            this.$toasted.show(
              this.$lang.general_ToastError,
              this.$toastedFailure
            );
          });
      },

      getStatus(prospect) {
        if (prospect.isBanned) {
          return "Banned";
        } else if (prospect.isConvoLimited) {
          return "ConvoLimited";
        } else {
          return "Active";
        }
      },

      getStatusDisplayText(prospect) {
        return this.$lang[`status_${this.getStatus(prospect)}`];
      },
    },
  };
</script>

<style lang="scss" scoped>
  .prospect-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .prospect-page-section-table {
    flex-grow: 1;
  }

  .prospect-page-table-container {
    height: 100%;
  }

  .prospect-page-header {
    background-color: $white;
    z-index: 1;
  }

  .prospect-page-header-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 2rem;
    position: relative;
  }

  .prospect-page-header-title-container {
    margin-right: 2.5rem;
    margin-bottom: 1.25rem;
    width: 100%;
  }

  .prospect-page-header-controls {
    display: flex;
    flex-direction: column;
  }

  .prospect-page-header-search {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    margin-bottom: 0.75rem;
    margin-right: 0.75rem;
  }

  .prospect-page-header-title {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 0.5rem;
  }

  .prospect-page-header-description {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .prospect-page-header-export-container {
    display: none;

    @media (min-width: 640px) {
      display: flex;
      align-items: center;
    }
  }

  .prospect-page-search-icon {
    width: 1rem;
    height: 1rem;
  }

  .prospect-page-header-refresh-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    font-size: 0.75rem;
    line-height: 1rem;
    color: $secondary-text;
  }

  .prospect-page-header-refresh-text {
    &.mobile {
      display: block;
    }

    &.desktop {
      display: none;
    }
  }

  .prospect-page-header-refresh-icon {
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
  }

  .prospect-page-table-container {
    overflow-x: auto;
  }

  .prospect-page-table {
    width: 100%;
  }

  .prospect-page-table-header {
    color: $grey-500;
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .prospect-page-table-header-col {
    font-weight: 500;
    color: $grey-500;
    font-size: 0.75rem;
    line-height: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    white-space: nowrap;
  }

  .prospect-page-table-header-label {
    display: flex;
    align-items: center;

    &.sortable {
      cursor: pointer;
    }
  }

  .prospect-page-table-header-sort-icon {
    margin-left: 0.5rem;
    width: 1.25rem;
    height: 1.25rem;
    flex: none;
  }

  .prospect-page-table-body {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .prospect-page-table-row {
    border-bottom-width: 1px;
    height: 3.5rem;

    &.skeleton {
      animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    }
  }

  .prospect-page-table-date-cell {
    white-space: nowrap;
  }

  .prospect-page-skeleton-cell {
    padding-right: 1.25rem;
  }

  .prospect-page-skeleton-row-content {
    background-color: $grey-200;
    border-radius: 0.5rem;
    width: 66.67%;
    height: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .prospect-page-empty-row {
    height: 3.5rem;
    position: relative;
  }

  .prospect-page-empty-text {
    position: absolute;
    top: 1rem;
    left: 0.5rem;
    color: $grey-500;
  }

  .prospect-page-empty-search-row {
    height: 3.5rem;
  }

  .prospect-page-empty-search-cell {
    color: $grey-500;
  }

  @media (min-width: 1024px) {
    .prospect-page-header-search {
      margin-bottom: 0;
    }
    .prospect-page-header-controls {
      flex-direction: row;
      width: 24rem;
    }

    .prospect-page-header-title-container {
      margin-bottom: 0;
      width: 60%;
    }

    .prospect-page-header-details {
      flex-direction: row;
    }

    .prospect-page-header {
      position: sticky;
      top: 0;
    }

    .prospect-page-header-export-button {
      margin-left: 0.75rem;
    }

    .prospect-page-header-refresh-container {
      justify-content: flex-end;
    }

    .prospect-page-header-refresh-text {
      &.mobile {
        display: none;
      }

      &.desktop {
        display: block;
      }
    }

    .prospect-page-header-refresh-icon {
      width: 0.75rem;
      height: 0.75rem;
      margin-left: 0.5rem;
    }
  }

  ::v-deep {
    .common-dropdown.status-dropdown {
      justify-content: start;
    }

    .common-dropdown.prospect-page-action-dropdown .common-dropdown-button {
      border: none;
    }

    .prospect-page-action-dropdown-icon {
      height: 1rem;
      width: 1rem;
    }

    .common-dropdown.prospect-page-action-dropdown
      .prospect-page-delete-user-option {
      color: $salmon-400;

      &:hover {
        background-color: $salmon-400;
        color: $white;
      }
    }

    .status-active .common-dropdown-button {
      color: $green-400;
      background-color: $green-100;
    }

    .status-banned .common-dropdown-button,
    .status-convolimited .common-dropdown-button {
      color: $pink-400;
      background-color: $pink-100;
    }

    .status-dropdown .common-dropdown-button {
      border-radius: 28px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      width: 110px;
      height: 32px;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
</style>
