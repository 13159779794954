<template>
  <div class="table-headers">
    <div
      v-for="header of headers"
      :key="header.name"
      class="table-headers-header font-primary-bold"
      @click="toggleSort(header, value)">
      <span class="table-headers-name">{{ header.name }}</span>
      <TableHeadersSort :header="header" :activeSort="value" />
    </div>
  </div>
</template>

<script>
  import TableHeadersSort from "@/components/common/TableHeadersSort.vue";

  export default {
    name: "table-headers",
    components: {
      TableHeadersSort,
    },
    props: {
      value: {
        type: Object,
        required: true,
      },
      headers: {
        type: Array,
        required: true,
      },
    },
    methods: {
      toggleSort(header, value) {
        if (!header.sortProperty) {
          return;
        }
        const reverse =
          value.sortProperty === header.sortProperty ? !value.reverse : true;
        const newSort = { ...header, reverse };
        this.$emit("input", newSort);
      },
    },
  };
</script>

<style scoped lang="scss">
  .table-headers {
    padding: 14px;
    display: grid;
    grid-template-columns: var(--table-columns);
    gap: 14px;
    cursor: pointer;
    margin-right: 28px; //
  }

  .table-headers-header {
    display: flex;
    overflow: hidden;
  }

  .table-headers-name {
    margin: auto 0;
  }
</style>
