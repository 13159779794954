var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.classPrefix},[_c('div',{class:(_vm.classPrefix + "-header")},[_c('div',{class:(_vm.classPrefix + "-header-details")},[_c('span',{class:(_vm.classPrefix + "-header-title-container")},[_c('h1',{class:(_vm.classPrefix + "-header-title")},[_vm._v(" "+_vm._s(_vm.$lang.prospectsPage_Title)+" ")]),_c('p',{class:(_vm.classPrefix + "-header-description")},[_vm._v(" "+_vm._s(_vm.$lang.prospectsPage_ListDescription)+" ")])]),_c('div',{class:(_vm.classPrefix + "-header-controls")},[_c('div',{class:(_vm.classPrefix + "-header-search")},[_c('common-input',{attrs:{"placeholder":_vm.$lang.general_SearchPlaceholder,"variant":"search","icon":""},model:{value:(_vm.searchParameter),callback:function ($$v) {_vm.searchParameter=$$v},expression:"searchParameter"}},[_c('img',{class:(_vm.classPrefix + "-search-icon"),attrs:{"src":require('@/assets/search-icon.svg')}})])],1),_c('div',{class:(_vm.classPrefix + "-header-export-container")},[_c('common-button',{class:(_vm.classPrefix + "-header-export-button"),attrs:{"variant":"secondary","icon":""},on:{"click":_vm.exportTableData}},[_c('template',{slot:"icon"},[_c('img',{attrs:{"src":require('@/assets/download-icon.svg')}})]),_vm._v(" "+_vm._s(_vm.$lang.prospectsPage_Export)+" ")],2)],1)])]),_c('div',{class:(_vm.classPrefix + "-header-refresh-container")},[_c('div',{class:(_vm.classPrefix + "-header-refresh-text mobile")},[_vm._v(" "+_vm._s(_vm.$lang.prospectsPage_ScrollInstructions)+" ")]),_c('p',{class:[
          (_vm.classPrefix + "-header-refresh-text desktop"),
          { 'animate-pulse': !_vm.lastFetched } ]},[_vm._v(" "+_vm._s(_vm.dataFetchDisplayText)+" ")]),_c('img',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'bottom', arrow: true, maxWidth: '500px' }),expression:"{ placement: 'bottom', arrow: true, maxWidth: '500px' }"}],class:[
          (_vm.classPrefix + "-header-refresh-icon"),
          { 'animate-spin': _vm.fetching } ],attrs:{"src":require('@/assets/spinner-icon.svg'),"content":"Fetch most recent data"},on:{"click":_vm.fetchMostRecent}})])]),_c('BanRequests'),_c('section',{class:[(_vm.classPrefix + "-section-table")]},[_c('div',{class:[(_vm.classPrefix + "-table-container")]},[_c('table',{class:(_vm.classPrefix + "-table")},[_c('thead',{class:(_vm.classPrefix + "-table-header")},[_c('tr',_vm._l((_vm.tableHeaders),function(header){return _c('th',{key:header.label,class:(_vm.classPrefix + "-table-header-col"),attrs:{"scope":"col"}},[_c('div',{class:[
                  (_vm.classPrefix + "-table-header-label"),
                  { sortable: header.sortable } ],on:{"click":function($event){header.sortable
                    ? _vm.sortByColumn(
                        header.sortable.property,
                        header.sortable.dataType
                      )
                    : null}}},[_vm._v(" "+_vm._s(header.label)+" "),(header.sortable)?_c('div',{class:(_vm.classPrefix + "-table-header-sort-icon")},[_c('sort-icon',{attrs:{"active":_vm.activeSort === header.sortable.property}})],1):_vm._e()])])}),0)]),_c('tbody',{class:(_vm.classPrefix + "-table-body")},[_vm._l((_vm.displayedProspects),function(prospect){return _c('tr',{key:prospect.email,class:[
              (_vm.classPrefix + "-table-row"),
              { hidden: _vm.emptySearchResults } ],on:{"click":function($event){return _vm.goToProspectProfile(
                prospect.id,
                ((prospect.firstName) + " " + (prospect.lastName)),
                prospect.email
              )}}},[_c('td',[_vm._v(_vm._s(prospect.lastName))]),_c('td',[_vm._v(_vm._s(prospect.firstName))]),_c('td',[_vm._v(_vm._s(prospect.email))]),_c('td',[_c('common-dropdown',{class:[
                  ("status-dropdown status-" + (_vm.getStatus(
                    prospect
                  ).toLowerCase())) ],attrs:{"placeholder":_vm.getStatusDisplayText(prospect),"fixedPlaceholder":"","variant":"","options":_vm.getStatusDropdownOptions(prospect)},on:{"input":function($event){return _vm.handleStatusClick($event, prospect)}}})],1),_c('td',[_c('div',{class:(_vm.classPrefix + "-table-date-cell")},[_vm._v(" "+_vm._s(prospect.signedUpDate)+" ")])]),_c('td',[_c('common-dropdown',{class:(_vm.classPrefix + "-action-dropdown"),attrs:{"alignDropdownLeft":"","options":_vm.getActionsDropdownOptions(),"button":{
                  variant: 'icon',
                  icon: true,
                  iconOnly: true,
                }},on:{"input":function($event){return _vm.handleActionClick($event, prospect)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('img',{class:(_vm.classPrefix + "-action-dropdown-icon"),attrs:{"src":require('@/assets/ellipsis-icon.svg')}})]},proxy:true}],null,true)})],1)])}),_vm._l((6),function(n,index){return _c('tr',{key:("skeleton-row-" + index),class:[
              (_vm.classPrefix + "-table-row skeleton"),
              { hidden: _vm.prospects.length || _vm.noProspectsToDisplay } ]},_vm._l((_vm.tableHeaders),function(header){return _c('td',{key:((header.label) + "-skeleton"),class:(_vm.classPrefix + "-skeleton-cell")},[_c('div',{class:(_vm.classPrefix + "-skeleton-row-content")})])}),0)}),_c('tr',{class:[
              (_vm.classPrefix + "-empty-row"),
              { hidden: !_vm.noProspectsToDisplay } ]},[_c('td',[_c('div',{class:(_vm.classPrefix + "-empty-text")},[_vm._v(" "+_vm._s(_vm.$lang.prospectsPage_NoProspects)+" ")])])]),_c('tr',{class:[
              (_vm.classPrefix + "-empty-search-row"),
              { hidden: !_vm.emptySearchResults } ]},[_c('td',{class:(_vm.classPrefix + "-empty-search-cell")},[_vm._v(" "+_vm._s(_vm.$lang.prospectsPage_NoSearchResults)+" ")])])],2)])])]),(_vm.prospectToDelete)?_c('delete-modal',{attrs:{"user":_vm.prospectToDelete},on:{"close":function($event){_vm.prospectToDelete = null},"delete-user":_vm.deleteUser}}):_vm._e(),_c('common-table-footer',{attrs:{"valueLabel":"Prospects","itemCount":_vm.prospects.length,"numberOfPages":_vm.numberOfPages,"currentPage":_vm.currentPage,"firstIndex":_vm.firstIndex,"lastIndex":_vm.lastIndex},on:{"updateRowsPerPage":_vm.updateRowsPerPage,"updateCurrentPage":_vm.updateCurrentPage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }