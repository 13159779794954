import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p-modal',{attrs:{"title":_vm.title,"size":"xl","value":_vm.value,"saveButtonText":_vm.$lang.general_Select,"isSaveDisabled":!_vm.validSelections.length},on:{"input":_vm.closeModal,"cancel":_vm.closeModal,"save":_vm.selectUsers},scopedSlots:_vm._u([(!_vm.isAmbassador)?{key:"actions",fn:function(){return [_c(VBtn,{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.$emit('addManually')}}},[_c(VIcon,{attrs:{"color":"blue lighten-1"}},[_vm._v("$plus")]),_vm._v(" "+_vm._s(_vm.$lang.addManuallyModal_title)+" ")],1),_c(VBtn,{attrs:{"color":"primary","depressed":"","disabled":!_vm.validSelections.length},on:{"click":_vm.selectUsers}},[_vm._v(" "+_vm._s(_vm.$lang.general_Select)+" ")])]},proxy:true}:null],null,true)},[_c('p',[_vm._v(_vm._s(_vm.modalSubtitle))]),_c('MultiUserSelectSearch',{staticClass:"multi-user-select-search",attrs:{"filterOptions":_vm.headers},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c(VDataTable,{staticClass:"multi-user-select-table",attrs:{"headers":_vm.headers,"items":_vm.filteredUsers,"item-key":"email","item-class":_vm.getItemClass,"items-per-page":-1,"singleSelect":_vm.isSingleSelect,"show-select":"","hide-default-footer":"","fixed-header":""},on:{"click:row":_vm.select},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [(!_vm.isSingleSelect)?_c(VSimpleCheckbox,{attrs:{"color":"primary","value":props.value || props.indeterminate,"indeterminate":props.indeterminate,"hide-details":""},on:{"input":on.input}}):_vm._e()]}},{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
var item = ref.item;
return [(_vm.isAutoMatch || !_vm.isMatchedUser(item) || _vm.isClickedUser(item))?_c(VSimpleCheckbox,{attrs:{"color":"primary","value":isSelected},on:{"input":select}}):_vm._e()]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('Avatar',{attrs:{"imgSrc":item.imageURL,"left":""}}),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isActive ? _vm.$lang.general_Yes : _vm.$lang.general_No)+" ")]}}],null,true),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }