// Dashboard Main Actions
export const SET_ACTIVE_YEAR = "setActiveYear";
export const FETCH_DASHBOARD = "fetchDashboard";
export const SET_LAST_FETCHED = "setLastFetched";
export const LOGOUT = "logout";

// Dashboard Prospect Actions
export const FETCH_PROSPECTS = "fetchProspects";

// Dashboard Ambassador Actions
export const FETCH_CURRENT_PARENTS = "fetchCurrentParents";
export const FETCH_INVITED_AMBASSADORS = "fetchInvitedAmbassadors";
export const SET_AMBASSADOR = "setAmbassador";
export const SET_AMBASSADORS = "setAmbassadors";
export const DELETE_AMBASSADOR = "deleteAmbassador";
export const DELETE_INVITED_AMBASSADOR = "deleteInvitedAmbassador";
export const REMIND_INVITED_AMBASSADOR = "remindInvitedAmbassador";
export const CREATE_BULK_INVITE = "createBulkInvite";
export const ARCHIVE_AMBASSADORS = "archiveAmbassadors";
export const RESTORE_AMBASSADORS = "restoreAmbassadors";
export const FIND_AMBASSADOR = "findAmbassador";

// Dashboard Administrator Actions
export const REGISTER_ADMIN = "registerAdmin";
export const FETCH_ADMINS = "fetchAdmins";
export const DELETE_ADMIN = "deleteAdmin";
export const VERIFY_SUPER_ADMIN = "verifySuperAdmin";
export const SUPER_ADMIN_GET_ALL_ORGANIZATIONS =
  "superAdminGetAllOrganizations";

// Dashboard Branding Actions
export const FETCH_BRANDING = "fetchBranding";
export const SET_BRANDING = "setBranding";
export const SET_PROFILE_FIELDS = "setProfileFields";

// Dashboard Conversation Actions
export const FETCH_CONVERSATIONS = "fetchConversations";
export const CLOSE_CONVERSATION = "closeConversation";

// Outreach Actions
export const CREATE_EMAIL_INVITE_REQUEST = "createEmailInviteRequest";
export const CREATE_CALL_INVITE_REQUEST = "createCallInviteRequest";
export const CREATE_VIDEO_INVITE_REQUEST = "createVideoInviteRequest";
export const FETCH_ALL_CALL_OUTREACH = "fetchAllCallOutreach";
export const FETCH_ALL_EMAIL_OUTREACH = "fetchAllEmailOutreach";
export const FETCH_ALL_VIDEO_OUTREACH = "fetchAllVideoOutreach";
export const FETCH_ALL_OUTREACH = "fetchAllOutreach";
export const DELETE_OUTREACH = "deleteOutreach";
export const REJECT_VIDEO_OUTREACH_SUBMISSION = "rejectVideoOutreachSubmission";
export const DELETE_VIDEO_OUTREACH_SUBMISSION = "deleteVideoOutreachSubmission";

// Campaign Outreach Actions
export const FETCH_CAMPAIGNS = "fetchCampaigns";

// Home Screen Actions
export const SET_VISITOR_VIEWS = "setVisitorViews";
export const SET_ENGAGEMENT_STATS = "setEngagementStats";
export const SET_ENGAGEMENT_STATS_VIDEO = "setEngagementStatsVideo";
export const SET_ENGAGEMENT_GRAPH = "setEngagementGraph";
export const SET_PENDING_VIDEO_INVITATIONS = "setPendingVideoInvitations";
export const SET_PENDING_VIDEO_STORIES = "setPendingVideoStories";
export const SET_CONVERSATION_GRAPH = "setConversationGraph";
export const RESET_HOME_STATE = "resetHomeState";
