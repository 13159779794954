<template>
  <common-accordion :isOpen="isOpen">
    <template v-slot:header>
      <div class="admin-grid-row" @click="isOpen = !isOpen">
        <SuperAdminLogo
          class="admin-grid-cell"
          :srcUrl="organization.logoUrl"
          :linkUrl="organization.url"
          :primaryColor="organization.color"
          :title="organization.url" />
        <div class="admin-grid-cell" :title="organization.name">
          {{ organization.name }}<br />
          <a
            class="link"
            rel="noreferrer"
            :href="organization.url"
            target="_blank"
            >{{ organization.url }}</a
          >
        </div>
        <div class="admin-grid-cell">
          <div>
            <ppicon name="star" />
            <span class="admin-premium-features">{{
              organization.activePremiumFeatures
            }}</span>
          </div>
        </div>
        <div class="admin-grid-cell">
          <common-button
            class="admin-view-btn"
            :class="{ show: isOpen }"
            variant="outline"
            @click="switchOrganization(organization.name)">
            {{ $lang.superAdminPanel_ViewDashboard }}
          </common-button>
        </div>
      </div>
    </template>

    <div class="admin-organization">
      <!-- display name -->
      <div class="admin-form-group">
        <label class="admin-form-input">
          <div class="admin-form-label">
            {{ $lang.superAdminPanel_DisplayName }}
          </div>
          <common-input v-model.trim="organization.displayName"></common-input>
        </label>
        <common-button
          iconOnly
          @click="saveOrganization"
          :aria-label="$lang.general_Save">
          <template slot="icon"><mdicon name="content-save" /></template>
        </common-button>
      </div>

      <!-- features -->
      <div>
        <div class="admin-section-title">
          {{ $lang.superAdminPanel_FeatureTitle }}
        </div>

        <div class="admin-features">
          <div
            v-for="feature of getOrganizationFeaturesOptions(organization)"
            :key="feature.label + organization._id"
            class="admin-feature-wrapper">
            <label class="admin-feature">
              <common-toggle
                :value="feature.value"
                @input="
                  $emit('feature-toggle', {
                    organization,
                    option: { ...feature, value: $event },
                  })
                " />

              <span
                class="admin-feature-label font-primary"
                :class="{ active: feature.value }"
                >{{ feature.label }}</span
              >
            </label>
          </div>
        </div>
      </div>

      <!-- notes -->
      <div>
        <div class="admin-section-title">
          {{ $lang.superAdminPanel_NotesTitle }}
          <div class="font-secondary">
            {{ $lang.superAdminPanel_NotesSubtext }}
          </div>
        </div>

        <div v-for="note of notes" :key="note.id" class="admin-notes-wrapper">
          <common-dropdown
            class="admin-notes-dropdown"
            :options="dropdownOptions"
            variant="icon"
            alignDropdownLeft
            @input="dropdownEventHandler(note, $event)">
            <template v-slot:icon><ppicon name="ellipsis-vertical" /></template>
          </common-dropdown>
          <div class="admin-note">{{ note.text }}</div>
          <div class="admin-note-footer font-secondary">
            <span>By:&#8192;</span>
            <span class="author font-secondary-bold">{{
              getAuthor(note)
            }}</span>
            <span class="dot">&#9679;</span>
            <span>{{ getFormattedDate(note.createdDate) }}</span>
          </div>
        </div>
        <div class="admin-form-group">
          <common-input v-model.trim="note.text" :maxLength="400" />
          <common-button
            iconOnly
            @click="addNote"
            :aria-label="$lang.general_Save">
            <template slot="icon"><mdicon name="content-save" /></template>
          </common-button>
        </div>
      </div>
    </div>
  </common-accordion>
</template>

<script>
  import screens from "@/constants/screens";
  import organizationService from "@/services/api/organization.service.js";
  import dashboardService from "@/services/api/dashboard.service";
  import dateService from "@/services/date.service.js";
  import { toFullName } from "@/shared/utils.js";
  import { mapGetters } from "vuex";
  import { GET_CURRENT_USER, GET_ACTIVE_YEAR } from "@/store/getters.type";
  import SuperAdminLogo from "@/components/dashboard/superAdmin/SuperAdminLogo.vue";

  export default {
    name: "SuperAdminAccordion",
    components: {
      SuperAdminLogo,
    },
    props: {
      organization: {
        type: Object,
        required: true,
      },

      features: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        activeSort: {
          sortProperty: "name",
          type: "string",
          reverse: false,
        },
        dropdownOptions: [
          { label: "Edit", value: "edit", icon: "pencil" },
          { label: "Delete", value: "delete", icon: "trash" },
        ],
        notes: [],
        note: { text: "" },
        isOpen: false,
        hover: false,
      };
    },

    computed: {
      actualYear() {
        return this.$route.params.year || this.activeYear;
      },

      ...mapGetters({
        getCurrentUser: GET_CURRENT_USER,
        activeYear: GET_ACTIVE_YEAR,
      }),
    },

    methods: {
      switchOrganization(orgName) {
        this.$router.push({
          name: screens.HOME_SCREEN,
          params: { orgName, year: this.actualYear },
        });
      },

      getOrganizationFeaturesOptions(organization) {
        return this.features.map(({ label, key }) => ({
          label,
          key,
          value: !!organization.features && organization.features[key],
        }));
      },

      getAuthor(note) {
        return toFullName(note.firstName, note.lastName);
      },

      getFormattedDate(createdAt) {
        return dateService.toDisplayDate(createdAt);
      },

      addNote() {
        if (this.note.text.length === 0) {
          return;
        }

        const note = {
          id: this.note.id,
          firstName: this.getCurrentUser.first,
          lastName: this.getCurrentUser.last,
          text: this.note.text,
        };

        const upsertNote = this.note.id
          ? organizationService.updateNote
          : organizationService.addNote;

        upsertNote(this.organization.id, note).then((response) => {
          if (response.status === 200) {
            this.notes.push(response.data);
            this.note.text = "";
          } else {
            this.$toasted.show(
              this.$lang.superAdminAccordion_NoteFailure,
              this.$toastedFailure
            );
          }
        });
      },

      dropdownEventHandler(note, strOptionLabel) {
        if (strOptionLabel === this.dropdownOptions[1].value) {
          this.deleteNote(note.id);
        } else {
          this.editNote(note);
        }
      },

      deleteNote(noteId) {
        organizationService
          .deleteNote(this.organization._id, noteId)
          .then((response) => {
            if (response.status === 200) {
              this.notes = this.notes.filter((note) => note.id !== noteId);
            } else {
              this.$toasted.show(
                this.$lang.superAdminAccordion_NoteFailure,
                this.$toastedFailure
              );
            }
          });
      },

      editNote(note) {
        this.note = note;
        this.notes = this.notes.filter((n) => n.id !== note.id);
      },

      async saveOrganization() {
        try {
          await dashboardService.updateBranding({
            name: this.organization.name,
            displayName: this.organization.displayName,
          });
          this.$toasted.show(
            this.$lang.superAdminPanel_DisplayNameSaveSuccess,
            this.$toastedSuccess
          );
        } catch {
          this.$toasted.show(
            this.$lang.general_ToastError,
            this.$toastedFailure
          );
        }
      },
    },

    watch: {
      organization: {
        handler(newOrganization) {
          this.notes = newOrganization.notes || [];
        },
        immediate: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .admin-grid-row {
    padding: 14px 34px 14px 14px;
    display: inline-grid;
    grid-template-columns: var(--table-columns);
    gap: 14px;
    width: 100%;
    min-height: 76px;
    content-visibility: auto; // 2022-05: only supported on Chrome

    &:hover .admin-view-btn {
      visibility: visible;
    }
  }

  .admin-view-btn {
    visibility: hidden;

    &.show {
      visibility: visible;
    }
  }

  @media (hover: none) {
    .admin-view-btn {
      visibility: visible;
    }
  }

  .admin-grid-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 280px;
    margin: auto 0;
  }

  .admin-premium-features {
    margin-left: 10px;
  }

  .admin-organization {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .admin-section-title {
    font-weight: 500;
    margin-bottom: 11px;
  }

  .admin-features {
    width: 100%;
    display: inline-grid;
    grid-template-columns: repeat(auto-fill, 300px);
    gap: 1rem;
    margin-right: 22px;
  }

  .admin-feature-wrapper {
    display: flex;
  }

  .admin-feature {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .admin-feature-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;

    &.active {
      color: $green-400;
      font-weight: 500;
    }
  }

  .admin-notes-subtext {
    margin-bottom: 11px;
  }

  .admin-notes-wrapper {
    background-color: $grey-100;
    border: solid 1px $grey-200;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 12px;
  }

  .admin-notes-dropdown {
    float: right;
  }

  .admin-note-footer {
    display: flex;
    align-items: center;
    margin-top: 12px;

    .author {
      color: $blue-400;
    }

    .dot {
      margin: 0 10px;
      font-size: 8px;
      line-height: 16px;
    }
  }

  .admin-form-group {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 12px;
  }

  .admin-form-input {
    width: 100%;
  }
  .admin-form-label {
    font-weight: 500;
    padding-bottom: 0.5rem;
  }

  .link {
    color: $blue-400;

    &:hover {
      text-decoration: underline;
    }
  }
</style>
