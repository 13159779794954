<template>
  <v-container fluid>
    <div class="campaign-header">
      <div class="campaign-title-schedule">
        <div class="campaign-title">
          <v-text-field
            v-if="showTitleEdit && currentStep > 1"
            v-model="data.campaign.title"
            :counter="50"
            :label="$lang.campaignOutreach_outreachTypeInputPlaceholder"
            maxlength="50"
            outlined
            hide-details
            dense
            @input="isDirty = true"></v-text-field>
          <h1 v-else>
            {{ data.campaign.title }}
          </h1>
          <v-icon
            v-if="currentStep > 1"
            small
            right
            @click="showTitleEdit = !showTitleEdit">
            {{ showTitleEdit ? "$close" : "$pencil" }}
          </v-icon>
        </div>
        <div v-if="data.campaign.scheduledFor">
          <a @click="scheduleModal = true">
            {{ $lang.campaign_ScheduledFor.format(scheduleDateFormatted) }}
          </a>
        </div>
      </div>

      <v-spacer></v-spacer>

      <div class="campaign-actions">
        <v-btn depressed @click="onExitCampaignClick">
          {{ $lang.campaign_Exit }}
        </v-btn>
        <v-btn
          depressed
          color="primary"
          :disabled="!steps[0].complete"
          @click="saveCampaign">
          <v-icon left>$content-save</v-icon>
          {{ $lang.campaign_Save }}
        </v-btn>
      </div>
    </div>

    <v-stepper alt-labels flat v-model="currentStep">
      <v-stepper-header>
        <v-divider></v-divider>
        <template v-for="(step, i) of steps">
          <v-stepper-step
            :key="`${step.label}-step`"
            :step="i + 1"
            :complete="showComplete(i, step.complete)"
            :editable="showComplete(i, step.complete)"
            edit-icon="$complete">
            <!-- edit icon overrides complete icon -->
            {{ step.label }}
          </v-stepper-step>
          <v-divider :key="`${step.label}-divider`"></v-divider>
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content
          v-for="(step, i) of steps"
          :key="`${step.label}-content`"
          :step="i + 1">
          <component
            v-if="ready"
            :is="step.component"
            :data="data"
            :csvReplaced="csvReplaced"
            :allAmbassadors="allAmbassadors"
            @set-csv-replaced="csvReplaced = $event"
            @update="updateData"
            @complete="completeStep(i, $event)"
            @save="saveCampaign"
            @send="sendCampaign" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <div class="campaign-stepper-buttons">
      <v-btn
        text
        color="primary"
        v-if="currentStep > 1"
        @click="goToPreviousStep">
        <v-icon left>$menu-left</v-icon>
        {{ $lang.stepper_Back }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-tooltip
        :open-on-click="false"
        :open-on-focus="false"
        bottom
        :disabled="
          steps[currentStep - 1].complete || !steps[currentStep - 1].tooltip
        ">
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn
              v-if="currentStep < steps.length"
              color="primary"
              class="right"
              :disabled="!steps[currentStep - 1].complete"
              v-on="on"
              @click="goToNextStep">
              {{ $lang.stepper_Next }}
              <v-icon right>$chevron-right</v-icon>
            </v-btn>
          </div>
        </template>
        <span>{{ steps[currentStep - 1].tooltip }}</span>
      </v-tooltip>
    </div>

    <p-modal
      :title="$lang.campaign_NonMatchedUsersModalTitle"
      :saveButtonText="$lang.general_Continue"
      v-model="showUnmatchedRecipientsModal"
      @cancel="showUnmatchedRecipientsModal = false"
      @save="continueWithUnmatchedUsers">
      {{ $lang.campaign_NonMatchedUsersModalBody }}
    </p-modal>

    <p-modal
      :title="$lang.campaign_UnsavedChangesModalTitle"
      :cancelButtonText="$lang.general_No"
      :saveButtonText="$lang.general_Yes"
      size="sm"
      v-model="showDirtyModal"
      @cancel="exitCampaign"
      @save="saveDirtyCampaign">
      {{ $lang.campaign_UnsavedChangesModalBody }}
    </p-modal>

    <!-- schedule modal -->
    <CampaignScheduleModal
      v-model="scheduleModal"
      :date="data.campaign.scheduledFor"
      @save="editSchedule" />
  </v-container>
</template>

<script>
  import { mapGetters } from "vuex";

  import CampaignOutreachTypeStep from "./CampaignOutreachTypeStep.vue";
  import CampaignEmailTemplateStep from "./CampaignEmailTemplateStep.vue";
  import CampaignImportStep from "./CampaignImportStep.vue";
  import CampaignMatchStep from "./CampaignMatchStep.vue";
  import CampaignSendStep from "./CampaignSendStep.vue";
  import CampaignScheduleModal from "./CampaignScheduleModal.vue";

  import { FETCH_CAMPAIGNS } from "@/store/actions.type";
  import dashboardService from "@/services/api/dashboard.service";
  import dateService from "@/services/date.service";
  import { toFullName } from "@/shared/utils";

  export default {
    name: "CampaignStepper",
    components: { CampaignScheduleModal },
    props: {
      campaignData: {},
    },
    data() {
      return {
        ready: false,
        csvReplaced: false,
        data: {
          campaign: {
            title: null,
            inviteType: null,
            email: {
              body: "",
              subject: "",
            },
            autoAccept: false,
          },
          pendingInvitations: [],
          ...this.campaignData,
        },
        isDirty: false,
        showDirtyModal: false,

        steps: [
          {
            label: this.$lang.campaign_StepSelectType,
            component: CampaignOutreachTypeStep,
            tooltip: this.$lang.campaignOutreach_outreachTypeTooltip,
            complete: false,
          },
          {
            label: this.$lang.campaign_StepEmailTemplate,
            component: CampaignEmailTemplateStep,
            tooltip: this.$lang.campaignOutreach_outreachEmailTooltip,
            complete: false,
          },
          {
            label: this.$lang.campaign_StepImportList,
            component: CampaignImportStep,
            tooltip: this.$lang.campaign_UploadCsvTooltip,
            complete: false,
          },
          {
            label: this.$lang.campaign_StepCreateMatches,
            component: CampaignMatchStep,
            complete: false,
          },
          {
            label: this.$lang.campaign_StepSendOutreachRequests,
            component: CampaignSendStep,
            complete: false,
          },
        ],

        showTitleEdit: false,

        allAmbassadors: [],

        showUnmatchedRecipientsModal: false,

        importedInvitees: [],

        scheduleModal: false,
      };
    },
    computed: {
      ...mapGetters({ branding: "getBranding" }),

      currentStep: {
        get() {
          const hash = this.$route.hash.slice(1);
          const step = Number.parseInt(hash);
          return step || 1;
        },
        set(val) {
          if (val >= 1 && val <= this.steps.length) {
            this.$router.replace({ hash: "#" + val });
          }
        },
      },
      scheduleDateFormatted() {
        return dateService.toDisplayDateTimeTz(this.data.campaign.scheduledFor);
      },
    },

    async created() {
      const uniqueInvites = new Map();

      for (const invite of this.data.pendingInvitations) {
        uniqueInvites.set(invite.invitee.email, invite);
      }
      this.data.pendingInvitations = Array.from(uniqueInvites.values());

      await this.getAmbassadors({ includeArchived: false }).then(
        (ambassadors) => {
          this.allAmbassadors = ambassadors ?? [];
        }
      );
      this.$store.dispatch(FETCH_CAMPAIGNS);

      this.ready = true;
    },

    methods: {
      updateData(data) {
        if ((data.campaign || data.pendingInvitations) && !data.isFirstUpdate) {
          this.isDirty = true;
        }

        if (data.importedInvitees) {
          this.importedInvitees = data.importedInvitees;
          delete data.importedInvitees;
        }

        Object.assign(this.data, data);
      },
      completeStep(i, val = true) {
        this.steps[i].complete = val;
      },

      editSchedule(date) {
        this.data.campaign.scheduledFor = date;
        this.isDirty = true;
        this.scheduleModal = false;
      },
      async saveCampaign(background) {
        if (background) {
          const options = { timeout: 1000 };
          this.$toast.success(this.$lang.campaign_AutoSave, options);
        }

        const response = await dashboardService.saveCampaign(this.data);

        if (response.status === 200) {
          Object.assign(this.data, response.data);

          if (typeof this.data.campaign.scheduledFor === "string") {
            this.data.campaign.scheduledFor = new Date(
              this.data.campaign.scheduledFor
            );
          }

          this.isDirty = false;

          if (!this.$route.params.campaignId) {
            this.$router.replace({
              params: { campaignId: this.data.campaign._id },
              hash: "#" + this.currentStep,
            });
          }
          if (!background) {
            this.$toast.success(this.$lang.campaign_SaveSuccessfully);
          }
        } else {
          this.$toast.success(this.$lang.general_ErrorMessage);
        }
      },
      async sendCampaign() {
        const response = await dashboardService.sendCampaign({
          campaignId: this.data.campaign._id,
        });
        if (response.status === 200) {
          this.$toast.success(this.$lang.campaign_SentSuccessfully);
        } else {
          this.$toast.success(this.$lang.general_ErrorMessage);
        }
      },

      async getAmbassadors(filters) {
        const data = {
          organization: this.branding.name,
          filters,
        };

        const response = await dashboardService.currentParents(data);
        if (response.status === 200) {
          return response.data.map((ambassador) => {
            ambassador.convoCount = ambassador.convoCount || 0;
            ambassador.name = toFullName(
              ambassador.first,
              ambassador.last,
              this.$lang.general_DeletedUser
            );
            let status = this.$lang.status_Active;
            if (ambassador.archived) {
              status = this.$lang.status_Archived;
            } else if (ambassador.isOnVacation) {
              status = this.$lang.status_Vacation;
            } else if (ambassador.isConvoLimited) {
              status = this.$lang.status_ConvoLimited;
            }
            ambassador.status = status;
            return ambassador;
          });
        } else {
          return null;
        }
      },
      goToPreviousStep() {
        this.currentStep--;

        if (this.isDirty && this.steps[0].complete) {
          this.saveCampaign(true);
        }
      },

      goToNextStep() {
        // If we have unmatched recipients and are on step 4, show unmatched recipients modal
        if (
          this.currentStep === 4 &&
          this.importedInvitees.some((invitee) => !invitee.inviterID)
        ) {
          this.showUnmatchedRecipientsModal = true;
        } else {
          this.next();
        }
      },

      continueWithUnmatchedUsers() {
        this.next();
        this.showUnmatchedRecipientsModal = false;
      },

      next() {
        this.currentStep++;

        if (this.isDirty && this.steps[0].complete) {
          this.saveCampaign(true);
        }
      },

      onExitCampaignClick() {
        // Check if there are changes, if there are, ask user if they'd like to save, otherwise, leave
        if (!this.isDirty) {
          this.exitCampaign();
        } else {
          this.showDirtyModal = true;
        }
      },

      exitCampaign() {
        this.showDirtyModal = false;
        this.$router.push({ name: "OUTREACH_SCREEN" });
      },

      async saveDirtyCampaign() {
        this.showDirtyModal = false;
        await this.saveCampaign();
        this.exitCampaign();
      },

      showComplete(index, isComplete) {
        return (
          isComplete &&
          index + 1 !== this.currentStep &&
          (index + 1 < this.currentStep ||
            !!this.data.campaign._id ||
            this.data.campaign.scheduledFor)
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  .campaign-header {
    display: flex;
    gap: 0.5rem;
    align-items: top;
    margin-bottom: 28px;

    @media (max-width: $md) {
      flex-direction: column;
    }
  }

  .campaign-title-schedule {
    display: flex;
    flex-direction: column;
    width: 40%;

    @media (max-width: $md) {
      width: 100%;
    }
  }

  .campaign-title {
    display: flex;
    align-items: center;
    height: 40px;

    h1 {
      margin-right: 30px;
    }
  }

  .campaign-actions {
    display: flex;
    gap: 25px;
  }
  .campaign-stepper-buttons {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
  }
</style>
