<template>
  <textarea
    v-if="fixed"
    class="focus:outline-none border rounded-lg p-3 text-sm w-full resize-none scrollbar-hide"
    :class="[fixedHeight]"
    v-model="textAreaValue"
    @input="$emit('input', textAreaValue)">
  </textarea>

  <textarea-autosize
    v-else
    class="focus:outline-none border rounded-lg p-3 text-sm w-full resize-none scrollbar-hide"
    v-model="textAreaValue"
    :rows="inputHeight ? '1' : '3'"
    :min-height="inputHeight ? 48 : 96"
    :autosize="!resizeDisabled"
    @input="$emit('input', textAreaValue)" />
</template>

<script>
  export default {
    name: "common-textarea",

    props: {
      value: {
        type: String,
        default: "",
      },

      resizeDisabled: {
        type: Boolean,
      },

      inputHeight: {
        type: Boolean,
      },

      fixed: {
        type: Boolean,
      },

      fixedHeight: {
        type: String,
      },
    },

    data() {
      return {
        textAreaValue: this.value,
      };
    },

    watch: {
      value(newVal) {
        this.textAreaValue = newVal;
      },
    },
  };
</script>
