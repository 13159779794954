<template>
  <OutreachRequestsTable :items="items" :columns="columns" />
</template>

<script>
  import OutreachRequestsTable from "./OutreachRequestsTable.vue";

  export default {
    components: { OutreachRequestsTable },
    props: {
      outreachRequests: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        columns: [
          "inviterName",
          "inviteeName",
          "inviteType",
          "sentAt",
          "status",
          "accountMade",
          "actions",
          "hasFeedback",
        ],
      };
    },
    computed: {
      items() {
        return this.outreachRequests.filter((outreach) => {
          return !outreach.campaignID;
        });
      },
    },
  };
</script>
