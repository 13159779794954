import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":_vm.maxWidth,"persistent":_vm.persistent},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"modal-title",class:{ 'success-title': _vm.success }},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.enableHeaderClose)?_c(VBtn,{staticClass:"modal-close",attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,[_vm._v("$close")])],1):_vm._e()],1),_c(VCardText,{staticClass:"modal-body text--primary",class:{ 'success-body': _vm.success }},[_vm._t("default")],2),(_vm.$slots.actions || _vm.showSaveButton || _vm.showCancelButton)?_c(VCardActions,[_c(VSpacer),_vm._t("actions",function(){return [(_vm.showCancelButton)?_c(VBtn,{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" "+_vm._s(_vm.cancelButtonText)+" ")]):_vm._e(),(_vm.showSaveButton)?_c(VBtn,{attrs:{"color":"primary","depressed":"","disabled":_vm.isSaveDisabled},on:{"click":function($event){return _vm.$emit('save')}}},[_vm._v(" "+_vm._s(_vm.saveButtonText)+" ")]):_vm._e()]}),(_vm.success)?_c(VSpacer):_vm._e()],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }