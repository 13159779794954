import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import Ripple from 'vuetify/lib/directives/ripple';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"match-toolbar"},[_c(VCheckbox,{staticClass:"select-all",attrs:{"label":"Select All","disabled":!_vm.value[0].ambassador,"hide-details":""},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}}),_c(VSpacer),_c(VBtn,{staticClass:"icon-button",attrs:{"icon":"","aria-label":_vm.$lang.general_Delete,"disabled":!_vm.value.some(function (match) { return match.selected; })},on:{"click":_vm.deleteMatches}},[_c(VIcon,[_vm._v("$delete")])],1),_c('SearchInput',{staticClass:"match-search",model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_vm._l((_vm.filteredMatches),function(match,i){return _c(VExpansionPanels,{key:'match' + ((match.ambassador && match.ambassador._id) || i),staticClass:"match-panel",attrs:{"flat":""}},[_c(VExpansionPanel,{attrs:{"readonly":!match.invites.length}},[_c(VExpansionPanelHeader,{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VIcon,{class:{ hidden: !match.invites.length },attrs:{"alt":""}},[_vm._v(" $chevron-down ")])]},proxy:true}],null,true)},[_c('div',{staticClass:"match-panel-header"},[_c(VSimpleCheckbox,{directives:[{def: Ripple,name:"ripple",rawName:"v-ripple"}],attrs:{"aria-label":"selected"},on:{"click":function($event){$event.stopPropagation();}},model:{value:(match.selected),callback:function ($$v) {_vm.$set(match, "selected", $$v)},expression:"match.selected"}}),_c('div',[_c(VBtn,{class:{ 'match-panel-button': match.ambassador },attrs:{"color":"primary","text":"","large":""},on:{"click":function($event){$event.stopPropagation();_vm.$emit('openUserSelect', {
                  match: match,
                  isAmbassador: true,
                  isSingleSelect: !(
                    !match.ambassador && !match.invites.length
                  ),
                })}}},[(match.ambassador)?[_c('Avatar',{staticClass:"match-ambassador-avatar",attrs:{"imgSrc":match.ambassador.imageURL,"size":"34"}}),_vm._v(" "+_vm._s(_vm.toFullName(match.ambassador))+" "),(match.ambassador.isOnVacation)?_c('IconTooltip',{attrs:{"icon":"$alert-circle","text":_vm.$lang.campaign_UserOnVacation}}):_vm._e()]:[_vm._v(" "+_vm._s(_vm.$lang.campaign_SelectAmbassadors)+" ")]],2)],1),_c('div',{staticClass:"match-arrow"},[_c('hr',{staticClass:"match-arrow-shaft"}),_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("$chevron-right")])],1),_c('div',{staticClass:"match-recipients"},[_c(VBtn,{class:{
                'match-panel-button':
                  match.hasMatches && match.invites.length,
              },attrs:{"color":"primary","text":"","large":"","disabled":!match.ambassador},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('openUserSelect', {
                  match: match,
                  isAmbassador: false,
                  isSingleSelect: false,
                })}}},[(match.invites.length > 1)?[_vm._v(" "+_vm._s(match.invites.length)+" "+_vm._s(_vm.$lang.campaign_Recipients)+" ")]:(match.invites.length === 1)?[_vm._v(" "+_vm._s(_vm.toFullName(match.invites[0].invitee))+" ")]:[_c(VIcon,{attrs:{"left":""}},[_vm._v("$plus")]),_vm._v(" "+_vm._s(_vm.$lang.campaign_SelectRecipients)+" ")]],2)],1)],1)]),_c(VExpansionPanelContent,[(match.invites.length)?_c(VSimpleTable,{staticClass:"match-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$lang.campaign_RecipientName))]),_vm._l((_vm.headers),function(col){return _c('th',{key:col.name},[_vm._v(" "+_vm._s(col.name)+" ")])})],2)]),_c('tbody',[_vm._l((match.invites),function(invite){return [(invite.invitee)?_c('tr',{key:invite._id},[_c('td',[_vm._v(_vm._s(_vm.toFullName(invite.invitee)))]),_vm._l((_vm.headers),function(col){return _c('td',{key:col.name},[_vm._v(" "+_vm._s(invite.dynamicColumns[col.name])+" ")])})],2):_vm._e()]})],2)]):_c('div',{staticClass:"match-table-empty-text"},[_vm._v(" "+_vm._s(_vm.$lang.general_TableNoContent)+" ")])],1)],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }