<template>
  <input
    type="checkbox"
    role="switch"
    class="toggle"
    :checked="checked"
    @click="toggle" />
</template>

<script>
  export default {
    name: "common-toggle",
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        checked: this.value,
      };
    },

    watch: {
      value(newVal) {
        this.checked = newVal;
      },
    },

    methods: {
      toggle() {
        this.$emit("input", !this.checked);
      },
    },
  };
</script>

<style scoped lang="scss">
  .toggle {
    appearance: none;
    min-width: 3rem;
    height: 1.5rem;
    background: #e5e7eb;
    border-radius: 24px;
    position: relative;
    transition: background-color 150ms ease;
    cursor: pointer;
  }
  .toggle:checked {
    background: $green-400;
  }
  .toggle:after {
    content: "";
    box-sizing: border-box;
    width: 1rem;
    height: 1rem;
    background: white;
    border-radius: 50%;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    transition: transform 150ms ease;
  }
  input:checked.toggle:after {
    transform: translateX(150%);
  }
</style>
