import APIService from "../../services/api/index";
import { FETCH_CAMPAIGNS } from "../actions.type.js";
import { GET_CAMPAIGNS } from "../getters.type.js";
import { UPDATE_CAMPAIGNS } from "../mutations.type.js";
const dashboardService = APIService.dashboard;

export const getInitialState = () => {
  return {
    campaignList: [],
  };
};

export const state = { ...getInitialState() };

export const actions = {
  async [FETCH_CAMPAIGNS]({ getters, commit }) {
    const data = getters.getBranding.name;
    const response = await dashboardService.getCampaigns(data);
    commit(UPDATE_CAMPAIGNS, response.data);
  },
};

export const mutations = {
  [UPDATE_CAMPAIGNS](state, campaigns) {
    state.campaignList = campaigns;
  },
};

export const getters = {
  [GET_CAMPAIGNS](state) {
    return state.campaignList;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
