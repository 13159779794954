<template>
  <OutreachRequestsTable :items="items" :columns="columns" />
</template>

<script>
  import OutreachRequestsTable from "./OutreachRequestsTable.vue";

  export default {
    components: { OutreachRequestsTable },
    props: {
      campaigns: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        columns: [
          "title",
          "sentAt",
          "inviteType",
          "invites",
          "ambassadorCount",
          "adminName",
          "actions",
        ],
      };
    },
    computed: {
      items() {
        return this.campaigns
          .filter((campaign) => {
            return campaign.sentAt;
          })
          .map((campaign) => {
            const ambassadorsSet = new Set(
              campaign.invites.map((invite) => invite.inviterID?.toString())
            );
            campaign.ambassadorCount = ambassadorsSet.size;
            return campaign;
          });
      },
    },
  };
</script>
