import httpService from "./http.service";

const request = httpService(process.env.VUE_APP_ENDPOINT_TWO);

function getChat() {
  return request({
    requireToken: true,
    method: "get",
    path: `dashboard/chat`,
  });
}

function newChat(pathParams, data) {
  return request({
    data,
    requireToken: true,
    method: "put",
    path: `dashboard/chat/new/${pathParams.userId}`,
  });
}

function newBulkMessage(data) {
  return request({
    data,
    requireToken: true,
    method: "post",
    path: `dashboard/chat/newBulkMessage`,
  });
}

function getConversation(pathParams) {
  return request({
    method: "get",
    requireToken: true,
    path: `dashboard/chat/${pathParams.conversationId}`,
  });
}

function validate(data) {
  return request({
    data,
    method: "post",
    path: "dashboard/validate",
  });
}

function review(data) {
  return request({
    data,
    method: "post",
    path: "dashboard/review",
  });
}

function closeConversation(conversationID) {
  return request({
    data: { conversationID },
    requireToken: true,
    method: "put",
    path: "users/closeConversation",
  });
}

export default {
  getChat,
  newChat,
  newBulkMessage,
  getConversation,
  validate,
  review,
  closeConversation,
};
