export function initRouteGuard(router, store) {
  router.beforeEach(async (to, from, next) => {
    if (
      !!process.env.VUE_APP_MAINTENANCE_END_DATE &&
      to.name !== "Maintenance"
    ) {
      next({
        name: "Maintenance",
      });
    }
    if (
      !process.env.VUE_APP_MAINTENANCE_END_DATE &&
      to.name === "Maintenance"
    ) {
      next({
        name: "Login",
      });
    }
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (store.getters.getCurrentUser) {
        return next();
      }
      const response = await store.dispatch("fetchDashboard");
      if (response.status === 200) {
        return next();
      }
      store.dispatch("logout");
      return next({
        name: "Login",
        query: {
          redirect: to.fullPath,
        },
      });
    }
    next();
  });
}
