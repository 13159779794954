<template>
  <div
    class="z-30 flex items-center justify-center absolute top-0 left-0 h-screen w-full"
  >
    <div
      class="flex items-center justify-center bg-gray-800 bg-opacity-50 h-full w-full fixed"
    >
      <div
        class="bg-white rounded-lg md:w-common-popup min-h-common-popup p-8 relative"
        :class="height"
      >
        <div class="absolute right-2 top-2" @click="closePopup">
          <svg
            class="ml-auto fill-current text-grey w-5 h-5 cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
        </div>
        <div class="flex flex-col justify-between">
          <div class="text-gray-900 font-medium text-lg">{{ headerText }}</div>
          <div class="text-sm mb-8 mt-6">{{ bodyText }}</div>
          <div
            class="flex"
            :class="{ 'w-1/2 self-end': singleOption, 'w-full': !singleOption }"
          >
            <common-button
              :variant="firstButton.variant"
              class="mr-1"
              @click="emitButtonClick('first-button')"
              >{{ firstButton.text }}</common-button
            >
            <common-button
              v-if="!singleOption"
              :variant="secondButton.variant"
              class="ml-1"
              @click="emitButtonClick('second-button')"
              >{{ secondButton.text }}</common-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  const CommonButton = () => import("./Button.vue");

  export default {
    name: "common-popup",
    components: {
      CommonButton,
    },
    props: {
      headerText: {
        type: String,
      },
      bodyText: {
        type: String,
      },
      firstButton: {
        type: Object,
      },
      secondButton: {
        type: Object,
      },
      height: {
        type: String,
        default: "h-common-popup",
      },
      singleOption: {
        type: Boolean,
      },
    },
    methods: {
      emitButtonClick(emitLabel) {
        this.$emit(emitLabel);
      },
      closePopup() {
        this.$emit("close");
      },
    },
    mounted() {
      document.body.style.overflow = "hidden";
    },
    beforeDestroy() {
      document.body.style.overflow = null;
    },
    unmounted() {
      document.body.style.overflow = null;
    },
  };
</script>
