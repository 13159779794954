<template>
  <v-text-field
    class="search-input"
    :placeholder="$lang.general_SearchPlaceholder"
    :aria-label="$lang.general_SearchPlaceholder"
    prepend-inner-icon="$magnify"
    filled
    outlined
    dense
    hide-details
    clearable
    :value="value"
    @input="$emit('input', $event)" />
</template>

<script>
  export default {
    name: "SearchInput",
    props: {
      value: String,
    },
  };
</script>

<style lang="scss" scoped>
  .search-input ::v-deep fieldset {
    border-color: transparent;
  }
</style>
