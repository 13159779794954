<template>
  <div class="table-footer">
    <div class="table-footer-preview">
      <p>
        Showing {{ strIndexes }} of {{ itemCount }}
        {{ valueLabel }}
      </p>
      <common-dropdown
        v-model="selected"
        :options="rowsPerPageDropdownOptions"
        size="sm"
        width="w-20" />
    </div>
    <common-stepper
      v-if="itemCount > 0"
      :numberOfSteps="numberOfPages"
      :completedSteps="numberOfPages"
      :currentStep="currentPage"
      @change-step="updateCurrentPage($event)" />
  </div>
</template>

<script>
  export default {
    props: {
      itemCount: {
        type: Number,
        required: true,
      },
      numberOfPages: {
        type: Number,
        required: true,
      },
      currentPage: {
        type: Number,
        default: 1,
      },
      firstIndex: {
        type: Number,
        default: 0,
      },
      lastIndex: {
        type: Number,
        required: true,
      },
      valueLabel: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        rowsPerPageDropdownOptions: [
          {
            label: "10",
            value: 10,
          },
          {
            label: "20",
            value: 20,
          },
          {
            label: "50",
            value: 50,
          },
          {
            label: "100",
            value: 100,
            default: true,
          },
          {
            label: "200",
            value: 200,
          },
        ],
        selected: {
          label: "100",
          value: 100,
        },
      };
    },
    computed: {
      strIndexes() {
        return this.itemCount === 0
          ? "0"
          : `${this.firstIndex + 1} - ${this.lastIndex}`;
      },
    },
    watch: {
      selected() {
        this.$emit("updateRowsPerPage", this.selected);
      },
    },
    methods: {
      updateCurrentPage(event) {
        this.$emit("updateCurrentPage", event);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .table-footer {
    background-color: $white;
    position: sticky;
    bottom: 0px;
    padding: 0.75rem 0 0.75rem 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    @media (min-width: $lg) {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
  }

  .table-footer-preview {
    margin-bottom: 0.75rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    p {
      font-size: $font-size-xs;
      line-height: $line-height-xs;
    }

    .common-dropdown {
      margin-left: 0.75rem;
    }

    @media (min-width: $lg) {
      margin-bottom: 0px;
    }
  }
</style>
