<template>
  <span :class="[name, 'pp-icon']"></span>
</template>

<script>
  export default {
    name: "icon",
    props: ["name"],
  };
</script>

<style scoped>
  @font-face {
    font-family: "peerpal-icons";
    src: url("../../assets/fonts/peerpal-icons.eot");
    src: url("../../assets/fonts/peerpal-icons.eot#iefix")
        format("embedded-opentype"),
      url("../../assets/fonts/peerpal-icons.woff") format("woff"),
      url("../../assets/fonts/peerpal-icons.ttf") format("truetype"),
      url("../../assets/fonts/peerpal-icons.svg#peerpal-icons") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  .pp-icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "peerpal-icons" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .pp-icon.academic-cap:before {
    content: "\e904";
  }
  .pp-icon.account-cancel:before {
    content: "\e91e";
  }
  .pp-icon.alert:before {
    content: "\e905";
  }
  .pp-icon.arrow-left:before {
    content: "\e906";
  }
  .pp-icon.bell:before {
    content: "\e907";
  }
  .pp-icon.block:before {
    content: "\e908";
  }
  .pp-icon.book-open:before {
    content: "\e909";
  }
  .pp-icon.chat:before {
    content: "\e90a";
  }
  .pp-icon.check:before {
    content: "\e900";
  }
  .pp-icon.chevron-down:before {
    content: "\e901";
  }
  .pp-icon.chevron-left:before {
    content: "\e90b";
  }
  .pp-icon.chevron-right:before {
    content: "\e90c";
  }
  .pp-icon.chevron-up:before {
    content: "\e902";
  }
  .pp-icon.close:before {
    content: "\e90d";
  }
  .pp-icon.close-circle:before {
    content: "\e90e";
  }
  .pp-icon.download:before {
    content: "\e903";
  }
  .pp-icon.ellipsis-vertical:before {
    content: "\e90f";
  }
  .pp-icon.feedback:before {
    content: "\e910";
  }
  .pp-icon.home:before {
    content: "\e911";
  }
  .pp-icon.menu:before {
    content: "\e912";
  }
  .pp-icon.message:before {
    content: "\e913";
  }
  .pp-icon.outreach:before {
    content: "\e914";
  }
  .pp-icon.pencil:before {
    content: "\e915";
  }
  .pp-icon.phone:before {
    content: "\e916";
  }
  .pp-icon.plus:before {
    content: "\e917";
  }
  .pp-icon.question-mark-circle:before {
    content: "\e918";
  }
  .pp-icon.save:before {
    content: "\e919";
  }
  .pp-icon.search:before {
    content: "\e91a";
  }
  .pp-icon.sign-out:before {
    content: "\e91b";
  }
  .pp-icon.star:before {
    content: "\e91c";
  }
  .pp-icon.trash:before {
    content: "\e91d";
  }
  .pp-icon.triangle-down:before {
    content: "\e91f";
  }
  .pp-icon.triangle-up:before {
    content: "\e920";
  }
  .pp-icon.user:before {
    content: "\e93e";
  }
</style>
