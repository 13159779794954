<template>
  <p-modal
    class="individual-outreach"
    :title="$lang.individualOutreach_Title"
    :value="value"
    @input="$emit('input', $event)"
    :saveButtonText="$lang.individualOutreach_ButtonSave"
    @cancel="$emit('input', false)"
    @save="promptInvite"
    :isSaveDisabled="$v.$invalid || saving">
    <common-multi-toggle
      class="individual-outreach-toggle"
      :selected="inviteType"
      :options="inviteTypeOptions"
      @toggle="inviteType = $event" />
    <v-select
      class="individual-outreach-select"
      v-model="selectedAmbassador"
      :items="ambassadors"
      :label="
        this.$lang.general_SelectVar.format(this.$lang.general_Ambassador)
      "
      :item-text="(item) => getFullName(item.first, item.last)"
      :error-messages="selectedAmbassadorErrors"
      :disabled="!!this.user"
      height="46"
      return-object
      @input="$v.selectedAmbassador.$touch()"
      @blur="$v.selectedAmbassador.$touch()">
      <template v-slot:item="{ item }">
        <Avatar
          :imgSrc="item.imageURL"
          class="individual-outreach-select-avatar" />
        {{ getFullName(item.first, item.last) }}
        <IconTooltip
          icon="$alert-circle"
          v-if="item.isOnVacation"
          :text="$lang.campaign_UserOnVacation" />
      </template>
      <template v-slot:selection="{ item }">
        <Avatar
          :imgSrc="item.imageURL"
          class="individual-outreach-select-avatar selection" />
        {{ getFullName(item.first, item.last) }}
        <IconTooltip
          v-if="item.isOnVacation"
          icon="$alert-circle"
          :text="$lang.campaign_UserOnVacation" />
      </template>
    </v-select>
    <div class="individual-outreach-row">
      <v-text-field
        :label="$lang.individualOutreach_FirstNameLabel"
        v-model="firstName"
        :error-messages="firstNameErrors"
        required
        outlined
        dense
        @input="$v.firstName.$touch()"
        @blur="$v.firstName.$touch()"></v-text-field>
      <v-text-field
        :label="$lang.individualOutreach_LastNameLabel"
        v-model="lastName"
        :error-messages="lastNameErrors"
        required
        outlined
        dense
        @input="$v.lastName.$touch()"
        @blur="$v.lastName.$touch()"></v-text-field>
    </div>
    <div class="individual-outreach-row">
      <v-text-field
        v-model="email"
        :label="$lang.individualOutreach_EmailLabel"
        :error-messages="emailErrors"
        required
        outlined
        dense
        @input="$v.email.$touch()"
        @blur="$v.email.$touch()"></v-text-field>
      <PhoneInput
        v-if="inviteType === callEnum"
        v-model="number"
        required
        :error-messages="phoneNumberErrors"
        ref="phone"
        @input="$v.number.$touch()"
        @blur="$v.number.$touch()" />
    </div>
    <div class="individual-outreach-row">
      <v-textarea
        v-model="message"
        :label="$lang.individualOutreach_MessageLabel"
        :error-messages="messageErrors"
        outlined
        dense
        auto-grow
        @input="$v.message.$touch()"
        @blur="$v.message.$touch()"></v-textarea>
    </div>
    <div class="individual-outreach-footer">
      <v-checkbox
        v-if="inviteType === videoEnum"
        v-model="isReviewRequired"
        :label="$lang.individualOutreach_ReviewLabel"
        dense
        hide-details />
    </div>
  </p-modal>
</template>
<script>
  import { required, email, maxLength } from "vuelidate/lib/validators";
  import {
    CREATE_EMAIL_INVITE_REQUEST,
    CREATE_CALL_INVITE_REQUEST,
    CREATE_VIDEO_INVITE_REQUEST,
  } from "@/store/actions.type.js";
  import { GET_BRANDING, GET_CURRENT_PARENTS } from "@/store/getters.type.js";
  import { mapGetters } from "vuex";
  import emailSrc from "@/assets/email.svg";
  import videoSrc from "@/assets/video.svg";
  import phoneSrc from "@/assets/phone.svg";
  import { toFullName } from "@/shared/utils.js";
  import PhoneInput from "@/components/common/vuetify/PhoneInput.vue";
  import Avatar from "@/components/common/vuetify/Avatar.vue";
  import IconTooltip from "@/components/common/vuetify/IconTooltip.vue";

  const MAX_MESSAGE_LENGTH = 500;

  const isValidNumber = (value, vm) => {
    return vm.$refs?.phone?.telephoneInput.isValidNumber() ?? false;
  };

  export default {
    name: "request-outreach-modal",

    components: {
      PhoneInput,
      Avatar,
      IconTooltip,
    },

    props: {
      value: {
        type: Boolean,
        required: true,
      },
      user: {
        type: Object,
        default: null,
      },
    },

    data() {
      return {
        message: "",
        firstName: "",
        lastName: "",
        email: "",
        number: "",
        inviteType: CREATE_EMAIL_INVITE_REQUEST,
        callEnum: CREATE_CALL_INVITE_REQUEST,
        videoEnum: CREATE_VIDEO_INVITE_REQUEST,
        isReviewRequired: true,
        saving: false,
        selectedAmbassador: {},
        errors: {
          selectedAmbassador: {
            required: this.$lang.individualOutreach_AmbassadorErrorRequired,
          },
          firstName: {
            required: this.$lang.individualOutreach_FirstNameErrorRequired,
          },
          lastName: {
            required: this.$lang.individualOutreach_LastNameErrorRequired,
          },
          email: {
            required: this.$lang.individualOutreach_EmailErrorRequired,
            email: this.$lang.general_ErrorEmailFormat,
          },
          number: {
            required: this.$lang.individualOutreach_PhoneNumberErrorRequired,
            isValidNumber: this.$lang.individualOutreach_ErrorInvalidNumber,
          },
          message: {
            maxLength:
              this.$lang.general_ErrorMaxLength.format(MAX_MESSAGE_LENGTH),
          },
        },
      };
    },

    validations() {
      const validators = {
        firstName: { required },
        lastName: { required },
        email: { required, email },
        message: { maxLength: maxLength(MAX_MESSAGE_LENGTH) },
      };
      if (!this.user) {
        validators.selectedAmbassador = { required };
      }
      if (this.inviteType === this.callEnum) {
        validators.number = { required, isValidNumber };
      }
      return validators;
    },

    computed: {
      ...mapGetters({
        branding: GET_BRANDING,
        ambassadors: GET_CURRENT_PARENTS,
      }),
      inviteTypeOptions() {
        let options = [
          {
            label: this.$lang.outreachPage_InviteTypeEmail,
            value: CREATE_EMAIL_INVITE_REQUEST,
            imgSrc: emailSrc,
          },
          {
            label: this.$lang.outreachPage_InviteTypeCall,
            value: CREATE_CALL_INVITE_REQUEST,
            imgSrc: phoneSrc,
          },
          {
            label: this.$lang.outreachPage_InviteTypeVideo,
            value: CREATE_VIDEO_INVITE_REQUEST,
            imgSrc: videoSrc,
            disabled: !this.branding?.features?.allowVideoOutreach,
            disabledMessage: !this.branding?.features?.allowVideoOutreach
              ? this.$lang.campaignOutreach_VideoOutreachDisabledMessage
              : "",
          },
        ];

        return options;
      },
      selectedAmbassadorErrors() {
        return this.getErrors("selectedAmbassador");
      },
      firstNameErrors() {
        return this.getErrors("firstName");
      },
      lastNameErrors() {
        return this.getErrors("lastName");
      },
      emailErrors() {
        return this.getErrors("email");
      },
      phoneNumberErrors() {
        return this.getErrors("number");
      },
      messageErrors() {
        return this.getErrors("message");
      },
    },

    watch: {
      value(isOpen) {
        if (this.user && isOpen) {
          this.selectedAmbassador = this.ambassadors.find(
            (ambassador) => ambassador.email === this.user.email
          );
        }
      },
    },

    methods: {
      async promptInvite() {
        let data = [
          {
            invitee: {
              first: this.firstName,
              last: this.lastName,
              email: this.email,
              number: this.number,
            },
            inviterID:
              this.selectedAmbassador._id || this.selectedAmbassador.id,
            organization: this.branding.name,
            notes: this.message,
            autoAccept: !this.isReviewRequired,
          },
        ];

        const payload = {
          inviteArray: data,
          organization: this.branding.name,
        };

        const response = await this.$store.dispatch(this.inviteType, payload);

        if (response) {
          this.$toasted.show(
            `Invite request sent to ${
              this.selectedAmbassador.first || this.selectedAmbassador.firstName
            } ${
              this.selectedAmbassador.last || this.selectedAmbassador.lastName
            }.`,
            this.$toastedSuccess
          );
          if (process.env.VUE_APP_API_KEY) {
            this.$amplitude
              .getInstance()
              .logEvent("INVITE_REQUESTED", { scheduled: false });
          }

          this.$emit("input", false);
        } else {
          this.$toasted.show(
            `Uh oh! There was an issue.`,
            this.$toastedFailure
          );
        }

        this.saving = false;
      },

      getFullName(first, last) {
        return toFullName(first, last);
      },

      getErrors(property) {
        const errors = [];
        if (!Object.hasOwn(this.$v, property) || !this.$v[property].$dirty) {
          return errors;
        }
        Object.entries(this.errors[property]).forEach(([key, value]) => {
          !this.$v[property][key] && errors.push(value);
        });
        return errors;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .individual-outreach {
    &-toggle {
      margin: 1.25rem 0 2.5rem 0;
    }

    &-select {
      margin: 0 0 0.5rem 0;

      &-avatar {
        margin: 0.25rem 0.875rem 0.25rem 0;
      }
    }

    &-row {
      display: flex;
      gap: 1rem;

      & > * {
        width: 50%;
      }
    }

    &-footer {
      display: flex;
      align-items: center;
      height: 31px;
    }
  }
</style>
